import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import * as Routes from '../../routes'
import { Theme } from '../../theme'
import { StoryCard } from '../../components/StoryCard'
import { HomePageContext } from './Homepage'
import { LayoutContainer } from '../../components/LayoutContainer'

const useStyles = createUseStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    columnGap: theme.spacing.L,
    [theme.breakpoints.down('md')]: {
      columnGap: theme.spacing.M,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  sectionTitleContainer: {
    marginBottom: theme.spacing.L,
    marginTop: 2 * theme.spacing.L,
    [theme.breakpoints.down('md')]: {
      marginTop: 2 * theme.spacing.L,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.L,
      marginBottom: theme.spacing.M,
    },
  },
  sectionTitle: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey700,
  },
  allBikesButton: {
    ...theme.typography.bebasButton(26),
    color: theme.colors.primaryWhite,
    textDecoration: 'none',
    background: theme.colors.primaryBlack,
    borderRadius: 2,
    padding: '12px 24px',
    letterSpacing: 0.8,
  },
  buttonContainer: {
    ...theme.traits.flexCentering,
    marginTop: 72,
    marginBottom: 176,
    [theme.breakpoints.down('md')]: {
      marginBottom: 91.42,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2 * theme.spacing.L,
      marginTop: theme.spacing.S,
    },
  },
}))

export const HomeContentSection: React.FC = () => {
  const classes = useStyles()
  const data = useContext(HomePageContext)

  const content = data?.bikeLineupCard
  const sectionTitle = data?.contentTitle

  return (
    <LayoutContainer>
      <div className={classes.sectionTitleContainer}>
        <h2 className={classes.sectionTitle}>{sectionTitle}</h2>
      </div>
      <div className={classes.mainContainer}>
        {content &&
          content.map((content) => (
            <StoryCard
              title={content?.lineupTitle || ''}
              href={content?.lineUpLink || ''}
              hrefLabel="View All"
              imgSrc={(content?.image && content?.image[0]?.url) || ''}
              subtitle2={content?.description || ''}
            />
          ))}
      </div>
      <div className={classes.buttonContainer}>
        <Link to={Routes.bikes} className={classes.allBikesButton}>
          View All Bikes
        </Link>
      </div>
    </LayoutContainer>
  )
}
