import * as React from 'react'

export const RoadBikesIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.24 4.733a1.25 1.25 0 011.222-.983c1.243 0 3.9.22 6.27.974 1.18.375 2.39.913 3.315 1.7.955.812 1.661 1.946 1.606 3.412-.052 1.374-.68 2.44-1.46 3.275-.752.808-1.713 1.47-2.534 2.034l-.03.02c-.86.592-1.567 1.077-2.08 1.607-.493.507-.688.934-.688 1.389 0 .02.01.182.311.502.296.315.77.67 1.427 1.047 1.309.752 3.106 1.46 4.972 2.074a72.409 72.409 0 005.114 1.456 78.84 78.84 0 001.966.46l.362.078a1.25 1.25 0 01-.516 2.446L28.75 25l-.253 1.224h-.004l-.006-.002h-.003l-.035-.008-.132-.028c-.114-.025-.28-.06-.491-.108a81.285 81.285 0 01-1.742-.412 74.98 74.98 0 01-5.294-1.508c-1.92-.631-3.906-1.402-5.436-2.28-.762-.438-1.471-.936-2.004-1.503-.527-.56-.99-1.307-.99-2.214 0-1.331.636-2.35 1.394-3.13.692-.714 1.584-1.326 2.361-1.858.043-.03.086-.06.129-.088.859-.59 1.586-1.105 2.121-1.679.51-.546.767-1.067.79-1.665.019-.507-.192-.957-.728-1.413-.564-.48-1.418-.893-2.453-1.222a13.818 13.818 0 00-.235-.072c.362.578.58 1.262.58 2.081 0 .901-.486 1.579-1.016 2.046-.52.46-1.197.823-1.885 1.128-1.03.456-2.327.88-3.612 1.3-.42.137-.837.274-1.244.41-1.728.583-3.316 1.192-4.462 1.943-1.146.75-1.594 1.465-1.594 2.219l-.001.065c-.065 1.234.528 2.596 1.272 3.758a13.456 13.456 0 001.096 1.474l.373.413a1.25 1.25 0 01-1.819 1.714l.892-.876c-.892.876-.891.877-.892.876l-.002-.002-.003-.004-.011-.01-.035-.037a13.788 13.788 0 01-.519-.586c-.325-.389-.757-.945-1.186-1.614-.826-1.29-1.759-3.19-1.664-5.204.014-1.971 1.286-3.337 2.723-4.278 1.443-.945 3.309-1.638 5.034-2.22.472-.158.932-.309 1.375-.454 1.246-.407 2.363-.773 3.266-1.173.607-.269 1.01-.51 1.244-.716a.74.74 0 00.17-.191c-.004-.522-.208-.903-.8-1.35-.658-.499-1.615-.938-2.948-1.55l-.13-.06a1.25 1.25 0 01-.7-1.403zm4.585 4.347l-.003.012.003-.012z"
          fill={props.color || '#989898'}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
