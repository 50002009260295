import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { HeroImage } from '../../components/HeroImage'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { Theme } from '../../theme'
import { classNames, fetchApiData, getHeaderFlexAlignment } from '../../utils'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import {
  KeyFeaturesPageQuery_entry_keyFeaturesPage_keyFeaturesPage_Entry as IKeyFeaturesPageQuery,
  KeyFeaturesPageQuery_entry_keyFeaturesPage_keyFeaturesPage_Entry_relatedKeyFeatures_keyFeature_keyFeature_Entry as IKeyFeatureEntry,
  KeyFeaturesPageQuery_entry_keyFeaturesPage_keyFeaturesPage_Entry_relatedKeyFeatures_keyFeature_keyFeature_Entry_relatedProducts as IRelatedProducts,
} from '../../api-types/KeyFeaturesPageQuery'
import { productEntry as ProductEntry } from '../../api-types/productEntry'
import { useQuery } from 'react-query'
import { Entry } from '../../types'
import { ProductCarousel } from '../../components/ProductCarousel'
import { LayoutContainer } from '../../components/LayoutContainer'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router'

interface RelatedProducts extends IRelatedProducts {
  productEntries: (ProductEntry | null)[]
}

interface KeyFeatureEntry extends IKeyFeatureEntry {
  relatedProducts: (RelatedProducts | null)[]
}

interface KeyFeaturesPageQuery extends IKeyFeaturesPageQuery {
  relatedKeyFeatures: (KeyFeatureEntry | null)[]
}

const useStyles = createUseStyles((theme: Theme) => ({
  content: {
    ...theme.traits.gridLayout,
    paddingLeft: theme.spacing.L,
    paddingRight: theme.spacing.L,
    marginBottom: theme.spacing.XXXL,
  },
  header: {
    ...theme.traits.gridColumn({
      lgCols: 6,
      lgStart: 4,
    }),
    marginTop: theme.spacing.XXL,
    marginBottom: 2 * theme.spacing.XXL,
  },
  heading: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey700,
    textAlign: 'center',
    marginBottom: theme.spacing.M,
  },
  subheading: {
    ...theme.typography.urw(21),
    color: theme.colors.secondaryGrey700,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.urw(16),
    },
  },
  featureList: {
    ...theme.traits.gridColumn({
      lgCols: 4,
      mdCols: 2,
      smCols: 4,
    }),
    maxHeight: 960,
    paddingRight: theme.spacing.L,
    [theme.breakpoints.up('sm')]: {
      overflow: 'scroll',
    },
  },
  featureList2: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.L,
      paddingRight: 0,
    },
  },
  listItem: {
    ...theme.typography.bebas(18),
    color: theme.colors.secondaryGrey700,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.S}px ${theme.spacing.M}px`,
    borderRadius: 2,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeItem: {
    backgroundColor: theme.colors.secondaryGrey900,
    color: theme.colors.primaryWhite,
    '& > svg': {
      stroke: theme.colors.primaryWhite,
    },
  },
  featureDetail: {
    width: '100%',
    ...theme.traits.gridColumn({
      lgCols: 8,
      lgStart: 5,
      mdCols: 6,
      mdStart: 3,
      smCols: 4,
      smStart: 1,
    }),
  },
  featureTitle: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey900,
    marginBottom: theme.spacing.M,
  },
  featureMedia: {
    width: '100%',
    marginBottom: theme.spacing.M,
  },
  featureHowAndWhy: {
    display: 'flex',
    marginBottom: theme.spacing.XXL,
    '& > *:not(:last-child)': {
      marginRight: theme.spacing.L,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  whyList: {
    listStyleType: 'initial',
    marginLeft: theme.spacing.M,
  },
  featureSubtitle: {
    ...theme.typography.bebas(24),
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.S,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  featureBody: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '24px',
    },
  },
  iFrameContainer: {
    height: 587,
    [theme.breakpoints.down('md')]: {
      height: 360,
    },
    [theme.breakpoints.down('sm')]: {
      height: 180,
    },
  },
}))

export const FeaturesPage: React.FC = () => {
  const classes = useStyles()
  const [activeFeatureKey, setActiveFeatureKey] = useState(0)
  const [activeHeroEmbed, setActiveHeroEmbed] = useState('')
  const { hash } = useLocation()
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const [featureIsSetFlag, setFeatureIsSetFlag] = useState(false)
  let featureRef: HTMLUListElement | null = null

  const { data: pageData, isLoading } = useQuery('salsa/tech', () =>
    fetchApiData<Entry<KeyFeaturesPageQuery>>('salsa/tech'),
  )

  const data = pageData?.entry?.relatedKeyFeatures[activeFeatureKey]
  const activeFeatureHero = data?.hero && data?.hero[0]
  const activeFeatureURL =
    (activeFeatureHero &&
      activeFeatureHero?.image &&
      activeFeatureHero?.image[0] &&
      activeFeatureHero?.image[0]?.url) ||
    ''

  const isJson = /\.json$/i.test(activeFeatureURL)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isJson) {
      fetch(activeFeatureURL, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        referrerPolicy: 'no-referrer',
      })
        .then((res) => res.json())
        .then((data) => setActiveHeroEmbed(data.url))
    }

    if (!pageData) return

    if (hash && !featureIsSetFlag) {
      const featureIndex = pageData?.entry?.relatedKeyFeatures.findIndex(
        (item) => item?.title === hash.replace('#', ''),
      )

      if (featureIndex && featureIndex >= 0) {
        setFeatureIsSetFlag(true)
        setActiveFeatureKey(featureIndex)
        featureRef?.scrollIntoView()
      }
    }
  }, [
    setActiveHeroEmbed,
    isJson,
    activeFeatureURL,
    pageData,
    hash,
    data,
    featureRef,
    isLoading,
    featureIsSetFlag,
  ])

  if (isLoading) {
    return <LoadingIndicator />
  }

  const embedCodeVimeoOrYoutube = activeHeroEmbed?.split('/')
  let embedCode = ''
  let videoURL = ''

  if (embedCodeVimeoOrYoutube[2] === 'vimeo.com') {
    embedCode = embedCodeVimeoOrYoutube[3]
    videoURL = `https://player.vimeo.com/video/${embedCode}`
  } else {
    embedCode = activeHeroEmbed.split('=')[1]
    videoURL = `https://www.youtube.com/embed/${embedCode}?autoplay=1&mute=1`
  }

  const hero = pageData?.entry?.hero && pageData?.entry?.hero[0]
  const heroImage = hero?.image && hero?.image[0]
  const missionStatement =
    pageData?.entry?.missionStatement && pageData?.entry?.missionStatement[0]

  return (
    <>
      <HeroImage
        src={heroImage?.url || ''}
        alt={heroImage?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
      >
        <ProductTitle>{hero?.heading}</ProductTitle>
      </HeroImage>
      <LayoutContainer>
        <Breadcrumbs />
        <div className={classes.content}>
          <div className={classes.header}>
            <h2 className={classes.heading}>{missionStatement?.heading}</h2>
            <div
              className={classes.subheading}
              dangerouslySetInnerHTML={{
                __html: missionStatement?.body || '',
              }}
            />
          </div>
          <ul
            className={classNames(classes.featureList, classes.featureList2)}
            ref={(ref) => {
              featureRef = ref
            }}
          >
            {pageData?.entry?.relatedKeyFeatures.map((feature, i) => {
              const isActive = i === activeFeatureKey
              return (
                <li
                  key={i}
                  className={classNames(
                    classes.listItem,
                    isActive && classes.activeItem,
                  )}
                  onClick={() => setActiveFeatureKey(i)}
                >
                  {feature?.title}
                  {isActive && <FeatherIcon name="chevron-right" size={32} />}
                </li>
              )
            })}
          </ul>
          <div className={classes.featureDetail}>
            <h3 className={classes.featureTitle}>{data?.title}</h3>
            {activeHeroEmbed && (
              <div className={classes.iFrameContainer}>
                <iframe
                  width="100%"
                  height="100%"
                  src={videoURL}
                  title="Salsa Video Player"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  style={{ zIndex: 1000 }}
                ></iframe>
              </div>
            )}
            {!activeHeroEmbed && (
              <img
                className={classes.featureMedia}
                src={
                  (activeFeatureHero?.image &&
                    activeFeatureHero?.image[0]?.url) ||
                  ''
                }
                alt=""
              />
            )}

            <div className={classes.featureHowAndWhy}>
              <div style={{ flex: 1 }}>
                <h4 className={classes.featureSubtitle}>
                  {(data?.sectionHeadings &&
                    data?.sectionHeadings[0]?.headingText) ||
                    'How it works'}
                </h4>
                <p className={classes.featureBody}>
                  {data?.keyFeatureDescription}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <h4 className={classes.featureSubtitle}>
                  {(data?.sectionHeadings &&
                    data?.sectionHeadings[1]?.headingText) ||
                    'Why it matters'}
                </h4>
                <ul className={classes.whyList}>
                  {data?.keyFeatureBulletPoints?.map((item, index) => (
                    <li key={index} className={classes.featureBody}>
                      {item?.bulletPointText}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {data?.relatedProducts &&
              data?.relatedProducts[0]?.productEntries &&
              data?.relatedProducts[0]?.productEntries.length > 0 && (
                <div>
                  <h4 className={classes.featureSubtitle}>
                    {(data?.sectionHeadings &&
                      data?.sectionHeadings[2]?.headingText) ||
                      'As seen on'}
                  </h4>
                  <ProductCarousel
                    slidesToShow={isMobile ? 1 : 3}
                    entries={data?.relatedProducts[0]?.productEntries}
                  />
                </div>
              )}
          </div>
        </div>
      </LayoutContainer>
    </>
  )
}
