import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'
import { FeatherIcon } from './icons/FeatherIcon'

interface AccordionItemProps {
  title: string
  as?: keyof JSX.IntrinsicElements
  startExpanded?: boolean
  className?: string
  titleClassName?: string
}

const useStyles = createUseStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.secondaryGrey100,
    padding: `${theme.spacing.S}px ${theme.spacing.M}px`,
    borderRadius: 2,
    '&:hover': { cursor: 'pointer' },
  },
  title: {
    ...theme.typography.urwBold(18),
    fontWeight: 600,
    border: 'none',
    fontStyle: 'inherit',
    backgroundColor: 'inherit',
    textTransform: 'uppercase',
    textAlign: 'left',
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex',
    cursor: 'pointer',
  },
  chevron: {
    stroke: theme.colors.secondaryGrey500,
    strokeWidth: 3,
    marginLeft: 'auto',
  },
}))

export const AccordionItem: React.FC<AccordionItemProps> = (props) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(Boolean(props.startExpanded))

  const toggleExpanded = () => setIsOpen((prev) => !prev)

  const ContainerComponent = props.as ?? 'div'

  return (
    <ContainerComponent className={props.className}>
      <h3
        tabIndex={0}
        className={classNames(classes.titleContainer, props.titleClassName)}
        onClick={toggleExpanded}
        onKeyPress={toggleExpanded}
      >
        <button
          className={classes.title}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-controls={props.title.replace(/ /g, '_').toLowerCase()}
        >
          {props.title}
          <FeatherIcon
            className={classes.chevron}
            name={isOpen ? 'chevron-up' : 'chevron-down'}
            size={24}
          />
        </button>
      </h3>

      {isOpen && props.children}
    </ContainerComponent>
  )
}
