import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.secondaryGrey100,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing.L,
    [theme.breakpoints.down('md')]: {
      // TODO implement tablet/mobile styles
      display: 'none',
    },
  },
  navLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    width: 180,
    height: '100%',
    justifyContent: 'space-between',
    ':last-of-type': {
      marginRight: 0,
    },
    color: theme.colors.secondaryGrey500,
    '& path': {
      fill: theme.colors.secondaryGrey500,
    },
  },
  navLinkActive: {
    color: '#000000',
    '& path': {
      fill: '#000000',
    },
  },
  label: {
    marginTop: theme.spacing.M,
    textAlign: 'center',
  },
}))

interface Props {
  routes: {
    route: string
    label: string
    icon: JSX.Element
  }[]
}

export const CategoryNav: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { routes } = props
  return (
    <nav aria-label="category">
      <ul id={`${pathname.substring(1)}_menu`} className={classes.container}>
        {routes.map((route) => (
          <li key={route.route}>
            <NavLink
              key={route.route}
              to={route.route}
              className={classes.navLink}
              activeClassName={classes.navLinkActive}
            >
              {route.icon}
              <span className={classes.label}>{route.label}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
