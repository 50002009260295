import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

interface Props {
  name: string
  imageUrl?: string
  title?: string
  datePosted?: Date
  readDuration?: string | number // minutes
}

const useStyles = createUseStyles((theme: Theme) => ({
  journalAuthorSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.L,
  },
  avatar: {
    height: 80,
    width: 80,
    borderRadius: '50%',
    backgroundSize: 'cover',
    marginRight: theme.spacing.L,
  },
  name: {
    ...theme.typography.urwBold(18),
    lineHeight: '28px',
  },
  jobTitle: {
    ...theme.typography.urwBold(18),
    color: theme.colors.secondaryGrey500,
    lineHeight: '28px',
  },
  datePosted: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
    lineHeight: '30px',
  },
  seperator: {
    margin: '0 8px',
  },
}))

export const JournalAuthorSection: React.FC<Props> = (props) => {
  const { name, imageUrl, title, datePosted, readDuration } = props

  const classes = useStyles()

  const date = datePosted
    ? new Intl.DateTimeFormat('en', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }).format(new Date(datePosted))
    : ''

  return (
    <div className={classes.journalAuthorSection}>
      {imageUrl && (
        <div
          className={classes.avatar}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}
      <div>
        <h3>
          <span className={classes.name}>{name}</span>
          {title && (
            <>
              <span className={classNames(classes.jobTitle, classes.seperator)}>
                /
              </span>
              <span className={classes.jobTitle}>{title}</span>
            </>
          )}
        </h3>
        <p className={classes.datePosted}>
          <span>{date}</span>
          {readDuration && (
            <>
              <span className={classes.seperator}>/</span>
              <span>{`${readDuration} min read`}</span>
            </>
          )}
        </p>
      </div>
    </div>
  )
}
