import * as React from 'react'

export const Accesories: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={31}
      height={23}
      viewBox="0 0 31 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.5 1.5C.5.81 1.06.25 1.75.25h27.494c.69 0 1.25.56 1.25 1.25v16.81l.004.07a4.499 4.499 0 01-.135 1.206 4.166 4.166 0 01-1.607 2.38c-.98.71-1.982.832-2.727.77a4.147 4.147 0 01-1.165-.272 2.603 2.603 0 01-.086-.037l-.012-.005L1.258 12.35A1.25 1.25 0 01.5 11.2V1.5zM28 18.476a.438.438 0 01-.001-.017v-.002.012l.001.003v.003zm-2.198 1.67l.004.002.078.028c.081.025.204.056.353.068.286.024.658-.016 1.052-.302.395-.286.57-.65.65-.97a2.004 2.004 0 00.06-.503v-.01a1.248 1.248 0 01-.005-.105V6.5H28L27.994 4V2.75H3v7.627l22.765 9.753.037.016zm.014.007l-.003-.001-.006-.003-.007-.003h.002l.014.007zM3 6.5h24.994V4H3v2.5zm1.194 2.051c0-.69.56-1.25 1.25-1.25h19.801a1.25 1.25 0 01.061 2.499l.488 1.21a1.25 1.25 0 01-1.16 1.717H23a1.25 1.25 0 01-1.192-1.626l.41-1.3H5.444c-.69 0-1.25-.56-1.25-1.25z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
