import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  loadingIndicator: {
    ...theme.traits.flexCentering,
  },
  loader: {
    backgroundColor: theme.colors.secondaryGrey700,
    borderRadius: '50%',
    height: 40,
    width: 40,
    animation: 'loader 1s infinite ease-in-out',
  },
}))

export const LoadingIndicator: React.FC<{
  className?: string
  useMinHeight?: boolean
}> = ({ className, useMinHeight = true }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const classes = useStyles()

  useEffect(() => {
    if (ref.current && useMinHeight) {
      const offsetTop = ref.current.offsetTop
      ref.current.style.minHeight = `calc(100vh - ${Number(offsetTop)}px)`
    }
  }, [useMinHeight])

  return (
    <div className={classNames(classes.loadingIndicator, className)} ref={ref}>
      <div className={classes.loader} />
    </div>
  )
}
