import React from 'react'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../theme'
import {
  StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry,
  StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry_journalAuthor_journalAuthor_default_Entry as JournalAuthor,
  StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry_storyBuilderField_storyBuilderField_storyContentSection_BlockType as StoryContentSection,
  StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry_storyBuilderField_storyBuilderField_sideBySideContent_BlockType as SideBySideContent,
  StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry_storyBuilderField_storyBuilderField_imageCarousel_BlockType as ImageCarousel,
  StoryPageQuery_entry_story_story_Entry_journalCategoryField as JournalCategoryField,
} from '../api-types/StoryPageQuery'
import { HeroImage } from './HeroImage'
import { ProductTitle } from './typography/ProductTitle'
import { getHeaderFlexAlignment, getMaxWidth } from '../utils'
import { LayoutContainer } from './LayoutContainer'
import { Breadcrumbs } from './Breadcrumbs'
import { JournalAuthorSection } from './JournalAuthorSection'
import { FixedHeightCarousel } from './carousel/FixedHeightCarousel'
import { CallToAction } from './CallToAction'
import * as routes from '../routes'
import { Button } from './Button'
import { Link } from 'react-router-dom'
import { useRouteMatch } from 'react-router'

interface StoryChapter
  extends StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry {
  journalAuthor: JournalAuthor[] | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  title: {
    ...theme.typography.bebas(37),
    marginTop: theme.spacing.XXL,
    paddingBottom: theme.spacing.L,
  },
  contained: {
    maxWidth: 780,
  },
  headingSection: {
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  rawBodyContainer: {
    marginBottom: theme.spacing.L,
    paddingTop: theme.spacing.L,
    paddingBottom: theme.spacing.L,
    '& p': {
      marginBottom: theme.spacing.M,
    },
    '& ul, & ol': {
      listStyle: 'initial',
      paddingLeft: theme.spacing.L,
    },
  },
  rawBodyContent: {
    ...theme.typography.urw(18),
    paddingTop: theme.spacing.L,
    overflow: 'auto',
    '& img': {
      width: '100%',
    },
    '& figure': {
      marginLeft: `${theme.spacing.L}px !important`,
      marginRight: `${theme.spacing.L}px !important`,
    },
    '& td': {
      padding: theme.spacing.M,
    },
    [theme.breakpoints.down('sm')]: {
      '& figure': {
        maxWidth: 'unset !important',
        float: 'none !important',
        width: `calc(100% - ${theme.spacing.L * 2}px) !important`,
        '& img': {
          height: 'auto',
        },
      },
    },
  },
  sideBySideSection: {
    display: 'flex',
    ...theme.typography.urw(18),
    '& > div': {
      flex: 1,
      '&:first-of-type': {
        marginRight: theme.spacing.L,
      },
    },
    '& figure': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > div': {
        '& img': {
          width: '100%',
        },
        '&:first-of-type': {
          marginBottom: theme.spacing.L,
        },
      },
    },
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${theme.colors.secondaryGrey300}`,
    paddingTop: theme.spacing.M,
    marginBottom: theme.spacing.L,
  },
  tag: {
    ...theme.typography.urwBold(14),
    color: theme.colors.primaryWhite,
    backgroundColor: theme.colors.primaryRed,
    padding: `${theme.spacing.XS}px ${theme.spacing.M}px`,
    borderRadius: 4,
    marginRight: theme.spacing.S,
    marginBottom: theme.spacing.S,
  },
  nextStory: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 50%)',
    marginBottom: theme.spacing.XXL,
    marginLeft: theme.spacing.L,
    marginRight: theme.spacing.L,
    marginTop: 136,
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%',
      gridTemplateRows: 'repeat(2, 50%)',
    },
  },
  nextStoryActionContainer: {
    ...theme.traits.flexCentering,
    ...theme.typography.bebas(31),
    flexDirection: 'column',
    backgroundColor: theme.colors.secondaryGrey100,
    padding: theme.spacing.L,
    '& a': {
      marginTop: theme.spacing.L,
    },
    '& h2': {
      textAlign: 'center',
    },
  },
  nextStoryButton: {
    backgroundColor: theme.colors.primaryBlack,
  },
}))

export const StoryPart: React.FC<{
  storyPart: StoryChapter | undefined
  nextPart: StoryChapter | undefined
  readDuration: string | undefined
  tags: (JournalCategoryField | null)[] | null
}> = ({ storyPart, nextPart, readDuration, tags }) => {
  const classes = useStyles()
  const match = useRouteMatch<{ storySlug: string; partIndex: string }>()

  if (!storyPart) {
    return null
  }

  const hero = storyPart?.hero && storyPart?.hero[0]
  const heroImage = hero?.image && hero?.image[0]
  const author = storyPart?.journalAuthor && storyPart?.journalAuthor[0]
  const datePosted = new Date(storyPart?.journalDate)

  return (
    <>
      <HeroImage
        src={heroImage?.url || ''}
        alt={heroImage?.alttext || ''}
        type={heroImage?.mimeType || ''}
        justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
      >
        <ProductTitle>{hero?.heading}</ProductTitle>
      </HeroImage>
      <LayoutContainer>
        <Breadcrumbs />
        <div className={classes.headingSection}>
          <h2 className={classes.title}>{storyPart?.title}</h2>
          <JournalAuthorSection
            name={author?.title || ''}
            title={author?.journalAuthorJobTitle || ''}
            readDuration={readDuration}
            datePosted={datePosted}
            imageUrl={
              (author?.journalAuthorAvatar &&
                author?.journalAuthorAvatar[0]?.url) ||
              ''
            }
          />
        </div>
      </LayoutContainer>
      <div>
        {storyPart?.storyBuilderField?.map((section) => {
          switch (section?.__typename) {
            case 'storyBuilderField_storyContentSection_BlockType': {
              const data = section as StoryContentSection
              return (
                <LayoutContainer
                  key={data?.uid}
                  containerClass={classes.rawBodyContainer}
                  contentClass={classes.rawBodyContent}
                  containerStyle={{
                    backgroundColor:
                      data?.backgroundColor || theme.colors.primaryWhite,
                  }}
                  contentStyle={{
                    maxWidth: getMaxWidth(data?.contentWidth),
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.contentHTML || '',
                    }}
                  />
                </LayoutContainer>
              )
            }
            case 'storyBuilderField_sideBySideContent_BlockType': {
              const data = section as SideBySideContent
              return (
                <LayoutContainer
                  key={data?.uid}
                  containerClass={classes.rawBodyContainer}
                  contentClass={classes.sideBySideSection}
                  containerStyle={{
                    backgroundColor: data?.backgroundColor || 'initial',
                  }}
                  contentStyle={{
                    alignItems: data?.verticalAlignment || 'center',
                    maxWidth: getMaxWidth(data?.contentWidth),
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.leftContentHTML || '',
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.rightContentHTML || '',
                    }}
                  />
                </LayoutContainer>
              )
            }
            case 'storyBuilderField_imageCarousel_BlockType': {
              const data = section as ImageCarousel
              return (
                <LayoutContainer
                  key={data?.uid}
                  containerClass={classes.rawBodyContainer}
                  contentStyle={{ maxWidth: getMaxWidth(data?.contentWidth) }}
                >
                  <FixedHeightCarousel slidesToShow={1}>
                    {data?.images?.map((image) => (
                      <img
                        key={image?.uid}
                        src={image?.url || ''}
                        alt={image?.alttext || ''}
                      />
                    ))}
                  </FixedHeightCarousel>
                </LayoutContainer>
              )
            }
            default:
              return null
          }
        })}
      </div>
      <LayoutContainer>
        <ul className={classes.tags}>
          {tags?.map((tag) => (
            <li className={classes.tag} key={tag?.uid}>
              {tag?.title}
            </li>
          ))}
        </ul>
        <JournalAuthorSection
          name={author?.title || ''}
          title={author?.journalAuthorJobTitle || ''}
          readDuration={readDuration}
          datePosted={datePosted}
          imageUrl={
            (author?.journalAuthorAvatar &&
              author?.journalAuthorAvatar[0]?.url) ||
            ''
          }
        />
      </LayoutContainer>
      {nextPart && (
        <div className={classes.nextStory}>
          <img
            src={
              (nextPart?.hero &&
                nextPart?.hero[0]?.image &&
                nextPart?.hero[0]?.image[0]?.url) ||
              ''
            }
            alt={
              (nextPart?.hero &&
                nextPart?.hero[0]?.image &&
                nextPart?.hero[0]?.image[0]?.alttext) ||
              ''
            }
          />
          <div className={classes.nextStoryActionContainer}>
            <h2 className={classes.title}>
              <span>Story title:</span>
              <br />
              <span>{nextPart?.title}</span>
            </h2>
            <Link
              to={`${routes.stories}/${match.params.storySlug}/parts/${
                match.params.partIndex
                  ? Number.parseInt(match.params.partIndex) + 1
                  : 2
              }`}
            >
              <Button className={classes.nextStoryButton}>
                View next chapter
              </Button>
            </Link>
          </div>
        </div>
      )}
      <CallToAction
        title="Looking for even More?"
        route={routes.stories}
        text="view all stories"
      />
    </>
  )
}
