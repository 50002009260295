import * as React from 'react'

export const ContactIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.75 5H6.25A3.75 3.75 0 002.5 8.75v12.5A3.75 3.75 0 006.25 25h17.5a3.75 3.75 0 003.75-3.75V8.75A3.75 3.75 0 0023.75 5zm-.835 2.5l-7.913 5.938L7.09 7.5h15.825zm.835 15H6.25c-.69 0-1.25-.56-1.25-1.25V9.062L14.25 16a1.25 1.25 0 001.5 0L25 9.062V21.25c0 .69-.56 1.25-1.25 1.25z"
        fill="#989898"
      />
    </svg>
  )
}
