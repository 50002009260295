import * as React from 'react'

export const FeaturesAndTechIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width={31}
      height={30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.764.964c3.488-1.7 7.829-1.113 10.732 1.79a9.377 9.377 0 012.363 9.288l10.787 10.8a2.495 2.495 0 010 3.53l-2.875 2.875a2.495 2.495 0 01-3.53 0L12.395 18.4c-3.145.877-6.676.087-9.154-2.392C.338 13.106-.248 8.792 1.436 5.295a1.25 1.25 0 012.01-.341l4.541 4.54L9.97 7.514 5.43 2.972A1.25 1.25 0 015.763.964zm2.86 1.667L12.62 6.63a1.25 1.25 0 010 1.768l-3.75 3.75a1.25 1.25 0 01-1.768 0l-3.99-3.992a6.85 6.85 0 001.896 6.086c1.963 1.964 4.84 2.486 7.288 1.564a1.25 1.25 0 011.324.286l11.385 11.385 2.87-2.87L16.54 13.26a1.25 1.25 0 01-.279-1.341c.976-2.481.464-5.4-1.533-7.396a6.86 6.86 0 00-6.104-1.89z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
