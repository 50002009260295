import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'
import CheckboxIcon from './icons/Checkbox.svg'

interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  checked: boolean
  rightLabel?: string
  autoFocus: boolean
}

const useStyles = createUseStyles((theme: Theme) => ({
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    height: 18,
    width: 18,
    margin: 0,
    '&:active, &:focus': {
      outline: '2px dotted black',
    },
    '&::before': {
      content: '""',
      display: 'block',
      height: 18,
      width: 18,
      border: `2px solid ${theme.colors.secondaryGrey300}`,
      borderRadius: 2,
    },
    '&:checked': {
      '&::before': {
        backgroundImage: `url(${CheckboxIcon})`,
        borderColor: theme.colors.primaryBlack,
        backgroundColor: '#fff',
        backgroundOrigin: 'border-box',
      },
    },
    '&:hover': { cursor: 'pointer' },
  },
  label: {
    ...theme.typography.urw(14),
    marginLeft: theme.spacing.S,
  },
}))

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { className, rightLabel, id, checked, autoFocus, ...restProps } = props
  const classes = useStyles()
  return (
    <div className={classNames(classes.checkbox, className)}>
      <input
        autoFocus={autoFocus}
        checked={checked}
        type="checkbox"
        className={classes.input}
        {...restProps}
        id={`checkbox-${id}`}
      />
      <label htmlFor={`checkbox-${id}`} className={classes.label}>
        {rightLabel}
      </label>
    </div>
  )
}
