import {
  Filter,
  Hierarchy,
  Platform,
  Product,
  ProductResponse,
  routeFilters,
  ServerErrorResponse,
  SortOption,
} from './product-types'
import { useInfiniteQuery, useQuery } from 'react-query'
import { fetchApiData } from './utils'
import * as routes from './routes'
import { useRouteMatch } from 'react-router'
import { WarrantyQuery_entry_warrantySection_warrantySection_Entry as WarrantyQuery } from './api-types/WarrantyQuery'
import { Entries, Entry, SearchParams } from './types'
import {
  JournalsQuery_entries_article_article_Entry as ArticleEntry,
  JournalsQuery_entries_story_story_Entry as StoryEntry,
  JournalsQuery_entries_video_video_Entry as VideoEntry,
} from './api-types/JournalsQuery'
import { UseInfiniteQueryResult } from 'react-query/types/react/types'

export type OptionValue =
  | string
  | string[]
  | Hierarchy
  | Hierarchy[]
  | number
  | boolean
  | undefined
  | null

export interface FilterState {
  [fieldId: string]: {
    [optionId: string]: OptionValue
    id?: number
  }
}

export enum SearchStrategyType {
  PRODUCT = 'PRODUCT',
  PLATFORM = 'PLATFORM',
  JOURNAL = 'JOURNAL',
}

export type SearchStrategyHook<T> = (
  path: string,
  filters: FilterState,
) => [SearchStrategyType, T | null]

export type SearchStrategyResponse =
  | ProductResponse
  | Platform[]
  | Entries<ArticleEntry | VideoEntry | StoryEntry>

export type FiltersStrategyResponse = Filter[] | null
export type ApiResponse<R> = R | ServerErrorResponse
export interface ApiFilterParams {
  [param: string]: OptionValue
}
export interface ApiSearchParams extends SearchParams, ApiFilterParams {}
/**
 *
 * Returns the filters that show up on screen based on hierarchy and path
 *
 */
export const useFilters = (
  hierarchy: Hierarchy,
  additionalFilters: ApiFilterParams = {},
  platform?: string,
): [FiltersStrategyResponse | null, SearchStrategyType] => {
  const match = useRouteMatch()

  const defaultFilterFilters =
    routeFilters[hierarchy]?.defaultFilterFilters || {}

  let filters = { ...defaultFilterFilters, ...additionalFilters }

  const { strategyType, apiFilterPath } = getTypeAndPathsForSearchStrategy(
    match.path,
  )

  if (strategyType === SearchStrategyType.PRODUCT && platform) {
    filters = { ...filters, platform }
  }

  const params = { query: filters }
  const enabled = !!apiFilterPath

  const { data, isLoading, isError } = useQuery(
    ['filters', strategyType, hierarchy, params.query],
    () =>
      fetchApiData<ApiResponse<FiltersStrategyResponse>, ApiFilterParams>(
        apiFilterPath,
        params,
      ),
    {
      refetchOnWindowFocus: false,
      enabled: enabled && Boolean(hierarchy),
      keepPreviousData: true,
    },
  )

  if (
    !enabled ||
    !data ||
    isLoading ||
    isError ||
    (data as ServerErrorResponse).error
  ) {
    return [null, strategyType]
  }

  return [data as FiltersStrategyResponse, strategyType]
}

type UseSearchResult = UseInfiniteQueryResult<
  ApiResponse<SearchStrategyResponse>
> & {
  strategyType: SearchStrategyType
}

export const useSearch = (
  hierarchy: Hierarchy,
  additionalFilters: ApiFilterParams = {},
  sort: SortOption,
  platform?: string,
): UseSearchResult => {
  const match = useRouteMatch()

  const defaultSearchFilters =
    routeFilters[hierarchy]?.defaultSearchFilters || {}

  let filters: Record<string, OptionValue> = {
    ...defaultSearchFilters,
    ...additionalFilters,
  }

  const {
    strategyType,
    apiSearchPath,
    itemsPerPage,
  } = getTypeAndPathsForSearchStrategy(match.path)

  if (strategyType === SearchStrategyType.PRODUCT && platform) {
    filters = { ...filters, platform }
  }

  if (strategyType === SearchStrategyType.JOURNAL) {
    const categoryIds = []

    for (const optionValues of Object.values(filters)) {
      if (Array.isArray(optionValues)) {
        categoryIds.push(...optionValues)
      }
    }

    filters = { categoryIds: categoryIds }
  }

  const params = {
    query: {
      ...filters,
      ascending: sort.ascending,
      sortBy: sort.value.concat(sort.useKeywordSearch ? '.keyword' : ''),
    },
  }

  const result = useInfiniteQuery<ApiResponse<SearchStrategyResponse>>(
    ['search', strategyType, hierarchy, params.query],
    (queryParams) =>
      fetchApiData<ApiResponse<SearchStrategyResponse>, ApiSearchParams>(
        apiSearchPath,
        {
          query: {
            ...params.query,
            limit: itemsPerPage,
            offset: 0,
            ...queryParams.pageParam,
          },
        },
      ),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: Boolean(hierarchy),
      getNextPageParam: (lastPage, pages): ApiSearchParams | undefined => {
        let offset = pages.length * itemsPerPage

        // Product and platforms searches with elasticsearch uses page number instead of offset
        if (
          strategyType === SearchStrategyType.PRODUCT ||
          strategyType === SearchStrategyType.PLATFORM
        ) {
          offset = pages.length
        }

        const totalHits =
          (lastPage as Entries<ArticleEntry>).entryCount ||
          (lastPage as ProductResponse).totalHits ||
          (lastPage as Platform[]).length

        if (totalHits <= itemsPerPage * pages.length) {
          return undefined
        }

        return {
          ...params.query,
          offset,
          limit: itemsPerPage,
        }
      },
    },
  )

  return {
    ...result,
    strategyType,
  }
}

/*
  Get the api endpoints for each page, also return SearchStrategyType
  enum value which is used to dynamically render the correct cards in the UI
 */
const getTypeAndPathsForSearchStrategy = (
  pageRoute: string,
): {
  strategyType: SearchStrategyType // The type of element we're searching for
  apiSearchPath: string // The API endpoint for fetching data
  apiFilterPath: string // The API endpoint for the filter fields
  itemsPerPage: number
} => {
  switch (pageRoute) {
    case routes.bikePlatformSearch:
    case routes.allBikes:
    case routes.archivedBikes:
    case routes.gear:
    case routes.allGear:
    case routes.clothing:
    case routes.accessories:
    case routes.archivedGear:
    case routes.parts:
      return {
        strategyType: SearchStrategyType.PRODUCT,
        apiSearchPath: 'products',
        apiFilterPath: 'filters',
        itemsPerPage: 200,
      }
    case routes.mountainBikes:
    case routes.roadBikes:
    case routes.fatTireBikes:
    case routes.eBikes:
      return {
        strategyType: SearchStrategyType.PLATFORM,
        apiSearchPath: 'platforms',
        apiFilterPath: 'filters',
        itemsPerPage: 200,
      }

    case routes.journal:
      return {
        strategyType: SearchStrategyType.JOURNAL,
        apiSearchPath: 'journals',
        apiFilterPath: '',
        itemsPerPage: 8,
      }
    case routes.videos:
      return {
        strategyType: SearchStrategyType.JOURNAL,
        apiSearchPath: 'videos',
        apiFilterPath: '',
        itemsPerPage: 8,
      }
    case routes.stories:
      return {
        strategyType: SearchStrategyType.JOURNAL,
        apiSearchPath: 'stories',
        apiFilterPath: '',
        itemsPerPage: 8,
      }
    case routes.articles:
    default:
      return {
        strategyType: SearchStrategyType.JOURNAL,
        apiSearchPath: 'articles',
        apiFilterPath: '',
        itemsPerPage: 8,
      }
  }
}

/*
 *   Search for a single product by it's code/model number
 * */
export const useProduct = (code: string): Product | null => {
  const { data } = useQuery(
    ['products', code],
    () => fetchApiData<Product>(`products/${code}`),
    { enabled: Boolean(code) },
  )
  return data || null
}

export const useHierarchyForCategoryRoute = (): Hierarchy => {
  const { params } = useRouteMatch<{ category: string }>()
  if (!params.category) {
    throw new Error(
      'Tried accessing the route param "category" but there was none found',
    )
  }
  switch (params.category) {
    case 'mountain':
      return Hierarchy.MOUNTAIN_BIKES
    case 'road':
      return Hierarchy.GRAVEL_ROAD_BIKES
    case 'fat-tire':
      return Hierarchy.FAT_TIRE_BIKES
    case 'ebike':
      return Hierarchy.EBIKES
    case 'clothing':
      return Hierarchy.CLOTHING
    case 'accessories':
      return Hierarchy.BIKE_ACCESSORIES
    case 'parts':
      return Hierarchy.BIKE_PARTS
    default:
      return Hierarchy.BIKES
  }
}

export const useProductWarranty = (): WarrantyQuery | null => {
  const { data } = useQuery(
    'warranty',
    () => fetchApiData<Entry<WarrantyQuery>>('warranty'),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  )
  return data?.entry || null
}

type PlatformSearchParams = {
  names?: string[]
  hierarchy?: Hierarchy[]
  limit?: number
}

type PlatformSearchOptions = PlatformSearchParams & {
  enabled?: boolean
}

export const usePlatforms = ({
  enabled = true,
  names = [],
  limit = 100,
  hierarchy = [
    ...routeFilters[Hierarchy.BIKES].defaultSearchFilters.hierarchy,
    ...routeFilters[Hierarchy.GEAR].defaultSearchFilters.hierarchy,
  ],
}: PlatformSearchOptions): Record<string, Platform> => {
  const { data } = useQuery(
    ['platforms', names],
    () =>
      fetchApiData<Platform[], PlatformSearchParams>('platforms', {
        query: { names, hierarchy, limit },
      }),
    { enabled },
  )
  return (
    data?.reduce(
      (acc, platform) => ({ ...acc, [platform.name]: platform }),
      {},
    ) || {}
  )
}
