import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../../theme'
import * as Routes from '../../routes'
import { DealersEmbed } from './DealersEmbed'
import { LayoutContainer } from '../../components/LayoutContainer'
import { CallToAction } from '../../components/CallToAction'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.gridLayout,
    paddingLeft: theme.spacing.L,
    paddingRight: theme.spacing.L,
    paddingTop: theme.spacing.XXL,
    paddingBottom: theme.spacing.XXXL,
  },
  pageHeader: {
    ...theme.traits.gridColumn({
      lgCols: 6,
      lgStart: 4,
    }),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing.XXL,
    '& > h1': {
      ...theme.typography.bebas(48),
      color: theme.colors.secondaryGrey900,
    },
    '& > p': {
      ...theme.typography.urw(18),
      color: theme.colors.secondaryGrey700,
      textAlign: 'center',
    },
    '& > a': {
      ...theme.typography.urwInput(18),
      color: theme.colors.primaryRed,
    },
  },
  embedContainer: {
    gridColumn: '1 / -1',
  },
  ctaContainer: {
    gridColumn: '1 / -1',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing.L,
    },
  },
  dealerResourceContainer: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    marginBottom: 225,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 50,
    },
  },
  becomeDealerCta: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey900,
    fontWeight: 200,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 32,
    },
  },
  becomeDealerLink: {
    color: theme.colors.primaryRed,
    marginBottom: theme.spacing.M,
    marginLeft: 8,
    textDecoration: 'none',
    borderBottom: `1px solid ${theme.colors.primaryRed}`,
  },
  dealerResourceLink: {
    ...theme.typography.urw(18),
    color: theme.colors.primaryRed,
    fontWeight: 200,
    borderBottom: `1px solid ${theme.colors.primaryRed}`,
    paddingBottom: 0,
  },
}))

export const DealersPage: React.FC = () => {
  const classes = useStyles()

  return (
    <LayoutContainer contentClass={classes.container}>
      <div className={classes.pageHeader}>
        <h1>Dealers</h1>
        <p>Find an Authorized Salsa Dealer near you</p>
        <Link to={Routes.dealerSignup}>Become a Dealer</Link>
        <Link to={Routes.distributors}>View Our Distributors</Link>
      </div>
      <div className={classes.embedContainer}>
        <DealersEmbed />
      </div>
      <div className={classes.ctaContainer}>
        <CallToAction
          title="Can't find a dealer in your country?"
          text="Check out our distributors"
          route={Routes.distributors}
        />
        <div className={classes.dealerResourceContainer}>
          <div className={classes.becomeDealerCta}>
            Learn how to become a
            <Link className={classes.becomeDealerLink} to={Routes.dealerSignup}>
              Dealer Here
            </Link>
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}
