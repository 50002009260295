import React from 'react'
import { ThemeProvider } from 'react-jss'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Homepage } from './pages/homepage/Homepage'
import { theme, useGlobalStyles } from './theme'
import { BikesRouter } from './pages/Bikes/BikesRouter'
import * as Routes from './routes'
import { SafetyAndSupport } from './pages/Support/SafetyAndSupport'
import { SalsaPageRouter } from './pages/Salsa/SalsaPageRouter'
import { GearPageRouter } from './pages/Gear/GearPageRouter'
import { DealersPage } from './pages/Dealers/DealersPage'
import { GearLanding } from './pages/Gear/GearLanding'
import { Distributors } from './pages/Dealers/Distributors'
import { DealerSignup } from './pages/Dealers/DealerSignup'
import ScrollToTop from './components/ScrollToTop'
import { JournalPageRouter } from './pages/Journal/JournalPageRouter'
import { AccessibilityPage } from './pages/AccessibilityPage'
import { QueryClientProvider } from './components/QueryClientProvider'
import { ErrorPage } from './pages/ErrorPage'
import { HeaderMeta } from './components/HeaderMeta'
import { SalsaRiderRegistration } from './pages/Salsa/SalsaRiderRegistration'
import { SalsaEventRegistration } from './pages/Salsa/SalsaEventRegistration'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage'
import { GDPRPage } from './pages/GDPRPage'
import { CCPAPage } from './pages/CCPAPage'
import { legacyRedirects } from './legacyRedirects'

export const App: React.FC = () => {
  useGlobalStyles()

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <HeaderMeta />
        <QueryClientProvider>
          {(error) => (
            <ScrollToTop>
              <Header />
              <main id="main">
                {error ? (
                  <ErrorPage errorCode={error as number} />
                ) : (
                  <Switch>
                    {legacyRedirects.map(([fromRoute, toRoute]) => (
                      <Redirect exact from={fromRoute} to={toRoute} />
                    ))}
                    <Route
                      path={Routes.distributors}
                      component={Distributors}
                    />
                    <Route
                      path={Routes.dealerSignup}
                      component={DealerSignup}
                    />
                    <Route path={Routes.dealers} component={DealersPage} />
                    <Route
                      path={Routes.accessibility}
                      component={AccessibilityPage}
                    />
                    <Route
                      path={Routes.supportAndSafety}
                      component={SafetyAndSupport}
                    />
                    <Route
                      path={Routes.riderRegistration}
                      component={SalsaRiderRegistration}
                    />
                    <Route
                      path={Routes.eventRegistration}
                      component={SalsaEventRegistration}
                    />
                    <Route path={Routes.gear} component={GearPageRouter} />
                    <Route path={Routes.bikes} component={BikesRouter} />
                    <Route path={Routes.gear} component={GearLanding} />
                    <Route path={Routes.salsa} component={SalsaPageRouter} />
                    <Route
                      path={Routes.journal}
                      component={JournalPageRouter}
                    />
                    <Route
                      path={Routes.privacy}
                      component={PrivacyPolicyPage}
                    />
                    <Route path={Routes.ccpa} component={CCPAPage} />
                    <Route path={Routes.gdpr} component={GDPRPage} />
                    <Route path={Routes.alert}>Alert Content</Route>
                    <Route path={Routes.notFound}>
                      <ErrorPage errorCode={404} />
                    </Route>
                    <Route exact path={Routes.home}>
                      <Homepage />
                    </Route>
                    <Redirect to={Routes.notFound} />
                  </Switch>
                )}
              </main>
              <Footer />
            </ScrollToTop>
          )}
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}
