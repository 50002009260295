import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarketoForm } from '../../MarketoForm'

const useStyles = createUseStyles((theme: Theme) => ({
  topSection: {
    ...theme.traits.gridColumn({
      lgCols: 6,
      lgStart: 4,
      mdCols: 6,
      mdStart: 2,
      smCols: 4,
      smStart: 1,
    }),
  },
  gridContainer: {
    ...theme.traits.gridLayout,
    marginTop: 24,
    marginBottom: 132,
  },
  topSectionTitleFont: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey900,
  },
  requiredKey: {
    margin: '10px 0px',
    textAlign: 'right',
    fontSize: 12,
    '& > span': {
      color: theme.colors.primaryRed,
      display: 'inline-block',
      marginRight: 3,
      fontSize: 14,
    },
  },
}))

export const SafetyAndSupportRegistration: React.FC = () => {
  const classes = useStyles()
  const handleFormRendered = useCallback(() => {
    document
      .getElementById('FirstName')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('LastName')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('Email')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('Country')
      ?.setAttribute('aria-autocomplete', 'list')
    document
      .getElementById('Address')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('PostalCode')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('Phone')
      ?.setAttribute('aria-autocomplete', 'inline')
    document.getElementById('City')?.setAttribute('aria-autocomplete', 'inline')
    document.getElementById('State')?.setAttribute('aria-autocomplete', 'list')
  }, [])
  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.gridContainer}>
        <div className={classes.topSection}>
          <h1 className={classes.topSectionTitleFont}>
            Bike & Frame Registration
          </h1>
          <div className={classes.requiredKey}>
            <span>*</span>
            indicates a required field
          </div>
          <div>
            <MarketoForm formId="1133" onRendered={handleFormRendered} />
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}
