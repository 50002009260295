import React, { useEffect } from 'react'
import { Theme } from './theme'
import { createUseStyles } from 'react-jss'

declare global {
  interface Window {
    /** https://developers.marketo.com/javascript-api/forms/api-reference/ */
    MktoForms2: {
      loadForm: (
        baseUrl: string,
        munchkinId: string,
        formId: string | number,
        callback?: (form: MktoForm) => void,
      ) => void
      whenRendered: (callback?: (form: MktoForm) => void) => void
    }
  }
}

/** https://developers.marketo.com/javascript-api/forms/api-reference/ */
export interface MktoForm<Values = Record<string, unknown>> {
  setValues: (values: Partial<Values>) => void
  getValues: () => Values
  validate: () => boolean
  submit: () => MktoForm
  allFieldsFilled: () => boolean
  onSuccess: (
    callback: (values: Values, redirectUrl: string | null) => void,
  ) => MktoForm
  getId: () => number
}

const useStyles = createUseStyles((theme: Theme) => ({
  formStyle: {
    width: '70% !important',
    '& fieldset': {
      border: 'none !important',
    },
    '& .mktoOffset': {
      display: 'none',
    },
    '& .mktoAsterix': {
      paddingLeft: '0 !important',
    },
    '& .mktoHtmlText': {
      ...theme.typography.urw(18),
      color: '#404146',
      marginBottom: theme.spacing.M,
    },
    '& .mktoGutter': {
      display: 'none',
    },
    '& legend': {
      ...theme.typography.bebas(31),
      width: '100%',
      color: theme.colors.secondaryGrey900,
      borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
      marginLeft: '0 !important',
      marginRight: '0 !important',
    },
    '& .mktoCheckboxList': {
      display: 'grid',
      gridTemplateColumns: '38px 1fr',
      rowGap: theme.spacing.S,
      width: '100%',
      alignItems: 'center',
    },
    '& label': {
      ...theme.typography.bebas(16),
      width: '100% !important',
      fontSize: '16px !important',
      letterSpacing: '0.2px !important',

      display: 'flex !important',
      color: theme.colors.secondaryGrey900,
      marginBottom: '5px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px !important',
      },
    },
    '& input, & select, & textarea': {
      ...theme.typography.urwInput(16),
      fontSize: '16px !important',
      width: '400px !important',
      height: '38px !important',
      float: 'left',
      clear: 'left',
      [theme.breakpoints.down('sm')]: {
        width: '300px !important',
      },
    },
    '& textarea': {
      ...theme.typography.urwInput(16),
      height: '74px !important',
    },
    '& input': {
      display: 'flex !important',
      border: '2px solid #D6D6D6 !important',
      boxSizing: 'border-box !important',
      borderRadius: '2px !important',
    },
    '& .mktoCheckboxList > input': {
      width: '38px !important',
    },
    '& button': {
      ...theme.typography.bebas(26),
      textTransform: 'uppercase',
      fontSize: '26px !important',
      display: 'flex !important',
      padding: '12px 24px !important',
      background: '#CC0033 !important',
      letterSpacing: '0.8px',
      borderRadius: '2px !important',
      cursor: 'pointer',
      border: 'none !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
        lineHeight: '28px !important',
      },
    },
  },
}))

type Props = {
  formId: string
  onReady?: (form: MktoForm) => void
  onRendered?: (form: MktoForm) => void
}

export const MarketoForm: React.FC<Props> = ({
  formId,
  onReady,
  onRendered,
}) => {
  const classes = useStyles()
  useEffect(() => {
    window.MktoForms2.loadForm(
      '//pages.qbp.com',
      '796-XAK-811',
      formId,
      (form) => {
        if (onReady) {
          onReady(form)
        }
      },
    )
  }, [formId, onReady])
  useEffect(() => {
    window.MktoForms2.whenRendered((form) => {
      if (onRendered) {
        onRendered(form)
      }
    })
  }, [formId, onRendered])

  return <form id={`mktoForm_${formId}`} className={classes.formStyle}></form>
}
