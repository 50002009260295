import * as React from 'react'

export const FlashIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={21}
      height={28}
      viewBox="0 0 21 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.39 27.75a1.247 1.247 0 01-1.238-1.387l.962-8.863H1.752a1.25 1.25 0 01-1.038-1.95L10.577.8a1.25 1.25 0 011.462-.475c.517.192.847.7.813 1.25l-.963 8.925h7.363a1.25 1.25 0 011.037 1.95L10.427 27.2a1.25 1.25 0 01-1.038.55zM4.09 15h6.412a1.25 1.25 0 011.237 1.388l-.562 5.187L16.914 13h-6.412a1.25 1.25 0 01-1.25-1.387l.562-5.188L4.09 15z"
        fill="#989898"
      />
    </svg>
  )
}
