import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { CategoryNav } from '../../components/CategoryNav'
import * as Routes from '../../routes'
import { GearLanding } from './GearLanding'
import { ClothingIcon } from '../../categoryIcons/ClothingIcon'
import { Accesories } from '../../categoryIcons/Accesories'
import { ArchivedGearIcon } from '../../categoryIcons/ArchivedGearIcon'
import { BikeAccesories } from './GearCategory/BikeAccessories'
import { ArchivedGear } from './GearCategory/ArchivedGear'
import { BikePartsIcon } from '../../categoryIcons/BikePartsIcon'
import { Clothing } from './GearCategory/Clothing'
import { AllGear } from './GearCategory/AllGear'
import { BikeParts } from './GearCategory/BikeParts'
import { ProductDetailPage } from '../ProductDetailPage'

export const GearPageRouter: React.FC = () => {
  return (
    <>
      <CategoryNav routes={gearNavRoutes} />
      <Switch>
        <Route path={Routes.allGear} component={AllGear} />
        <Route path={Routes.clothing} component={Clothing} />
        <Route path={Routes.accessories} component={BikeAccesories} />
        <Route path={Routes.parts} component={BikeParts} />
        <Route path={Routes.archivedGear} component={ArchivedGear} />
        <Route path={Routes.gearDetail} component={ProductDetailPage} />
        <Route path={Routes.gear} component={GearLanding} />
      </Switch>
    </>
  )
}

const gearNavRoutes = [
  {
    route: Routes.clothing,
    label: 'Clothing',
    icon: <ClothingIcon />,
  },
  {
    route: Routes.accessories,
    label: 'Bike Accessories',
    icon: <Accesories />,
  },
  {
    route: Routes.parts,
    label: 'Bike Parts',
    icon: <BikePartsIcon />,
  },
  {
    route: Routes.archivedGear,
    label: 'Archived Gear',
    icon: <ArchivedGearIcon />,
  },
]
