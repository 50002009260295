import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { LayoutContainer } from '../../components/LayoutContainer'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { useQuery } from 'react-query'
import {
  fetchApiData,
  getHeaderFlexAlignment,
  resizeVimeoVideos,
} from '../../utils'
import { useRouteMatch } from 'react-router'
import * as routes from '../../routes'
import { ArticlePageQuery_entry_article_article_Entry as ArticlePageQuery } from '../../api-types/ArticlePageQuery'
import { Entry } from '../../types'
import { JournalAuthorSection } from '../../components/JournalAuthorSection'
import { CallToAction } from '../../components/CallToAction'
import { HeroImage } from '../../components/HeroImage'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { Helmet } from 'react-helmet'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { useHistory } from 'react-router-dom'
import * as Routes from '../../routes'

const useStyles = createUseStyles((theme: Theme) => ({
  contentContainer: {
    maxWidth: 780,
  },
  title: {
    ...theme.typography.bebas(37),
    marginTop: theme.spacing.XXL,
    paddingBottom: theme.spacing.L,
  },
  rawBodyContainer: {
    ...theme.typography.urw(18),
    borderTop: `1px solid ${theme.colors.secondaryGrey300}`,
    paddingTop: theme.spacing.L,
    '& img': {
      width: '100%',
    },
    '& p': {
      marginBottom: theme.spacing.L,
    },
    marginBottom: theme.spacing.XXL,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `1px solid ${theme.colors.secondaryGrey300}`,
    paddingTop: theme.spacing.M,
    marginBottom: theme.spacing.XXL,
  },
  tag: {
    ...theme.typography.urwBold(14),
    color: theme.colors.primaryWhite,
    backgroundColor: theme.colors.primaryRed,
    padding: `${theme.spacing.XS}px ${theme.spacing.M}px`,
    borderRadius: 4,
    marginRight: theme.spacing.S,
    marginBottom: theme.spacing.S,
  },
}))

export const ArticlePage: React.FC = () => {
  const match = useRouteMatch<{ articleSlug: string }>()
  const classes = useStyles()
  const history = useHistory()
  const rawBodyRef = useRef<HTMLDivElement | null>(null)

  const { data, isLoading, isFetched } = useQuery(
    ['articles', match.params.articleSlug],
    () =>
      fetchApiData<Entry<ArticlePageQuery>>(
        `articles/${match.params.articleSlug}`,
      ),
  )

  useEffect(() => {
    if (rawBodyRef?.current && !isLoading) {
      resizeVimeoVideos(rawBodyRef.current)
    }
  }, [isLoading])

  if (isFetched && !data?.entry) {
    history.replace(Routes.notFound)
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  const hero = data?.entry?.hero && data?.entry?.hero[0]
  const heroImage = hero?.image && hero?.image[0]

  return (
    <>
      <Helmet>
        <title>{`${data?.entry?.title} | Salsa Bikes`}</title>
        {hero && <meta property="og:image" content={heroImage?.url ?? ''} />}
        {data && (
          <meta
            property="og:description"
            content={data.entry?.articleSeoDescription ?? ''}
          />
        )}
      </Helmet>
      {hero && (
        <HeroImage
          src={heroImage?.url || ''}
          alt={heroImage?.alttext || ''}
          type={heroImage?.mimeType || ''}
          justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
        >
          <ProductTitle>{hero?.heading}</ProductTitle>
        </HeroImage>
      )}
      <LayoutContainer contentClass={classes.contentContainer}>
        <Breadcrumbs />
        <h1 className={classes.title}>{data?.entry?.title}</h1>
        <JournalAuthorSection
          name={data?.entry?.articleAuthor || ''}
          datePosted={data?.entry?.articleDate}
        />
        {data?.entry?.articleRawBody && (
          <div
            ref={rawBodyRef}
            className={classes.rawBodyContainer}
            dangerouslySetInnerHTML={{ __html: data?.entry?.articleRawBody }}
          />
        )}
        <ul className={classes.tags}>
          {data?.entry?.journalCategoryField?.map((tag) => (
            <li key={tag?.uid} className={classes.tag}>
              {tag?.title}
            </li>
          ))}
        </ul>
        <CallToAction
          title="Looking for more?"
          route={routes.articles}
          text="View all Journal Entries"
        />
      </LayoutContainer>
    </>
  )
}
