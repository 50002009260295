import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { AccordionItem } from '../components/AccordionItem'
import { LayoutContainer } from '../components/LayoutContainer'
import { useQuery } from 'react-query'
import { Entry } from '../types'
import { fetchApiData } from '../utils'
import { AccessibilityQuery_entry_accessibility_accessibility_Entry as AccessibilityQuertyType } from '../api-types/AccessibilityQuery'
import { LoadingIndicator } from '../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.gridLayout,
    paddingLeft: theme.spacing.L,
    paddingRight: theme.spacing.L,
    paddingTop: theme.spacing.L,
  },
  content: {
    ...theme.traits.gridColumn({ lgCols: 8, lgStart: 3 }),
    marginBottom: theme.spacing.XXXL,
    color: theme.colors.primaryBlack,
  },
  header: {
    marginBottom: theme.spacing.L,
    paddingBottom: theme.spacing.S,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  heading: {
    ...theme.typography.bebas(48),
    marginBottom: theme.spacing.L,
  },
  subheading: {
    ...theme.typography.bebas(31),
    marginBottom: theme.spacing.S,
  },
  description: {
    ...theme.typography.urw(14),
  },
  item: {
    marginBottom: theme.spacing.M,
  },
  itemDetail: {
    ...theme.typography.urw(18),
    padding: theme.spacing.M,
    color: theme.colors.secondaryGrey900,
  },
}))

export const AccessibilityPage: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('/accessibility', () =>
    fetchApiData<Entry<AccessibilityQuertyType>>('accessibility'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const accessibility = data?.entry

  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.header}>
            <h1 className={classes.heading}>
              {accessibility && accessibility.accessibilitytitle}
            </h1>
            <h2>Accessibility Items</h2>
          </div>
          <ul>
            {accessibility?.accordionItem?.map((item) => (
              <AccordionItem
                title={(item && item?.accordionheading) || ''}
                as="li"
                className={classes.item}
              >
                <div
                  id={
                    item && item.accordionheading
                      ? item.accordionheading.replace(/ /g, '_').toLowerCase()
                      : ''
                  }
                  className={classes.itemDetail}
                  dangerouslySetInnerHTML={{
                    __html: item?.accordionresponse || '',
                  }}
                />
              </AccordionItem>
            ))}
          </ul>
        </div>
      </div>
    </LayoutContainer>
  )
}
