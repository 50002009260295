import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { FeatherIcon } from './icons/FeatherIcon'
import { Link } from 'react-router-dom'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.gridLayout,
    position: 'relative',
    backgroundColor: theme.colors.secondaryGrey100,
    paddingTop: 48,
    paddingBottom: 96,
  },
  content: {
    ...theme.traits.gridColumn({
      lgCols: 6,
      lgStart: 4,
      mdCols: 6,
      mdStart: 2,
    }),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  openQuotation: {
    ...theme.typography.bebas(140),
    // Override font scaling to preserve big quotation
    fontSize: [140, '!important'],
    lineHeight: ['144px', '!important'],
    color: theme.colors.primaryRed,
    marginBottom: -64,
  },
  quote: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey900,
    marginBottom: theme.spacing.L,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.bebas(16),
      paddingLeft: theme.spacing.L,
      paddingRight: theme.spacing.L,
    },
  },
  link: {
    ...theme.typography.urw(21),
    color: theme.colors.secondaryGrey900,
    display: 'flex',
    alignItems: 'center',
  },
  linkIcon: {
    stroke: theme.colors.secondaryGrey900,
    marginLeft: theme.spacing.S,
  },
}))

type Props = {
  quote: string
  credit: string
  linkTo: string
  className?: string
}

export const PullQuote: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.container, props.className)}>
      <div className={classes.content}>
        <span className={classes.openQuotation}>“</span>
        <p className={classes.quote}>{props.quote}”</p>
        <Link
          to={{ pathname: props.linkTo }}
          className={classes.link}
          target={props.linkTo.includes('http') ? '_blank' : '_self'}
        >
          <span>- {props.credit}</span>
          <FeatherIcon
            name="external-link"
            size={24}
            className={classes.linkIcon}
          />
        </Link>
      </div>
    </div>
  )
}
