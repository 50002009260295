import React from 'react'
import { PrivacyPolicyPageQuery_entry_privacyPolicyPage_privacyPolicyPage_Entry as PrivacyPolicyPageQuery } from '../api-types/PrivacyPolicyPageQuery'
import { useQuery } from 'react-query'
import { fetchApiData } from '../utils'
import { Entry } from '../types'
import { LayoutContainer } from '../components/LayoutContainer'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { AccordionItem } from '../components/AccordionItem'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { LoadingIndicator } from '../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  privacyPolicyPage: {
    ...theme.typography.urw(21),
    marginBottom: theme.spacing.XXXL,
    '& h1, & h2, & h3, & h4, & h5, & p, & li': {
      margin: 'revert',
    },
    '& ol, & ul': {
      listStyle: 'revert',
      padding: 'revert',
      margin: 'revert',
    },
  },
  heading: {
    ...theme.typography.bebas(48),
    marginBottom: theme.spacing.L,
    marginTop: theme.spacing.L,
    paddingBottom: theme.spacing.XXL,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  item: {
    marginBottom: theme.spacing.M,
  },
  itemDetail: {
    ...theme.typography.urw(18),
    padding: theme.spacing.M,
    color: theme.colors.secondaryGrey900,
  },
}))

export const PrivacyPolicyPage: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('privacy-page', () =>
    fetchApiData<Entry<PrivacyPolicyPageQuery>>('privacy'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <LayoutContainer>
        <Breadcrumbs />
      </LayoutContainer>
      <LayoutContainer contentClass={classes.privacyPolicyPage}>
        <h1 className={classes.heading}>Privacy Policy</h1>
        {data?.entry?.privacyPolicyHeading && (
          <div
            dangerouslySetInnerHTML={{
              __html: data?.entry?.privacyPolicyHeading || '',
            }}
          />
        )}
        <ul>
          {data?.entry?.accordionItem?.map((item) => (
            <AccordionItem
              title={(item && item.accordionheading) ?? ''}
              as="li"
              className={classes.item}
            >
              <div
                className={classes.itemDetail}
                dangerouslySetInnerHTML={{
                  __html: item?.accordionresponse || '',
                }}
              />
            </AccordionItem>
          ))}
        </ul>
      </LayoutContainer>
    </>
  )
}
