import React from 'react'
import { HeroImage } from './HeroImage'
import { Breadcrumbs } from './Breadcrumbs'
import { PlatformDescription } from './PlatformDescription'
import { ProductTitle } from './typography/ProductTitle'
import { PlatformSearch } from './search/PlatformSearch'
import { ProductPageMedia } from './ProductPageMedia'
import { PlatformJournalSection } from './PlatformJournalSection'
import { StoryCard } from './StoryCard'
import { CallToAction } from './CallToAction'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { Hierarchy, JOURNAL_ENTRY_TYPES } from '../product-types'
import { LayoutContainer } from './LayoutContainer'
import { useQuery } from 'react-query'
import { fetchApiData, getHeaderFlexAlignment, jsxJoin } from '../utils'
import {
  ProductCategoryPageQuery_entry_productCategoryPage_default_Entry,
  ProductCategoryPageQuery_entry_productCategoryPage_default_Entry_relatedJournalArticles_article_article_Entry as JournalArticle,
} from '../api-types/ProductCategoryPageQuery'
import { Entry } from '../types'
import * as routes from '../routes'
import { LoadingIndicator } from './LoadingIndicator'
import { useRouteMatch } from 'react-router-dom'

interface ProductCategoryPageQuery
  extends ProductCategoryPageQuery_entry_productCategoryPage_default_Entry {
  relatedJournalArticles: JournalArticle[] | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  ctaMargin: {
    marginLeft: 212,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  mobileHeadingContainer: {
    backgroundColor: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
  },
  mobileHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryWhite,
    textAlign: 'center',
  },
  mobileLinkContainer: {
    padding: theme.spacing.L,
    display: 'flex',
    justifyContent: 'center',
  },
  mobileTextContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export const ProductCategorySearchAndFilterPage: React.FC<{
  hierarchy: Hierarchy
}> = ({ hierarchy }) => {
  const classes = useStyles()
  const hierarchyKey = Object.keys(Hierarchy)[
    Object.values(Hierarchy).indexOf(hierarchy)
  ]
  const match = useRouteMatch()
  const isBike = match.path.includes(routes.bikes)

  const { data, isLoading } = useQuery(
    ['product-category-page', hierarchyKey],
    () =>
      fetchApiData<Entry<ProductCategoryPageQuery>>(
        `productcategorypage/${hierarchyKey}`,
      ),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const hero1 = data?.entry?.hero && data?.entry?.hero[0]
  const hero1Image = hero1?.image && hero1?.image[0]
  const hero2 = data?.entry?.hero && data?.entry?.hero[1]
  const hero2Image = hero2?.image && hero2?.image[0]
  const sectionHeading1 =
    data?.entry?.sectionHeadings && data?.entry?.sectionHeadings[0]
  const sectionHeading2 =
    data?.entry?.sectionHeadings && data?.entry?.sectionHeadings[1]
  const callToAction =
    data?.entry?.callToActionInternalLink &&
    data?.entry?.callToActionInternalLink[0]

  return (
    <>
      <HeroImage
        src={hero1Image?.url || ''}
        alt={hero1Image?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero1?.headingAlignment)}
      >
        <ProductTitle>{hero1?.heading}</ProductTitle>
      </HeroImage>
      <div className={classes.mobileTextContainer}>
        <div className={classes.mobileHeadingContainer}>
          <h1 className={classes.mobileHeading}>
            {jsxJoin(hero1?.heading || '', '\n', <br />)}
          </h1>
        </div>
      </div>
      <LayoutContainer>
        <Breadcrumbs />
        <PlatformDescription description={data?.entry?.productBlurb || ''} />
        <PlatformSearch hierarchy={hierarchy} />
        <div className={classes.ctaMargin}>
          <CallToAction
            title={
              callToAction?.label || isBike
                ? "Can't Find Your Bike?"
                : "Can't Find Your Gear?"
            }
            route={
              callToAction?.linkTo || isBike ? routes.allBikes : routes.allGear
            }
            text={
              callToAction?.linkLabel || isBike
                ? 'View All Bikes'
                : 'View All Gear'
            }
          />
        </div>
        <ProductPageMedia
          source={hero2Image?.url || ''}
          title={sectionHeading1?.headingText || ''}
        />
        {data?.entry?.relatedJournalArticles &&
          data?.entry?.relatedJournalArticles?.length > 0 && (
            <PlatformJournalSection
              title={sectionHeading2?.headingText || 'Journal Entries'}
            >
              {data?.entry?.relatedJournalArticles?.map((entry) => {
                const type = JOURNAL_ENTRY_TYPES[entry.__typename]
                const category = entry?.productCategory?.replace('_', ' ')
                return (
                  <StoryCard
                    key={entry.uid}
                    title={entry?.title || ''}
                    href={`${type.linkTo}/${entry.slug}`}
                    imgSrc={
                      (entry?.imageThumbnail &&
                        entry?.imageThumbnail[0]?.url) ||
                      ''
                    }
                    subtitle={type.label.concat(
                      category ? ` | ${category}` : '',
                    )}
                    subtitle2={entry.productBlurb || ''}
                    hrefLabel={
                      type.label === 'Video'
                        ? 'Watch the Video'
                        : `Read the ${type.label}`
                    }
                  />
                )
              })}
            </PlatformJournalSection>
          )}
      </LayoutContainer>
    </>
  )
}
