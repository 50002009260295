import React from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router'
import { VideoPageQuery_entry_video_video_Entry as VideoPageQuery } from '../../api-types/VideoPageQuery'
import { fetchApiData } from '../../utils'
import { Entry } from '../../types'
import { HeroImage } from '../../components/HeroImage'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import * as routes from '../../routes'
import { LayoutContainer } from '../../components/LayoutContainer'
import { CallToAction } from '../../components/CallToAction'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Helmet } from 'react-helmet'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import * as Routes from '../../routes'
import { useHistory } from 'react-router-dom'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  titleContainer: {
    maxWidth: theme.measurements.contentNarrowWidth,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
    paddingBottom: theme.spacing.L,
    margin: '0 auto',
  },
  title: {
    ...theme.typography.bebas(37),
    color: theme.colors.primaryBlack,
    marginTop: theme.spacing.XL,
    textAlign: 'center',
  },
  blurb: {
    ...theme.typography.urw(21),
    color: theme.colors.secondaryGrey700,
    textAlign: 'center',
  },
}))

export const VideoPage: React.FC = () => {
  const match = useRouteMatch<{ videoSlug: string }>()
  const classes = useStyles()
  const history = useHistory()

  const { data, isLoading, isFetched } = useQuery(
    ['videos', match.params.videoSlug],
    () =>
      fetchApiData<Entry<VideoPageQuery>>(`videos/${match.params.videoSlug}`),
  )

  if (isFetched && !data?.entry) {
    history.replace(Routes.notFound)
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  const asset = data?.entry?.videoAsset && data?.entry?.videoAsset[0]
  const routeUrl = `${routes.videos}/${data?.entry?.slug}`

  return (
    <>
      {data && (
        <Helmet>
          <title>{`${data?.entry?.title} | Salsa Bikes`}</title>
          <meta
            property="og:description"
            content={data?.entry?.productBlurb ?? ''}
          />
        </Helmet>
      )}
      <HeroImage
        src={data?.entry?.vimeoLink || asset?.url || ''}
        containVideo
        type="video"
        alt={
          (data?.entry?.vimeoLink ? data?.entry?.title : asset?.alttext) || ''
        }
      />
      <LayoutContainer>
        <Breadcrumbs
          entries={[
            { label: 'Journal', routeUrl: routes.journal },
            { label: 'Videos', routeUrl: routes.videos },
            { label: data?.entry?.title || '', routeUrl },
          ]}
        />
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>{data?.entry?.title}</h1>
          <p className={classes.srOnly}>{data?.entry?.videoDescription}</p>
          <p className={classes.blurb}>{data?.entry?.productBlurb}</p>
        </div>
        <CallToAction
          title="Looking for more?"
          route={routes.videos}
          text="View all Salsa Videos"
        />
      </LayoutContainer>
    </>
  )
}
