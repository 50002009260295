import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../theme'
import { classNames } from '../utils'
import play from './icons/Play.png'
import pause from './icons/Pause.svg'
import { LayoutContainer } from './LayoutContainer'

export interface Props {
  src: string
  alt: string
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
  className?: string
  type?: string
  containVideo?: boolean
}

const useStyles = createUseStyles((theme: Theme) => ({
  heroContainer: (props: Props) => ({
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    backgroundImage: `url(${props.src})`,
    backgroundPosition: 'center',
    color: 'black',
    backgroundSize: 'cover',
    position: 'relative',
    height: 640,
    overflow: 'hidden',
    width: '100%',
    '&': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
        height: 320,
      },
    },
  }),
  childrenContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  childrenContent: (props: Props) => ({
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    alignItems: props.justifyContent ?? 'flex-start',
    position: 'relative',
  }),
  heroVideoContainer: {
    position: 'relative',
    width: '100%',
    ...theme.traits.flexCentering,
  },
  playButton: {
    background: `url(${play})`,
    backgroundSize: 'contain',
    position: 'absolute',
    zIndex: 1,
    height: 137,
    width: 137,
    border: 'none',
    '&:hover': { cursor: 'pointer' },
  },
  playButtonSmall: {
    right: theme.spacing.XXL,
    bottom: theme.spacing.XXL,
    height: 32,
    width: 32,
  },
  playButtonPlaying: {
    background: `url(${pause})`,
  },
  videoContainer: {
    width: '100%',
    padding: theme.spacing.L,
    backgroundColor: theme.colors.secondaryGrey900,
  },
  vimeoContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.secondaryGrey900,
    zIndex: 999,
  },
}))

export const HeroImage: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  const isVideo = props.type?.indexOf('video') === 0
  const isJson = /\.json$/i.test(props.src)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [embedURL, setEmbedUrl] = useState('')
  const childrenExist = React.Children.count(props.children) > 0
  let embedCode = ''
  let videoURL = ''

  useEffect(() => {
    if (isPlaying) {
      videoRef.current?.play()
    } else {
      videoRef.current?.pause()
    }
  }, [isPlaying, setIsPlaying])

  useEffect(() => {
    if (isJson) {
      fetch(props.src, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        referrerPolicy: 'no-referrer',
      })
        .then((res) => res.json())
        .then((data) => setEmbedUrl(data.url))
        .catch(console.error)
    }
  }, [embedURL, setEmbedUrl, isJson, props.src])

  const embedCodeVimeoOrYoutube = embedURL.split('/')

  if (embedCodeVimeoOrYoutube[2] === 'vimeo.com') {
    embedCode = embedCodeVimeoOrYoutube[3]
    videoURL = `https://player.vimeo.com/video/${embedCode}`
  } else {
    embedCode = embedURL.split('=')[1]
    videoURL = `https://www.youtube.com/embed/${embedCode}?autoplay=1&mute=1`
  }

  if (!props.src) return null

  return (
    <div
      className={classNames(classes.heroContainer, props.className)}
      style={isVideo ? { height: 'auto' } : undefined}
      role="region"
      aria-label={isVideo && props.alt ? props.alt : 'Video'}
    >
      {childrenExist && (
        <LayoutContainer
          containerClass={classes.childrenContainer}
          contentClass={classes.childrenContent}
        >
          {props.children}
        </LayoutContainer>
      )}
      {isVideo && (
        <>
          <LayoutContainer
            containerClass={classes.childrenContainer}
            contentClass={classes.childrenContent}
            contentStyle={{ alignItems: 'center' }}
            containerStyle={
              isPlaying && !childrenExist ? { display: 'none' } : undefined
            }
          >
            <button
              onClick={() => setIsPlaying((prevState) => !prevState)}
              aria-label={
                props.alt
                  ? `Play embedded video: ${props.alt}`
                  : 'Play embedded video'
              }
              className={classNames(
                classes.playButton,
                childrenExist && classes.playButtonSmall,
                isPlaying && classes.playButtonPlaying,
              )}
            />
          </LayoutContainer>
          <LayoutContainer
            containerClass={classes.videoContainer}
            contentStyle={{
              padding: 0,
              maxWidth: props.containVideo
                ? theme.measurements.contentNarrowWidth
                : 'unset',
            }}
          >
            <video
              ref={videoRef}
              width="100%"
              height="auto"
              muted
              controls={isPlaying && !childrenExist}
              onPause={() => setIsPlaying(false)}
              preload="true"
              src={props.src || ''}
            >
              Your browser does not support the video tag.
            </video>
          </LayoutContainer>
        </>
      )}
      {embedURL && (
        <div className={classes.vimeoContainer}>
          <iframe
            width="100%"
            height="100%"
            src={videoURL}
            title="Salsa Video Player"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            style={{ zIndex: 1000 }}
          ></iframe>
        </div>
      )}
    </div>
  )
}
