import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { HeroImage } from '../../components/HeroImage'
import { PlatformSearch } from '../../components/search/PlatformSearch'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { Theme } from '../../theme'
import {
  fetchApiData,
  getHeaderFlexAlignment,
  journalCategoriesToFilters,
} from '../../utils'
import { Hierarchy } from '../../product-types'
import { useQuery } from 'react-query'
import { Entry } from '../../types'
import { VideosPageQuery_entry_videosPage_videosPage_Entry as VideosPageQuery } from '../../api-types/VideosPageQuery'
import { LayoutContainer } from '../../components/LayoutContainer'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  heroLink: {
    ...theme.typography.bebas(26),
    backgroundColor: theme.colors.primaryRed,
    textDecoration: 'none',
    color: theme.colors.primaryWhite,
    padding: '12px 24px',
    borderRadius: 2,
    letterSpacing: '0.8px',
    marginTop: theme.spacing.M,
  },
  breadcrumbs: {
    marginBottom: theme.spacing.XL,
  },
}))

export const JournalVideos: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('videos-page', () =>
    fetchApiData<Entry<VideosPageQuery>>('videos-page'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const hero = data?.entry?.hero && data?.entry?.hero[0]
  const heroImage = hero?.image && hero?.image[0]
  const link = hero?.linkUrl
  const linkLabel = hero?.linkLabel

  return (
    <>
      <HeroImage
        src={heroImage?.url || ''}
        alt={heroImage?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
      >
        <ProductTitle>{hero?.heading}</ProductTitle>
        {linkLabel && (
          <Link
            className={classes.heroLink}
            to={{ pathname: link || '#' }}
            target={link?.includes('http') ? '_blank' : '_self'}
          >
            {linkLabel}
          </Link>
        )}
      </HeroImage>
      <LayoutContainer contentClass={classes.breadcrumbs}>
        <Breadcrumbs />
      </LayoutContainer>
      <LayoutContainer>
        <h2 className={classes.srOnly}>Videos</h2>
        <PlatformSearch
          hierarchy={Hierarchy.VIDEO}
          pageFilters={journalCategoriesToFilters(
            data?.entry?.journalCategoryField,
          )}
        />
      </LayoutContainer>
    </>
  )
}
