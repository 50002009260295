import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../../theme'
import { CallToAction } from '../../components/CallToAction'
import { AnimatedImageHeightCarousel } from '../../components/carousel/AnimatedHeightCarousel'
import { fetchApiData, getHeaderFlexAlignment, jsxJoin } from '../../utils'
import {
  GearLandingPageQuery_entry_gearLandingPage_gearLandingPage_Entry as GearLandingPageQuery,
  GearLandingPageQuery_entry_gearLandingPage_gearLandingPage_Entry_productCarousels as ProductCarousel,
} from '../../api-types/GearLandingPageQuery'
import { productEntry as ProductEntry } from '../../api-types/productEntry'
import { useQuery } from 'react-query'
import { Entry } from '../../types'
import { HeroImage } from '../../components/HeroImage'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { Button } from '../../components/Button'

interface Carousel extends ProductCarousel {
  productEntries: (ProductEntry | null)[] | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  mainContainer: {
    ...theme.traits.flexCentering,
    justifyContent: 'flex-start',
    backgroundSize: 'cover',
    color: 'black',
    display: 'flex',
    height: 640,
    marginBottom: 72,
    '&:nth-child(4)': {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      height: 320,
    },
  },
  sectionTitle: {
    ...theme.typography.bebas(120),
    textAlign: 'center',
    color: theme.colors.primaryWhite,
    textShadow: `0px 10px 30px ${theme.colors.secondaryGrey900}`,
  },
  imageContainer: {
    justifyContent: 'center',
  },
  sectionLabel: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    marginLeft: 48,
    marginRight: 48,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    ...theme.traits.flexCentering,
    ...theme.typography.bebasButton(26),
    padding: '12px 24px',
    color: theme.colors.primaryWhite,
    background: theme.colors.primaryRed,
    borderRadius: 2,
    borderStyle: 'none',
    textDecoration: 'none',
  },
  linkText: {
    ...theme.typography.bebas(48),
    color: theme.colors.primaryBlack,
  },
  carouselContainer: {
    marginBottom: 128,
  },
  mobileHeadingContainer: {
    backgroundColor: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
  },
  mobileHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryWhite,
    textAlign: 'center',
  },
  mobileLinkContainer: {
    padding: theme.spacing.L,
    display: 'flex',
    justifyContent: 'center',
  },
  mobileTextContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileButton: {
    backgroundColor: theme.colors.secondaryGrey900,
    marginBottom: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      backgroundColor: theme.colors.primaryRed,
    },
  },
}))

export const GearLanding: React.FC = () => {
  const classes = useStyles()
  const { data, isLoading } = useQuery('gear/landingpage', () =>
    fetchApiData<Entry<GearLandingPageQuery>>('gear/landingpage'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const carousels = (data?.entry?.productCarousels || []) as Carousel[]
  const callToAction =
    data?.entry?.callToActionInternalLink &&
    data?.entry?.callToActionInternalLink[0]
  return (
    <>
      {data?.entry?.hero?.map((hero, i) => (
        <React.Fragment key={hero?.uid}>
          <h1 className={classes.srOnly}>Gear</h1>
          <HeroImage
            src={(hero?.image && hero?.image[0]?.url) || ''}
            alt={(hero?.image && hero?.image[0]?.alttext) || ''}
            type={(hero?.image && hero?.image[0]?.mimeType) || ''}
            justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
          >
            <div className={classes.sectionLabel}>
              <h2 className={classes.sectionTitle}>
                {jsxJoin(hero?.heading || '', '\n', <br />)}
              </h2>
              <Link to={hero?.linkUrl || ''} className={classes.button}>
                {hero?.linkLabel}
              </Link>
            </div>
          </HeroImage>
          <div className={classes.mobileTextContainer}>
            <div className={classes.mobileHeadingContainer}>
              <h2 className={classes.mobileHeading}>
                {jsxJoin(hero?.heading || '', '\n', <br />)}
              </h2>
            </div>
            <div className={classes.mobileLinkContainer}>
              <Link to={hero?.linkUrl || ''}>
                <Button className={classes.button}>{hero?.linkLabel}</Button>
              </Link>
            </div>
          </div>
          {carousels && carousels[i]?.productEntries && (
            <div className={classes.carouselContainer}>
              <AnimatedImageHeightCarousel
                entries={carousels[i]?.productEntries}
                centerPaddingPercentage={27}
                imageWidthPercentage={58}
              />
            </div>
          )}
        </React.Fragment>
      ))}
      <CallToAction
        text={callToAction?.linkLabel || ''}
        title={callToAction?.label || ''}
        route={callToAction?.linkTo || ''}
      />
    </>
  )
}
