import React, { useState, useCallback, useEffect, useRef } from 'react'
import FocusTrap from 'focus-trap-react'
import { createUseStyles } from 'react-jss'
import { Link, NavLink } from 'react-router-dom'
import { theme, Theme } from '../theme'
import { classNames } from '../utils'
import { FeatherIcon } from './icons/FeatherIcon'
import { Logo } from './icons/Logo'
import * as Routes from '../routes'
import { MountainBikesIcon } from '../categoryIcons/MountainBikesIcon'
import { RoadBikesIcon } from '../categoryIcons/RoadBikesIcon'
import { FatTireIcon } from '../categoryIcons/FatTire'
import { FlashIcon } from '../categoryIcons/FlashIcon'
import { BikeIcon } from '../categoryIcons/BikeIcon'
import { ClothingIcon } from '../categoryIcons/ClothingIcon'
import { ArchivedGearIcon } from '../categoryIcons/ArchivedGearIcon'
import { BikePartsIcon } from '../categoryIcons/BikePartsIcon'
import { Accesories as AccesoriesIcon } from '../categoryIcons/Accesories'
import { EventsIcon } from '../categoryIcons/EventsIcon'
import { FeaturesAndTechIcon } from '../categoryIcons/FeaturesAndTechIcon'
import { GlobeIcon } from '../categoryIcons/GlobeIcon'
import { CampaignIcon } from '../categoryIcons/CampaignIcon'
import { SponsoredTeamIcon } from '../categoryIcons/SponsoredTeamIcon'
import { Articles as ArticleIcon } from '../pages/Journal/icons/Articles'
import { Stories as StoryIcon } from '../pages/Journal/icons/Stories'
import { Videos as VideoIcon } from '../pages/Journal/icons/Videos'
import { LayoutContainer } from './LayoutContainer'
import { GlobalSearch } from './GlobalSearch'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  navBar: {
    height: 87,
    [theme.breakpoints.down('md')]: {
      height: 80,
    },
    [theme.breakpoints.down('sm')]: {
      height: 60,
      alignItems: 'flex-end',
    },
  },
  navBarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 101,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
    },
  },
  logoContainer: {
    flex: 1,
    ...theme.traits.flexCentering,
    justifyContent: 'flex-start',

    [theme.breakpoints.down('md')]: {
      marginLeft: 10,
      paddingTop: 25,
      paddingBottom: 25,
      width: '40%',
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 13,
      paddingBottom: 10,
    },
  },
  navItems: {
    ...theme.typography.bebas(26),
    padding: theme.spacing.L,
    color: theme.colors.grey600,
  },
  activeNavItem: {
    color: theme.colors.primaryBlack,
  },
  navLinksContainer: {
    flex: 2,
    ...theme.traits.flexCentering,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  navIconContainer: {
    ...theme.traits.flexCentering,
    paddingRight: 0,
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  searchIconContainer: {
    border: 'none',
    background: 'none',
    '&:hover': { cursor: 'pointer' },
    '&:focus': { outline: '1px dotted #000' },
  },
  navMobileMenuOpen: {
    ...theme.traits.clickable,
    ...theme.traits.flexCentering,
    justifyContent: 'flex-end',
    borderStyle: 'none',
    background: 'none',
    '&:focus': { border: '2px solid #4c8bf5', borderRadius: '3px' },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navMobileMenuClose: {
    ...theme.traits.clickable,
    ...theme.traits.flexCentering,
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '-4em',
    right: '1em',
    borderStyle: 'none',
    background: 'none',
    '&:focus': { border: '2px solid #4c8bf5', borderRadius: '3px' },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    width: '50%',
    height: 'auto',
  },
  menu: {
    width: 64,
    [theme.breakpoints.down('sm')]: {
      width: 32,
    },
  },
  navLinkList: {
    display: 'flex',
  },
  mobileMenu: {
    position: 'absolute',
    backgroundColor: theme.colors.primaryWhite,
    top: 60,
    width: 300,
    right: 0,
    zIndex: 100,
  },
  mobileMenuList: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileLinks: {
    textDecoration: 'none',
    color: theme.colors.secondaryGrey700,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.primaryWhite,
    Color: theme.colors.secondaryGrey700,
    height: 72,
    paddingLeft: theme.spacing.M,
    border: 'none',
    borderRadius: 2,
    '&:hover': { cursor: 'pointer' },
  },
  title: {
    ...theme.typography.urwBold(18),
    fontWeight: 600,
  },
  chevron: {
    stroke: theme.colors.secondaryGrey500,
    strokeWidth: 3,
    marginRight: 30,
  },
  accordionItem: {
    textDecoration: 'none',
    color: theme.colors.secondaryGrey900,
    flexDirection: 'column',
    backgroundColor: theme.colors.secondaryGrey100,
  },
  mobileAccordion: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordionIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  accordionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    justifyContent: 'center',
  },
  accordionName: {
    display: 'flex',
    justifyContent: 'center',
  },
  accordionItemBreak: {
    borderColor: theme.colors.secondaryGrey300,
    height: 1,
    margin: 0,
  },
  mobileAccordionTitle: {
    ...theme.typography.bebas(26),
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: theme.colors.secondaryGrey700,
  },
  activeAccordionItem: {
    color: theme.colors.primaryBlack,
  },
  mobileMenubBottomLinksContainers: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileMenuBottomLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    paddingLeft: theme.spacing.M,
    textTransform: 'uppercase',
    height: 72,
    color: theme.colors.secondaryGrey700,
    backgroundColor: theme.colors.secondaryGrey100,
  },
  mailIcon: {
    marginRight: theme.spacing.M,
  },
  inputMobileDiv: {
    border: `2px solid ${theme.colors.secondaryGrey100}`,
  },
  inputMobile: {
    border: 'none',
    width: 300,
    height: 56,
  },
  searchIcon: {
    position: 'absolute',
    right: 24,
    top: 18,
  },
  mobileMenuBackground: {
    backgroundColor: theme.colors.secondaryGrey900,
    opacity: 0.5,
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 100,
  },
}))

type Props = {
  active: boolean
}

export const Navbar: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { active } = props
  const [focusTrapped, setFocusTrapped] = useState(false)
  const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const searchButtonRef = useRef<HTMLButtonElement>(null)
  const escapePressed = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsMobileMenuOpen(false)
      setFocusTrapped(false)
    }
  }, [])

  const toggleOpen = () => {
    setIsMobileMenuOpen((prevState) => !prevState)
  }

  const openMenu = () => {
    setIsMobileMenuOpen(true)
  }

  const closeMenu = () => {
    setIsMobileMenuOpen(false)
  }

  const checkActive = (route: string) => {
    return window.location.pathname.toString() === route
  }

  useEffect(() => {
    if (active) {
      setFocusTrapped(active)
    }
    if (!isMobileMenuOpen) {
      setFocusTrapped(false)
    }
    if (!isGlobalSearchOpen) {
      searchButtonRef.current?.focus()
    }
    document.addEventListener('keydown', escapePressed)
    return () => {
      document.removeEventListener('keydown', escapePressed)
    }
  }, [isMobileMenuOpen, active, escapePressed, isGlobalSearchOpen])

  return (
    <>
      <LayoutContainer
        containerClass={classes.navBar}
        contentClass={classes.navBarContent}
      >
        <div className={classes.logoContainer}>
          <Link to={Routes.home} aria-label="Salsa Cycles Home">
            <Logo />
          </Link>
        </div>
        <nav className={classes.navLinksContainer} aria-label="primary">
          <ul className={classes.navLinkList}>
            <li>
              <NavLink
                className={classes.navItems}
                activeClassName={classes.activeNavItem}
                to={Routes.bikes}
                aria-controls="bikes_menu"
              >
                Bikes
              </NavLink>
            </li>
            <li>
              <NavLink
                className={classes.navItems}
                activeClassName={classes.activeNavItem}
                to={Routes.gear}
                aria-controls="gear_menu"
              >
                Gear
              </NavLink>
            </li>
            <li>
              <NavLink
                className={classes.navItems}
                activeClassName={classes.activeNavItem}
                to={Routes.salsa}
                aria-controls="salsa_menu"
              >
                Salsa
              </NavLink>
            </li>
            <li>
              <NavLink
                className={classes.navItems}
                activeClassName={classes.activeNavItem}
                to={Routes.journal}
                aria-controls="journal_menu"
              >
                Journal
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className={classNames(classes.navItems, classes.navIconContainer)}>
          <button
            ref={searchButtonRef}
            className={classes.searchIconContainer}
            tabIndex={0}
            aria-label="Open global search dialog"
            onClick={() => setIsGlobalSearchOpen(true)}
            onKeyPress={() => {
              setIsGlobalSearchOpen(true)
            }}
          >
            <FeatherIcon
              name="search"
              size={24}
              color={theme.colors.secondaryGrey500}
            />
          </button>
        </div>
        <GlobalSearch
          active
          show={isGlobalSearchOpen}
          onExit={() => setIsGlobalSearchOpen(false)}
        />
        {!isMobileMenuOpen && (
          <button
            className={classes.navMobileMenuOpen}
            onClick={openMenu}
            onKeyPress={openMenu}
            aria-label="open mobile navigation"
          >
            <FeatherIcon
              name="menu"
              className={classes.menu}
              color={theme.colors.secondaryGrey500}
            />
            <span className={classes.srOnly}>OPEN MENU</span>
          </button>
        )}
        {isMobileMenuOpen && (
          <FocusTrap active={focusTrapped}>
            <div
              className={classes.mobileMenu}
              role="dialog"
              aria-modal="true"
              aria-label="menu"
            >
              <button
                className={classes.navMobileMenuClose}
                onClick={closeMenu}
                onKeyPress={closeMenu}
                aria-label="close mobile navigation"
              >
                <FeatherIcon
                  name="x"
                  className={classes.menu}
                  color={theme.colors.secondaryGrey500}
                />
                <span className={classes.srOnly}>CLOSE MENU</span>
              </button>
              <ul className={classes.mobileMenuList}>
                <li
                  className={classes.inputMobileDiv}
                  tabIndex={0}
                  aria-label="open global search modal"
                  onKeyPress={() => {
                    setIsMobileMenuOpen(false)
                    setIsGlobalSearchOpen(true)
                  }}
                  onClick={() => {
                    setIsMobileMenuOpen(false)
                    setIsGlobalSearchOpen(true)
                  }}
                >
                  <FeatherIcon
                    name="search"
                    size={24}
                    className={classes.searchIcon}
                    color={theme.colors.secondaryGrey700}
                  />
                  <span className={classes.srOnly}>SEARCH</span>
                  <label htmlFor="mobile_search" className={classes.srOnly}>
                    Search
                  </label>
                  <input
                    id="mobile_search"
                    className={classes.inputMobile}
                    type="text"
                  ></input>
                </li>
                <li className={classes.mobileLinks}>
                  <MobileAccordion
                    className={classes.mobileAccordion}
                    title="Bikes"
                    route={Routes.bikes}
                    onClick={toggleOpen}
                  >
                    <ul id="bikes_menu_mobile">
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Mountain Bikes'}
                          route={Routes.mountainBikes}
                          icon={
                            <MountainBikesIcon
                              color={
                                checkActive(Routes.mountainBikes)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Gravel & Road Bikes'}
                          route={Routes.roadBikes}
                          icon={
                            <RoadBikesIcon
                              color={
                                checkActive(Routes.roadBikes)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Fat Tire Bikes'}
                          route={Routes.fatTireBikes}
                          icon={
                            <FatTireIcon
                              color={
                                checkActive(Routes.fatTireBikes)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'EBikes'}
                          route={Routes.eBikes}
                          icon={
                            <FlashIcon
                              color={
                                checkActive(Routes.eBikes)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'All Bikes'}
                          route={Routes.allBikes}
                          icon={
                            <BikeIcon
                              color={
                                checkActive(Routes.allBikes)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Archived Bikes'}
                          route={Routes.archivedBikes}
                          icon={
                            <ArchivedGearIcon
                              color={
                                checkActive(Routes.archivedBikes)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                    </ul>
                  </MobileAccordion>
                </li>
                <li className={classes.mobileLinks}>
                  <MobileAccordion
                    className={classes.mobileAccordion}
                    route={Routes.gear}
                    title="Gear"
                    onClick={toggleOpen}
                  >
                    <ul id="gear_menu_mobile">
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Clothing'}
                          route={Routes.clothing}
                          icon={
                            <ClothingIcon
                              color={
                                checkActive(Routes.clothing)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Bike Accesories'}
                          route={Routes.accessories}
                          icon={
                            <AccesoriesIcon
                              color={
                                checkActive(Routes.accessories)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Bike Parts'}
                          route={Routes.parts}
                          icon={
                            <BikePartsIcon
                              color={
                                checkActive(Routes.parts)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Archived Gear'}
                          route={Routes.archivedGear}
                          icon={
                            <ArchivedGearIcon
                              color={
                                checkActive(Routes.clothing)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                    </ul>
                  </MobileAccordion>
                </li>
                <li className={classes.mobileLinks}>
                  <MobileAccordion
                    className={classes.mobileAccordion}
                    route={Routes.salsa}
                    title="Salsa"
                    onClick={toggleOpen}
                  >
                    <ul id="salsa_menu_mobile">
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'About Salsa'}
                          route={Routes.about}
                          icon={
                            <GlobeIcon
                              color={
                                checkActive(Routes.about)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Adventure'}
                          route={Routes.campaign}
                          icon={
                            <CampaignIcon
                              color={
                                checkActive(Routes.campaign)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Features & Technology'}
                          route={Routes.features}
                          icon={
                            <FeaturesAndTechIcon
                              color={
                                checkActive(Routes.features)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Events'}
                          route={Routes.events}
                          icon={
                            <EventsIcon
                              color={
                                checkActive(Routes.events)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Sponsored Riders'}
                          route={Routes.team}
                          icon={
                            <SponsoredTeamIcon
                              color={
                                checkActive(Routes.events)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                    </ul>
                  </MobileAccordion>
                </li>
                <li className={classes.mobileLinks}>
                  <MobileAccordion
                    className={classes.mobileAccordion}
                    title="Journal"
                    route={Routes.journal}
                    onClick={toggleOpen}
                  >
                    <ul id="journal_menu_mobile">
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Article'}
                          route={Routes.articles}
                          icon={
                            <ArticleIcon
                              color={
                                checkActive(Routes.clothing)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Video'}
                          route={Routes.videos}
                          icon={
                            <VideoIcon
                              color={
                                checkActive(Routes.clothing)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                      <li>
                        <AccordionDiv
                          onClick={toggleOpen}
                          name={'Story'}
                          route={Routes.stories}
                          icon={
                            <StoryIcon
                              color={
                                checkActive(Routes.clothing)
                                  ? theme.colors.primaryBlack
                                  : theme.colors.secondaryGrey500
                              }
                            />
                          }
                        />
                      </li>
                    </ul>
                  </MobileAccordion>
                  <div className={classes.mobileMenubBottomLinksContainers}>
                    <ul>
                      <li>
                        <NavLink
                          className={classes.mobileMenuBottomLink}
                          to={Routes.contact}
                          onClick={toggleOpen}
                          role="button"
                        >
                          <FeatherIcon
                            name="mail"
                            size={24}
                            color={theme.colors.secondaryGrey700}
                            className={classes.mailIcon}
                          />
                          Contact
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={classes.mobileMenuBottomLink}
                          to={Routes.dealers}
                          onClick={toggleOpen}
                          role="button"
                        >
                          Dealers
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={classes.mobileMenuBottomLink}
                          to={Routes.supportAndSafety}
                          onClick={toggleOpen}
                          role="button"
                        >
                          Support and Safety
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </FocusTrap>
        )}
      </LayoutContainer>
    </>
  )
}

interface AccordionItemProps {
  title: string
  as?: keyof JSX.IntrinsicElements
  className?: string
  titleClassName?: string
  route: string
  onClick?: () => void
}

export const MobileAccordion: React.FC<AccordionItemProps> = (props) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(Boolean)

  const toggleExpanded = () => setIsOpen((prev) => !prev)

  const ContainerComponent = props.as ?? 'div'

  return (
    <ContainerComponent className={props.className}>
      <button
        className={classNames(classes.titleContainer, props.titleClassName)}
        tabIndex={0}
        onClick={toggleExpanded}
        onKeyPress={toggleExpanded}
        aria-controls={`${props.route.substring(1)}_menu_mobile`}
        aria-expanded={isOpen ? 'true' : 'false'}
        style={{
          backgroundColor: isOpen
            ? theme.colors.primaryBlack
            : theme.colors.primaryWhite,
          color: isOpen
            ? theme.colors.secondaryGrey300
            : theme.colors.secondaryGrey500,
        }}
      >
        <NavLink
          className={classes.mobileAccordionTitle}
          to={props.route || '/'}
          style={{
            color: isOpen
              ? theme.colors.secondaryGrey300
              : theme.colors.secondaryGrey500,
          }}
          onClick={props.onClick}
        >
          {props.title}
        </NavLink>

        <FeatherIcon
          className={classes.chevron}
          name={isOpen ? 'chevron-up' : 'chevron-down'}
          size={24}
        />
      </button>

      {isOpen && props.children}
    </ContainerComponent>
  )
}

interface AccordionDivProps {
  name: string
  route: string
  icon: JSX.Element
  onClick?: () => void
}

const AccordionDiv: React.FC<AccordionDivProps> = (props) => {
  const { name, route, icon, onClick } = props
  const classes = useStyles()

  return (
    <NavLink
      to={route || Routes.home}
      className={classes.accordionItem}
      style={{ color: theme.colors.secondaryGrey500 }}
      role="button"
      activeStyle={{
        color: theme.colors.primaryBlack,
      }}
    >
      <div className={classes.accordionItemContainer} onClick={onClick}>
        <div className={classes.accordionIcon}>{icon}</div>
        <div className={classes.accordionName}>{name}</div>
      </div>
      <hr className={classes.accordionItemBreak} />
    </NavLink>
  )
}
