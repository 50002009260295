import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../../theme'
import * as Routes from '../../routes'
import { RouteLabels } from '../../routes'
import { LayoutContainer } from '../../components/LayoutContainer'
import { CallToAction } from '../../components/CallToAction'
import { useQuery } from 'react-query'
import { Entry } from '../../types'
import { fetchApiData } from '../../utils'
import { SupportAndSafetyLandingQuery_entry_supportAndSafetyLanding_supportAndSafetyLanding_Entry as SupportAndSafetyLandingQuery } from '../../../src/api-types/SupportAndSafetyLandingQuery'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    width: 700,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing.XXL,
    marginBottom: theme.spacing.XXXL,
  },
  sectionHeading: {
    ...theme.typography.bebas(31),
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
    paddingBottom: theme.spacing.S,
    marginBottom: theme.spacing.L,
  },
  navLink: {
    ...theme.typography.urwBoldInput(18),
    color: theme.colors.primaryRed,
    marginBottom: theme.spacing.L,
  },
}))

export const SafetyAndSupportLanding: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('/supportandsafety', () =>
    fetchApiData<Entry<SupportAndSafetyLandingQuery>>('supportandsafety'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const cta =
    data?.entry?.callToActionInternalLink &&
    data?.entry?.callToActionInternalLink[0]
  const ctaLink = cta?.linkTo
  const ctaLabel = cta?.label
  const ctaText = cta?.linkLabel

  return (
    <LayoutContainer contentClass={classes.container}>
      <h1 className={classes.srOnly}>Support &amp; Safety</h1>
      <h2 className={classes.sectionHeading}>Support</h2>
      <Link to={Routes.bikeRegistration} className={classes.navLink}>
        {RouteLabels[Routes.bikeRegistration]}
      </Link>
      <Link to={Routes.faq} className={classes.navLink}>
        {RouteLabels[Routes.faq]}
      </Link>
      <Link to={Routes.warranty} className={classes.navLink}>
        {RouteLabels[Routes.warranty]}
      </Link>
      <h2 className={classes.sectionHeading}>Safety</h2>
      <Link to={Routes.recalls} className={classes.navLink}>
        {RouteLabels[Routes.recalls]}
      </Link>
      <Link to={Routes.downloads} className={classes.navLink}>
        {RouteLabels[Routes.downloads]}
      </Link>
      <CallToAction
        title={ctaLabel || ''}
        route={ctaLink || '/'}
        text={ctaText || ''}
      />
    </LayoutContainer>
  )
}
