import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../theme'
import { Button } from './Button'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    padding: 96,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  CTAText: {
    ...theme.typography.bebas(26),
    color: theme.colors.primaryBlack,
    marginBottom: theme.spacing.M,
  },
  button: {
    backgroundColor: theme.colors.primaryRed,
    ...theme.typography.bebasButton(26),
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      padding: '12px 24px',
      letterSpacing: 0.8,
    },
  },
}))

interface CTAProps {
  title: string
  route: string
  text: string
}

export const CallToAction: React.FC<CTAProps> = (props) => {
  const classes = useStyles()
  const { title, route, text } = props
  return (
    <div className={classes.container}>
      <h2 className={classes.CTAText}>{title}</h2>
      <Link
        to={{ pathname: route }}
        target={route.includes('http') ? '_blank' : '_self'}
      >
        <Button className={classes.button}>{text}</Button>
      </Link>
    </div>
  )
}
