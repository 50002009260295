import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'

const useStyles = createUseStyles((theme: Theme) => ({
  productTitle: {
    ...theme.typography.bebas(120),
    letterSpacing: 2,
    color: theme.colors.primaryWhite,
    textShadow: '0px 0px 1px rgba(13, 13, 13, 0.9), 0px 10px 30px #0D0D0D',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export const ProductTitle: React.FC = ({ children }) => {
  const classes = useStyles()
  return <h1 className={classes.productTitle}>{children}</h1>
}
