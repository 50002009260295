import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { Theme } from '../theme'
import { useSliderRef } from './carousel/carousel.hooks'
import { FeatherIcon } from './icons/FeatherIcon'
import {
  classNames,
  getAssetUrl,
  getRouteForPlatform,
  getRouteForProduct,
} from '../utils'
import { productEntry as ProductEntry } from '../api-types/productEntry'
import { platformEntry as PlatformEntry } from '../api-types/platformEntry'
import { relatedProducts_productEntries as RelatedProductEntries } from '../api-types/relatedProducts'
import { usePlatforms } from '../hooks'

interface Props {
  className?: string
  slidesToShow?: number
  entries: (RelatedProductEntries | null)[] | null
}

// TODO: optional aspect ratio control for slide images
export const ProductCarousel: React.FC<Props> = (props) => {
  const classes = useProductCarouselStyles(props)
  const theme = useTheme<Theme>()
  const [sliderRef, handleForward, handleBack] = useSliderRef()
  const platformNames: string[] =
    props.entries?.reduce((names, entry) => {
      if (entry?.__typename === 'platformPage_platformPage_Entry') {
        names.push((entry as PlatformEntry).title as string)
      }
      return names
    }, [] as string[]) || []
  const platformData = usePlatforms({
    names: platformNames,
    enabled: platformNames.length > 0,
  })

  if (!props.entries?.length) {
    return null
  }

  return (
    <div
      className={classNames(classes.container, props.className)}
      role="region"
      aria-label="carousel"
    >
      <p className={classes.srOnly}>
        This is a carousel. Use Next and Previous buttons to navigate slides.
      </p>
      <Slider
        ref={sliderRef}
        className={classes.slider}
        infinite={(props.slidesToShow ?? 3) < (props.entries?.length || 0)}
        arrows={false}
        centerMode
        slidesToShow={props.slidesToShow ?? 3}
        responsive={[
          {
            breakpoint: theme.breakpoints.md,
            settings: {
              slidesToShow: 2,
              infinite:
                (props.slidesToShow ?? 2) < (props.entries?.length || 0),
            },
          },
          {
            breakpoint: theme.breakpoints.sm,
            settings: {
              slidesToShow: 1,
              infinite:
                (props.slidesToShow ?? 1) < (props.entries?.length || 0),
            },
          },
        ]}
      >
        {props.entries?.map((entry, index) => {
          if (entry?.__typename === 'platformPage_platformPage_Entry') {
            const data = entry as PlatformEntry
            const platform = platformData[data.title || '']
            const missionStatement =
              data?.missionStatement && data?.missionStatement[0]
            if (!platform) return null
            return (
              <div
                key={index}
                className={classes.slide}
                role="group"
                aria-label={`slide ${index} of ${entry.title?.length}`}
              >
                <Link
                  to={getRouteForPlatform(platform?.hierarchy, platform?.name)}
                  className={classes.slideLink}
                  style={{ display: 'flex' }}
                >
                  <img
                    src={getAssetUrl(platform.images[0])}
                    alt={platform.name}
                  />
                  <p className={classes.title}>{data.title}</p>
                  {missionStatement?.heading && (
                    <p className={classes.description}>
                      {missionStatement.heading}
                    </p>
                  )}
                  {Number.isInteger(platform.lowestPrice) && (
                    <p className={classes.price}>
                      {platform.lowestPrice === platform.highestPrice
                        ? `$${platform.lowestPrice.toLocaleString()}`
                        : `$${platform.lowestPrice.toLocaleString()}-$${platform.highestPrice.toLocaleString()}`}
                    </p>
                  )}
                </Link>
              </div>
            )
          } else {
            const data = entry as ProductEntry
            return (
              <div key={index} className={classes.slide}>
                <Link
                  to={getRouteForProduct(
                    data.productCategory || '',
                    data.productUrlSlug || '404',
                  )}
                  className={classes.slideLink}
                  style={{ display: 'flex' }}
                >
                  <img
                    src={
                      (data.productAssets && data.productAssets[0]?.url) || ''
                    }
                    alt={
                      (data.productAssets && data.productAssets[0]?.alttext) ||
                      ''
                    }
                  />
                  <p className={classes.title}>{data.title}</p>
                  <p className={classes.price} />
                </Link>
              </div>
            )
          }
        })}
      </Slider>
      <button
        onClick={handleBack}
        className={classes.sliderButton}
        style={{ left: 0 }}
      >
        <FeatherIcon
          name="chevron-left"
          color={theme.colors.secondaryGrey500}
          size={40}
        />
        <span className={classes.srOnly}>Previous</span>
      </button>
      <button
        onClick={handleForward}
        className={classes.sliderButton}
        style={{ right: 0 }}
      >
        <FeatherIcon
          name="chevron-right"
          color={theme.colors.secondaryGrey500}
          size={40}
        />
        <span className={classes.srOnly}>Next</span>
      </button>
    </div>
  )
}
const useProductCarouselStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: (props: Props) => ({
    position: 'relative',
    '& > button': {
      display: 'block',
    },
  }),
  slider: {
    width: '100%',
    paddingLeft: 40,
    paddingRight: 40,
    '&& .slick-track': {
      display: 'flex',
    },
    '&& .slick-slide': {
      height: 'auto',
    },
    '&& .slick-slide > div': {
      height: '100%',
    },
  },
  sliderButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: 'calc(50% - 20px)',
    padding: 0,
    borderStyle: 'none',
    background: 'none',
    '& > svg': {
      strokeWidth: 3,
    },
  },
  slide: {
    height: '100%',
  },
  slideLink: {
    paddingLeft: 40,
    paddingRight: 40,
    textDecoration: 'none',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing.L,
      paddingRight: theme.spacing.L,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.M,
      paddingRight: theme.spacing.M,
    },
    '& > img': {
      margin: '0 auto',
      maxHeight: 200,
      width: '100%',
    },
  },
  title: {
    ...theme.typography.bebas(24),
    textAlign: 'center',
    color: theme.colors.secondaryGrey700,
    marginTop: theme.spacing.M,
  },
  description: {
    ...theme.typography.urw(16),
    color: theme.colors.secondaryGrey900,
    textAlign: 'center',
    marginTop: theme.spacing.XS,
  },
  price: {
    ...theme.typography.urwBold(14),
    color: theme.colors.secondaryGrey900,
    textAlign: 'center',
    marginTop: theme.spacing.XS,
  },
  availability: {
    ...theme.typography.urw(14),
    color: theme.colors.secondaryGrey900,
    textAlign: 'center',
    marginTop: theme.spacing.XS,
  },
}))
