import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import Slider from 'react-slick'
import { Theme } from '../theme'
import { useSliderRef } from './carousel/carousel.hooks'
import { FeatherIcon } from './icons/FeatherIcon'
import { Link } from 'react-router-dom'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: (props: Props) => ({
    position: 'relative',
    '& > button': {
      display: props.items.length > 4 ? 'block' : 'none',
      [theme.breakpoints.down('md')]: {
        display: props.items.length > 3 ? 'block' : 'none',
      },
      [theme.breakpoints.down('sm')]: {
        display: props.items.length > 1 ? 'block' : 'none',
      },
    },
  }),
  slider: (props: Props) => {
    const leftPadding = 40 + theme.spacing.L
    return {
      paddingLeft: leftPadding,
      paddingRight: 40,
      '& .slick-list': {
        overflow: props.items.length > 4 ? 'hidden' : 'visible',
        [theme.breakpoints.down('md')]: {
          overflow: props.items.length > 3 ? 'hidden' : 'visible',
        },
        [theme.breakpoints.down('sm')]: {
          overflow: props.items.length > 1 ? 'hidden' : 'visible',
        },
      },
      '& .slick-track': {
        marginLeft: props.items.length > 4 ? 'auto' : -leftPadding,
        [theme.breakpoints.down('md')]: {
          marginLeft: props.items.length > 3 ? 'auto' : -leftPadding,
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: props.items.length > 1 ? 'auto' : -leftPadding,
        },
      },
    }
  },
  sliderButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: 'calc(50% - 20px)',
    padding: 0,
    borderStyle: 'none',
    background: 'none',
    '& > svg': {
      strokeWidth: 3,
      stroke: theme.colors.secondaryGrey700,
    },
  },
  sliderItem: {
    paddingRight: theme.spacing.L,
  },
  imageContainer: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    paddingTop: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  contentContainer: {
    padding: theme.spacing.M,
  },
  itemTitle: {
    ...theme.typography.bebas(24),
    color: theme.colors.secondaryGrey700,
    marginBottom: 2,
  },
  itemDescription: {
    ...theme.typography.urw(16),
    color: '#404146',
    marginBottom: 2,
  },
  itemLink: {
    ...theme.traits.clickable,
    ...theme.typography.urwButton(16),
    display: 'flex',
    alignItems: 'center',
    color: '#404146',
  },
  linkIcon: {
    strokeWidth: 3,
    stroke: theme.colors.secondaryGrey700,
    marginLeft: theme.spacing.S,
  },
}))

type Props = {
  className?: string
  items: {
    imageSrc: string
    title: string
    description: string
    linkLabel?: string
    linkTo: string
  }[]
}

/** List view that is static at low item counts and a slider at high counts */
export const AdaptiveList: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const theme = useTheme<Theme>()
  const [sliderRef, handleForward, handleBack] = useSliderRef()

  return (
    <div
      className={classes.container}
      role="region"
      aria-label="feature carousel"
    >
      <p className={classes.srOnly}>
        This is a carousel. Use Next and Previous buttons to navigate slides.
      </p>
      <Slider
        ref={sliderRef}
        className={classes.slider}
        infinite={props.items.length > 4}
        slidesToShow={4}
        arrows={false}
        responsive={[
          {
            breakpoint: theme.breakpoints.md,
            settings: {
              slidesToShow: 3,
              infinite: props.items.length > 3,
            },
          },
          {
            breakpoint: theme.breakpoints.sm,
            settings: {
              slidesToShow: 1,
              infinite: props.items.length > 1,
            },
          },
        ]}
      >
        {props.items.map((item, index) => (
          <div
            className={classes.sliderItem}
            key={index}
            role="group"
            aria-label={`slide ${index + 1} of ${props.items.length}`}
          >
            <div className={classes.imageContainer}>
              <img
                src={item.imageSrc}
                alt={`${item.title} view ${index + 1}`}
                className={classes.image}
              />
            </div>
            <div className={classes.contentContainer}>
              <h3 className={classes.itemTitle}>{item.title}</h3>
              <p className={classes.itemDescription}>{item.description}</p>
              <Link
                to={{ pathname: item.linkTo, hash: item.title }}
                className={classes.itemLink}
              >
                {item.linkLabel ?? 'View More'}
                <FeatherIcon
                  name="chevron-right"
                  className={classes.linkIcon}
                  size={24}
                />
              </Link>
            </div>
          </div>
        ))}
      </Slider>
      <button
        onClick={handleBack}
        className={classes.sliderButton}
        style={{ left: 0 }}
      >
        <FeatherIcon name="chevron-left" size={40} />
        <span className={classes.srOnly}>Previous</span>
      </button>
      <button
        onClick={handleForward}
        className={classes.sliderButton}
        style={{ right: 0 }}
      >
        <FeatherIcon name="chevron-right" size={40} />
        <span className={classes.srOnly}>Next</span>
      </button>
    </div>
  )
}
