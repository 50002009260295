import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'

const useStyles = createUseStyles((theme: Theme) => ({
  logo: {
    width: 278.05,
    height: 40,
    [theme.breakpoints.down('md')]: {
      width: 208.64,
      height: 30,
    },
    [theme.breakpoints.down('sm')]: {
      width: 153,
      height: 22,
    },
  },
}))

export const Logo: React.FC = () => {
  const classes = useStyles()
  return (
    <svg
      role="img"
      className={classes.logo}
      viewBox="0 0 279 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Salsa Cycles logo</title>
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={202}
        y={0}
        width={76}
        height={40}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M202.255.075h75.641V40h-75.641V.075z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.475 23.689h-9.272c-.714 0-.408-.768.148-1.29l14.487-13.605c.416-.39.97-.617 1.505-.617h2.016c.581 0 .879.529.588 1.043l-7.558 13.34c-.381.673-1.153 1.129-1.914 1.129zM277.195.075h-28.226c-.49 0-.999.194-1.4.533l-44.963 38.064c-.605.512-.39 1.328.35 1.328h11.658c.525 0 1.106-.222 1.517-.604l7.403-6.89c.445-.415 1.036-.656 1.605-.656h13.781c.58 0 .878.527.589 1.041l-3.418 6.068c-.29.514.008 1.04.589 1.04h16.968c.689 0 1.382-.446 1.665-1.073L269.141 8.23c.049-.108.12-.21.21-.3l8.261-6.589c.527-.525.283-1.266-.417-1.266z"
          fill="#BE1E2D"
        />
      </g>
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={88}
        height={40}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 .075h87.574V40H0V.075z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.646.075C35.219.075 29.216 5.9 28.028 11.4c-.5 2.315-.629 5.108 2.314 8.516l7.833 7.541c1.57 1.512-.206 4.393-2.708 4.393H9.69c-.593 0-1.227.256-1.672.7L.282 38.645C-.25 39.176.012 40 .72 40h43.997c7.593-.038 13.41-5.748 14.614-11.325.917-4.245-.78-6.846-2.44-8.637l-7.53-7.254c-1.646-1.586.217-4.608 2.84-4.608h25.264c.557 0 1.677-.245 2.097-.661l7.724-6.162c.534-.53.287-1.278-.422-1.278H42.646z"
          fill="#BE1E2D"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M232.139 0h-43.951c-7.381 0-13.349 5.836-14.529 11.346-.497 2.32-.625 5.118 2.3 8.532l7.785 7.556c1.561 1.514-.204 4.401-2.691 4.401l-39.161.01c-1.392-.005-1.944-1.425-.987-2.54l24.048-27.99c.497-.578.208-1.315-.515-1.315h-15.894c-.646 0-1.312.317-1.754.835l-32.245 37.808c-.51.598-.211 1.357.534 1.357l33.335-.004-.003.004H190.247c7.547-.038 13.329-5.758 14.526-11.346.911-4.253-.775-6.86-2.425-8.654l-7.485-7.268c-1.636-1.588.215-4.616 2.823-4.616h25.111c.554 0 1.667-.246 2.084-.662l7.678-6.174c.531-.531.285-1.28-.42-1.28z"
        fill="#BE1E2D"
      />
      <mask
        id="prefix__c"
        maskUnits="userSpaceOnUse"
        x={56}
        y={0}
        width={76}
        height={40}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.33.075h75.641V40h-75.64V.075z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__c)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.55 23.689h-9.272c-.714 0-.407-.768.148-1.29l14.488-13.605c.415-.39.969-.617 1.504-.617h2.016c.582 0 .879.529.588 1.043l-7.558 13.34c-.381.673-1.153 1.129-1.914 1.129zM131.27.075h-28.226c-.49 0-.999.194-1.399.533L56.681 38.672c-.604.512-.39 1.328.35 1.328H68.69a2.31 2.31 0 001.517-.604l7.403-6.89c.445-.415 1.036-.656 1.606-.656h13.78c.58 0 .879.527.59 1.041l-3.419 6.068c-.29.514.009 1.04.59 1.04h16.967a1.89 1.89 0 001.665-1.073L123.216 8.23a1.02 1.02 0 01.211-.3l8.26-6.589c.527-.525.283-1.266-.417-1.266z"
          fill="#BE1E2D"
        />
      </g>
    </svg>
  )
}
