import React from 'react'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../../theme'
import { classNames } from '../../utils'
import { FeatherIcon } from '../icons/FeatherIcon'
import { LoadingIndicator } from '../LoadingIndicator'

interface SearchResultsProps {
  onLoadMore: () => void
  className?: string
  loadMoreClassName?: string
  isLoading?: boolean
  hasNextPage?: boolean
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  resultsCount: {
    display: 'none',
  },
  searchResults: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '5%',
    rowGap: theme.spacing.XXL,
    marginBottom: theme.spacing.XXXL,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  loadingIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  noResults: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    ...theme.typography.urwBold(18),
    color: theme.colors.secondaryGrey500,
  },
  loadMoreContainer: {
    ...theme.traits.flexCentering,
    ...theme.typography.urwBold(21),
    color: theme.colors.secondaryGrey700,
    gridColumn: '1 / -1',
    marginTop: theme.spacing.XXL,
    '&:hover': { cursor: 'pointer' },
    '& span': {
      marginRight: theme.spacing.XS,
    },
  },
}))

export const SearchResults: React.FC<SearchResultsProps> = (props) => {
  const {
    children,
    className,
    isLoading,
    loadMoreClassName,
    onLoadMore,
    hasNextPage,
  } = props
  const classes = useStyles()
  return (
    <div>
      <div
        className={[classes.srOnly, classes.resultsCount].join(' ')}
        aria-live="assertive"
      >
        {React.Children.count(children)} Results
      </div>
      <ul className={classNames(classes.searchResults, className)}>
        {isLoading && (
          <div className={classes.loadingIndicator}>
            <LoadingIndicator useMinHeight={false} />
          </div>
        )}
        {React.Children.count(children) === 0 && !isLoading && (
          <div className={classes.noResults}>No Results</div>
        )}
        {children}
        {React.Children.count(children) > 0 && hasNextPage && (
          <div
            className={classNames(classes.loadMoreContainer, loadMoreClassName)}
            tabIndex={0}
            onClick={onLoadMore}
            onKeyPress={onLoadMore}
          >
            <span>View More</span>
            <FeatherIcon
              name="chevron-down"
              size={32}
              color={theme.colors.secondaryGrey700}
            />
          </div>
        )}
      </ul>
    </div>
  )
}
