import React, { useMemo, useState } from 'react'
import { PlatformFilterGroup } from './PlatformFilterGroup'
import { PlatformFilter } from './PlatformFilter'
import { SearchResultCard } from './SearchResultCard'
import { SearchResults } from './SearchResults'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Dropdown } from '../Dropdown'
import {
  FilterState,
  OptionValue,
  SearchStrategyType,
  useFilters,
  useSearch,
} from '../../hooks'
import { useLocation } from 'react-router-dom'
import {
  Filter,
  Hierarchy,
  JOURNAL_ENTRY_TYPES,
  Platform,
  ProductResponse,
  routeFilters,
  SortOption,
} from '../../product-types'
import { classNames, getAssetUrl, getRouteForProduct } from '../../utils'
import { StoryCard } from '../StoryCard'
import { Entries } from '../../types'
import {
  JournalsQuery_entries_article_article_Entry as ArticleEntry,
  JournalsQuery_entries_video_video_Entry as VideoEntry,
} from '../../api-types/JournalsQuery'
import { InfiniteData } from 'react-query'

type GetOptionFunction = (fieldId: string, optionId: string) => OptionValue

type UpdateStateFunction = (
  fieldId: string,
  optionId: string,
  value: OptionValue,
) => void

export const SearchContext = React.createContext<
  [GetOptionFunction, UpdateStateFunction]
>([() => null, () => null])

interface PlatformSearchProps {
  hierarchy: Hierarchy
  // filter prop to explicitly pass filter data
  // rather than fetch from the API
  pageFilters?: Filter[]
  platform?: string
}

const useFilterRequestFromState = (state: FilterState = {}) => {
  return useMemo(
    () =>
      Object.entries(state).reduce(
        (accumulator, [field, options]) => ({
          ...accumulator,
          [field]: Object.keys(options).filter((option) => options[option]),
        }),
        {},
      ),
    [state],
  )
}

const useStyles = createUseStyles((theme: Theme) => ({
  platformSearch: {
    display: 'flex',
  },
  resultsSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  sortDropdown: {
    alignSelf: 'flex-end',
    marginBottom: theme.spacing.S,
  },
  articleContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
}))

// Drives search for platforms, models, and articles/stories
export const PlatformSearch: React.FC<PlatformSearchProps> = ({
  hierarchy,
  pageFilters,
  platform,
}) => {
  const classes = useStyles()
  const location = useLocation<FilterState | null>()
  const [searchState, setSearchState] = useState<FilterState>(
    location.state ?? {},
  )
  // turn FilterState type into ApiFilter type
  const filterRequest = useFilterRequestFromState(searchState)

  // get true/false to reverse sort for archived bikes
  const setSortDirection = hierarchy === Hierarchy.ARCHIVED ? false : true

  const SORT_OPTIONS: Record<string, SortOption> = {
    name: {
      label: 'Name',
      value: 'name',
      ascending: setSortDirection,
      useKeywordSearch: true,
    },
    lowestPrice: {
      label: 'Lowest Price',
      value: 'lowestPrice',
      ascending: true,
      useKeywordSearch: false,
    },
    highestPrice: {
      label: 'Highest Price',
      value: 'highestPrice',
      ascending: false,
      useKeywordSearch: false,
    },
  }

  const [sortState, setSortState] = useState<SortOption>(SORT_OPTIONS.name)

  const [filters] = useFilters(hierarchy, filterRequest, platform)

  const {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    strategyType: searchStrategyType,
  } = useSearch(hierarchy, filterRequest, sortState, platform)

  const updateOptionValue: UpdateStateFunction = (
    fieldId: string,
    optionId: string,
    value: OptionValue,
  ) => {
    setSearchState((previousState) => {
      const previousFieldState = previousState[fieldId] || {}

      return {
        ...previousState,
        [fieldId]: { ...previousFieldState, [optionId]: value },
      }
    })
  }

  const getOptionValue: GetOptionFunction = (
    fieldId: string,
    optionId: string,
  ) => {
    const field = searchState[fieldId] || {}
    return field[optionId]
  }

  const getResults = () => {
    switch (searchStrategyType) {
      case SearchStrategyType.PRODUCT: {
        const response = data as InfiniteData<ProductResponse>
        return response?.pages.reduce((acc, page) => {
          acc.push(
            ...page.searchHits.map(({ content: product }) => (
              <SearchResultCard
                key={product?.id}
                title={product.name}
                subtitle={
                  product.lowestPrice === 0
                    ? undefined
                    : `$${product.lowestPrice.toLocaleString()}`
                }
                image1={getAssetUrl(
                  product?.fullRecord['Thumbnail Image'] || '',
                )}
                href={getRouteForProduct(
                  product.hierarchy[0]?.code,
                  product?.fullRecord['URL Title'] || '404',
                )}
              />
            )),
          )
          return acc
        }, ([] as unknown) as React.ReactElement[])
      }
      case SearchStrategyType.PLATFORM: {
        const platforms = data as InfiniteData<Platform[]>
        return platforms?.pages.reduce((acc, page) => {
          acc.push(
            ...page?.map((platform) => (
              <SearchResultCard
                key={platform.name}
                filterState={searchState}
                title={platform.name}
                subtitle2={
                  platform.lowestPrice === platform.highestPrice
                    ? `$${platform.lowestPrice.toLocaleString()}`
                    : `$${platform.lowestPrice.toLocaleString()}-$${platform.highestPrice.toLocaleString()}`
                }
                subtitle3={`${platform.numModels} Models Available`}
                image1={getAssetUrl(platform.images[0])}
                href={`${routeFilters[hierarchy]?.route}/${platform.name}`}
              />
            )),
          )
          return acc
        }, ([] as unknown) as React.ReactElement[])
      }
      case SearchStrategyType.JOURNAL: {
        const entries = data as InfiniteData<Entries<ArticleEntry | VideoEntry>>
        return entries?.pages.reduce((acc, page) => {
          acc.push(
            ...(page?.entries?.map((entry) => {
              const type = JOURNAL_ENTRY_TYPES[entry.__typename]
              const category = entry?.productCategory?.replace('_', ' ')
              return (
                <StoryCard
                  key={entry.uid}
                  title={entry?.title || ''}
                  href={`${type.linkTo}/${entry.slug}`}
                  imgSrc={
                    (entry?.imageThumbnail && entry?.imageThumbnail[0]?.url) ||
                    ''
                  }
                  subtitle={type.label.concat(category ? ` | ${category}` : '')}
                  subtitle2={entry.productBlurb || ''}
                  hrefLabel={
                    type.label === 'Video'
                      ? 'Watch the Video'
                      : `Read the ${type.label}`
                  }
                />
              )
            }) || []),
          )
          return acc
        }, ([] as unknown) as React.ReactElement[])
      }
    }
  }

  return (
    <SearchContext.Provider value={[getOptionValue, updateOptionValue]}>
      <div className={classes.platformSearch} role="region" aria-label="filter">
        <PlatformFilterGroup>
          {(pageFilters ?? filters)?.map((filter, i) => (
            <PlatformFilter
              filter={filter}
              filterIndex={i}
              key={i}
              showCount={searchStrategyType !== SearchStrategyType.JOURNAL}
            />
          ))}
        </PlatformFilterGroup>
        <div className={classes.resultsSection}>
          {searchStrategyType !== SearchStrategyType.JOURNAL && (
            <Dropdown
              active
              onChange={(val) => {
                setSortState(SORT_OPTIONS[val])
              }}
              options={Object.values(SORT_OPTIONS)}
              value={sortState.value}
              wrapperClassName={classes.sortDropdown}
            />
          )}
          <SearchResults
            onLoadMore={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading || isFetching}
            className={classNames(
              searchStrategyType === SearchStrategyType.JOURNAL &&
                classes.articleContainer,
            )}
          >
            {getResults()}
          </SearchResults>
        </div>
      </div>
    </SearchContext.Provider>
  )
}
