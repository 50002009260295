import React from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { PlatformDescription } from '../../components/PlatformDescription'
import { HeroImage } from '../../components/HeroImage'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { ProductPageMedia } from '../../components/ProductPageMedia'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { ProductCarouselSection } from '../../components/ProductCarouselSection'
import { NameDescription } from '../../components/NameDescription'
import * as Routes from '../../routes'
import * as routes from '../../routes'
import { features, RouteLabels } from '../../routes'
import { Link } from 'react-router-dom'
import { AdaptiveList } from '../../components/AdaptiveList'
import {
  PlatformPageQuery_entry_platformPage_platformPage_Entry as IPlatformPageQuery,
  PlatformPageQuery_entry_platformPage_platformPage_Entry_relatedKeyFeatures_keyFeature_keyFeature_Entry as KeyFeatureEntry,
} from '../../api-types/PlatformPageQuery'
import { useRouteMatch } from 'react-router'
import { useQuery } from 'react-query'
import { fetchApiData, getHeaderFlexAlignment, jsxJoin } from '../../utils'
import { Entry } from '../../types'
import { PlatformSearch } from '../../components/search/PlatformSearch'
import { routeFilters } from '../../product-types'
import { useHierarchyForCategoryRoute } from '../../hooks'
import { Helmet } from 'react-helmet'
import { LayoutContainer } from '../../components/LayoutContainer'
import { LoadingIndicator } from '../../components/LoadingIndicator'

interface PlatformPageQuery extends IPlatformPageQuery {
  relatedKeyFeatures: (KeyFeatureEntry | null)[]
}

const useStyles = createUseStyles((theme: Theme) => ({
  storyCard: {
    marginRight: theme.spacing.L,
    '&:nth-of-type(4)': {
      marginRight: 0,
    },
  },
  productPageMedia: {
    marginBottom: theme.spacing.XXXL * 2,
  },
  bikeInAction: {
    marginBottom: theme.spacing.XXXL,
  },
  categoryButton: {
    ...theme.typography.bebasButton(26),
    ...theme.traits.flexCentering,
    color: theme.colors.primaryWhite,
    background: theme.colors.secondaryGrey500,
    borderRadius: 2,
    padding: `12px ${theme.spacing.L}px`,
    height: 64,
    letterSpacing: 0.8,
  },
  sectionHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.L,
  },
  keyFeatures: {
    ...theme.traits.gridColumn(),
    paddingLeft: theme.spacing.L,
    paddingRight: theme.spacing.L,
    marginBottom: theme.spacing.XXXL,
  },
  mobileHeadingContainer: {
    backgroundColor: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
  },
  mobileHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryWhite,
    textAlign: 'center',
  },
  mobileLinkContainer: {
    padding: theme.spacing.L,
    display: 'flex',
    justifyContent: 'center',
  },
  mobileTextContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export const ModelSearchPage: React.FC = () => {
  const classes = useStyles()
  const { params } = useRouteMatch<{ platform: string }>()
  const hierarchy = useHierarchyForCategoryRoute()

  const url = `platformpage/${params.platform}`
  const { data, isLoading } = useQuery(url, () =>
    fetchApiData<Entry<PlatformPageQuery>>(url),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const platform = data?.entry?.title
  const hero1 = data?.entry?.hero && data?.entry?.hero[0]
  const hero1Image = hero1?.image && hero1?.image[0]
  const hero2 = data?.entry?.hero && data?.entry?.hero[1]
  const hero2Image = hero2?.image && hero2?.image[0]
  const missionStatement =
    data?.entry?.missionStatement && data?.entry?.missionStatement[0]
  const media = data?.entry?.media && data?.entry?.media[0]
  const nameDescription =
    data?.entry?.bikeLineNameDescription &&
    data?.entry?.bikeLineNameDescription[0]
  const hierarchyRoute = routeFilters[hierarchy]?.route || ''

  return (
    <>
      <Helmet>
        {missionStatement && (
          <meta
            property="og:description"
            content={missionStatement.body ?? ''}
          />
        )}
        {hero1Image && (
          <meta property="og:image" content={hero1Image.url ?? ''} />
        )}
      </Helmet>
      <HeroImage
        src={hero1Image?.url || ''}
        alt={hero1Image?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero1?.headingAlignment)}
      >
        <ProductTitle>{hero1?.heading}</ProductTitle>
      </HeroImage>
      <div className={classes.mobileTextContainer}>
        <div className={classes.mobileHeadingContainer}>
          <h1 className={classes.mobileHeading}>
            {jsxJoin(hero1?.heading || '', '\n', <br />)}
          </h1>
        </div>
      </div>
      <LayoutContainer>
        <Breadcrumbs
          entries={[
            { routeUrl: routes.bikes, label: RouteLabels[routes.bikes] },
            {
              routeUrl: hierarchyRoute,
              label: RouteLabels[hierarchyRoute],
            },
            {
              routeUrl: `${routes.bikes}/platform/${params.platform}`,
              label: data?.entry?.title || '',
            },
          ]}
        />
        <PlatformDescription
          description={missionStatement?.body || ''}
          heading={missionStatement?.heading || ''}
        />
        <PlatformSearch hierarchy={hierarchy} platform={platform || ''} />
        {media && (
          <ProductPageMedia
            source={media?.url || ''}
            title={
              (data?.entry?.sectionHeadings &&
                data?.entry?.sectionHeadings[0]?.headingText) ||
              ''
            }
            type={media?.mimeType || ''}
            className={classes.productPageMedia}
          />
        )}
        {data?.entry?.platformAssets && data?.entry?.platformAssets.length > 0 && (
          <ProductCarouselSection
            className={classes.bikeInAction}
            title={
              (data?.entry?.sectionHeadings &&
                data?.entry?.sectionHeadings[1]?.headingText) ||
              'Bike in action'
            }
            media={
              data?.entry?.platformAssets?.map((asset) => ({
                src: asset?.url || '',
                alt: asset?.alttext || '',
              })) || []
            }
          />
        )}
        {nameDescription && (
          <NameDescription
            title={nameDescription?.heading || ''}
            body={nameDescription?.body || ''}
            image={
              (nameDescription?.image && nameDescription?.image[0]?.url) || ''
            }
            imageAlt={
              (nameDescription?.image && nameDescription?.image[0]?.alttext) ||
              ''
            }
          />
        )}
        {data?.entry?.relatedKeyFeatures &&
          data?.entry?.relatedKeyFeatures?.length > 0 && (
            <div className={classes.keyFeatures}>
              <h2 className={classes.sectionHeading}>
                {(data?.entry?.sectionHeadings &&
                  data?.entry?.sectionHeadings[2]?.headingText) ||
                  'Key Features'}
              </h2>
              <AdaptiveList
                items={
                  data?.entry?.relatedKeyFeatures.map((feature) => ({
                    imageSrc:
                      (feature?.imageThumbnail &&
                        feature.imageThumbnail[0]?.url) ||
                      '',
                    title: feature?.title || '',
                    linkTo: features,
                    linkLabel: 'View More',
                    description: feature?.productBlurb || '',
                  })) || []
                }
              />
            </div>
          )}
      </LayoutContainer>
      <HeroImage
        src={hero2Image?.url || ''}
        alt={hero2Image?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero2?.headingAlignment)}
      >
        <ProductTitle>
          {hero2?.heading || Routes.RouteLabels[hierarchyRoute]}
        </ProductTitle>
        <Link
          to={hero2?.linkUrl || hierarchyRoute}
          className={classes.categoryButton}
        >
          {hero2?.linkLabel ||
            `Explore All ${Routes.RouteLabels[hierarchyRoute]}`}
        </Link>
      </HeroImage>
    </>
  )
}
