import * as React from 'react'

export const SponsoredTeamIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width={31}
      height={30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 8.75a5 5 0 11-10 0 5 5 0 0110 0zm-2.5 0a2.5 2.5 0 10-5 0 2.5 2.5 0 005 0zM25.5 12.5a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zm-2.5 0a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zm-5.075 6.313A6.25 6.25 0 0121.75 17.5 6.25 6.25 0 0128 23.75a1.25 1.25 0 01-2.5 0 3.75 3.75 0 00-6.075-2.937A8.625 8.625 0 0120.5 25a1.25 1.25 0 01-2.5 0 6.25 6.25 0 10-12.5 0A1.25 1.25 0 013 25a8.75 8.75 0 0114.925-6.187z"
        fill="#6f6f6f"
      />
    </svg>
  )
}
