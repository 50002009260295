import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { SocialFooter } from './SocialFooter'
import { CopyrightFooter } from './CopyrightFooter'
import QBPLogo from './icons/QBPLogo.svg'
import BCorpLogo from './icons/BCorpLogo.svg'
import a360Src from './icons/A360-Badge-White-on-Black.svg'
import { Link } from 'react-router-dom'

const useStyles = createUseStyles((theme: Theme) => ({
  footerDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      marginBottom: theme.spacing.L,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  centerContent: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      order: 1,
      marginBottom: theme.spacing.M,
    },
  },
  leftLogo: {
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing.XXL,
      textAlign: 'center',
    },
  },
  rightLogo: {
    [theme.breakpoints.down('md')]: {
      order: 3,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing.M,
      textAlign: 'center',
    },
  },
  a360logo: {
    marginLeft: theme.spacing.XL,
  },
}))

export const FooterDetails: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.footerDetails}>
      <div className={classes.leftLogo}>
        <Link
          to={{ pathname: 'https://www.qbp.com/' }}
          target={'_blank'}
          aria-label="QBP Home"
        >
          <img src={QBPLogo} alt="QBP Logo" />
        </Link>
      </div>
      <div className={classes.centerContent}>
        <SocialFooter />
        <CopyrightFooter />
      </div>
      <div className={classes.rightLogo}>
        <Link
          to={{ pathname: 'https://www.qbp.com/b-corp' }}
          target={'_blank'}
          aria-label="B Corporation page for QBP"
        >
          <img
            src={BCorpLogo}
            alt="Certified B Corporation. This company meets the highest standards of social and environmental impact."
          />
        </Link>
      </div>
      <div className={classes.rightLogo}>
        <a target="_blank" href="https://accessible360.com" rel="noreferrer">
          <img
            src={a360Src}
            className={classes.a360logo}
            alt="Reviewed by Accessible360"
            width="100px"
          />
        </a>
      </div>
    </div>
  )
}
