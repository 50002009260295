import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { AlertBar } from '../../components/AlertBar'
import { Button } from '../../components/Button'
import { HeroImage } from '../../components/HeroImage'
import { Theme } from '../../theme'
import { Entries } from '../../types'
import { fetchApiData, getHeaderFlexAlignment } from '../../utils'
import { HomePageContext } from './Homepage'
import { BannerQuery_entries_eventBanner_eventBanner_Entry as BannerEntriesQuery } from '../../api-types/BannerQuery'
import { RecallsQuery_entries_recallSection_recallSection_Entry as Recall } from '../../api-types/RecallsQuery'
import * as routes from '../../routes'

const useStyles = createUseStyles((theme: Theme) => ({
  backgroundContainer: {
    ...theme.traits.flexCentering,
    justifyContent: 'flex-end',
    padding: '256px 48px',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      padding: '96px 48px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      height: 300,
    },
  },
  textContainer: {
    color: theme.colors.primaryWhite,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileTextContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  button: {
    backgroundColor: theme.colors.secondaryGrey900,
    ...theme.typography.bebasButton(26),
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      backgroundColor: theme.colors.primaryRed,
      padding: '12px 24px',
      letterSpacing: 0.8,
    },
  },
  majorTitle: {
    ...theme.typography.bebas(120),
    letterSpacing: 2,
    textShadow: `0px 10px 30px ${theme.colors.primaryShadow}`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  minorTitle: {
    ...theme.typography.urw(48),
    textShadow: `0px 8px 16px ${theme.colors.primaryShadow}`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileHeadingContainer: {
    backgroundColor: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
  },
  mobileHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryWhite,
    textAlign: 'center',
  },
  mobileLinkContainer: {
    padding: theme.spacing.L,
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const HomeHeroSection: React.FC = () => {
  const classes = useStyles()
  const homeData = useContext(HomePageContext)

  const { data } = useQuery('/banners', () =>
    fetchApiData<Entries<BannerEntriesQuery>>('banners'),
  )

  const alertData = useQuery(['recalls', { showAsBanner: true }], () =>
    fetchApiData<Entries<Recall>, { showAsBanner: boolean }>('recalls', {
      query: { showAsBanner: true },
    }),
  )

  const cmsHero = homeData?.homePageHero && homeData?.homePageHero[0]
  const cmsHeroMedia = cmsHero?.image && cmsHero.image[0]
  const buttonText = cmsHero && cmsHero.buttonText
  const minorHeading = cmsHero && cmsHero.minorHeading
  const majorHeading = cmsHero && cmsHero.majorHeading
  const heading = [minorHeading, majorHeading]
  const link = cmsHero && cmsHero.homePageHeroLink

  const banners = data?.entries && data?.entries
  const alerts = alertData.data?.entries

  return (
    <div>
      {banners?.map((b, i) => {
        const bannerCheck = (b?.banner ?? [])[0]
        if (!bannerCheck) return null
        return (
          b?.banner && (
            <AlertBar
              message={
                (b?.banner[0] && b?.banner[0].bannerText) || 'No Current Event'
              }
              linkText={
                (b?.banner[0] && b?.banner[0]?.bannerLinkText) || 'salsa'
              }
              linkTo={(b?.banner[0] && b?.banner[0]?.bannerUrl) || 'salsa.com'}
              variant={
                b?.banner[0] && b?.banner[0]?.bannerType === 'info'
                  ? 'info'
                  : 'warning'
              }
            />
          )
        )
      })}
      {alerts?.map((alert) => (
        <AlertBar
          message={alert.recallBannerText || ''}
          linkText="See Recall"
          linkTo={`${routes.recalls}/${alert?.slug}`}
          variant={'warning'}
        />
      ))}
      <HeroImage
        src={cmsHeroMedia?.url || ''}
        alt={'Salsa Home Page'}
        justifyContent={getHeaderFlexAlignment(cmsHero?.alignment)}
        type={cmsHeroMedia?.mimeType || 'video'}
      >
        <div className={classes.textContainer}>
          <h1>
            <div className={classes.minorTitle}>{minorHeading}</div>
            <div className={classes.majorTitle}>{majorHeading}</div>
          </h1>
          <Link to={link ? link : ''}>
            <Button className={classes.button}>{buttonText}</Button>
          </Link>
        </div>
      </HeroImage>
      <div className={classes.mobileTextContainer}>
        <div className={classes.mobileHeadingContainer}>
          <h1 className={classes.mobileHeading}>{heading.join(' ')}</h1>
        </div>
        <div className={classes.mobileLinkContainer}>
          <Link to={link ? link : ''}>
            <Button className={classes.button}>{buttonText}</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
