import React from 'react'
import { Switch, Route } from 'react-router-dom'
import * as Routes from '../../routes'
import { BikesLanding } from './BikesLanding'
import { AllBikes } from './AllBikes'
import { CategoryNav } from '../../components/CategoryNav'
import { ArchivedBikes } from './ArchivedBikes'
import { FatTire } from './FatTire'
import { RoadBikes } from './RoadBikes'
import { MountainBikes } from './MountainBikes'
import { Ebikes } from './Ebikes'
import { ArchivedGearIcon } from '../../categoryIcons/ArchivedGearIcon'
import { MountainBikesIcon } from '../../categoryIcons/MountainBikesIcon'
import { RoadBikesIcon } from '../../categoryIcons/RoadBikesIcon'
import { FatTireIcon } from '../../categoryIcons/FatTire'
import { FlashIcon } from '../../categoryIcons/FlashIcon'
import { BikeIcon } from '../../categoryIcons/BikeIcon'
import { ProductDetailPage } from '../ProductDetailPage'
import { ModelSearchPage } from './ModelSearchPage'

export const BikesRouter: React.FC = () => {
  return (
    <>
      <CategoryNav routes={bikeCategoryNav} />
      <Switch>
        <Route path={Routes.bikePlatformSearch} component={ModelSearchPage} />
        <Route path={Routes.mountainBikes} component={MountainBikes} />
        <Route path={Routes.roadBikes} component={RoadBikes} />
        <Route path={Routes.fatTireBikes} component={FatTire} />
        <Route path={Routes.eBikes} component={Ebikes} />
        <Route path={Routes.allBikes} component={AllBikes} />
        <Route path={Routes.archivedBikes} component={ArchivedBikes} />
        <Route path={Routes.bikeDetail} component={ProductDetailPage} />
        <Route path={Routes.bikes} component={BikesLanding} />
      </Switch>
    </>
  )
}

export const bikeCategoryNav = [
  {
    route: Routes.mountainBikes,
    label: 'Mountain Bikes',
    icon: <MountainBikesIcon />,
  },
  {
    route: Routes.roadBikes,
    label: 'Gravel & Road Bikes',
    icon: <RoadBikesIcon />,
  },
  {
    route: Routes.fatTireBikes,
    label: 'Fat Tire',
    icon: <FatTireIcon />,
  },
  {
    route: Routes.eBikes,
    label: 'EBikes',
    icon: <FlashIcon />,
  },
  {
    route: Routes.allBikes,
    label: 'All Bikes',
    icon: <BikeIcon />,
  },
  {
    route: Routes.archivedBikes,
    label: 'Archived Bikes',
    icon: <ArchivedGearIcon />,
  },
]
