import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { MarketoForm } from '../MarketoForm'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    background: 'rgb(244, 244, 244)',
    padding: `70px ${theme.spacing.L}px 90px`,
    '& #mktoForm_1760': {
      width: 'auto !important',
      maxWidth: '460px !important',
      '& .mktoFieldDescriptor': {
        width: '100% !important',
      },
      '& .mktoFieldWrap': {
        width: '100% !important',
      },
      '& input': {
        width: '100% !important',
        maxWidth: '460px !important',
      },
      '& .mktoHtmlText': {
        fontSize: '12px !important',
        lineHeight: 'normal',
        width: 'auto !important',
        maxWidth: '100% !important',
        marginTop: '16px !important',
        marginBottom: '20px !important',
        '& a, em': {
          padding: '0px !important',
        },
      },
      '& .mktoButtonRow': {
        width: '100% !important',
      },
      '& .mktoButtonWrap': {
        marginLeft: '0px !important',
      },
      '& .mktoButton': {
        margin: 'auto !important',
        [theme.breakpoints.down('md')]: {
          marginTop: '10px !important',
          fontSize: '26px !important',
          lineHeight: '40px !important',
        },
      },
    },
  },
  labelContainer: {
    textAlign: 'center',
  },
  label: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey700,
    marginBottom: 25,
  },
  subLabel: {
    fontWeight: 300,
    margin: '15px 0 25px 0',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginBottom: 20,
    },
  },
}))

export const CampaignSignup: React.FC = () => {
  const classes = useStyles()

  const handleFormReady = useCallback(() => {
    document
      .getElementById('Email')
      ?.setAttribute('aria-autocomplete', 'inline')
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.labelContainer}>
        <h2 className={classes.label}>Join the adventure!</h2>
        <p className={classes.subLabel}>
          Sign up to get stories, tips, new product info and more.
        </p>
      </div>
      <MarketoForm formId="1760" onReady={handleFormReady} />
    </div>
  )
}
