import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'
import { FeatherIcon } from './icons/FeatherIcon'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  input: {
    ...theme.typography.urwInput(21),
    padding: `${theme.spacing.M}px ${theme.spacing.L}px`,
    borderRadius: 4,
    paddingRight: (props: TextFieldProps) => (props.endIcon ? 80 : 24),
    '&:disabled': {
      backgroundColor: theme.colors.secondaryGrey700,
      color: 'white',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 3px white',
      borderRadius: 4,
      border: '3px solid #CC0033',
    },
  },
  endIcon: {
    cursor: 'pointer',
    fill: 'white',
    position: 'absolute',
    right: theme.spacing.L,
    top: 32,
    transform: 'translateY(-50%)',
    height: 32,
    width: 32,
    stroke: theme.colors.secondaryGrey500,
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing.M,
      height: 24,
      width: 24,
    },
    '& :hover': {
      stroke: theme.colors.secondaryGrey900,
    },
  },
  buttonHide: {
    borderStyle: 'none',
    padding: 0,
  },
}))

export interface TextFieldProps extends React.HTMLAttributes<HTMLInputElement> {
  endIcon?: string
  containerClassName?: string
  success?: string
  autocomplete?: string
  ariaLabel?: string
}

export const TextField: React.FC<TextFieldProps> = (props) => {
  const {
    containerClassName,
    className,
    endIcon,
    success,
    ariaLabel,
    autocomplete,
    ...restProps
  } = props
  const classes = useStyles(props)

  return (
    <div className={classNames(classes.container, containerClassName)}>
      <input
        className={classNames(classes.input, className)}
        {...restProps}
        value={success}
        autoComplete={autocomplete}
        disabled={Boolean(success)}
      />

      {endIcon && (
        <button
          type={'submit'}
          className={classes.buttonHide}
          aria-label={ariaLabel}
          disabled={Boolean(success)}
        >
          <FeatherIcon className={classes.endIcon} name={endIcon} size={24} />
        </button>
      )}
    </div>
  )
}
