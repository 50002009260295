import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarketoForm } from '../../MarketoForm'

const useStyles = createUseStyles((theme: Theme) => ({
  topSection: {
    ...theme.traits.gridColumn({
      lgCols: 6,
      lgStart: 4,
      mdCols: 6,
      mdStart: 2,
      smCols: 4,
      smStart: 1,
    }),
  },
  gridContainer: {
    ...theme.traits.gridLayout,
    marginTop: 24,
    marginBottom: 102,
  },
  topSectionTitleFont: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey900,
    marginBottom: 16,
  },
  topSectionSubTitleFont: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey900,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  topSectionParagraphFont: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
    marginBottom: 48,
  },
}))

export const SalsaEventRegistration: React.FC = () => {
  const classes = useStyles()
  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.gridContainer}>
        <div className={classes.topSection}>
          <h1 className={classes.topSectionTitleFont}>
            Event Sponsorship Request
          </h1>
          <p className={classes.topSectionParagraphFont}>
            Thank you for your interest in working with Salsa Cycles! Please
            fill out the form below for consideration of sponsorship for your
            event or partnership opportunity. Sponsorship for events and
            initiatives taking place before November 2022 have been secured. We
            are now accepting applications until March 15th, 2022 for
            sponsorships taking place between November 2022 - November 2023.
            Applications placed after March 15th will be deferred to the
            following year.
          </p>
          <p className={classes.topSectionParagraphFont}>
            We will contact you if we are interested in pursuing the opportunity
            presented. Due to a large number of inquiries, we will not be
            responding to every application.
          </p>
          <div>
            <MarketoForm formId="1186" />
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}
