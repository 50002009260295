import * as React from 'react'

export const BikePartsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width={30}
      height={10}
      viewBox="0 0 30 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.601.395c-.376-.076-.897-.128-1.421-.17A64.072 64.072 0 0018.931.1C17.318.033 15.586 0 14.596 0h-.004c-.81 0-2.342 0-3.879.05C9.3.096 7.745.187 6.772.395H4.524a1.25 1.25 0 00-1.135.727L1.58 5.044A1.251 1.251 0 000 6.25v2.5a1.25 1.25 0 002.385.523l2.94-6.378h1.477c.658.108 1.856.176 3.206.221 1.492.05 3.294.075 5.06.075 1.766 0 3.507-.025 4.874-.075a39.283 39.283 0 001.754-.096c.234-.02.45-.042.632-.07.082-.011.19-.028.306-.055h2.042l2.939 6.378A1.25 1.25 0 0030 8.75v-2.5a1.25 1.25 0 00-1.581-1.206L26.61 1.122a1.25 1.25 0 00-1.135-.727h-2.875z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
