import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { HeaderTopBar } from './HeaderTopBar'
import { Navbar } from './NavBar'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    zIndex: theme.layers.header,
  },
}))

export const Header: React.FC = () => {
  const classes = useStyles()

  return (
    <header className={classes.container}>
      <HeaderTopBar />
      <Navbar active />
    </header>
  )
}
