import React, { useMemo } from 'react'
import { RouteLabels } from '../routes'
import { useLocation, NavLink } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { Home } from './icons/Home'

interface Props {
  entries?: {
    routeUrl: string
    label: string
  }[]
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.M,
    paddingBottom: theme.spacing.M,
  },
  homeIcon: {
    fill: theme.colors.secondaryGrey700,
    marginRight: theme.spacing.S,
  },
  slash: {
    margin: '0 4px',
  },
  navLink: {
    ...theme.typography.urwInput(16),
    color: theme.colors.secondaryGrey500,
  },
  activeNavLink: {
    color: theme.colors.primaryBlack,
    fontWeight: 400,
  },
}))

export const Breadcrumbs: React.FC<Props> = ({ entries }) => {
  const { pathname } = useLocation()
  const classes = useStyles()

  const routeEntries = useMemo(() => {
    return (
      entries ??
      Object.entries(RouteLabels)
        .filter(([routeUrl]) => pathname.indexOf(routeUrl) === 0)
        .sort(([routeA], [routeB]) => (routeA.length > routeB.length ? 1 : -1))
        .map(([routeUrl, label]) => ({ routeUrl, label }))
    )
  }, [pathname, entries])

  return (
    <nav className={classes.container} role="region" aria-label="breadcrumbs">
      <Home className={classes.homeIcon} />
      {routeEntries.map(({ routeUrl, label }, index) => (
        <NavLink
          key={routeUrl}
          exact
          to={routeUrl}
          className={classes.navLink}
          activeClassName={classes.activeNavLink}
          isActive={() => index === routeEntries.length - 1}
        >
          {label}
          {index !== routeEntries.length - 1 && (
            <span className={classes.slash}>/</span>
          )}
        </NavLink>
      ))}
    </nav>
  )
}
