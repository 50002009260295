import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Link } from 'react-router-dom'
import { AnimatedImageHeightCarousel } from '../../components/carousel/AnimatedHeightCarousel'
import { CallToAction } from '../../components/CallToAction'
import { fetchApiData, getHeaderFlexAlignment, jsxJoin } from '../../utils'
import {
  BikesLandingPageQuery_entry_bikesLandingPage_bikesLandingPage_Entry as BikesLandingPageQuery,
  BikesLandingPageQuery_entry_bikesLandingPage_bikesLandingPage_Entry_productCarousels_productEntries_product_product_Entry as ProductEntry,
  BikesLandingPageQuery_entry_bikesLandingPage_bikesLandingPage_Entry_productCarousels as ProductCarousel,
} from '../../api-types/BikesLandingPageQuery'
import { useQuery } from 'react-query'
import { Entry } from '../../types'
import { HeroImage } from '../../components/HeroImage'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { Button } from '../../components/Button'

interface Carousel extends ProductCarousel {
  productEntries: (ProductEntry | null)[] | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    '& > div:not(:last-child)': {
      paddingBottom: 20,
    },
  },
  sectionContainer: {
    ...theme.traits.flexCentering,
    color: 'black',
    display: 'flex',
    height: 640,
    marginBottom: 72,
    backgroundSize: 'cover',
    '&:nth-child(4)': {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      height: 320,
    },
  },
  sectionTitle: {
    ...theme.typography.bebas(120),
    color: theme.colors.primaryWhite,
    textShadow: `0px 10px 30px ${theme.colors.secondaryGrey900}`,
  },
  imageContainer: {
    justifyContent: 'center',
  },
  sectionLabel: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    textAlign: 'center',
    marginLeft: 48,
    marginRight: 48,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    ...theme.traits.flexCentering,
    ...theme.typography.bebasButton(26),
    padding: '12px 24px',
    color: theme.colors.primaryWhite,
    background: theme.colors.primaryRed,
    borderRadius: 2,
    borderStyle: 'none',
    textDecoration: 'none',
  },
  mobileButton: {
    backgroundColor: theme.colors.secondaryGrey900,
    marginBottom: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      backgroundColor: theme.colors.primaryRed,
    },
  },
  mobileHeadingContainer: {
    backgroundColor: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
  },
  mobileHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryWhite,
    textAlign: 'center',
  },
  mobileLinkContainer: {
    padding: theme.spacing.L,
    display: 'flex',
    justifyContent: 'center',
  },
  mobileTextContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export const BikesLanding: React.FC = () => {
  const classes = useStyles()
  const { data, isLoading } = useQuery('bikes/landingpage', () =>
    fetchApiData<Entry<BikesLandingPageQuery>>('bikes/landingpage'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const carousels = (data?.entry?.productCarousels || []) as Carousel[]
  const callToAction =
    data?.entry?.callToActionInternalLink &&
    data?.entry?.callToActionInternalLink[0]
  return (
    <>
      <h1 className={classes.srOnly}>Bikes</h1>
      <div className={classes.container}>
        {data?.entry?.hero?.map((hero, i) => (
          <div key={hero?.uid}>
            <HeroImage
              src={(hero?.image && hero?.image[0]?.url) || ''}
              alt={(hero?.image && hero?.image[0]?.alttext) || ''}
              justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
              type={(hero?.image && hero?.image[0]?.mimeType) || ''}
            >
              <div className={classes.sectionLabel}>
                <h2 className={classes.sectionTitle}>
                  {jsxJoin(hero?.heading || '', '\n', <br />)}
                </h2>
                <Link to={hero?.linkUrl || ''} className={classes.button}>
                  {hero?.linkLabel}
                </Link>
              </div>
            </HeroImage>
            <div className={classes.mobileTextContainer}>
              <div className={classes.mobileHeadingContainer}>
                <h2 className={classes.mobileHeading}>
                  {jsxJoin(hero?.heading || '', '\n', <br />)}
                </h2>
              </div>
              <div className={classes.mobileLinkContainer}>
                <Link to={hero?.linkUrl || ''}>
                  <Button className={classes.button}>{hero?.linkLabel}</Button>
                </Link>
              </div>
            </div>
            {data?.entry?.productCarousels &&
              data?.entry?.productCarousels[i] && (
                <AnimatedImageHeightCarousel
                  entries={carousels[i]?.productEntries}
                  centerPaddingPercentage={27}
                  imageWidthPercentage={58}
                />
              )}
          </div>
        ))}
      </div>
      <CallToAction
        text={callToAction?.linkLabel || ''}
        title={callToAction?.label || ''}
        route={callToAction?.linkTo || ''}
      />
    </>
  )
}
