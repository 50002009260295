import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../../theme'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LayoutContainer } from '../../components/LayoutContainer'
import { useQuery } from 'react-query'
import { RecallsQuery_entries_recallSection_recallSection_Entry as Recall } from '../../api-types/RecallsQuery'
import { Entries } from '../../types'
import { fetchApiData } from '../../utils'
import * as routes from '../../routes'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: 700,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing.L,
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  sectionHeading: {
    ...theme.typography.bebas(31),
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
    paddingBottom: theme.spacing.S,
    marginBottom: theme.spacing.L,
  },
  navLink: {
    ...theme.typography.urwBoldInput(18),
    color: theme.colors.primaryRed,
    marginBottom: theme.spacing.L,
  },
}))

export const SafetyAndSupportRecalls: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('/recalls', () =>
    fetchApiData<Entries<Recall>>('recalls'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const recallArray = data?.entries

  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.container}>
        <h1 className={classes.sectionHeading}>Recalls</h1>
        {recallArray?.map((recall) => (
          <Link
            to={`${routes.recalls}/${recall?.slug}`}
            className={classes.navLink}
          >
            {recall?.title}
          </Link>
        ))}
      </div>
    </LayoutContainer>
  )
}
