import React from 'react'

export const Home: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4201 8.1797L9.71011 0.299705V0.299704C9.32119 -0.0924175 8.68803 -0.0950135 8.29591 0.293906C8.29397 0.295831 8.29204 0.297764 8.29012 0.299705L0.580115 8.1897H0.580115C0.20327 8.56959 -0.00563149 9.08464 0.000115515 9.6197V17.9997L0.000115489 17.9997C-0.00148438 19.0627 0.82874 19.9412 1.89014 19.9997H16.1101C17.1715 19.9412 18.0017 19.0627 18.0001 17.9997V9.6197V9.61968C18.0009 9.08261 17.7929 8.56627 17.4201 8.17969L17.4201 8.1797ZM7.00011 17.9997V11.9997H11.0001V17.9997H7.00011ZM16.0001 17.9997H13.0001V10.9997C13.0001 10.4474 12.5524 9.9997 12.0001 9.9997H6.00011C5.44783 9.9997 5.00011 10.4474 5.00011 10.9997V17.9997H2.00011V9.5797L9.00011 2.4297L16.0001 9.6197V17.9997Z"
        fill="#989898"
      />
    </svg>
  )
}
