import React, { createContext, useContext } from 'react'
import { useRouteMatch } from 'react-router'
import { ProductDetailView } from '../components/ProductDetailView'
import {
  ProductPageQuery_entry_product_product_Entry as IProductPageQuery,
  ProductPageQuery_entry_product_product_Entry_productFeatures_keyFeature_keyFeature_Entry as KeyFeatureEntry,
  ProductPageQuery_entry_product_product_Entry_relatedFaq_faq_faq_Entry as RelatedFaq,
} from '../api-types/ProductPageQuery'
import { relatedProducts as RelatedProducts } from '../api-types/relatedProducts'
import { useQuery } from 'react-query'
import { fetchApiData } from '../utils'
import { Entry } from '../types'
import { Helmet } from 'react-helmet'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { useHistory } from 'react-router-dom'
import * as Routes from '../routes'

interface ProductPageQuery extends IProductPageQuery {
  relatedProducts: RelatedProducts[] | null
  productFeatures: KeyFeatureEntry[] | null
  relatedFaq: RelatedFaq[] | null
}

const ProductPageQueryContext = createContext<ProductPageQuery | null>(null)
export const useProductPageQuery = (): ProductPageQuery | null =>
  useContext(ProductPageQueryContext)

export const ProductDetailPage: React.FC = () => {
  const {
    params: { productSlug },
  } = useRouteMatch<{ productSlug: string }>()
  const history = useHistory()

  const { data, isLoading, isFetched } = useQuery(
    ['productpage', productSlug],
    () => fetchApiData<Entry<ProductPageQuery>>(`productpage/${productSlug}`),
  )

  if (isFetched && !data?.entry) {
    history.replace(Routes.notFound)
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <ProductPageQueryContext.Provider value={data?.entry || null}>
      {data && (
        <Helmet>
          <title>{`${data.entry?.title} | Salsa Cycles`}</title>
          <meta
            property="og:description"
            content={data.entry?.productBlurb ?? ''}
          />
          <meta
            property="og:image"
            content={(data.entry?.productAssets ?? [])[0]?.url ?? ''}
          />
        </Helmet>
      )}
      <ProductDetailView productName={productSlug} />
    </ProductPageQueryContext.Provider>
  )
}
