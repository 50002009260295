export const home = '/'

export const bikes = '/bikes'
export const mountainBikes = `${bikes}/mountain`
export const roadBikes = `${bikes}/road`
export const fatTireBikes = `${bikes}/fat-tire`
export const eBikes = `${bikes}/ebike`
export const allBikes = `${bikes}/all`
export const archivedBikes = `${bikes}/archived`
export const bikeDetail = `${bikes}/:productSlug`

export const bikePlatformSearch = `${bikes}/:category/:platform`

export const gear = '/gear'
export const gearDetail = `${gear}/:productSlug`
export const gearLanding = `${gear}/landing`
export const clothing = `${gear}/clothing`
export const accessories = `${gear}/accessories`
export const parts = `${gear}/parts`
export const allGear = `${gear}/all`
export const archivedGear = `${gear}/archived`

export const salsa = '/salsa'
export const staff = `${salsa}/salsa-crew`
export const staffMember = `${staff}/:staffSlug`
export const career = `${salsa}/career`
export const about = `${salsa}/about`
export const features = `${salsa}/features`
export const team = `${salsa}/sponsored-riders`
export const riderRegistration = `${team}/rider_registration`
export const rider = `${team}/:riderSlug`
export const events = `${salsa}/events`
export const eventRegistration = `${events}/event_registration`
export const contact = `${salsa}/contact`
export const press = `${salsa}/press`
export const careers = `${salsa}/careers`
export const media = `${salsa}/media`
export const campaign = `${salsa}/adventure-by-bike`

export const journal = '/journal'
export const videos = `${journal}/videos`
export const video = `${journal}/videos/:videoSlug`
export const articles = `${journal}/articles`
export const article = `${journal}/articles/:articleSlug`
export const stories = `${journal}/stories`
export const story = `${journal}/stories/:storySlug`
export const storyPart = `${journal}/stories/:storySlug/parts/:partIndex`

export const dealers = '/dealers'
export const distributors = `${dealers}/distributors`
export const dealerSignup = `${dealers}/signup`

export const supportAndSafety = '/support'
export const bikeRegistration = `${supportAndSafety}/registration`
export const warranty = `${supportAndSafety}/warranty`
export const recalls = `${supportAndSafety}/recalls`
export const recall = `${recalls}/:recallSlug`
export const downloads = `${supportAndSafety}/downloads`
export const faq = `${supportAndSafety}/faq`

export const alert = '/alert'
export const accessibility = '/accessibility'
export const privacy = '/privacy'
export const ccpa = '/privacy-policy-ccpa'
export const gdpr = '/privacy-policy-gdpr'

export const search = '/search'

export const notFound = '/not_found'

export const RouteLabels = {
  [bikes]: 'Bikes',
  [mountainBikes]: 'Mountain Bikes',
  [roadBikes]: 'Gravel & Road Bikes',
  [fatTireBikes]: 'Fat Tire Bikes',
  [eBikes]: 'Ebikes',
  [allBikes]: 'All Bikes',
  [archivedBikes]: 'Archived',

  [gear]: 'Gear',
  [gearLanding]: 'Landing',
  [clothing]: 'Clothing',
  [accessories]: 'Bike Accessories',
  [parts]: 'Bike Parts',
  [allGear]: 'All Gear',
  [archivedGear]: 'Archived Gear',

  [salsa]: 'Salsa',
  [staff]: 'Salsa Crew',
  [career]: 'Career',
  [about]: 'About Salsa',
  [features]: 'Features & Technology',
  [team]: 'Sponsored Riders',
  [riderRegistration]: 'Rider Sponsorship Application',
  [events]: 'Events',
  [eventRegistration]: 'Event Sponsorship Application',
  [contact]: 'Contact',
  [media]: 'Media Mentions',
  [press]: 'Press',
  [campaign]: 'Adventure by Bike',

  [journal]: 'Journal',
  [videos]: 'Video',
  [articles]: 'Articles',
  [stories]: 'Stories',

  [dealers]: 'Dealers',
  [distributors]: 'Distributors',
  [dealerSignup]: 'Become A Salsa Dealer',

  [supportAndSafety]: 'Support & Safety',
  [bikeRegistration]: 'Bike & Frame Registration',
  [warranty]: 'Warranty',
  [recalls]: 'Recalls',
  [downloads]: 'Safety and Instruction Manuals',
  [faq]: 'FAQ',

  [alert]: 'Alert',
  [accessibility]: 'Accessibility',
  [privacy]: 'Privacy Policy',
  [search]: 'Search',
}
