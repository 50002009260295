import React, { useRef, useEffect, useState } from 'react'
import Slider from 'react-slick'
import ResizeObserver from 'resize-observer-polyfill' // Fixes IOS 12 bug where it can't reference ResizeObserver

const SLICK_TRACK_CLASSNAME = '.slick-track'
const SLICK_SLIDE_CLASSNAME = '.slick-slide'
const SLICK_ACTIVE_SLIDE_CLASSNAME = '.slick-active'
const MUTATION_OBSERVER_CONFIG = { attributes: true }

// handles the methods for controlling the slider programmatically
export const useSliderRef = (): [
  React.MutableRefObject<Slider>,
  () => void,
  () => void,
] => {
  const sliderRef = React.useRef<Slider>() as React.MutableRefObject<Slider>

  const handleForward = () => {
    sliderRef.current?.slickNext()
  }

  const handleBack = () => {
    sliderRef.current?.slickPrev()
  }

  return [sliderRef, handleForward, handleBack]
}

// handle click event only when slider isn't dragged
export const useOnLinkClick = (
  containerRef: React.MutableRefObject<HTMLDivElement>,
  callback: (pathname: string) => void,
): [
  React.EventHandler<React.MouseEvent>,
  (pathname: string) => React.EventHandler<React.MouseEvent>,
] => {
  const offsetRef = useRef(0)

  const handleDown = () => {
    const trackNode = getTrackNode(containerRef.current)
    const leftOffset = getLeftOffset(trackNode)
    offsetRef.current = leftOffset || 0
  }

  const getHandleUp = (pathname: string) => (e: React.MouseEvent) => {
    const trackNode = getTrackNode(containerRef.current)
    const leftOffset = getLeftOffset(trackNode)
    if (leftOffset === offsetRef.current) {
      callback(pathname)
    }
  }
  return [handleDown, getHandleUp]
}

// handles the animation of the slides as it's being dragged
export const useObservedAnimationSlider = (
  // the width of the images when they aren't centered
  imageWidthPercentage: number,

  // className of the image, probably generated from react-jss
  imageClassName: string,
): React.MutableRefObject<HTMLDivElement> => {
  const containerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const observerRef = useRef<MutationObserver>() as React.MutableRefObject<MutationObserver>
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  useEffect(() => {
    const slides = getSlides(containerRef.current)
    const images = getImages(containerRef.current, imageClassName)
    const selected = getSelectedSlide(containerRef.current)
    const trackNode = getTrackNode(containerRef.current)

    // listen for when slides gain the 'active' className and set the index
    // in local state. This method seems to be a bit more reliable than tracking
    // via callbacks since it will use the correct index whether or not the slide
    // is a clone.
    const slideObserver = new MutationObserver(
      (mutationsList: MutationRecord[]) => {
        for (const mutation of mutationsList) {
          if (mutation.attributeName === 'class') {
            const node = mutation.target as HTMLDivElement
            // ".split-active" -> "split-active"
            const activeClassName = SLICK_ACTIVE_SLIDE_CLASSNAME.split('.')[1]
            if (node.classList.contains(activeClassName)) {
              const newIndex = slides.indexOf(node)
              setCurrentSlideIndex(newIndex)
            }
          }
        }
      },
    )

    // handles any custom layout changes when need when the window resizes
    const resizeObserver = new ResizeObserver(() => {
      const selectedSlideWidth = selected.clientWidth
      const selectedImage = selected.querySelector('img')
      const selectedImageWidth = selectedImage?.clientWidth || 0
      const trackOffset = (selectedImageWidth - selectedSlideWidth) / 2
      trackNode.style.marginLeft = `-${trackOffset}px`

      images.forEach((image) => {
        if (image === selectedImage) {
          translateLabelContainer(image, 100)
        } else {
          translateLabelContainer(image, imageWidthPercentage)
        }
      })
    })

    slides.forEach((slide) =>
      slideObserver.observe(slide, MUTATION_OBSERVER_CONFIG),
    )

    resizeObserver.observe(trackNode)

    return () => {
      slideObserver.disconnect()
      resizeObserver.disconnect()
    }
  }, [imageWidthPercentage, imageClassName])

  useEffect(() => {
    // so to not create multiple listeners and call
    // the callback multiple times
    observerRef.current?.disconnect()

    const trackNode = getTrackNode(containerRef.current)

    const slides = getSlides(trackNode)
    const images = getImages(trackNode, imageClassName)

    const selected = getSelectedSlide(trackNode)
    if (!selected) {
      return
    }

    const selectedIndex = slides.indexOf(selected)

    const callback = (mutationsList: MutationRecord[]) => {
      let previousLeftOffset = null
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'style') {
          const node = mutation.target as HTMLDivElement
          const leftOffset = getLeftOffset(node)

          // exit iteration if offset hasn't changed (track hasn't moved since last iteration)
          if (leftOffset === previousLeftOffset || leftOffset === null) {
            continue
          }
          previousLeftOffset = leftOffset

          const slideWidth = selected.clientWidth

          const transitionImageWidth = (numSlideBeforeIndex: number) => {
            const indexSlideDistanceToCenter = Math.abs(
              leftOffset - slideWidth * numSlideBeforeIndex,
            )

            // decimal representing how close slide is to center
            // i.e Centered slide is 1, 20% away from center is either -.2
            // or .2 depending on if it's before or after the current slide
            const ratioAwayFromCenter = -(
              indexSlideDistanceToCenter / slideWidth -
              1
            )

            // percentage to add/subtract to the slide image
            const scalePercentageToAdd =
              (100 - imageWidthPercentage) * ratioAwayFromCenter

            // the total scaled percentage
            const widthPercentage = imageWidthPercentage + scalePercentageToAdd

            const selectedImage = images[
              numSlideBeforeIndex
            ] as HTMLImageElement

            // apply style to all images with the same imgIndex attribute
            // this applies same style to clones that react-slick creates
            images.forEach((image) => {
              if (
                image?.dataset?.imgIndex === selectedImage?.dataset?.imgIndex
              ) {
                // only apply new style if in range
                if (
                  widthPercentage >= imageWidthPercentage &&
                  widthPercentage <= 100
                ) {
                  // actually transform the image
                  image.style.transform = `scale(${widthPercentage / 100})`
                  translateLabelContainer(image, widthPercentage)
                } else {
                  image.style.transform = `scale(${imageWidthPercentage / 100})`
                }
              }
            })
          }

          transitionImageWidth(selectedIndex - 1)
          transitionImageWidth(selectedIndex + 1)
          transitionImageWidth(selectedIndex)
        }
      }
      previousLeftOffset = null
    }
    observerRef.current = new MutationObserver(callback)
    observerRef.current.observe(trackNode, MUTATION_OBSERVER_CONFIG)

    return () => {
      observerRef.current?.disconnect()
    }
  }, [currentSlideIndex, imageWidthPercentage, imageClassName])

  return containerRef
}

// returns the offset of the entire track but parsing the translate3d style property
const getLeftOffset = (node: HTMLElement): number | null => {
  const transformStyle = node.style.transform
  if (!transformStyle || !transformStyle.startsWith('translate3d(')) {
    return null
  }
  return Math.abs(
    Number(transformStyle.split('translate3d(')[1].split('px,')[0]),
  )
}

// returns The node that slide-react creates as the actual track for the slides
const getTrackNode = (container: HTMLElement) => {
  return container?.querySelector(SLICK_TRACK_CLASSNAME) as HTMLElement
}

// returns all the slides, including the cloned slides react-slick creates
// to achieve the infinite effect
const getSlides = (container: HTMLElement): HTMLDivElement[] => {
  return Array.from(container?.querySelectorAll(SLICK_SLIDE_CLASSNAME) || [])
}

const getSelectedSlide = (container: HTMLElement) => {
  return container?.querySelector(
    SLICK_ACTIVE_SLIDE_CLASSNAME,
  ) as HTMLDivElement
}

// returns the image elements for each slide
const getImages = (
  container: HTMLElement,
  imageClassName: string,
): HTMLImageElement[] => {
  return Array.from(container?.querySelectorAll('.' + imageClassName) || [])
}

// Returns the corresponding label container element for a slide
const getLabelContainerForImage = (image: HTMLImageElement) => {
  return image.nextElementSibling as HTMLDivElement
}

// apply transition to labelContainer so it moves with image
const translateLabelContainer = (
  image: HTMLImageElement,
  imageScalePercentage: number,
) => {
  const labelContainer = getLabelContainerForImage(image)

  // height of the scaled image
  const actualImageHeight = image.offsetHeight * (imageScalePercentage * 0.01)

  // the amount of space between the top of the scaled image and the top of the container
  const remainingTopOffset = (image.offsetHeight - actualImageHeight) / 2

  labelContainer.style.transform = `translateY(${
    actualImageHeight + remainingTopOffset
  }px)`
}
