import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'
import { FeatherIcon } from './icons/FeatherIcon'

const useStyles = createUseStyles((theme: Theme) => ({
  list: (props: DetailListProps<unknown>) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: theme.spacing.L,
    gridTemplateRows: `repeat(${Object.keys(props.data).length}, 1fr)`,
  }),
  listItem: {
    ...theme.typography.bebas(18),
    gridColumn: 1,
    color: theme.colors.secondaryGrey700,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.S}px ${theme.spacing.M}px`,
    borderRadius: 2,
    '&:hover': {
      cursor: 'pointer',
    },
    '& button': {
      border: 'none',
      cursor: 'pointer',
      font: 'inherit',
      color: 'inherit',
      textAlign: 'inherit',
      backgroundColor: 'transparent',
    },
  },
  activeListItem: {
    color: theme.colors.secondaryGrey900,
    backgroundColor: theme.colors.secondaryGrey300,
    '& > svg': {
      stroke: theme.colors.secondaryGrey900,
      strokeWidth: 3,
    },
  },
  detailView: {
    gridRow: '1 / -1',
    gridColumn: 2,
  },
}))

type DetailListProps<T> = {
  data: Record<string, T>
  renderDetail: (data: T) => JSX.Element
  className?: string
  listItemClassName?: string
}

export function DetailList<T>(props: DetailListProps<T>): JSX.Element {
  const classes = useStyles(props as DetailListProps<unknown>)
  const [openIndex, setOpenIndex] = useState(0)

  return (
    <dl
      className={classNames(classes.list, props.className)}
      role="tablist"
      aria-orientation="vertical"
      aria-label="feature details"
    >
      {Object.entries(props.data).map(([label, value], index) => {
        const isActive = index === openIndex
        const listItem = (
          <dt
            key={label}
            tabIndex={isActive ? 0 : -1}
            className={classNames(
              classes.listItem,
              isActive && classes.activeListItem,
              props.listItemClassName,
            )}
          >
            <button
              id={`tab_${index + 1}`}
              aria-controls={`result_${index + 1}`}
              aria-selected={isActive ? 'true' : 'false'}
              role="tab"
              onClick={() => setOpenIndex(index)}
            >
              {label}
            </button>
            {isActive && <FeatherIcon name="chevron-right" size={32} />}
          </dt>
        )

        if (isActive) {
          return (
            <React.Fragment key={index}>
              {listItem}
              <dd
                className={classes.detailView}
                id={`result_${index + 1}`}
                tabIndex={isActive ? 0 : -1}
                role="tabpanel"
                aria-labelledby={`tab_${index + 1}`}
              >
                {props.renderDetail(value)}
              </dd>
            </React.Fragment>
          )
        }

        return listItem
      })}
    </dl>
  )
}
