import * as React from 'react'

export const CampaignIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width={30}
      height={30}
      viewBox="0 0 31 24.372"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="st0"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color || '#737173'}
        d="M23.449,17.7557c0.2842,0.5312,0.832,0.8623,1.4307,0.8623s1.1465-0.3311,1.4316-0.8652
	c1.9795-3.7988,4.3389-8.7603,4.3389-10.8252c0-3.1816-2.5889-5.77-5.7705-5.77s-5.7695,2.5884-5.7695,5.77
	C19.1101,9.1508,21.8279,14.6649,23.449,17.7557z M24.8796,2.8778c1.085,0,2.1006,0.4243,2.8633,1.1963
	c0.7588,0.7588,1.1768,1.7725,1.1768,2.8535c0,1.3276-1.5605,5.0542-4.083,9.7568c-2.4756-4.7515-4.0068-8.4766-4.0068-9.7568
	C20.8298,4.6947,22.6462,2.8778,24.8796,2.8778z M26.3083,19.3593c-0.0371-0.0195-0.0771-0.0293-0.1182-0.0293
	c-0.0479,0-0.0967,0.0137-0.1377,0.042l-0.1504,0.0986c-0.2461,0.1621-0.542,0.2617-0.9492,0.2998
	c-0.127,0.0146-0.2227,0.1211-0.2227,0.249v1.6191L18.53,20.3251V2.5687l0.7842,0.147c0.0156,0.0029,0.0303,0.0044,0.0459,0.0044
	c0.0693,0,0.1377-0.0293,0.1846-0.082c0.2812-0.3086,0.5781-0.5806,0.8691-0.7969l0.1895-0.1196
	c0.085-0.0542,0.1299-0.1548,0.1123-0.2544S20.6228,1.288,20.5232,1.266l-2.6797-0.5923c-0.0176-0.0039-0.0352-0.0059-0.0537-0.0059
	c-0.0176,0-0.0342,0.002-0.0518,0.0054l-8.3882,1.769l-8.6987-1.829C0.634,0.6097,0.6169,0.6078,0.5999,0.6078
	c-0.0566,0-0.1128,0.0195-0.1577,0.0562C0.384,0.7113,0.3499,0.7826,0.3499,0.8578v20.7495c0,0.1182,0.0825,0.2197,0.1978,0.2441
	l8.73,1.8701c0.0171,0.0039,0.0347,0.0059,0.0522,0.0059s0.0352-0.002,0.0522-0.0059l8.3872-1.7979l8.3691,1.7783
	c0.0166,0.0039,0.0342,0.0059,0.0518,0.0059c0.0566,0,0.1123-0.0195,0.1572-0.0557c0.0586-0.0479,0.0928-0.1191,0.0928-0.1943
	v-3.8779C26.4402,19.4882,26.3894,19.4032,26.3083,19.3593z M8.4998,21.7792l-6.4302-1.374V2.6664l6.4302,1.3638V21.7792z
	 M16.8093,20.3856l-6.5796,1.3936V4.0301l6.5796-1.394V20.3856z M5.3826,10.7093c-0.1797,0.1304-0.2915,0.3188-0.3354,0.5806
	c-0.019,0.2129,0.0435,0.4312,0.1733,0.6104c0.1064,0.1523,0.2563,0.2578,0.4458,0.3135c0.083,0.0244,0.168,0.0371,0.2534,0.0371
	c0.1816,0,0.3569-0.0557,0.522-0.1714c0.2393-0.1934,0.5488-0.3613,1.0005-0.543c0.21-0.0811,0.3838-0.2495,0.4761-0.4614
	c0.0923-0.2119,0.0981-0.4448,0.0142-0.6597c-0.1021-0.252-0.3071-0.4395-0.563-0.5146C7.2972,9.8793,7.2206,9.8686,7.142,9.8686
	c-0.106,0-0.2134,0.0195-0.3232,0.0586C6.2576,10.1405,5.7859,10.3978,5.3826,10.7093z M27.5496,7.0878
	c0-1.4722-1.1973-2.6699-2.6699-2.6699s-2.6699,1.1978-2.6699,2.6699s1.1973,2.6699,2.6699,2.6699S27.5496,8.5599,27.5496,7.0878z
	 M23.9294,7.0878c0-0.5239,0.4258-0.9502,0.9502-0.9502c0.5166,0,0.9424,0.4277,0.9502,0.9502c0,0.5234-0.4258,0.9497-0.9502,0.9497
	S23.9294,7.6112,23.9294,7.0878z M20.5564,16.9676c-0.3721,0-0.7002,0.2432-0.8164,0.6055
	c-0.1074,0.3369,0.0088,0.7109,0.2871,0.9307c0.0537,0.043,0.1641,0.1211,0.2852,0.1553c0.4209,0.1338,0.7939,0.2881,1.1289,0.4639
	c0.123,0.0742,0.2695,0.1143,0.4209,0.1143h0.001c0.082,0,0.165-0.0117,0.2607-0.04c0.1904-0.0693,0.3701-0.2197,0.4834-0.4111
	c0.2119-0.3828,0.0898-0.834-0.3418-1.1719c-0.4072-0.2305-0.8672-0.4229-1.4463-0.6055
	C20.7332,16.9813,20.6453,16.9676,20.5564,16.9676z M5.1174,16.9296c0.103,0.041,0.2119,0.0615,0.3228,0.0615
	c0.3467,0,0.6562-0.1982,0.7852-0.498c0.1011-0.2148,0.1094-0.4541,0.0205-0.6826c-0.0908-0.2109-0.25-0.3789-0.4497-0.4727
	c-0.4288-0.2002-0.7432-0.3936-0.919-0.5616c-0.1011-0.1133-0.2085-0.166-0.3003-0.2021c-0.1011-0.04-0.207-0.0605-0.3149-0.0605
	c-0.2354,0-0.459,0.0967-0.6338,0.2754c-0.3179,0.3438-0.3047,0.8896,0.0347,1.2227C4.0349,16.3553,4.5071,16.6542,5.1174,16.9296z
	 M4.3801,6.7474c0.123,0.0459,0.2446,0.0688,0.3613,0.0688c0.0898,0,0.1782-0.0137,0.2627-0.041
	c0.4102-0.1338,0.9111-0.2422,1.271-0.312C6.4329,6.4247,6.572,6.3461,6.6892,6.2303c0.2109-0.208,0.2998-0.4956,0.2373-0.814
	C6.8601,5.1936,6.7336,5.014,6.5535,4.8924c-0.148-0.0927-0.3037-0.1396-0.4624-0.1396c-0.0601,0-0.1206,0.0068-0.1719,0.019
	C5.2683,4.9032,4.8176,5.016,4.4495,5.1415c-0.1279,0.0483-0.228,0.1104-0.314,0.1953C3.8953,5.5736,3.8137,5.9139,3.9167,6.2079
	C3.9788,6.4398,4.1296,6.6224,4.3801,6.7474z M13.7209,8.702C13.5783,8.9037,13.3,9.1317,12.9211,9.3592
	c-0.1963,0.1123-0.3408,0.2993-0.394,0.5024c-0.0684,0.2168-0.0381,0.4707,0.0815,0.6802c0.1523,0.2622,0.4385,0.4248,0.7466,0.4248
	c0.1323,0,0.2622-0.0337,0.4634-0.1289c0.6284-0.3965,1.0449-0.7583,1.2983-1.1211c0.1362-0.1631,0.1968-0.397,0.1631-0.6592
	c-0.042-0.2104-0.1636-0.4023-0.3384-0.5356c-0.1445-0.1162-0.3184-0.1753-0.5166-0.1753
	C14.1511,8.3466,13.8894,8.4774,13.7209,8.702z M14.4998,15.788c-0.0547-0.0195-0.1621-0.0518-0.3145-0.0518
	c-0.0024,0-0.2739,0.0049-0.2739,0.0049c-0.3403,0-0.6567-0.0225-0.9551-0.0664c-0.0488-0.0098-0.0996-0.0146-0.1509-0.0146
	c-0.1768,0-0.3555,0.0586-0.5151,0.1738c-0.1567,0.1289-0.2754,0.3311-0.3188,0.5488c-0.0713,0.4307,0.1938,0.8154,0.7134,0.9902
	c0.3656,0.0645,0.7421,0.0947,1.1855,0.0947c0.1221,0,0.2485-0.002,0.3794-0.0068c0.228-0.0068,0.439-0.1006,0.5938-0.2646
	c0.1587-0.1689,0.2422-0.3916,0.2354-0.627C15.0686,16.2157,14.8342,15.9012,14.4998,15.788z M12.4202,6.2709
	c0.4727,0.0283,0.8359,0.0928,1.0625,0.1841c0.1343,0.0669,0.2534,0.0762,0.3521,0.0762c0.3486,0,0.6626-0.2134,0.8027-0.5493
	c0.1694-0.4365-0.0444-0.9395-0.4829-1.124c-0.4717-0.1821-1.0205-0.2856-1.6899-0.3164c-0.4653,0-0.8555,0.3687-0.8696,0.8149
	C11.5805,5.5922,11.661,5.8178,11.828,5.998C11.9905,6.1605,12.2004,6.2577,12.4202,6.2709z"
      />
    </svg>
  )
}
