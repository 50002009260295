import React from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../theme'
import { FeatherIcon } from './icons/FeatherIcon'
import { classNames } from '../utils'

interface StoryCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subtitle?: string
  subtitle2?: string
  href: string
  hrefLabel?: string
  imgSrc: string
}

const useStyles = createUseStyles((theme: Theme) => ({
  linkWrapper: {
    textDecoration: 'none',
  },
  card: {
    flexBasis: '100%',
    listStyleType: 'none',
  },
  content: {
    marginLeft: theme.spacing.M,
    marginRight: theme.spacing.M,
    paddingBottom: theme.spacing.M,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing.XL,
    },
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: 448,
    marginBottom: theme.spacing.M,
    aspectRatio: 1,
    [theme.breakpoints.down('md')]: {
      maxHeight: 234,
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 323,
    },
  },
  title: {
    ...theme.typography.bebas(24),
    color: theme.colors.secondaryGrey700,
    marginBottom: 2,
  },
  subtitle: {
    ...theme.typography.bebas(16),
    color: theme.colors.secondaryGrey500,
    marginBottom: 2,
  },
  subtitle2: {
    ...theme.typography.urw(16),
    marginBottom: 2,
    color: theme.colors.secondaryGrey700,
  },
  link: {
    ...theme.typography.urwBoldButton(16),
    color: theme.colors.primaryBlack,
    display: 'flex',
    alignItems: 'center',
  },
}))

export const StoryCard: React.FC<StoryCardProps> = (props) => {
  const {
    title,
    subtitle,
    subtitle2,
    href,
    hrefLabel,
    imgSrc,
    className,
  } = props
  const classes = useStyles()
  const Card = (
    <li
      className={classNames(classes.card, className)}
      role="group"
      aria-label="item"
    >
      <img src={imgSrc} alt="" className={classes.image} />
      <div className={classes.content}>
        <h3 className={classes.title}>{title}</h3>
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
        {subtitle2 && <p className={classes.subtitle2}>{subtitle2}</p>}
        {hrefLabel && (
          <Link to={href} className={classes.link}>
            <span>{hrefLabel}</span>
            <FeatherIcon
              name="chevron-right"
              color={theme.colors.primaryBlack}
              size={24}
            />
          </Link>
        )}
      </div>
    </li>
  )
  if (!hrefLabel) {
    return (
      <Link to={href} className={classes.linkWrapper}>
        {Card}
      </Link>
    )
  }
  return Card
}
