import React from 'react'
import { HeroImage } from '../../components/HeroImage'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { PlatformDescription } from '../../components/PlatformDescription'
import { ProductCarousel } from '../../components/ProductCarousel'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../../theme'
import { StoryCard } from '../../components/StoryCard'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import { classNames, fetchApiData, getHeaderFlexAlignment } from '../../utils'
import { AccordionItem } from '../../components/AccordionItem'
import * as routes from '../../routes'
import { RouteLabels } from '../../routes'
import { useRouteMatch } from 'react-router'
import {
  SalsaSponsoredTeamMemberPageQuery_entry_sponsoredTeamMember_sponsoredTeamMember_Entry as ISalsaSponsoredTeamMemberPageQuery,
  SalsaSponsoredTeamMemberPageQuery_entry_sponsoredTeamMember_sponsoredTeamMember_Entry_relatedProducts as IRelatedProducts,
  SalsaSponsoredTeamMemberPageQuery_entry_sponsoredTeamMember_sponsoredTeamMember_Entry_relatedJournalArticles_article_article_Entry as JournalArticle,
} from '../../api-types/SalsaSponsoredTeamMemberPageQuery'
import { productEntry as ProductEntry } from '../../api-types/productEntry'
import { useQuery } from 'react-query'
import { Entry } from '../../types'
import { LayoutContainer } from '../../components/LayoutContainer'
import { Helmet } from 'react-helmet'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { JOURNAL_ENTRY_TYPES } from '../../product-types'
import * as Routes from '../../routes'
import { useHistory } from 'react-router-dom'

interface SalsaSponsoredTeamMemberPageQuery
  extends ISalsaSponsoredTeamMemberPageQuery {
  relatedJournalArticles: JournalArticle[] | null
}

interface RelatedProducts extends IRelatedProducts {
  productEntries: (ProductEntry | null)[] | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  riderLocation: {
    ...theme.typography.bebas(48),
    letterSpacing: 2,
    color: theme.colors.primaryWhite,
    textShadow: '0px 0px 1px rgba(13, 13, 13, 0.9), 0px 10px 30px #0D0D0D',
    maxWidth: 700,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: theme.spacing.M,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileHeadingContainer: {
    backgroundColor: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
    textAlign: 'center',
  },
  mobileHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryWhite,
    display: 'inline',
  },
  mobileLinkContainer: {
    padding: theme.spacing.L,
    display: 'flex',
    justifyContent: 'center',
  },
  mobileTextContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  description: {
    marginTop: theme.spacing.XXL,
    marginBottom: theme.spacing.XXXL + theme.spacing.M,
  },
  products: {
    marginBottom: theme.spacing.XXXL + theme.spacing.M,
  },
  posts: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: theme.spacing.L,
    marginBottom: 138,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  section: {
    ...theme.traits.gridColumn(),
  },
  sectionHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.L,
  },
  faqContainer: {
    backgroundColor: theme.colors.secondaryGrey100,
    paddingTop: theme.spacing.XXL,
    paddingBottom: theme.spacing.XXL,
    marginBottom: 30,
  },
  faqSectionTitle: {
    color: theme.colors.secondaryGrey900,
    marginBottom: theme.spacing.XXL,
    textAlign: 'center',
  },
  faq: {
    width: theme.measurements.contentNarrowWidth,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  faqAccordionItem: {
    marginBottom: 16,
  },
  faqAccordionTitle: {
    ...theme.typography.urwBold(18),
    backgroundColor: theme.colors.secondaryGrey300,
  },
  faqAccordionContent: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
  },
}))

export const SponsoredRider: React.FC = () => {
  const classes = useStyles()
  const { params } = useRouteMatch<{ riderSlug: string }>()
  const history = useHistory()
  const { data, isLoading, isFetched } = useQuery(
    ['salsa/team/member', params.riderSlug],
    () =>
      fetchApiData<Entry<SalsaSponsoredTeamMemberPageQuery>>(
        `salsateam/${params.riderSlug}`,
      ),
  )

  if (isFetched && !data?.entry) {
    history.replace(Routes.notFound)
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  const hero = data?.entry?.hero && data?.entry?.hero[0]
  const heroImage = hero?.image && hero?.image[0]
  const memberName = hero?.heading || ''
  const bio = data?.entry?.missionStatement && data?.entry?.missionStatement[0]
  const relatedProducts = (data?.entry?.relatedProducts &&
    data?.entry?.relatedProducts[0]) as RelatedProducts

  return (
    <>
      {data && (
        <Helmet>
          <title>{memberName} | Salsa Cycles</title>
          <meta property="og:description" content={bio?.body ?? ''} />
          <meta property="og:image" content={heroImage?.url ?? ''} />{' '}
        </Helmet>
      )}
      <HeroImage
        src={heroImage?.url || ''}
        alt={heroImage?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
      >
        <ProductTitle>{memberName}</ProductTitle>
        <div className={classes.riderLocation}>
          <FeatherIcon
            name="map-pin"
            color={theme.colors.primaryWhite}
            size={42}
          />
          <span>{data?.entry?.sponsoredTeamMemberLocation}</span>
        </div>
      </HeroImage>
      <div className={classes.mobileTextContainer}>
        <div className={classes.mobileHeadingContainer}>
          <FeatherIcon
            name="map-pin"
            color={theme.colors.primaryWhite}
            size={21}
          />
          <h1 className={classes.mobileHeading}>
            &nbsp;{data?.entry?.sponsoredTeamMemberLocation}
          </h1>
        </div>
      </div>
      <LayoutContainer>
        <Breadcrumbs
          entries={[
            { routeUrl: routes.salsa, label: RouteLabels[routes.salsa] },
            { routeUrl: routes.team, label: RouteLabels[routes.team] },
            {
              routeUrl: `${routes.team}/${data?.entry?.id}`,
              label: memberName,
            },
          ]}
        />
        <PlatformDescription
          heading={bio?.heading || ''}
          description={bio?.body || ''}
          className={classes.description}
        />
        {relatedProducts?.productEntries &&
          relatedProducts?.productEntries.length > 0 && (
            <div className={classNames(classes.section, classes.products)}>
              <h2 className={classes.sectionHeading}>
                {(data?.entry?.sectionHeadings &&
                  data?.entry?.sectionHeadings[0]?.headingText) ||
                  `${memberName?.split(' ')[0]}'s Kit`}
              </h2>
              <ProductCarousel entries={relatedProducts?.productEntries} />
            </div>
          )}

        {data?.entry?.relatedJournalArticles &&
          data?.entry?.relatedJournalArticles.length > 0 && (
            <div className={classes.section}>
              <h2 className={classes.sectionHeading}>
                {(data?.entry?.sectionHeadings &&
                  data?.entry?.sectionHeadings[1]?.headingText) ||
                  'Recent Posts'}
              </h2>
              <div className={classes.posts}>
                {data?.entry?.relatedJournalArticles?.map((entry) => {
                  const type = JOURNAL_ENTRY_TYPES[entry.__typename]
                  const category = entry?.productCategory?.replace('_', ' ')
                  return (
                    <StoryCard
                      key={entry.uid}
                      title={entry?.title || ''}
                      href={`${type.linkTo}/${entry.slug}`}
                      imgSrc={
                        (entry?.imageThumbnail &&
                          entry?.imageThumbnail[0]?.url) ||
                        ''
                      }
                      subtitle={type.label.concat(
                        category ? ` | ${category}` : '',
                      )}
                      subtitle2={entry.productBlurb || ''}
                      hrefLabel={
                        type.label === 'Video'
                          ? 'Watch the Video'
                          : `Read the ${type.label}`
                      }
                    />
                  )
                })}
              </div>
            </div>
          )}
        {data?.entry?.qAMatrix && data?.entry?.qAMatrix.length > 0 && (
          <div className={classes.faqContainer}>
            <h2
              className={classNames(
                classes.sectionHeading,
                classes.faqSectionTitle,
              )}
            >
              {(data?.entry?.sectionHeadings &&
                data?.entry?.sectionHeadings[2]?.headingText) ||
                `Get to Know ${memberName?.split(' ')[0]}`}
            </h2>
            <div className={classNames(classes.section, classes.faq)}>
              {data?.entry?.qAMatrix?.map((qa) => (
                <AccordionItem
                  key={qa?.id}
                  title={qa?.question || ''}
                  className={classes.faqAccordionItem}
                  titleClassName={classes.faqAccordionTitle}
                >
                  <div
                    id={
                      qa && qa.question
                        ? qa.question.replace(/ /g, '_').toLowerCase()
                        : ''
                    }
                    className={classes.faqAccordionContent}
                    dangerouslySetInnerHTML={{ __html: qa?.answer || '' }}
                  />
                </AccordionItem>
              ))}
            </div>
          </div>
        )}
      </LayoutContainer>
    </>
  )
}
