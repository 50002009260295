import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import * as Routes from '../../routes'
import { SafetyAndSupportLanding } from './SafetyAndSupportLanding'
import { SafetyAndSupportRecalls } from './SafetyAndSupportRecalls'
import { SafetyAndSupportFaq } from './SafetyAndSupportFaq'
import { Instructions } from './Instructions'
import { Warranty } from './Warranty'
import { SafetyAndSupportRegistration } from './SafetyAndSupportRegistration'
import { RecallPage } from './RecallPage'

export const SafetyAndSupport: React.FC = () => {
  return (
    <Switch>
      <Route path={Routes.recall} component={RecallPage} />
      <Route path={Routes.recalls} component={SafetyAndSupportRecalls} />
      <Route path={Routes.faq} component={SafetyAndSupportFaq} />
      <Route path={Routes.downloads} component={Instructions} />
      <Route path={Routes.warranty} component={Warranty} />
      <Route
        exact
        path={Routes.bikeRegistration}
        component={SafetyAndSupportRegistration}
      />
      <Route
        path={Routes.supportAndSafety}
        component={SafetyAndSupportLanding}
      />
      <Redirect to={Routes.supportAndSafety} />
    </Switch>
  )
}
