import React, { CSSProperties } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  layoutContainerOuter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  layoutContainerInner: {
    width: '100%',
    maxWidth: theme.measurements.contentMaxWidth,
    paddingLeft: theme.measurements.contentPadding,
    paddingRight: theme.measurements.contentPadding,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.measurements.contentPaddingTablet,
      paddingRight: theme.measurements.contentPaddingTablet,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.measurements.contentPaddingMobile,
      paddingRight: theme.measurements.contentPaddingMobile,
    },
  },
}))

export const LayoutContainer: React.FC<{
  containerClass?: string
  contentClass?: string
  containerStyle?: CSSProperties
  contentStyle?: CSSProperties
  ContainerElement?: keyof JSX.IntrinsicElements
  ContentElement?: keyof JSX.IntrinsicElements
}> = (props) => {
  const {
    ContainerElement = 'section',
    ContentElement = 'div',
    containerStyle = {},
    contentStyle = {},
  } = props
  const classes = useStyles()

  return (
    <ContainerElement
      style={containerStyle}
      className={classNames(classes.layoutContainerOuter, props.containerClass)}
    >
      <ContentElement
        style={contentStyle}
        className={classNames(classes.layoutContainerInner, props.contentClass)}
      >
        {props.children}
      </ContentElement>
    </ContainerElement>
  )
}
