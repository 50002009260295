import React from 'react'
import { createUseStyles } from 'react-jss'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { JournalLandingPageQuery_entry_journalLandingPage_journalLandingPage_Entry as JournalLandingPageQuery } from '../../api-types/JournalLandingPageQuery'
import { HeroImage } from '../../components/HeroImage'
import { PlatformSearch } from '../../components/search/PlatformSearch'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { Theme } from '../../theme'
import {
  fetchApiData,
  getHeaderFlexAlignment,
  journalCategoriesToFilters,
} from '../../utils'
import { Entry } from '../../types'
import { Hierarchy } from '../../product-types'
import { Button } from '../../components/Button'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LayoutContainer } from '../../components/LayoutContainer'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  heroLink: {
    ...theme.typography.bebas(26),
    backgroundColor: theme.colors.primaryRed,
    textDecoration: 'none',
    color: theme.colors.primaryWhite,
    padding: '12px 24px',
    borderRadius: 2,
    letterSpacing: '0.8px',
    marginTop: theme.spacing.M,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  breadcrumbs: {
    marginBottom: theme.spacing.XL,
  },
  heroImage: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      height: 320,
    },
  },
  mobileHeadingContainer: {
    backgroundColor: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
  },
  mobileHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryWhite,
    textAlign: 'center',
  },
  mobileLinkContainer: {
    padding: theme.spacing.L,
    display: 'flex',
    justifyContent: 'center',
  },
  mobileTextContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  button: {
    ...theme.traits.flexCentering,
    ...theme.typography.bebasButton(26),
    padding: '12px 24px',
    color: theme.colors.primaryWhite,
    background: theme.colors.primaryRed,
    borderRadius: 2,
    borderStyle: 'none',
    textDecoration: 'none',
  },
}))

export const JournalLanding: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('journals-page', () =>
    fetchApiData<Entry<JournalLandingPageQuery>>('journals-page'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const hero = data?.entry?.hero && data?.entry?.hero[0]
  const heroImage = hero?.image && hero?.image[0]
  const link = hero?.linkUrl
  const linkLabel = hero?.linkLabel

  return (
    <>
      <HeroImage
        src={heroImage?.url || ''}
        alt={heroImage?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
        className={classes.heroImage}
      >
        <ProductTitle>{hero?.heading}</ProductTitle>
        {linkLabel && (
          <Link className={classes.heroLink} to={link || '#'}>
            {linkLabel}
          </Link>
        )}
      </HeroImage>
      <div className={classes.mobileTextContainer}>
        <div className={classes.mobileHeadingContainer}>
          <h1 className={classes.mobileHeading}>{hero?.heading}</h1>
        </div>
        <div className={classes.mobileLinkContainer}>
          <Link to={link || '#'}>
            <Button className={classes.button}>{linkLabel}</Button>
          </Link>
        </div>
      </div>
      <LayoutContainer contentClass={classes.breadcrumbs}>
        <Breadcrumbs />
      </LayoutContainer>
      <LayoutContainer>
        <h2 className={classes.srOnly}>Journal</h2>
        <PlatformSearch
          hierarchy={Hierarchy.JOURNAL}
          pageFilters={journalCategoriesToFilters(
            data?.entry?.journalCategoryField,
          )}
        />
      </LayoutContainer>
    </>
  )
}
