import React, { useState, useEffect, useCallback, useRef } from 'react'
import FocusTrap from 'focus-trap-react'
import ReactDOM from 'react-dom'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../theme'
import { classNames } from '../utils'
import { FeatherIcon } from './icons/FeatherIcon'

/**
 * This dropdown/select is a "hybrid" component meaning that on desktops we render our own hand spun select component
 * with custom styles that conform to the design spec. A native select element will always render to support tabbing
 * however devices that don't support hover (mobile, screen-readers, etc..) will only render the native select element.
 */

export interface DropdownOption {
  label: string
  value: string
}

interface DropdownProps {
  options: DropdownOption[]
  onChange: (value: string) => void
  value: string | null
  label?: string
  wrapperClassName?: string
  active: boolean
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  dropdownWrapper: {
    border: 'none',
    position: 'relative',
    width: '100%',
    '& label > span': {
      fontWeight: 700,
      whiteSpace: 'nowrap',
      marginLeft: 'auto',
    },
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  dropdown: {
    ...theme.typography.urwInput(16),
    height: 32,
    display: 'flex',
    alignItems: 'center',
    zIndex: 99,
    color: theme.colors.secondaryGrey700,
  },
  dropdownNative: {
    ...theme.typography.urwInput(16),
    padding: 8,
    float: 'right',
    '@media (hover: hover)': {
      '&:focus + div': { display: 'none' },
    },
    '& select': {
      color: theme.colors.secondaryGrey700,
      border: 'none',
      marginBottom: 1,
    },
  },
  dropdownCustom: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'none',
    backgroundColor: 'white',
    width: '100%',
    justifyContent: 'flex-end',
    '& > button': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 8,
      fontFamily: 'URW DIN, sans-serif;',
      fontWeight: 700,
      fontSize: 16,
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      color: '#636569',
    },
    '@media (hover: hover)': {
      display: 'flex',
    },
  },
  focused: {
    outline: '4px solid #4D90FE',
  },
  dropdownItems: {
    position: 'absolute',
    top: '100%',
    right: 0,
    width: 335,
    backgroundColor: 'white',
    borderRadius: 6,
    padding: `${theme.spacing.XS}px 0`,
    boxShadow:
      '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1)',
  },
  dropdownItem: {
    ...theme.typography.urwInput(16),
    padding: `${theme.spacing.S}px ${theme.spacing.M}px`,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.secondaryGrey100,
    },
    color: '#404146',
  },
  selected: {
    backgroundColor: theme.colors.secondaryGrey100,
    fontWeight: 700,
    color: '#636569',
  },
}))

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const { options, onChange, value, wrapperClassName, label } = props
  const classes = useStyles()
  const { active } = props
  const [focusTrapped, setFocusTrapped] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>()
  const buttonCloseRef = useRef<HTMLButtonElement>(null)
  const escapePressed = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsOpen(false)
    }
  }, [])

  const findOption = (targetValue: string | null): DropdownOption =>
    options.find((o) => o.value === targetValue) as DropdownOption

  const selected = findOption(value)

  useEffect(() => {
    document.addEventListener('keydown', escapePressed)
    if (isOpen) {
      buttonCloseRef.current?.focus()
      setFocusTrapped(active)
    }
    setFocusTrapped(false)
    return () => {
      document.removeEventListener('keydown', escapePressed)
    }
  }, [isOpen, active, escapePressed])

  return (
    <fieldset className={classNames(classes.dropdownWrapper, wrapperClassName)}>
      {ReactDOM.createPortal(
        isOpen && (
          <div className={classes.backdrop} onClick={() => setIsOpen(false)} />
        ),
        document.querySelector('#root') as Element,
      )}
      <legend className={classes.srOnly}>Filter by Price:</legend>
      <div className={classes.srOnly}>
        The Name drop down filters bike results by price. Use Tab to access the
        results.
      </div>
      <div className={classNames(classes.dropdown, classes.dropdownNative)}>
        {label && <span>{label}</span>}
        <select
          aria-labelledby="priceLabel"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={selected?.value}
          onChange={(e) => onChange(findOption(e.target.value).value)}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              id={option.value}
              aria-selected={selected === option ? 'true' : 'false'}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <FocusTrap active={focusTrapped}>
        <div
          aria-hidden={true}
          className={classNames(
            classes.dropdown,
            classes.dropdownCustom,
            isFocused && classes.focused,
          )}
        >
          <button
            ref={buttonCloseRef}
            onClick={() => setIsOpen(true)}
            role="combobox"
            aria-haspopup="listbox"
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-labelledby={`priceLabel ${selected?.value}`}
            aria-activedescendant={selected?.value}
            aria-controls="priceFilter"
          >
            <label htmlFor="priceFilter" id="priceLabel">
              {label ? `${label} ` : ''}
              {selected?.label}
            </label>
            <FeatherIcon
              name="chevron-down"
              size={24}
              color={theme.colors.secondaryGrey700}
            />
          </button>
          {isOpen && (
            <div
              className={classes.dropdownItems}
              onClick={() => setIsOpen(false)}
              id="priceFilter"
            >
              <ul role="listbox" aria-labelledby="priceLabel">
                {options.map((option) => (
                  <li
                    tabIndex={-1}
                    role="option"
                    id={option.value}
                    key={option.value}
                    onClick={() => onChange(option.value)}
                    className={classNames(
                      classes.dropdownItem,
                      selected === option && classes.selected,
                    )}
                    aria-selected={selected === option ? 'true' : 'false'}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
              <input type="hidden" value={selected?.value} />
            </div>
          )}
        </div>
      </FocusTrap>
    </fieldset>
  )
}
