import { createUseStyles } from 'react-jss'

const colors = {
  secondaryGrey100: '#F4F4F4',
  secondaryGrey300: '#D6D6D6',
  secondaryGrey500: '#6F6F6F',
  grey600: '#757575',
  secondaryGrey700: '#636569',
  secondaryGrey900: '#212222',
  primaryRed: '#CC0033',
  primaryBlack: '#212222',
  primaryWhite: '#FFFFFF',
  primaryShadow: '#0D0D0D',
  success: '#18A957',
  warning: '#FFBB38',
  error: '#DF1642',
}

type Breakpoint = 'sm' | 'md'

// const proximaNova = '"proxima-nova", sans-serif'
const bebasNeue = '"bebas-neue", sans-serif'
const urwDin = '"URW DIN", sans-serif'

// accurate for bebas / headings
const baseTypography = {
  140: {
    fontSize: 140,
    lineHeight: '144px',
  },
  120: {
    fontSize: 120,
    lineHeight: '144px',
    // others: 130px
  },
  112: {
    fontSize: 112,
    lineHeight: '120px',
  },
  80: {
    fontSize: 80,
    lineHeight: '96px',
  },
  48: {
    fontSize: 48,
    lineHeight: '60px',
  },
  37: {
    fontSize: 37,
    lineHeight: '52px',
  },
  31: {
    fontSize: 31,
    lineHeight: '40px',
  },
  26: {
    fontSize: 26,
    lineHeight: '40px',
  },
  24: {
    fontSize: 24,
    lineHeight: '32px',
  },
  21: {
    fontSize: 21,
    lineHeight: '32px',
  },
  18: {
    fontSize: 18,
    lineHeight: '32px',
    // others: 20px, 28px, 30px
  },
  16: {
    fontSize: 16,
    lineHeight: '22px',
    //others: 22px, 18px, 28px, 24px
  },
  14: {
    fontSize: 14,
    lineHeight: '20px',
  },
  12: {
    fontSize: 12,
    lineHeight: '20px',
  },
  10: {
    fontSize: 10,
    lineHeight: '16px',
  },
}

const inputTypography = {
  18: { fontSize: 18, lineHeight: '28px' },
  16: { fontSize: 16, lineHeight: '24px' },
}

const inputTypographyUrw = {
  ...inputTypography,
  16: { fontSize: 16, lineHeight: '22px' },
}

const buttonTypography = {
  21: { fontSize: 21, lineHeight: '32px' },
  16: { fontSize: 16, lineHeight: '28px' },
  14: { fontSize: 14, lineHeight: '20px' },
}

type FontSize = keyof typeof baseTypography

interface TypographyStyle {
  fontSize: number
  fontFamily: string
  lineHeight: string
  fontWeight: number
  textTransform?: string
  textDecoration?: string
  // letterSpacing?: number
}

function typographyStyle(
  size: FontSize,
  config?: Partial<{
    variant: 'bebas' | 'urw' | 'urw-bold' // default bebas
    context: 'input' | 'button' // default none
  }>,
): TypographyStyle {
  let fontWeight: number
  let fontFamily: string
  let textTransform: string | undefined
  const tabletMaxSize: FontSize = 112
  const mobileMaxSize: FontSize = 37

  switch (config?.variant) {
    case 'urw':
      fontFamily = urwDin
      fontWeight = 300
      break
    case 'urw-bold':
      fontFamily = urwDin
      fontWeight = 500
      break
    default:
      fontFamily = bebasNeue
      fontWeight = 400
      textTransform = 'uppercase'
  }

  let sizes: Record<
    number,
    Pick<TypographyStyle, 'fontSize' | 'lineHeight'>
  > = baseTypography

  switch (config?.context) {
    case 'input':
      if (config.variant === 'urw' || config.variant === 'urw-bold') {
        sizes = { ...sizes, ...inputTypographyUrw }
      } else {
        sizes = { ...sizes, ...inputTypography }
      }
      break
    case 'button':
      sizes = { ...sizes, ...buttonTypography }
      break
    // default:
    //   sizes = baseTypography
  }

  return {
    fontWeight,
    fontFamily,
    textTransform,
    textDecoration: 'none',
    ...sizes[size],
    [breakpoints.down('md')]: {
      ...sizes[Math.min(size, tabletMaxSize)],
    },
    [breakpoints.down('sm')]: {
      ...sizes[Math.min(size, mobileMaxSize)],
    },
  }
}

const spacing = {
  XS: 4,
  S: 8,
  M: 16,
  L: 24,
  XL: 32,
  XXL: 48,
  XXXL: 80,
}

const measurements = {
  headerHeight: 80,
  contentMaxWidth: 1440,
  contentNarrowWidth: 684,
  contentPadding: spacing.XXL,
  contentPaddingTablet: spacing.L,
  contentPaddingMobile: spacing.M,
}

const maxWidthMedia = (size: number) =>
  `@media screen and (max-width: ${size}px)`

const minWidthMedia = (size: number) =>
  `@media screen and (min-width: ${size}px)`

const mdMinWidth = 768
const lgMinWidth = 991

const breakpoints = {
  md: lgMinWidth - 1,
  sm: mdMinWidth - 1,
  down: (size: Breakpoint): string => {
    switch (size) {
      case 'sm':
        return maxWidthMedia(mdMinWidth - 1)
      case 'md':
        return maxWidthMedia(lgMinWidth - 1)
    }
  },
  up: (size: Breakpoint): string => {
    switch (size) {
      case 'sm':
        return minWidthMedia(mdMinWidth - 1)
      case 'md':
        return minWidthMedia(lgMinWidth - 1)
    }
  },
}

const traits = {
  clickable: {
    cursor: 'pointer',
    '&:active': {
      opacity: 0.7,
    },
  },
  flexCentering: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridLayout: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    columnGap: spacing.L,
    [breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(8, 1fr)',
    },
    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  gridColumn: (
    config: {
      lgCols?: number
      lgStart?: number
      mdCols?: number
      mdStart?: number
      smCols?: number
      smStart?: number
    } = {},
  ): Record<string, string | number | Record<string, string | number>> => {
    const {
      lgCols = 12,
      lgStart = 1,
      mdCols = 8,
      mdStart = 1,
      smCols = 4,
      smStart = 1,
    } = config

    return {
      gridColumnStart: lgStart,
      gridColumnEnd: Math.min(13, lgStart + lgCols),
      [breakpoints.down('md')]: {
        gridColumnStart: mdStart,
        gridColumnEnd: Math.min(9, mdStart + mdCols),
      },
      [breakpoints.down('sm')]: {
        gridColumnStart: smStart,
        gridColumnEnd: Math.min(5, smStart + smCols),
      },
    }
  },
}

const layers = {
  header: 1,
}

type TypographyClass =
  | 'bebas'
  | 'bebasInput'
  | 'bebasButton'
  | 'urw'
  | 'urwBold'
  | 'urwInput'
  | 'urwBoldInput'
  | 'urwButton'
  | 'urwBoldButton'

const typography: Record<
  TypographyClass,
  (size: FontSize) => TypographyStyle
> = {
  bebas: (size) => typographyStyle(size),
  bebasInput: (size) => typographyStyle(size, { context: 'input' }),
  bebasButton: (size) => typographyStyle(size, { context: 'button' }),
  urw: (size) => typographyStyle(size, { variant: 'urw' }),
  urwBold: (size) => typographyStyle(size, { variant: 'urw-bold' }),
  urwInput: (size) =>
    typographyStyle(size, { variant: 'urw', context: 'input' }),
  urwBoldInput: (size) =>
    typographyStyle(size, { variant: 'urw-bold', context: 'input' }),
  urwButton: (size) =>
    typographyStyle(size, { variant: 'urw', context: 'button' }),
  urwBoldButton: (size) =>
    typographyStyle(size, { variant: 'urw-bold', context: 'button' }),
}

/** ada styles */
const ada = {
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: 0,
  },
  srOnlyFocusable: {
    '&:active, &:focus': {
      position: 'relative',
      display: 'block',
      width: '100% !important',
      height: 'auto',
      margin: '0 0 10px 0',
      overflow: 'visible',
      clip: 'auto',
      padding: '5px',
      zIndex: '1000',
      color: colors.primaryWhite,
      background: colors.primaryRed,
    },
  },
}

export type Theme = typeof theme

export const theme = {
  colors,
  typography,
  // typographyStyle,
  spacing,
  measurements,
  breakpoints,
  traits,
  layers,
  ada,
}

export const useGlobalStyles = createUseStyles({
  '@global': {
    body: {
      margin: 0,
      minHeight: '100vh',
      scrollBehavior: 'smooth',
      fontWeight: 400,
      fontFamily: urwDin,
    },
    '#root': {
      position: 'relative',
    },
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },
    'ul, ol, dl': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    'h1,h2,h3,h4,h5,p,li,dd': {
      margin: 0,
    },
    a: {
      color: '#CC0033',
      textDecoration: 'none',
    },
    'button:active': {
      outlineStyle: 'none',
    },
    '@keyframes loader': {
      '0%': {
        transform: 'scale(0)',
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 0,
      },
    },
  },
})
