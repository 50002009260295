import React from 'react'

export const Vimeo: React.FC = (props) => (
  <svg
    aria-hidden="true"
    role="img"
    aria-label="Vimeo logo"
    width="32"
    height="28"
    viewBox="0 0 32 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.3288 0.00718353C22.8916 -0.153403 19.8943 2.37882 18.313 7.56405C19.1263 7.20173 19.917 7.03982 20.6823 7.03982C22.3076 7.03982 23.0262 7.96485 22.8342 9.80297C22.7382 10.9165 22.0236 12.5356 20.685 14.6657C19.3396 16.7958 18.3343 17.8628 17.665 17.8628C15.6357 17.8628 14.4157 8.19578 14.0024 5.55605C13.4278 1.88645 11.8958 0.170425 9.40649 0.410641C7.1212 0.626969 3.13328 4.72259 0 7.49902L1.50531 9.4526C2.93861 8.4413 3.7746 7.93433 4.01326 7.93433C6.50788 7.93433 7.71586 16.3844 9.65316 22.6685C10.9531 26.1364 12.5118 27.871 14.3904 27.871C17.409 27.871 21.0863 25.0255 25.4435 19.332C29.6501 13.8813 31.8287 9.58532 31.9687 6.45189L31.9861 6.43861C32.1647 2.23814 30.6248 0.0881405 27.3288 0.00718353Z"
      fill="#F4F4F4"
    />
  </svg>
)
