import * as React from 'react'

export const EventsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={31}
      height={30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 5h-1.25V3.75a1.25 1.25 0 10-2.5 0V5h-7.5V3.75a1.25 1.25 0 10-2.5 0V5H8a3.75 3.75 0 00-3.75 3.75v15A3.75 3.75 0 008 27.5h15a3.75 3.75 0 003.75-3.75v-15A3.75 3.75 0 0023 5zM8 7.5h1.25v1.25a1.25 1.25 0 102.5 0V7.5h7.5v1.25a1.25 1.25 0 102.5 0V7.5H23c.69 0 1.25.56 1.25 1.25v5H6.75v-5c0-.69.56-1.25 1.25-1.25zM6.75 23.75c0 .69.56 1.25 1.25 1.25h15c.69 0 1.25-.56 1.25-1.25v-7.5H6.75v7.5zm4.634-4.634a1.25 1.25 0 11-1.768 1.768 1.25 1.25 0 011.768-1.768zm4.116-.366h5a1.25 1.25 0 110 2.5h-5a1.25 1.25 0 110-2.5z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
