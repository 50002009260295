import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { AccordionItem } from '../../components/AccordionItem'
import { LayoutContainer } from '../../components/LayoutContainer'
import { useQuery } from 'react-query'
import { Entry } from '../../types'
import { fetchApiData } from '../../utils'

import { FaqQuery_entry_frequentlyAskedQuestions_frequentlyAskedQuestions_Entry as FaqQuery } from '../../api-types/FaqQuery'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.gridLayout,
    paddingLeft: theme.spacing.L,
    paddingRight: theme.spacing.L,
    paddingTop: theme.spacing.L,
  },
  content: {
    ...theme.traits.gridColumn({ lgCols: 8, lgStart: 3 }),
    marginBottom: theme.spacing.XXXL,
    color: theme.colors.primaryBlack,
  },
  header: {
    marginBottom: theme.spacing.L,
    paddingBottom: theme.spacing.S,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  heading: {
    ...theme.typography.bebas(48),
    marginBottom: theme.spacing.L,
  },
  subheading: {
    ...theme.typography.bebas(31),
    marginBottom: theme.spacing.S,
  },
  description: {
    ...theme.typography.urw(18),
  },
  item: {
    marginBottom: theme.spacing.M,
  },
  itemDetail: {
    ...theme.typography.urw(18),
    padding: theme.spacing.M,
    color: theme.colors.secondaryGrey900,
  },
}))

export const SafetyAndSupportFaq: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('/frequentlyaskedquestions', () =>
    fetchApiData<Entry<FaqQuery>>('frequentlyaskedquestions'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const faqs = data?.entry

  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.header}>
            <h1 className={classes.heading}>FAQ</h1>
            <h2 className={classes.subheading}>
              {' '}
              {data?.entry &&
                data?.entry?.faqTitle &&
                data?.entry?.faqTitle[0]?.heading}
            </h2>
            <p className={classes.description}>
              {data?.entry &&
                data?.entry?.faqTitle &&
                data?.entry?.faqTitle[0]?.subHeading}
            </p>
          </div>
          <ul>
            {faqs?.qAMatrix?.map((faq) => (
              <AccordionItem
                title={(faq && faq?.question) ?? ''}
                as="li"
                className={classes.item}
              >
                <div
                  id={
                    faq && faq.question
                      ? faq.question.replace(/ /g, '_').toLowerCase()
                      : ''
                  }
                  className={classes.itemDetail}
                  dangerouslySetInnerHTML={{ __html: faq?.answer || '' }}
                />
              </AccordionItem>
            ))}
          </ul>
        </div>
      </div>
    </LayoutContainer>
  )
}
