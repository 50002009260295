import React from 'react'

export const YouTube: React.FC = (props) => (
  <svg
    aria-hidden="true"
    role="img"
    aria-label="Youtube logo"
    width={32}
    height={22}
    viewBox="0 0 32 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.912 0.181302C28.83 0.287718 29.772 0.567554 30.64 2.08891C31.544 3.6083 32 6.22536 32 10.8348V10.8407V10.8505C32 15.4383 31.544 18.075 30.642 19.5787C29.774 21.1 28.832 21.3759 26.914 21.504C24.996 21.6124 20.178 21.6774 16.004 21.6774C11.822 21.6774 7.002 21.6124 5.086 21.502C3.172 21.3739 2.23 21.098 1.354 19.5767C0.46 18.0731 0 15.4363 0 10.8486V10.8446V10.8387V10.8328C0 6.22536 0.46 3.6083 1.354 2.08891C2.23 0.565583 3.174 0.287718 5.088 0.179331C7.002 0.0532082 11.822 0 16.004 0C20.178 0 24.996 0.0532082 26.912 0.181302ZM12 4.92669V16.7507L22 10.8387L12 4.92669Z"
      fill="#F4F4F4"
    />
  </svg>
)
