import React from 'react'

export const FilledMapPin: React.FC = () => {
  return (
    <svg
      aria-hidden="true"
      width={17}
      height={20}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.667 0a8 8 0 00-8 7.92c0 5.47 7.025 11.559 7.348 11.839l.002.001a1 1 0 001.3 0c.35-.26 7.35-6.36 7.35-11.84a8 8 0 00-8-7.92zm0 11a3.5 3.5 0 110-7 3.5 3.5 0 010 7z"
        fill="#989898"
      />
    </svg>
  )
}
