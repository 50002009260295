import React from 'react'
import { NewsLetterSignup } from './NewsLetterSignup'
import { FooterNav } from './FooterNav'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { FooterDetails } from './FooterDetails'

const useStyles = createUseStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.colors.primaryBlack,
  },
  footerContent: {
    width: '100%',
    maxWidth: 1800,
    padding: `0 ${theme.spacing.XXXL}px`,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing.L}px`,
    },
  },
}))

export const Footer: React.FC = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContent}>
        <NewsLetterSignup />
        <FooterNav />
        <FooterDetails />
      </div>
    </footer>
  )
}
