import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing.M,
  },
  message: {
    ...theme.typography.urw(16),
    textAlign: 'center',
    color: theme.colors.primaryWhite,
  },
  link: {
    color: theme.colors.primaryWhite,
    fontWeight: 'bold',
    paddingLeft: theme.spacing.S,
  },
  info: {
    backgroundColor: theme.colors.primaryBlack,
    borderTop: `1px Solid ${theme.colors.primaryWhite}`,
  },
  warning: {
    backgroundColor: theme.colors.error,
    borderTop: `1px Solid ${theme.colors.primaryWhite}`,
  },
}))

export const AlertBar: React.FC<{
  variant?: 'info' | 'warning'
  message: string
  linkText: string
  linkTo: string
}> = (props) => {
  const { variant = 'info' } = props
  const classes = useStyles()

  return (
    <div className={classNames(classes.container, classes[variant])}>
      <p className={classes.message}>
        {props.message}{' '}
        <Link to={props.linkTo} className={classes.link}>
          {props.linkText}
        </Link>
      </p>
    </div>
  )
}
