import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarketoForm } from '../../MarketoForm'

const useStyles = createUseStyles((theme: Theme) => ({
  topSection: {
    ...theme.traits.gridColumn({
      lgCols: 6,
      lgStart: 4,
      mdCols: 6,
      mdStart: 2,
      smCols: 4,
      smStart: 1,
    }),
  },
  gridContainer: {
    ...theme.traits.gridLayout,
    marginTop: 24,
    marginBottom: 102,
  },
  topSectionTitleFont: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey900,
    marginBottom: 16,
  },
  topSectionSubTitleFont: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey900,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  topSectionParagraphFont: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
    marginBottom: 48,
  },
}))

export const SalsaRiderRegistration: React.FC = () => {
  const classes = useStyles()
  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.gridContainer}>
        <div className={classes.topSection}>
          <h1 className={classes.topSectionTitleFont}>
            Become a salsa sponsored rider
          </h1>
          <p className={classes.topSectionParagraphFont}>
            Thank you for your interest in working with Salsa Cycles! Please
            fill out the form below for consideration of becoming a salsa
            sponsor rider.
          </p>
          <div>
            <MarketoForm formId="1188" />
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}
