import React from 'react'

export const Stories: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.275 1.275a1.25 1.25 0 00-1.05-.25L11.5 3.212 1.775 1A1.25 1.25 0 00.25 2.25V17.5a1.25 1.25 0 00.975 1.25l10 2.25h.55l10-2.25c.582-.131.99-.654.975-1.25V2.25a1.25 1.25 0 00-.475-.975zM2.75 3.812l7.5 1.688v12.687L2.75 16.5V3.812zM20.25 16.5l-7.5 1.687V5.5l7.5-1.688V16.5z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
