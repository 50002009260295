import React from 'react'
import { createUseStyles } from 'react-jss'
import { NavLink, useHistory } from 'react-router-dom'
import { theme, Theme } from '../../theme'
import { useRouteMatch } from 'react-router'
import { useQuery } from 'react-query'
import { fetchApiData } from '../../utils'
import { Entry } from '../../types'
import * as routes from '../../routes'
import {
  StoryPageQuery_entry_story_story_Entry,
  StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry,
  StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry_journalAuthor_journalAuthor_default_Entry as JournalAuthor,
} from '../../api-types/StoryPageQuery'
import { LayoutContainer } from '../../components/LayoutContainer'
import { StoryPart } from '../../components/StoryPart'
import { Helmet } from 'react-helmet'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import * as Routes from '../../routes'

interface StoryChapter
  extends StoryPageQuery_entry_story_story_Entry_storyChapters_storyChapter_default_Entry {
  journalAuthor: JournalAuthor[] | null
}

interface StoryPageQuery extends StoryPageQuery_entry_story_story_Entry {
  storyChapters: StoryChapter[] | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  //nav
  navBar: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    '& nav': {
      display: 'flex',
    },
  },
  navLink: {
    ...theme.typography.bebas(31),
    ...theme.traits.flexCentering,
    width: 228,
    textDecoration: 'none',
    borderBottom: '6px solid white',
    textAlign: 'center',
    height: '100%',
  },
  logo: {},
}))

export const StoryPage: React.FC = () => {
  const match = useRouteMatch<{ storySlug: string; partIndex: string }>()
  const classes = useStyles()
  const history = useHistory()

  const { data, isLoading, isFetched } = useQuery(
    ['stories', match.params.storySlug],
    () =>
      fetchApiData<Entry<StoryPageQuery>>(`stories/${match.params.storySlug}`),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const parts = data?.entry?.storyChapters || []
  const partIndex = match.params.partIndex
    ? Number.parseInt(match.params.partIndex) - 1
    : 0
  const storyPart =
    data?.entry?.storyChapters && data?.entry?.storyChapters[partIndex]
  const nextPart =
    data?.entry?.storyChapters && data?.entry?.storyChapters[partIndex + 1]
  const logo = data?.entry?.storyLogo && data?.entry?.storyLogo[0]

  if (isFetched && (!data?.entry || !parts[partIndex])) {
    history.replace(Routes.notFound)
  }

  return (
    <>
      {data && (
        <Helmet>
          <title>{`${data?.entry?.title} | Salsa Bikes`}</title>
          <meta property="og:description" content={data.entry?.title ?? ''} />
          <meta
            property="og:image"
            content={(data.entry?.imageThumbnail ?? [])[0]?.url ?? ''}
          />
        </Helmet>
      )}
      <LayoutContainer
        contentClass={classes.navBar}
        containerStyle={{
          backgroundColor:
            data?.entry?.storyHeaderColor || theme.colors.primaryWhite,
        }}
      >
        <nav aria-label="story chapters">
          <ul className={classes.navBar}>
            {parts?.map((part, i) => (
              <li>
                <NavLink
                  key={part?.uid}
                  to={`${routes.stories}/${match.params.storySlug}/parts/${
                    i + 1
                  }`}
                  className={classes.navLink}
                  isActive={() => i === partIndex}
                  style={{
                    color:
                      data?.entry?.storyHeaderTextColor ||
                      theme.colors.secondaryGrey500,
                    borderBottomColor:
                      data?.entry?.storyHeaderColor ||
                      theme.colors.primaryBlack,
                  }}
                  activeStyle={{
                    color:
                      data?.entry?.storyHeaderTextColorActive ||
                      theme.colors.primaryBlack,
                    borderBottomColor:
                      data?.entry?.storyHeaderTextColorActive ||
                      theme.colors.primaryBlack,
                  }}
                >
                  Part {i + 1}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <img
          src={logo?.url || ''}
          alt={logo?.alttext || ''}
          className={classes.logo}
        />
      </LayoutContainer>
      <StoryPart
        storyPart={storyPart || undefined}
        nextPart={nextPart || undefined}
        readDuration={data?.entry?.journalReadDuration}
        tags={data?.entry?.journalCategoryField || []}
      />
    </>
  )
}
