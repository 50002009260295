import React from 'react'
import { RecallQuery_entry_recallSection_recallSection_Entry as RecallQuery } from '../../api-types/RecallQuery'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router'
import { fetchApiData } from '../../utils'
import { Entry } from '../../types'
import { LayoutContainer } from '../../components/LayoutContainer'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import * as routes from '../../routes'
import { RouteLabels } from '../../routes'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  recallContentContainer: {
    ...theme.typography.urw(21),
    marginTop: theme.spacing.L,
    marginBottom: theme.spacing.XXXL,
  },
}))

export const RecallPage: React.FC = () => {
  const match = useRouteMatch<{ recallSlug: string }>()
  const classes = useStyles()
  const { data, isLoading } = useQuery(
    ['recalls', match.params.recallSlug],
    () =>
      fetchApiData<Entry<RecallQuery>>(`recalls/${match.params.recallSlug}`),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <LayoutContainer>
      <Breadcrumbs
        entries={[
          {
            label: RouteLabels[routes.supportAndSafety],
            routeUrl: routes.supportAndSafety,
          },
          { label: RouteLabels[routes.recalls], routeUrl: routes.recalls },
          {
            label: data?.entry?.title || '',
            routeUrl: `${routes.recalls}/${data?.entry?.slug}`,
          },
        ]}
      />
      <div
        className={classes.recallContentContainer}
        dangerouslySetInnerHTML={{ __html: data?.entry?.recallContent || '' }}
      />
    </LayoutContainer>
  )
}
