import React from 'react'
import { Hierarchy } from '../../product-types'
import { PlatformSearch } from '../../components/search/PlatformSearch'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LayoutContainer } from '../../components/LayoutContainer'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  paddedBreadcrumbs: { marginBottom: theme.spacing.XL },
}))

export const AllBikes: React.FC = () => {
  const classes = useStyles()
  return (
    <LayoutContainer>
      <div className={classes.paddedBreadcrumbs}>
        <Breadcrumbs />
      </div>
      <h1 className={classes.srOnly}>All Bikes</h1>
      <PlatformSearch hierarchy={Hierarchy.BIKES} />
    </LayoutContainer>
  )
}
