import { SVGProps } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import spriteSheet from 'feather-icons/dist/feather-sprite.svg'
import { classNames } from '../../utils'

interface Props {
  name: string
  color?: string
  className?: string
  size?: number
}

const useStyles = createUseStyles((theme: Theme) => ({
  icon: {
    width: (props: Props) => props.size ?? 48,
    height: (props: Props) => props.size ?? 48,
    stroke: (props: Props) => props.color ?? theme.colors.secondaryGrey300,
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none',
  },
}))

export const FeatherIcon: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  const spriteProps: SVGProps<SVGUseElement> = {
    xlinkHref: `${spriteSheet}#${props.name}`,
  }

  return (
    <svg
      aria-hidden="true"
      className={classNames(classes.icon, props.className)}
    >
      <use {...spriteProps} />
    </svg>
  )
}
