import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { StoryCard } from '../../components/StoryCard'
import { useSalsaLandingPageEntry } from './SalsaLandingPage'
import { LayoutContainer } from '../../components/LayoutContainer'

const useStyles = createUseStyles((theme: Theme) => ({
  mainContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    justifyContent: 'space-between',
    flexDirection: 'row',
    columnGap: theme.spacing.L,
    marginRight: theme.spacing.L,
    marginLeft: theme.spacing.L,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      marginRight: theme.spacing.M,
      marginLeft: theme.spacing.M,
      columnGap: theme.spacing.M,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      marginRight: 0,
      marginLeft: 0,
    },
  },
  sectionTitleContainer: {
    marginLeft: theme.spacing.L,
    marginRight: theme.spacing.L,
    marginBottom: theme.spacing.L,
    marginTop: 2 * theme.spacing.L,
    [theme.breakpoints.down('md')]: {
      marginTop: 2 * theme.spacing.L,
      marginRight: theme.spacing.M,
      marginLeft: theme.spacing.M,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.L,
      marginBottom: theme.spacing.M,
    },
  },
  sectionTitle: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey700,
  },
  section: {
    marginBottom: theme.spacing.XXXL,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
}))

export const SalsaContentSection: React.FC = () => {
  const classes = useStyles()
  const salsaLandingPageEntry = useSalsaLandingPageEntry()

  return (
    <LayoutContainer contentClass={classes.section}>
      <div className={classes.sectionTitleContainer}>
        <h2 className={classes.sectionTitle}>
          {salsaLandingPageEntry?.sectionHeading}
        </h2>
      </div>
      <ul className={classes.mainContainer}>
        {salsaLandingPageEntry?.adaptableCardList?.map((content) => (
          <StoryCard
            key={content?.uid}
            title={content?.heading || ''}
            subtitle={content?.subheading1 || ''}
            subtitle2={content?.subheading2 || ''}
            href={content?.linkHref || '#'}
            hrefLabel={content?.linkLabel || ''}
            imgSrc={
              (content?.image && content?.image[0] && content?.image[0]?.url) ||
              ''
            }
          />
        ))}
      </ul>
    </LayoutContainer>
  )
}
