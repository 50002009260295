import React, { useState, useEffect } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Slider from 'react-slick'
import { useMediaQuery } from 'react-responsive'
import { Theme } from '../theme'
import { Breadcrumbs } from './Breadcrumbs'
import { HeroImage } from './HeroImage'
import { useSliderRef } from './carousel/carousel.hooks'
import { FeatherIcon } from './icons/FeatherIcon'
import { classNames, getAssetUrl } from '../utils'
import { AdaptiveList } from './AdaptiveList'
import { AccordionItem } from './AccordionItem'
import { Button } from './Button'
import { PullQuote } from './PullQuote'
import { KeyValueDataView } from './KeyValueDataView'
import { DetailList } from './DetailList'
import { ProductCarousel } from './ProductCarousel'
import * as routes from '../routes'
import { RouteLabels } from '../routes'
import { useProductPageQuery } from '../pages/ProductDetailPage'
import { useRouteMatch } from 'react-router'
import { useProduct, useProductWarranty } from '../hooks'
import { FrameSpec, Geometry, Product } from '../product-types'
import { LayoutContainer } from './LayoutContainer'
import { RISModal } from './RISModal'

const IGNORED_GEOMETRY_FIELDS = [
  'BB Center to Center Metric',
  'Geometry Size',
  'Geometry Group',
  'sequencePim',
]

const GeoNoteEx = new RegExp('[A-Z]. ')

const useStyles = createUseStyles((theme: Theme) => ({
  pageHeaderContent: (product: Product) => ({
    position: 'relative',
    '& > button': {
      display:
        (product?.fullRecord['Consumer Model Images']?.length ?? 0) > 1
          ? 'block'
          : 'none',
    },
  }),
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  pageHeader: {
    overflow: 'hidden',
  },
  slideContainer: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingLeft: theme.spacing.XXXL,
    paddingRight: theme.spacing.XXXL,
    '& > img': {
      width: '100%',
      margin: '0 auto',
    },
  },
  sliderButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: 'calc(50% - 20px)',
    padding: 0,
    borderStyle: 'none',
    background: 'none',
    '& > svg': {
      strokeWidth: 3,
    },
  },
  breadcrumbs: {
    background: `linear-gradient(180deg, ${theme.colors.secondaryGrey100} 1.22%, rgba(244, 244, 244, 0) 100%)`,
  },
  pageContent: {
    ...theme.traits.gridLayout,
    rowGap: theme.spacing.XXXL + theme.spacing.S,
    paddingBottom: theme.spacing.L,
  },
  productDetailsContainer: {
    gridColumn: '1 / -1',
    '& > *': {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing.L,
        paddingRight: theme.spacing.L,
      },
    },
  },
  section: {
    ...theme.traits.gridColumn(),
    width: '100%',
  },
  paddedAccordionContent: {
    padding: theme.spacing.L,
    paddingBottom: 0,
  },
  productHeading: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey500,
    textAlign: 'center',
    '& > span': {
      color: theme.colors.secondaryGrey700,
    },
    marginBottom: theme.spacing.M,
  },
  productPrice: {
    ...theme.typography.urwBold(26),
    fontWeight: 700,
    color: theme.colors.secondaryGrey500,
    textAlign: 'center',
    display: 'block',
    marginBottom: theme.spacing.M,
  },
  productReviewsSummary: {
    textAlign: 'center',
    marginBottom: theme.spacing.M,
  },
  positioningKeyword: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey500,
    textAlign: 'center',
    marginBottom: theme.spacing.M,
  },
  positioningStatement: {
    ...theme.typography.urw(21),
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.M,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.urw(16),
      marginTop: 50,
    },
  },
  bulletedList: {
    listStyleType: 'disc',
    paddingLeft: theme.spacing.XL,
  },
  primaryInfoContainer: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      '& > div': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  selectContainer: {
    paddingTop: 7,
  },
  button: {
    backgroundColor: theme.colors.secondaryGrey900,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      margin: 'auto',
    },
  },
  dropdownContainer: {
    marginBottom: 30,
    '& > label': {
      display: 'block',
      fontFamily: 'bebas-neue',
      fontSize: 18,
      color: '#6f6f6f',
      marginBottom: 5,
    },
  },
  dropdown: {
    height: 42,
    border: '2px solid #D6D6D6',
    display: 'block',
    minWidth: 400,
    fontFamily: 'inherit',
    fontSize: 16,
    color: theme.colors.secondaryGrey700,
    [theme.breakpoints.down('md')]: {
      minWidth: 250,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    '-moz-appearance': 'none',
    '-webkit-appearance': 'none',
    appearance: 'none',
    backgroundImage: 'none',
    paddingLeft: 5,
    background:
      'url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 96% !important',
  },
  required: {
    fontSize: 14,
    color: theme.colors.primaryRed,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  embedContainer: {
    marginTop: theme.spacing.XXXL,
  },
  sectionHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.L,
  },
  accordionItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing.L,
    },
  },
  accordionTitle: {
    ...theme.typography.bebas(26),
    color: theme.colors.secondaryGrey500,
  },
  nestedAccordionSection: {
    ...theme.traits.gridLayout,
    padding: theme.spacing.L,
    '& > *': {
      ...theme.traits.gridColumn({ lgCols: 6, lgStart: 4 }),
    },
  },
  nestedAccordionItem: {
    marginBottom: theme.spacing.M,
  },
  nestedAccordionTitle: {
    ...theme.typography.bebas(18),
    color: theme.colors.secondaryGrey900,
    backgroundColor: theme.colors.secondaryGrey300,
    '& > svg': {
      stroke: theme.colors.secondaryGrey900,
    },
  },
  nestedAccordionContent: {
    ...theme.typography.urw(16),
    color: '#404146',
    marginBottom: theme.spacing.L,
  },
  geometryContent: {
    ...theme.traits.gridLayout,
    overflow: 'auto',
    rowGap: theme.spacing.L,
    paddingTop: theme.spacing.L,
    '& > *:first-child': {
      gridColumn: 'span 4',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        gridColumn: '2 / -2',
      },
    },
    '& > *:nth-child(2)': {
      gridColumn: 'span 8',
      [theme.breakpoints.down('md')]: {
        gridColumn: '1 / -1',
      },
    },
    '& > *:last-child': {
      gridColumn: '5 / span 8',
      [theme.breakpoints.down('md')]: {
        gridColumn: '1 / -1',
      },
    },
  },
  table: {
    ...theme.typography.urw(18),
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    '& thead': {
      borderBottom: `2px solid ${theme.colors.secondaryGrey100}`,
    },
    '& tr': {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.secondaryGrey100,
      },
    },
    '& td,th': {
      padding: theme.spacing.S,
    },
    '& th': {
      textAlign: 'left',
      fontWeight: 500,
    },
    '& td:first-child': {
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
  },
  geometryNotes: {
    color: theme.colors.secondaryGrey700,
    '& p': {
      ...theme.typography.urwBold(21),
    },
    '& ul': {
      ...theme.typography.urw(21),
      listStyle: 'initial',
      paddingLeft: 18,
    },
  },
  documentList: {
    ...theme.typography.urw(18),
    padding: theme.spacing.L,
    paddingBottom: 0,
    '& > li': {
      display: 'flex',
      alignItems: 'center',
      minHeight: 40,
      '&:not(:last-child)': {
        marginBottom: theme.spacing.M,
      },
      '& > svg': {
        stroke: theme.colors.secondaryGrey500,
      },
      '& > a': {
        marginLeft: theme.spacing.S,
        textDecoration: 'none',
        color: theme.colors.secondaryGrey500,
        '& > span': {
          color: theme.colors.primaryRed,
        },
      },
    },
  },
  featuresContent: {
    ...theme.traits.gridLayout,
    paddingTop: theme.spacing.L,
    paddingBottom: theme.spacing.L,
  },
  featuresList: {
    ...theme.traits.gridColumn({ lgCols: 10, lgStart: 2 }),
  },
  featureDescription: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
    '&:not(:last-child)': {
      marginBottom: theme.spacing.L,
    },
  },
  similarProductsContainer: {
    paddingTop: theme.spacing.XXL,
    paddingBottom: theme.spacing.XXL,
  },
  priceNote: {
    padding: theme.spacing.L,
    fontStyle: 'italic',
    color: theme.colors.secondaryGrey500,
    fontSize: '18px',
  },
}))

type Props = {
  productName: string
}

export const ProductDetailView: React.FC<Props> = () => {
  const theme = useTheme<Theme>()
  const match = useRouteMatch()
  const { pathname } = useLocation()
  const productPageQuery = useProductPageQuery()
  const [sliderRef, handleForward, handleBack] = useSliderRef()
  const product = useProduct(productPageQuery?.productModelNumber || '')
  const warrantyQuery = useProductWarranty()
  const isBike = match.path === routes.bikeDetail
  const relatedAccessories =
    productPageQuery?.relatedProducts && productPageQuery?.relatedProducts[0]
  const relatedProducts =
    productPageQuery?.relatedProducts && productPageQuery?.relatedProducts[1]
  const classes = useStyles(product ?? undefined)

  /** Dropdown functionality */
  // sorts options from least to highest sku
  const options = product?.skus.sort((a, b) => {
    return (a.SKU.slice(2) as any) - (b.SKU.slice(2) as any)
  })
  const [selectedUPC, setSelectedUPC] = useState('')
  const [selectedSKU, setSelectedSKU] = useState<any | null>('')
  const [showRequiredAlert, setShowRequiredAlert] = useState(false)

  useEffect(() => {
    if (selectedUPC !== '' && selectedSKU !== '') {
      setShowRequiredAlert(false)
    }
  }, [selectedUPC, selectedSKU])

  /** RIS modal functionality */
  const [isRISOpen, setIsRISOpen] = useState(false)
  const openRIS = () => {
    if (selectedUPC === '') {
      setShowRequiredAlert(true)
    } else {
      setIsRISOpen(true)
    }
  }

  const handleMediaQueryChange = (matches: any) => {
    // matches will be true or false based on the value for the media query
  }

  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 768 },
    undefined,
    handleMediaQueryChange,
  )

  if (!product) {
    return null
  }

  const frameFeatures = product.fullRecord['Frame Features']?.reduce(
    (acc, feature) => ({
      ...acc,
      [feature['Frame Feature Title']]: feature['Frame Feature Content'],
    }),
    {},
  )

  const buildKit = product.fullRecord['Build Kit']?.reduce((acc, item) => {
    const key = item['Part of Bike']
    if (!acc[key]) {
      acc[key] = {}
    }
    acc[key] = {
      ...acc[key],
      [item['Build Kit Component']]: item['Component Description'],
    }
    return acc
  }, {} as Record<string, Record<string, string>>)

  const frameSpecs = product.fullRecord['Frame Specs']?.reduce((acc, spec) => {
    if (spec['Frame Spec Content'] && spec['Frame Spec Content'] !== ' ') {
      const key = spec['Frame Spec Title']
      acc[key] = spec['Frame Spec Content'] as keyof FrameSpec
    }
    return acc
  }, {} as Record<string, string>)

  const geometry = product.fullRecord.Geometry
  const geometrySizes = geometry?.map((geo) => geo['Geometry Size'])
  const geometryMap =
    geometry?.reduce((acc, geo) => {
      Object.keys(geo).forEach((geoKey) => {
        if (IGNORED_GEOMETRY_FIELDS.every((ignored) => ignored !== geoKey)) {
          if (
            geo[geoKey as keyof Geometry] &&
            geo[geoKey as keyof Geometry] !== ' '
          ) {
            if (!acc[geoKey]) {
              acc[geoKey] = []
            }
            acc[geoKey].push(geo[geoKey as keyof Geometry])
          }
        }
      })
      return acc
    }, {} as Record<string, string[]>) || {}

  return (
    <>
      <RISModal active show={isRISOpen} onExit={() => setIsRISOpen(false)} />
      <LayoutContainer
        containerClass={classes.pageHeader}
        contentClass={classes.pageHeaderContent}
      >
        <div role="region" aria-label="product carousel">
          <p className={classes.srOnly}>
            This is a carousel. Use Next and Previous buttons to navigate
            slides.
          </p>
          <Slider
            ref={sliderRef}
            infinite
            adaptiveHeight={false}
            arrows={false}
          >
            {product.fullRecord['Consumer Model Images']?.map((fileName, i) => (
              <div key={fileName} className={classes.slideContainer}>
                <img
                  src={getAssetUrl(fileName)}
                  alt={`${product.name} Detailed View ${i + 1}`}
                  role="region"
                  aria-label={`slide ${i + 1} of ${__filename.length}`}
                />
              </div>
            ))}
          </Slider>
          <button
            onClick={handleBack}
            className={classes.sliderButton}
            style={{ left: theme.spacing.L }}
          >
            <FeatherIcon
              name="chevron-left"
              color={theme.colors.secondaryGrey500}
              size={40}
            />
            <span className={classes.srOnly}>Previous</span>
          </button>
          <button
            onClick={handleForward}
            className={classes.sliderButton}
            style={{ right: theme.spacing.L }}
          >
            <FeatherIcon
              name="chevron-right"
              color={theme.colors.secondaryGrey500}
              size={40}
            />
            <span className={classes.srOnly}>Next</span>
          </button>
        </div>
      </LayoutContainer>
      <LayoutContainer containerClass={classes.breadcrumbs}>
        <Breadcrumbs
          entries={[
            {
              routeUrl: isBike ? routes.bikes : routes.gear,
              label: RouteLabels[isBike ? routes.bikes : routes.gear],
            },
            {
              routeUrl: `${isBike ? routes.bikes : routes.gear}/${
                productPageQuery?.productUrlSlug
              }`,
              label: productPageQuery?.title || '',
            },
          ]}
        />
      </LayoutContainer>
      <LayoutContainer contentClass={classes.pageContent}>
        <div className={classes.productDetailsContainer}>
          <h1 className={classes.productHeading}>
            <span>{product.name}</span>
          </h1>
          {pathname.indexOf('/bikes') === 0 && (
            <ReactTooltip effect="solid" role="tooltip" />
          )}
          {pathname.indexOf('/bikes') === 0 && product.lowestPrice !== 0 && (
            <p
              className={classes.productPrice}
              tabIndex={0}
              data-place="bottom"
              data-background-color="black"
              data-padding="5px 10px"
              data-multiline="true"
              data-tip="* Manufacturer's suggested retail price may<br />not include additional shipping or preparation charges.<br />Please contact your local dealer for a quote."
            >
              ${product.lowestPrice?.toLocaleString()} USD (MSRP) *
            </p>
          )}
          {pathname.indexOf('/bikes') === 0 && (
            <span className={classes.srOnly}>
              * Manufacturer's suggested retail price may not include additional
              shipping or preparation charges. Please contact your local dealer
              for a quote.
            </span>
          )}
          {pathname.indexOf('/bikes') === 0 && (
            <p className={classes.productPrice}>
              {productPageQuery?.underMsrpProductText}
            </p>
          )}
          {pathname.indexOf('/gear') === 0 && product.lowestPrice !== 0 && (
            <p className={classes.productPrice}>
              ${product.lowestPrice?.toLocaleString()} USD (MSRP)
            </p>
          )}
          {pathname.indexOf('/gear') === 0 && (
            <p className={classes.productPrice}>
              {productPageQuery?.underMsrpProductText}
            </p>
          )}
          <div id="pr-snippet" />
          {/*<div className={classes.productReviewsSummary}>*/}
          {/*  [*][*][*][*][ ] 4 (99 Reviews)*/}
          {/*</div>*/}
          {/*<h2 className={classes.positioningKeyword}>*/}
          {/*  {productPageQuery?.missionStatement &&*/}
          {/*    productPageQuery?.missionStatement[0]?.heading}*/}
          {/*</h2>*/}
          <div className={classes.primaryInfoContainer}>
            <div>
              <p className={classes.positioningStatement}>
                {product.description}
              </p>
              {product?.fullRecord['Consumer Model Bullets'] &&
                product?.fullRecord['Consumer Model Bullets'].length > 0 && (
                  <ul
                    className={classNames(
                      classes.positioningStatement,
                      classes.bulletedList,
                    )}
                  >
                    {product?.fullRecord['Consumer Model Bullets']?.map(
                      (bullet) => {
                        if (!bullet || bullet === '') return undefined
                        return <li key={bullet}>{bullet}</li>
                      },
                    )}
                  </ul>
                )}
            </div>
            {product.status !== 'Archived' && product.skus.length && (
              <div className={classes.selectContainer}>
                <div className={classes.dropdownContainer}>
                  <div className={classes.srOnly}>
                    This dropdown allows you to select a product option and
                    search for dealers who carry said product.
                  </div>
                  <label htmlFor="RISselect">CHOOSE AN OPTION:</label>
                  <select
                    value={selectedUPC}
                    id="RISselect"
                    className={classes.dropdown}
                    aria-required={true}
                    onChange={(e) => {
                      setSelectedUPC(e.target.value)
                      setSelectedSKU(
                        e.target[e.target.selectedIndex].getAttribute(
                          'data-sku',
                        ),
                      )
                    }}
                  >
                    <option value="" disabled hidden></option>
                    {options
                      ?.filter(
                        (option) =>
                          option.fullRecord['Consumer Brand SKU Status'] ===
                          'Open',
                      )
                      .map((option) => (
                        <option
                          value={option.fullRecord.UPCs[0]['UPC Value']}
                          data-sku={option.SKU}
                          key={option.SKU}
                        >
                          {option.fullRecord['RIS Selector Name']}
                        </option>
                      ))}
                  </select>
                  {showRequiredAlert ? (
                    <div className={classes.required} role="alert">
                      Please choose an option.
                    </div>
                  ) : null}
                </div>
                <Button
                  aria-label="Launches the shop local tool"
                  className={classes.button}
                  onClick={() => {
                    openRIS()
                  }}
                >
                  SHOP LOCAL
                </Button>
              </div>
            )}
          </div>
        </div>
        {/* Additional Information for Gear */}
        {!isBike &&
          (!!productPageQuery?.relatedFaq?.length ||
            !!productPageQuery?.quotes?.length) && (
            <>
              <div
                className={classes.section}
                onChange={handleMediaQueryChange}
              >
                <h2 className={classes.sectionHeading}>
                  Additional Information
                </h2>
                {(productPageQuery?.relatedFaq?.length || []) > 0 && (
                  <AccordionItem
                    className={classes.accordionItem}
                    title="faq"
                    titleClassName={classes.accordionTitle}
                    startExpanded={true}
                  >
                    <div className={classes.nestedAccordionSection} id="faq">
                      {productPageQuery?.relatedFaq?.map((faq) => (
                        <AccordionItem
                          title={faq?.question || ''}
                          key={faq.uid}
                          className={classes.nestedAccordionItem}
                          titleClassName={classes.nestedAccordionTitle}
                        >
                          <div
                            className={classes.paddedAccordionContent}
                            id={
                              faq && faq.question
                                ? faq.question.replace(/ /g, '_').toLowerCase()
                                : ''
                            }
                          >
                            <div
                              className={classes.nestedAccordionContent}
                              dangerouslySetInnerHTML={{
                                __html: faq?.answer || '',
                              }}
                            />
                          </div>
                        </AccordionItem>
                      ))}
                    </div>
                  </AccordionItem>
                )}
              </div>
              {productPageQuery?.quotes && productPageQuery?.quotes[0] && (
                <div className={classes.section}>
                  <PullQuote
                    quote={
                      (productPageQuery?.quotes &&
                        productPageQuery?.quotes[0]?.body) ||
                      ''
                    }
                    credit={
                      (productPageQuery?.quotes &&
                        productPageQuery?.quotes[0]?.author) ||
                      ''
                    }
                    linkTo={
                      (productPageQuery?.quotes &&
                        productPageQuery?.quotes[0]?.linkTo) ||
                      ''
                    }
                  />
                </div>
              )}
            </>
          )}
        {/* Additional Information for Bikes */}
        {isBike && (
          <>
            {product?.fullRecord['Video'] &&
              product?.fullRecord['Video'] !== ' ' && (
                <div className={classes.section}>
                  <h2 className={classes.sectionHeading}>
                    {(productPageQuery?.sectionHeadings &&
                      productPageQuery?.sectionHeadings[0]?.headingText) ||
                      `${product?.name} in action`}
                  </h2>
                  <HeroImage
                    src={product?.fullRecord['Video']}
                    alt={`${product?.name} in action`}
                    type="video"
                  />
                </div>
              )}
            {Number(productPageQuery?.productFeatures?.length) > 0 && (
              <div className={classes.section}>
                <h2 className={classes.sectionHeading}>
                  {(productPageQuery?.sectionHeadings &&
                    productPageQuery?.sectionHeadings[1]?.headingText) ||
                    'Key Features'}
                </h2>
                <AdaptiveList
                  items={
                    productPageQuery?.productFeatures?.map((feature) => ({
                      imageSrc:
                        (feature?.imageThumbnail &&
                          feature?.imageThumbnail[0]?.url) ||
                        '',
                      title: feature?.title || '',
                      description: feature?.productBlurb || '',
                      linkTo: routes.features,
                      linkLabel: 'View More',
                    })) || []
                  }
                />
              </div>
            )}
            <div className={classes.section} onChange={handleMediaQueryChange}>
              <h2 className={classes.sectionHeading}>
                {(productPageQuery?.sectionHeadings &&
                  productPageQuery?.sectionHeadings[2]?.headingText) ||
                  'Additional Information'}
              </h2>
              <AccordionItem
                className={classes.accordionItem}
                title="features"
                titleClassName={classes.accordionTitle}
                {...(isDesktopOrLaptop
                  ? { startExpanded: true }
                  : { startExpanded: false })}
              >
                <div className={classes.featuresContent} id="features">
                  <DetailList
                    className={classes.featuresList}
                    data={(frameFeatures || {}) as Record<string, string>}
                    renderDetail={(content) => (
                      <p className={classes.featureDescription}>{content}</p>
                    )}
                  />
                </div>
              </AccordionItem>
              {buildKit && (
                <AccordionItem
                  className={classes.accordionItem}
                  title="build kit"
                  titleClassName={classes.accordionTitle}
                  startExpanded={false}
                >
                  <div
                    className={classes.paddedAccordionContent}
                    id="build_kit"
                  >
                    {Object.keys(buildKit).map((partOfBike) => (
                      <KeyValueDataView
                        key={partOfBike}
                        heading={partOfBike}
                        data={buildKit[partOfBike] || {}}
                      />
                    ))}
                  </div>
                  <p className={classes.priceNote}>
                    *Specification and pricing subject to change without notice.
                  </p>
                </AccordionItem>
              )}

              <AccordionItem
                className={classes.accordionItem}
                title="frame specs"
                titleClassName={classes.accordionTitle}
                startExpanded={false}
              >
                <div
                  className={classes.paddedAccordionContent}
                  id="frame_specs"
                >
                  <KeyValueDataView data={frameSpecs || {}} />
                </div>
              </AccordionItem>

              <AccordionItem
                className={classes.accordionItem}
                title="geometry"
                titleClassName={classes.accordionTitle}
                startExpanded={false}
              >
                <div className={classes.geometryContent} id="geometry">
                  <img
                    src={getAssetUrl(
                      product?.fullRecord['Geometry Drawing File'] || '',
                    )}
                    alt={`${product.name} frame`}
                  />
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th scope="col">Size</th>
                        {geometrySizes?.map((size, i) => (
                          <th scope="col" key={size + i}>
                            {size}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(geometryMap)
                        .sort()
                        .sort((a, b) =>
                          GeoNoteEx.test(a) && !GeoNoteEx.test(b) ? -1 : 0,
                        )
                        .map((geoKey) => (
                          <tr key={geoKey}>
                            <th scope="row">{geoKey}</th>
                            {geometryMap[geoKey]?.map((geoProp, i) => (
                              <td key={geoKey + geoProp + i}>{geoProp}</td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {product?.fullRecord['Geometry Note'] &&
                    Number(product?.fullRecord['Geometry Note']?.length) >
                      0 && (
                      <div className={classes.geometryNotes}>
                        <p>Measurements based on:</p>
                        <ul>
                          {product?.fullRecord['Geometry Note'] &&
                            product?.fullRecord[
                              'Geometry Note'
                            ].map((geoNote) =>
                              geoNote ? <li key={geoNote}>{geoNote}</li> : null,
                            )}
                        </ul>
                      </div>
                    )}
                </div>
              </AccordionItem>
              {(productPageQuery?.relatedFaq?.length || []) > 0 && (
                <AccordionItem
                  className={classes.accordionItem}
                  title="faq"
                  titleClassName={classes.accordionTitle}
                  startExpanded={false}
                >
                  <div className={classes.nestedAccordionSection} id="faq">
                    {productPageQuery?.relatedFaq?.map((faq) => (
                      <AccordionItem
                        title={faq?.question || ''}
                        key={faq.uid}
                        className={classes.nestedAccordionItem}
                        titleClassName={classes.nestedAccordionTitle}
                      >
                        <div
                          className={classes.paddedAccordionContent}
                          id={
                            faq && faq.question
                              ? faq.question.replace(/ /g, '_').toLowerCase()
                              : ''
                          }
                        >
                          <div
                            className={classes.nestedAccordionContent}
                            dangerouslySetInnerHTML={{
                              __html: faq?.answer || '',
                            }}
                          />
                        </div>
                      </AccordionItem>
                    ))}
                  </div>
                </AccordionItem>
              )}

              <AccordionItem
                className={classes.accordionItem}
                title="supportive documents"
                titleClassName={classes.accordionTitle}
                startExpanded={false}
              >
                <ul className={classes.documentList} id="supportive_documents">
                  {product?.fullRecord['Tech Documents']?.map((doc, i) => (
                    <li key={doc + i}>
                      <FeatherIcon name="file" size={32} />
                      <a
                        href={getAssetUrl(doc)}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span>
                          {doc
                            .replace(/\.[^/._]+$/g, ' ')
                            .replace(/[_]/g, ' ')
                            .trim()}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </AccordionItem>
              <AccordionItem
                className={classes.accordionItem}
                title="warranty"
                titleClassName={classes.accordionTitle}
                startExpanded={false}
              >
                <div className={classes.nestedAccordionSection} id="warranty">
                  {warrantyQuery?.accordionItem?.map((item, index) => (
                    <AccordionItem
                      title={item?.accordionheading || ''}
                      key={index}
                      className={classes.nestedAccordionItem}
                      titleClassName={classes.nestedAccordionTitle}
                    >
                      <div
                        className={classes.paddedAccordionContent}
                        id={
                          item && item.accordionheading
                            ? item.accordionheading
                                .replace(/ /g, '_')
                                .toLowerCase()
                            : ''
                        }
                      >
                        <div
                          className={classes.nestedAccordionContent}
                          dangerouslySetInnerHTML={{
                            __html: item?.accordionresponse || '',
                          }}
                        />
                      </div>
                    </AccordionItem>
                  ))}
                </div>
              </AccordionItem>
            </div>
            <div className={classes.section}>
              {productPageQuery?.quotes && productPageQuery?.quotes[0] && (
                <PullQuote
                  quote={
                    (productPageQuery?.quotes &&
                      productPageQuery?.quotes[0]?.body) ||
                    ''
                  }
                  credit={
                    (productPageQuery?.quotes &&
                      productPageQuery?.quotes[0]?.author) ||
                    ''
                  }
                  linkTo={
                    (productPageQuery?.quotes &&
                      productPageQuery?.quotes[0]?.linkTo) ||
                    ''
                  }
                />
              )}
            </div>
          </>
        )}
        {relatedAccessories?.productEntries &&
          relatedAccessories?.productEntries?.length > 0 && (
            <div className={classes.section}>
              <h2 className={classes.sectionHeading}>
                {(productPageQuery?.sectionHeadings &&
                  productPageQuery?.sectionHeadings[3]?.headingText) ||
                  'Complete the Experience'}
              </h2>

              <ProductCarousel entries={relatedAccessories?.productEntries} />
            </div>
          )}
        {relatedProducts?.productEntries &&
          relatedProducts?.productEntries?.length > 0 && (
            <div
              className={classNames(
                classes.section,
                classes.similarProductsContainer,
              )}
            >
              <h2 className={classes.sectionHeading}>
                {(productPageQuery?.sectionHeadings &&
                  productPageQuery?.sectionHeadings[isBike ? 4 : 0]
                    ?.headingText) ||
                  'Related Products'}
              </h2>
              <ProductCarousel
                slidesToShow={3}
                entries={relatedProducts?.productEntries}
              />
            </div>
          )}
      </LayoutContainer>
    </>
  )
}
