import * as React from 'react'

export const ClothingIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width={28}
      height={29}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58 1.154c-.085.039-.168.076-.248.11-.046.021-.091.044-.135.07-1.222.722-3.915 2.24-5.257 2.768-1.054.415-1.805 1.239-2.3 1.972a7.733 7.733 0 00-.978 2.054 1.25 1.25 0 00-.048.291L.25 16.27A1.25 1.25 0 001.5 17.578h4.71c.051 0 .102-.003.151-.01.032.404.048.823.048 1.258 0 2.962-.926 6.943-1.4 8.573a1.25 1.25 0 001.201 1.599L21.79 29a1.25 1.25 0 001.2-1.598c-.473-1.63-1.4-5.611-1.4-8.574 0-.392.036-.813.1-1.252.033.003.066.004.1.004h4.71a1.25 1.25 0 001.249-1.307l-.363-7.852a1.256 1.256 0 00-.048-.29 7.735 7.735 0 00-.979-2.054c-.494-.734-1.245-1.557-2.3-1.973-1.34-.528-4.034-2.046-5.256-2.768a1.238 1.238 0 00-.135-.069C17.518.763 16.245.25 14.18.25m9.084 11.816c-.007.109-.024.2-.038.265-.036.172-.1.372-.187.61v2.14h2.151l-.294-6.383a5.406 5.406 0 00-.611-1.225c-.352-.521-.748-.887-1.143-1.043-.832-.328-2.036-.943-3.159-1.553-.32.397-.733.831-1.271 1.217-.631.451-1.4.816-2.35 1.036.104.354.155.73.155 1.11 0 .681-.167 1.359-.503 1.907a2.6 2.6 0 01-.584.668v15.683l4.74.002c-.496-2.071-1.08-5.142-1.08-7.672 0-2.096.746-4.554 1.511-6.512l.126-.455a1.35 1.35 0 01.03-.31c.035-.166.096-.303.128-.371.038-.084.087-.177.14-.275a1.25 1.25 0 012.304.928l-.065.233zm-2.271.555a.249.249 0 01-.006-.008l.006.008zM12.93 26.498H7.83c.496-2.071 1.08-5.143 1.08-7.672 0-3.37-.88-5.975-1.935-7.921a1.25 1.25 0 00-2.304.929l.29 1.045v2.199H2.81l.295-6.382c.106-.313.31-.78.61-1.225.352-.522.748-.888 1.143-1.043.845-.333 2.076-.964 3.215-1.584a7.994 7.994 0 003.933 2.264c-.107.36-.16.745-.16 1.131 0 .682.166 1.36.502 1.908.14.228.335.469.584.668v15.683zM10.404 3.524l-.056.034c.807.67 2.052 1.303 3.833 1.303 1.589 0 2.503-.39 3.077-.8a3.61 3.61 0 00.504-.437 59.25 59.25 0 01-.165-.097c-.995-.433-1.891-.777-3.416-.777-1.198 0-1.899.103-2.397.235-.417.11-.715.243-1.133.43-.077.034-.16.07-.247.11zm-.824-2.37c.431-.193.935-.42 1.564-.586l-1.564.586zm1.564-.586c.77-.204 1.7-.318 3.037-.318l-3.037.318z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
