import React, { createContext, useContext } from 'react'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { HeroImage } from '../../components/HeroImage'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { PullQuote } from '../../components/PullQuote'
import { useSliderRef } from '../../components/carousel/carousel.hooks'
import Slider from 'react-slick'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import { theme, Theme } from '../../theme'
import { createUseStyles } from 'react-jss'
import {
  classNames,
  fetchApiData,
  formatDateString,
  getHeaderFlexAlignment,
} from '../../utils'
import { Link } from 'react-router-dom'
import { PressPageQuery_entry_pressPage_pressPage_Entry as PressPageQuery } from '../../api-types/PressPageQuery'
import { useQuery } from 'react-query'
import { Entry } from '../../types'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { LayoutContainer } from '../../components/LayoutContainer'

const useStyles = createUseStyles((theme: Theme) => ({
  slider: {
    height: 400,
    width: '100%',
    background: theme.colors.secondaryGrey100,
  },
  slide: {
    height: 400,
    '& > div': {
      justifyContent: 'space-between',
    },
  },
  sliderContainer: {
    marginBottom: 128,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 64,
    },
  },
  sliderButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: 'calc(50% - 20px)',
    padding: 0,
    borderStyle: 'none',
    background: 'none',
  },
  pressTitleandInputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 48,
  },
  pressTitleContainer: {
    ...theme.typography.bebas(31),
    flex: 1,
    color: theme.colors.primaryBlack,
  },
  inputContainer: {
    flex: 1,
    position: 'relative',
  },
  input: {
    ...theme.typography.urwInput(16),
    padding: `${theme.spacing.S}px ${theme.spacing.M}px`,
    borderRadius: 4,
    borderColor: theme.colors.secondaryGrey300,
    borderStyle: 'solid',
    width: '100%',
  },
  icon: {
    position: 'absolute',
    right: theme.spacing.L,
    top: 24,
    transform: 'translateY(-50%)',
    height: 24,
    width: 24,
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing.M,
      top: 24,
      height: 24,
      width: 24,
    },
  },
  pressLinkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pressReleases: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    margin: 'auto',
    marginBottom: 96,
    gridColumnGap: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      gridColumnGap: 12,
    },
  },
  pressRelease: {
    marginBottom: theme.spacing.XL,
    width: '100%',
  },
  pressLink: {
    ...theme.typography.urwBoldButton(16),
    ...theme.traits.clickable,
    color: theme.colors.secondaryGrey900,
  },
  pressTitle: {
    ...theme.typography.bebas(24),
    color: theme.colors.secondaryGrey700,
  },
  externalLink: {
    position: 'relative',
    color: theme.colors.primaryBlack,
    marginLeft: 11,
    top: 4,
  },
  urlContainer: {
    ...theme.typography.bebas(16),
    color: theme.colors.secondaryGrey500,
    letterSpacing: '0.2px',
    marginBottom: theme.spacing.L,
  },
  viewMore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 96,
    marginBottom: 96,
  },
  viewMoreText: {
    ...theme.typography.urwBoldButton(21),
    color: theme.colors.secondaryGrey700,
    marginRight: theme.spacing.S,
  },
}))

const PressPageQueryContext = createContext<PressPageQuery | null>(null)
export const usePressPageEntry = (): PressPageQuery | null =>
  useContext(PressPageQueryContext)

export const SalsaPressPage: React.FC = () => {
  const classes = useStyles()
  const { data, isLoading } = useQuery('presspage', () =>
    fetchApiData<Entry<PressPageQuery>>('presspage'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }
  const hero = data?.entry?.hero && data?.entry?.hero[0]
  const heroImage = hero?.image && hero.image[0]
  return (
    <PressPageQueryContext.Provider value={data?.entry || null}>
      <HeroImage
        src={heroImage?.url || ''}
        alt={heroImage?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
      >
        <ProductTitle>{hero?.heading}</ProductTitle>
      </HeroImage>
      <LayoutContainer>
        <Breadcrumbs />
      </LayoutContainer>
      <SalsaPressSlider />
      <LayoutContainer>
        <div>
          <div className={classes.pressTitleandInputContainer}>
            <h2 className={classes.pressTitleContainer}>{hero?.heading}</h2>
            {/*<div className={classes.inputContainer}>*/}
            {/*  <input*/}
            {/*    className={classes.input}*/}
            {/*    type="text"*/}
            {/*    placeholder="Search Press"*/}
            {/*  />*/}
            {/*  <FeatherIcon className={classes.icon} name="search" />*/}
            {/*</div>*/}
          </div>
          <div className={classes.pressReleases}>
            {data?.entry?.pressArticles?.map((pr) => (
              <>
                <div className={classes.pressRelease}>
                  <h3 className={classes.pressTitle}>{pr?.heading}</h3>
                  <div className={classes.urlContainer}>
                    {pr?.source} | {formatDateString(pr?.date || '')}
                  </div>
                  <div className={classes.pressLinkContainer}>
                    <Link
                      className={classes.pressLink}
                      to={{ pathname: pr?.linkTo || '' }}
                      target={pr?.linkTo?.includes('http') ? '_blank' : '_self'}
                    >
                      {pr?.linkLabel}
                      <FeatherIcon
                        className={classes.externalLink}
                        name="external-link"
                        size={24}
                        color={theme.colors.primaryBlack}
                      />
                    </Link>
                  </div>
                </div>
              </>
            ))}
          </div>
          {/*<div className={classes.viewMore}>*/}
          {/*  <div className={classes.viewMoreText}> View More </div>*/}
          {/*  <FeatherIcon*/}
          {/*    name="chevron-down"*/}
          {/*    color={theme.colors.secondaryGrey700}*/}
          {/*    size={32}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </LayoutContainer>
    </PressPageQueryContext.Provider>
  )
}

const SalsaPressSlider: React.FC = () => {
  const settings = {
    infinite: true,
    centerMode: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const [sliderRef, handleForward, handleBackward] = useSliderRef()
  const pressPageEntry = usePressPageEntry()

  const classes = useStyles()
  const showArrows = (pressPageEntry?.quotes?.length ?? 0) > 1
  return (
    <div className={classes.sliderContainer}>
      <Slider
        ref={sliderRef}
        className={classNames('center', classes.slider)}
        {...settings}
      >
        {pressPageEntry?.quotes?.map((quote, i) => (
          <PullQuote
            key={i}
            className={classes.slide}
            quote={quote?.body || ''}
            credit={quote?.author || ''}
            linkTo={quote?.linkTo || ''}
          />
        ))}
      </Slider>
      {showArrows && (
        <>
          <button
            className={classes.sliderButton}
            style={{ left: 0 }}
            onClick={handleBackward}
          >
            <FeatherIcon
              name="chevron-left"
              color={theme.colors.secondaryGrey500}
              size={40}
            />
          </button>
          <button
            className={classes.sliderButton}
            style={{ right: 0 }}
            onClick={handleForward}
          >
            <FeatherIcon
              name="chevron-right"
              color={theme.colors.secondaryGrey500}
              size={40}
            />
          </button>
        </>
      )}
    </div>
  )
}
