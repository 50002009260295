import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Settings as SettingsIcon } from '../icons/Settings'

const useStyles = createUseStyles((theme: Theme) => ({
  h2: {
    font: 'inherit',
  },
  platformFilterGroup: {
    marginBottom: theme.spacing.XXXL,
    paddingBottom: theme.spacing.L,
    marginRight: theme.spacing.L,
    width: 236,
    border: 'none',
  },
  label: {
    ...theme.typography.bebas(24),
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.S,
    '& span': {
      marginRight: theme.spacing.S,
    },
  },
}))

export const PlatformFilterGroup: React.FC = ({ children }) => {
  const classes = useStyles()

  if (React.Children.count(children) === 0) {
    return null
  }

  return (
    <fieldset className={classes.platformFilterGroup}>
      <legend className={classes.label}>
        <h2 className={classes.h2}>FILTERS</h2>
        <SettingsIcon />
      </legend>
      {children}
    </fieldset>
  )
}
