import React from 'react'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../../theme'
import Slider from 'react-slick'
import { useSliderRef } from './carousel.hooks'
import { FeatherIcon } from '../icons/FeatherIcon'

interface FixedHeightCarouselProps {
  slidesToShow?: number
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  fixedHeightCarousel: {
    display: 'flex',
    justifyContent: 'center',
  },
  sliderContainer: {
    width: 'calc(100% - 104px)',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 52,
    '&:hover': {
      cursor: 'pointer',
    },
    border: 'none',
    background: 'none',
  },
}))

export const FixedHeightCarousel: React.FC<FixedHeightCarouselProps> = (
  props,
) => {
  const { children, slidesToShow = 4 } = props
  const classes = useStyles()
  const [sliderRef, handleForward, handleBack] = useSliderRef()
  const childrenCount = React.Children.count(children)
  if (childrenCount === 0) {
    return null
  }
  const showArrows = childrenCount > slidesToShow
  return (
    <div className={classes.fixedHeightCarousel}>
      {showArrows && (
        <button tabIndex={0} onClick={handleBack} className={classes.arrow}>
          <FeatherIcon
            name="chevron-left"
            size={32}
            color={theme.colors.secondaryGrey500}
          />
          <span className={classes.srOnly}>Previous</span>
        </button>
      )}
      <div className={classes.sliderContainer}>
        <Slider
          ref={sliderRef}
          slidesToScroll={slidesToShow}
          slidesToShow={slidesToShow}
          arrows={false}
        >
          {children}
        </Slider>
      </div>
      {showArrows && (
        <button tabIndex={0} onClick={handleForward} className={classes.arrow}>
          <FeatherIcon
            name="chevron-right"
            size={32}
            color={theme.colors.secondaryGrey500}
          />
          <span className={classes.srOnly}>Next</span>
        </button>
      )}
    </div>
  )
}
