import React from 'react'

export const Videos: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 15c0 6.903-5.597 12.5-12.5 12.5S2.5 21.903 2.5 15 8.097 2.5 15 2.5 27.5 8.097 27.5 15zM25 15c0-5.523-4.477-10-10-10S5 9.477 5 15s4.477 10 10 10 10-4.477 10-10zM14.285 9.144c-1.413-.974-3.45-.04-3.45 1.691v8.33c0 1.731 2.037 2.665 3.451 1.69l6.059-4.163a2.031 2.031 0 000-3.384l-6.06-4.164zm-1.184 1.716c-.057-.04-.124-.03-.16-.024a.25.25 0 00-.005 0c-.013.002-.021.003-.021 0v8.328c0-.004.01-.003.026 0 .036.005.103.015.161-.025L19.125 15 13.1 10.86z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
