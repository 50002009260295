import React from 'react'
import { ProductCategorySearchAndFilterPage } from '../../../components/ProductCategorySearchAndFilter'
import { Hierarchy } from '../../../product-types'

export const BikeAccesories: React.FC = () => {
  return (
    <ProductCategorySearchAndFilterPage
      hierarchy={Hierarchy.BIKE_ACCESSORIES}
    />
  )
}
