import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import Slider from 'react-slick'
import { createUseStyles } from 'react-jss'
import {
  classNames,
  getAssetUrl,
  getRouteForPlatform,
  getRouteForProduct,
} from '../../utils'
import {
  useObservedAnimationSlider,
  useOnLinkClick,
  useSliderRef,
} from './carousel.hooks'
import { Theme, theme } from '../../theme'
import { FeatherIcon } from '../icons/FeatherIcon'
import { productEntry as ProductEntry } from '../../api-types/productEntry'
import { platformEntry as PlatformEntry } from '../../api-types/platformEntry'
import { usePlatforms } from '../../hooks'
const TRANSITION_DURATION = 800

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The width the image takes up on the slide when not the active slide
   */
  imageWidthPercentage: number

  /**
   * The amount of padding on the left and right of the centered slide
   * Determines of much of the next/previous slide show up
   */
  centerPaddingPercentage: number

  entries: (ProductEntry | PlatformEntry | null)[] | null
}

const useStyles = createUseStyles<
  string,
  { enableTransition: boolean; imageWidthPercentage: number },
  Theme
>((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    position: 'relative',
    '&& .slick-track': {
      display: 'flex',
      paddingBottom: 68,
    },
    '&& .slick-slide img': {
      margin: '0 auto',
      transition: (props) =>
        props.enableTransition ? `transform ${TRANSITION_DURATION}ms` : '',
      transform: (props) => `scale(${props.imageWidthPercentage / 100})`,
    },
  },
  arrow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: '100%',
    width: (props) => `${props.imageWidthPercentage * 0.25}vw`,
    zIndex: 99,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.S,
    '&:hover': { cursor: 'pointer' },
    border: 'none',
  },
  leftArrow: {
    left: 0,
    justifyContent: 'flex-start',
    background:
      'linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 25%, rgba(255, 255, 255, 0.0001) 100%)',
  },
  rightArrow: {
    right: 0,
    justifyContent: 'flex-end',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 75%, #FFFFFF 100%)',
  },
  slide: {
    '&&': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textDecoration: 'none',
      width: (props) => `${props.imageWidthPercentage}vw`,
    },
  },
  titleContainer: {
    textAlign: 'center',
    position: 'absolute',
    zIndex: 99,
    transition: (props) =>
      props.enableTransition ? `transform ${TRANSITION_DURATION}ms` : '',
  },
  title: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryBlack,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  sublabel: {
    ...theme.typography.urw(21),
    color: theme.colors.secondaryGrey500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  image: {
    width: '100%',
  },
}))

export const AnimatedImageHeightCarousel: React.FC<Props> = (props) => {
  const {
    className,
    imageWidthPercentage,
    entries,
    centerPaddingPercentage,
  } = props

  const history = useHistory()
  const [enableTransition, setEnableTransition] = useState(false)
  const [sliderRef, handleForward, handleBackward] = useSliderRef()
  const classes = useStyles({ ...props, enableTransition })
  const platformNames: string[] =
    entries?.reduce((names, entry) => {
      if (entry?.__typename === 'platformPage_platformPage_Entry') {
        names.push((entry as PlatformEntry).title as string)
      }
      return names
    }, [] as string[]) || []
  const platformData = usePlatforms({
    names: platformNames,
    enabled: platformNames.length > 0,
  })

  const containerRef = useObservedAnimationSlider(
    imageWidthPercentage,
    classes.image,
  )

  const [handleKeyDown, getHandleKeyUp] = useOnLinkClick(
    containerRef,
    (pathname) => {
      history.push(pathname)
    },
  )

  const handleContainerKeyUp = (e: React.KeyboardEvent) => {
    switch (e.code) {
      case 'Enter': {
        const target = e.target as HTMLDivElement
        const link = target.querySelector('a')
        const hrefAttr = link?.attributes.getNamedItem('href')
        if (hrefAttr) {
          history.push(hrefAttr.value)
        }
        break
      }
      case 'ArrowLeft':
        handleBackward()
        break
      case 'ArrowRight':
        handleForward()
        break
    }
  }

  if (!entries || entries.length === 0) {
    return null
  }

  return (
    <div
      className={classNames(classes.container, className)}
      ref={containerRef}
      onMouseDown={() => setEnableTransition(false)}
      onMouseUp={() => setEnableTransition(true)}
      onTouchStart={() => setEnableTransition(false)}
      onTouchEnd={() => setEnableTransition(true)}
      tabIndex={0}
      role="region"
      aria-label="carousel"
      onKeyUp={handleContainerKeyUp}
      onFocus={(e) => setEnableTransition(true)}
    >
      <p className={classes.srOnly}>
        This is a carousel. Use Next and Previous buttons to navigate slides.
      </p>
      <button
        className={classNames(classes.arrow, classes.leftArrow)}
        onClick={() => handleBackward()}
      >
        <FeatherIcon
          name="chevron-left"
          color={theme.colors.secondaryGrey500}
          size={32}
        />
        <span className={classes.srOnly}>Previous</span>
      </button>
      <button
        className={classNames(classes.arrow, classes.rightArrow)}
        onClick={() => handleForward()}
      >
        <FeatherIcon
          name="chevron-right"
          color={theme.colors.secondaryGrey500}
          size={32}
        />
        <span className={classes.srOnly}>Next</span>
      </button>
      <Slider
        ref={sliderRef}
        className={classNames('center', classes.container)}
        arrows={false}
        centerMode
        centerPadding={`${centerPaddingPercentage}%`}
        speed={TRANSITION_DURATION}
        focusOnSelect
        afterChange={(current) => sliderRef.current.slickGoTo(current, false)}
        responsive={[
          {
            breakpoint: 380,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {entries.map((entry, i) => {
          if (entry?.__typename === 'platformPage_platformPage_Entry') {
            const data = entry as PlatformEntry
            const platform = platformData[data.title || '']
            const missionStatement =
              data?.missionStatement && data?.missionStatement[0]
            return (
              <div
                key={data?.uid}
                role="group"
                aria-label={`slide ${i + 1} of ${entries.length}`}
              >
                <Link
                  className={classes.slide}
                  to={getRouteForPlatform(platform?.hierarchy, platform?.name)}
                  onClick={(e) => e.preventDefault()}
                  onFocus={() => sliderRef.current.slickGoTo(i, i === 0)}
                  onMouseDown={handleKeyDown}
                  onMouseUp={getHandleKeyUp(
                    getRouteForPlatform(platform?.hierarchy, platform?.name),
                  )}
                >
                  <img
                    alt={platform?.name}
                    src={getAssetUrl(
                      platform?.images[1] || platform?.images[0],
                    )}
                    className={classes.image}
                    data-img-index={i}
                    onLoad={(e) => {
                      const image = e.target as HTMLImageElement
                      const labelContainer = image.nextSibling as HTMLDivElement
                      labelContainer.style.transform = `translateY(${image.height}px)`
                    }}
                  />
                  <div className={classes.titleContainer}>
                    <p className={classes.title}>{platform?.name}</p>
                    <p className={classes.sublabel}>
                      {missionStatement?.heading}
                    </p>
                    <span className={classes.srOnly}>{platform?.name}</span>
                  </div>
                </Link>
              </div>
            )
          } else {
            const image =
              entry?.productAssets &&
              (entry?.productAssets[1] || entry?.productAssets[0])
            return (
              <div key={entry?.id}>
                <Link
                  className={classes.slide}
                  to={getRouteForProduct('', entry?.productUrlSlug || '404')}
                  onClick={(e) => e.preventDefault()}
                  onFocus={() => sliderRef.current.slickGoTo(i, i === 0)}
                  onMouseDown={handleKeyDown}
                  onMouseUp={getHandleKeyUp(
                    getRouteForProduct('', entry?.productUrlSlug || '404'),
                  )}
                >
                  <img
                    alt={image?.alttext || ''}
                    src={image?.url || ''}
                    className={classes.image}
                    data-img-index={i}
                    onLoad={(e) => {
                      const image = e.target as HTMLImageElement
                      const labelContainer = image.nextSibling as HTMLDivElement
                      labelContainer.style.transform = `translateY(${image.height}px)`
                    }}
                  />
                  <div className={classes.titleContainer}>
                    <p className={classes.title}>{entry?.title || ''}</p>
                    <p className={classes.sublabel}>
                      {entry?.productBlurb || ''}
                    </p>
                    <span className={classes.srOnly}>{entry?.title || ''}</span>
                  </div>
                </Link>
              </div>
            )
          }
        })}
      </Slider>
    </div>
  )
}
