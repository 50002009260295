import * as React from 'react'

export const ArchivedGearIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width={25}
      height={22}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.875 21.625H3.125A3.088 3.088 0 010 18.587V3.412A3.088 3.088 0 013.125.376h5.75c.374.002.727.172.962.462l3.25 3.975h8.75A3.088 3.088 0 0125 7.85v10.737a3.088 3.088 0 01-3.125 3.038zM2.5 13.2v5.387a.575.575 0 00.625.538h18.75a.575.575 0 00.625-.538V7.85a.575.575 0 00-.625-.538H12.5a1.25 1.25 0 01-.963-.462l-3.25-3.975H3.125a.575.575 0 00-.625.537V13.2z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
