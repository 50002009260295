import * as React from 'react'

export const BikeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 13.75a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM0 17.5a6.25 6.25 0 1112.5 0 6.25 6.25 0 01-12.5 0zM23.75 13.75a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM17.5 17.5a6.25 6.25 0 1112.5 0 6.25 6.25 0 01-12.5 0z"
        fill={props.color || '#989898'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.343 6.602c0-.69.56-1.25 1.25-1.25h3.333a1.25 1.25 0 01.531 2.381l1.067 2.25 5.8-1.061-.506-1.07h-1.151a1.25 1.25 0 110-2.5h1.944c.484 0 .924.279 1.13.716l5.14 10.899a1.25 1.25 0 01-2.249 1.092l-1.008-2.016.046-.023-1.922-4.076-3.647 6.774a1.25 1.25 0 01-1.101.657H6.25a1.25 1.25 0 01-.965-2.045l5.05-6.126-1.589-3.352H7.593c-.69 0-1.25-.56-1.25-1.25zm5.175 7.097L8.9 16.875h4.124l-1.506-3.176zm3.553 1.658l1.97-3.659-3.409.624 1.439 3.035z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
