import React, { useEffect, useRef } from 'react'

declare global {
  interface Window {
    FB?: {
      XFBML: {
        parse: (el?: HTMLDivElement | null) => void
      }
    }
  }
}

export const FacebookEventsEmbed: React.FC = () => {
  const eventsRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    window.FB?.XFBML.parse(eventsRef.current)
  }, [])

  return (
    <div ref={eventsRef}>
      <div
        className="fb-page"
        data-href="https://www.facebook.com/salsacycles"
        data-tabs="events"
        data-width="500"
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/salsacycles"
          className="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/salsacycles">Salsa Cycles</a>
        </blockquote>
      </div>
    </div>
  )
}
