import React from 'react'
import { useLocation } from 'react-router-dom'
import * as Routes from '../routes'
import { Helmet } from 'react-helmet'

export const HeaderMeta: React.FC = () => {
  const { pathname } = useLocation()
  const pageTitle = Routes.RouteLabels[pathname]
  const metaTitle = `${pageTitle ? `${pageTitle} |` : ''} Salsa Cycles`
  const pageUrl = `https://salsacycles.com${pathname}`

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <link rel="canonical" href={pageUrl} />
      <meta property="og:site_name" content="Salsa Cycles" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={pageUrl} />
    </Helmet>
  )
}
