import * as Routes from './routes'

export const legacyRedirects: Array<[from: string, to: string]> = [
  ['/bikes/fat-bikes', Routes.fatTireBikes],
  ['/components', Routes.gear],
  ['/components/category/accessories', Routes.accessories],
  ['/components/archive', Routes.archivedGear],
  ['/clothing', Routes.clothing],
  ['/people', Routes.team],
  ['/culture', Routes.salsa],
  ['/videos', Routes.videos],
  ['/culture/posts', Routes.journal],
  ['/stories', Routes.stories],
  ['/support/contact', Routes.contact],
  ['/dealers/become_a_dealer', Routes.dealerSignup],
  ['/privacy-policy', Routes.privacy],
  ['/safety', Routes.supportAndSafety],

  // Bike Category Routes
  ['/bikes/cassidy', `${Routes.mountainBikes}/cassidy`],
  ['/bikes/blackthorn', `${Routes.mountainBikes}/blackthorn`],
  ['/bikes/horsethief', `${Routes.mountainBikes}/horsethief`],
  ['/bikes/spearfish', `${Routes.mountainBikes}/spearfish`],
  ['/bikes/rustler', `${Routes.mountainBikes}/rustler`],
  ['/bikes/timberjack', `${Routes.mountainBikes}/timberjack`],
  ['/bikes/timberjack_kids', `${Routes.mountainBikes}/timberjack`],
  ['/bikes/rangefinder', `${Routes.mountainBikes}/rangefinder`],

  ['/bikes/warbird', `${Routes.roadBikes}/warbird`],
  ['/bikes/cutthroat', `${Routes.roadBikes}/cutthroat`],
  ['/bikes/warroad', `${Routes.roadBikes}/warroad`],
  ['/bikes/journeyman', `${Routes.roadBikes}/journeyman`],
  ['/bikes/journeyman_650b', `${Routes.roadBikes}/journeyman`],
  ['/bikes/journeyman_24', `${Routes.roadBikes}/Journeyman%2024`],
  ['/bikes/stormchaser', `${Routes.roadBikes}/stormchaser`],
  ['/bikes/fargo', `${Routes.roadBikes}/fargo`],
  ['/bikes/vaya', `${Routes.roadBikes}/vaya`],
  ['/bikes/marrakesh', `${Routes.roadBikes}/marrakesh`],

  ['/bikes/beargrease', `${Routes.fatTireBikes}/beargrease`],
  ['/bikes/mukluk', `${Routes.fatTireBikes}/mukluk`],
  ['/bikes/blackborow', `${Routes.fatTireBikes}/blackborow`],

  // Salsa Crew
  ['/salsa/salsa-crew', Routes.salsa],
  ['/salsa/salsa-crew/:staffSlug', Routes.salsa],

  // Bike Product Routes
  [
    '/bikes/journeyman_24/2021_journeyman_24',
    Routes.bikeDetail.replace(':productSlug', '2021_journeyman_24_red'),
  ],
  ['/bikes/warbird/:productSlug', Routes.bikeDetail],
  ['/bikes/cutthroat/:productSlug', Routes.bikeDetail],
  ['/bikes/warroad/:productSlug', Routes.bikeDetail],
  ['/bikes/journeyman/:productSlug', Routes.bikeDetail],
  ['/bikes/journeyman_650b/:productSlug', Routes.bikeDetail],
  ['/bikes/journeyman_24/:productSlug', Routes.bikeDetail],
  ['/bikes/stormchaser/:productSlug', Routes.bikeDetail],
  ['/bikes/fargo/:productSlug', Routes.bikeDetail],
  ['/bikes/vaya/:productSlug', Routes.bikeDetail],
  ['/bikes/marrakesh/:productSlug', Routes.bikeDetail],
  ['/bikes/cassidy/:productSlug', Routes.bikeDetail],
  ['/bikes/blackthorn/:productSlug', Routes.bikeDetail],
  ['/bikes/horsethief/:productSlug', Routes.bikeDetail],
  ['/bikes/spearfish/:productSlug', Routes.bikeDetail],
  ['/bikes/rustler/:productSlug', Routes.bikeDetail],
  ['/bikes/timberjack/:productSlug', Routes.bikeDetail],
  ['/bikes/timberjack_kids/:productSlug', Routes.bikeDetail],
  ['/bikes/rangefinder/:productSlug', Routes.bikeDetail],
  ['/bikes/beargrease/:productSlug', Routes.bikeDetail],
  ['/bikes/mukluk/:productSlug', Routes.bikeDetail],
  ['/bikes/blackborow/:productSlug', Routes.bikeDetail],

  // Part Category Routes
  ['/components/category/alternator_dropouts', Routes.parts],
  ['/components/category/collars', Routes.parts],
  ['/components/category/dropouts', Routes.parts],
  ['/components/category/forks', Routes.parts],
  ['/components/category/grips_bar_tape', Routes.parts],
  ['/components/category/hangers', Routes.parts],
  ['/components/category/mountain_handlebars', Routes.parts],
  ['/components/category/pivot_kits', Routes.parts],
  ['/components/category/rack_hardware', Routes.parts],
  ['/components/category/road_handlebars', Routes.parts],
  ['/components/category/seatposts', Routes.parts],
  ['/components/category/skewers', Routes.parts],
  ['/components/category/stems', Routes.parts],

  // Accessory Category Routes
  ['/components/category/bags_frame_packs', Routes.accessories],
  ['/components/category/racks', Routes.accessories],

  // Part Product Routes
  ['/components/category/alternator_dropouts/:productSlug', Routes.gearDetail],
  ['/components/category/collars/:productSlug', Routes.gearDetail],
  ['/components/category/dropouts/:productSlug', Routes.gearDetail],
  ['/components/category/forks/:productSlug', Routes.gearDetail],
  ['/components/category/grips_bar_tape/:productSlug', Routes.gearDetail],
  ['/components/category/hangers/:productSlug', Routes.gearDetail],
  ['/components/category/mountain_handlebars/:productSlug', Routes.gearDetail],
  ['/components/category/pivot_kits/:productSlug', Routes.gearDetail],
  ['/components/category/rack_hardware /:productSlug', Routes.gearDetail],
  ['/components/category/road_handlebars/:productSlug', Routes.gearDetail],
  ['/components/category/seatposts/:productSlug', Routes.gearDetail],
  ['/components/category/skewers/:productSlug', Routes.gearDetail],
  ['/components/category/stems/:productSlug', Routes.gearDetail],
  ['/components/category/bags_frame_packs/:productSlug', Routes.gearDetail],
  ['/components/category/racks/:productSlug', Routes.gearDetail],

  // Clothing Category Routes
  ['/clothing/category/jerseys', Routes.clothing],
  ['/clothing/category/matching_kit', Routes.clothing],
  ['/clothing/category/casual_wear', Routes.clothing],
  ['/clothing/category/accessories', Routes.clothing],

  // Clothing Product Routes
  ['/clothing/category/jerseys/:productSlug', Routes.gearDetail],
  ['/clothing/category/matching_kit/:productSlug', Routes.gearDetail],
  ['/clothing/category/casual_wear/:productSlug', Routes.gearDetail],
  ['/clothing/category/accessories/:productSlug', Routes.gearDetail],

  // Chase the Chaise
  ['/chasethechaise', `${Routes.journal}/stories/chasethechaise`],

  // Recalls
  [
    '/files/tech/19241_SAL_Skewer_Tech_Bulletin.pdf',
    'https://www.salsacycles.com/assets/19241_SAL_Skewer_Tech_Bulletin.pdf',
  ],
  [
    '/culture/salsa_minimalist_rack_voluntary_recall',
    `${Routes.recalls}/minimalist-rack-recall`,
  ],
  [
    '/bearpawforkrecall',
    `${Routes.recalls}/mukluk-aluminum-bearpaw-fork-recall`,
  ],
  ['/salsaforkrecall', `${Routes.recalls}/vaya-fork-recall`],
  ['/salsa_waxwing_fork_recall', `${Routes.recalls}/waxwing-fork-recall`],
  [
    '/cutthroat-carbon-v2-fork-recall',
    `${Routes.recalls}/cutthroat-carbon-v2-fork-recall`,
  ],
  ['/support/recall', Routes.recalls],
]
