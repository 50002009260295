import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  button: {
    ...theme.traits.clickable,
    padding: '12px 24px',
    color: theme.colors.primaryWhite,
    backgroundColor: theme.colors.primaryRed,
    borderStyle: 'none',
    borderRadius: 2,
    letterSpacing: 0.8,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.bebasButton(26),
    },
    [theme.breakpoints.down('md')]: {
      ...theme.typography.bebasButton(21),
      padding: '8px 16px',
    },
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.bebasButton(16),
      padding: '6px 16px',
    },
  },
}))

type Props = Pick<
  React.HTMLProps<HTMLButtonElement>,
  'onClick' | 'className' | 'disabled'
>

export const Button: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <button {...props} className={classNames(classes.button, props.className)}>
      {children}
    </button>
  )
}
