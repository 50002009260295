import React from 'react'
import { createUseStyles } from 'react-jss'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { HeroImage } from '../../components/HeroImage'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { Theme } from '../../theme'
import { FacebookEventsEmbed } from './FacebookEventsEmbed'
import * as Routes from '../../routes'
import { Link } from 'react-router-dom'
import { SalsaEventsPageQuery_entry_salsaEvents_salsaEvents_Entry as SalsaEventsPageQuery } from '../../api-types/SalsaEventsPageQuery'
import { useQuery } from 'react-query'
import { fetchApiData, getHeaderFlexAlignment } from '../../utils'
import { Entry } from '../../types'
import { LayoutContainer } from '../../components/LayoutContainer'
import { LoadingIndicator } from '../../components/LoadingIndicator'
const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    ...theme.traits.gridLayout,
    rowGap: theme.spacing.XXXL,
    paddingLeft: theme.spacing.L,
    paddingRight: theme.spacing.L,
    paddingTop: 24,
    paddingBottom: 96,
  },
  copyContainer: {
    ...theme.traits.gridColumn({ lgCols: 7 }),
    [theme.breakpoints.up('md')]: {
      marginRight: 56,
    },
  },
  embedContainer: {
    ...theme.traits.gridColumn({ lgCols: 5, lgStart: 8 }),
  },
  heading: {
    ...theme.typography.bebas(48),
  },
  paragraph: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
    marginTop: theme.spacing.L,
  },
  contentFooter: {
    marginTop: 50,
    '& > p': {
      ...theme.typography.urw(18),
      color: theme.colors.secondaryGrey700,
      marginBottom: theme.spacing.S,
      '& > strong': {
        color: theme.colors.secondaryGrey900,
      },
      '& > a': {
        ...theme.traits.clickable,
        color: theme.colors.primaryRed,
        textDecoration: 'none',
      },
    },
  },
}))

export const SalsaEvents: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('salsa/events', () =>
    fetchApiData<Entry<SalsaEventsPageQuery>>('salsa/events'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const hero = data?.entry?.hero && data?.entry?.hero[0]
  const heroImage = hero?.image && hero?.image[0]

  return (
    <>
      <HeroImage
        src={heroImage?.url || ''}
        alt={heroImage?.alttext || ''}
        justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
      >
        <ProductTitle>{hero?.heading}</ProductTitle>
      </HeroImage>
      <LayoutContainer>
        <Breadcrumbs />
        <div className={classes.container}>
          <div className={classes.copyContainer}>
            <h1 className={classes.srOnly}>Events</h1>
            {data?.entry?.salsaEventsBody?.map((paragraph) => (
              <p className={classes.paragraph} key={paragraph?.id}>
                {paragraph?.paragraphText}
              </p>
            ))}
            <div className={classes.contentFooter}>
              <p>
                For <strong>Event Sponsorship or Partnership Requests:</strong>
              </p>
              <p>
                Please complete our{' '}
                <Link
                  to={
                    data?.entry?.eventSponsorshipLinkTo ||
                    Routes.eventRegistration
                  }
                >
                  Event Sponsorship Application
                </Link>
              </p>
            </div>
          </div>
          <div className={classes.embedContainer}>
            <FacebookEventsEmbed />
          </div>
        </div>
      </LayoutContainer>
    </>
  )
}
