import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'

interface DevKitConfig {
  apiBaseUrl: string
  apiPublicKey: string
  googleMapsApiKey: string
  addToCartSelector?: string
  availabilitySelector?: string
  cartIconSelector?: string
  checkoutSelector?: string
  drawerSelector?: string
  findRetailersSelector?: string
}

declare global {
  interface Window {
    QDKProductDealerLocator: (
      vendor: string,
      elId: string,
      config: Pick<
        DevKitConfig,
        'apiBaseUrl' | 'apiPublicKey' | 'googleMapsApiKey'
      >,
    ) => any
  }
}

export const RISEmbed: React.FC = () => {
  const classes = useDevkitStyles()

  const {
    REACT_APP_DEVKIT_VENDOR,
    REACT_APP_DEVKIT_API_BASE_URL,
    REACT_APP_DEVKIT_API_PUBLIC_KEY,
    REACT_APP_DEVKIT_GOOGLE_MAPS_API_KEY,
  } = process.env

  useEffect(() => {
    if (
      !REACT_APP_DEVKIT_VENDOR ||
      !REACT_APP_DEVKIT_API_BASE_URL ||
      !REACT_APP_DEVKIT_API_PUBLIC_KEY ||
      !REACT_APP_DEVKIT_GOOGLE_MAPS_API_KEY
    ) {
      return
    }

    const qdk = window.QDKProductDealerLocator(
      REACT_APP_DEVKIT_VENDOR,
      '#qdk-dealer-locator',
      {
        apiBaseUrl: REACT_APP_DEVKIT_API_BASE_URL,
        apiPublicKey: REACT_APP_DEVKIT_API_PUBLIC_KEY,
        googleMapsApiKey: REACT_APP_DEVKIT_GOOGLE_MAPS_API_KEY,
      },
    )

    function getProductFromChange(event: any, builder: any) {
      // user-defined function to return product from provided EVENT
      if (
        event &&
        event.target &&
        event.target.selectedOptions &&
        event.target.selectedOptions.length > 0
      ) {
        return builder
          .gtin(event.target.value)
          .productCode(event.target.selectedOptions[0].dataset.sku)
          .build()
      }
    }

    qdk
      .findElement('select#RISselect')
      .addListener('change', getProductFromChange)

    const activeSelector = document.querySelector(
      'select#RISselect',
    ) as HTMLInputElement
    if (activeSelector) {
      activeSelector.dispatchEvent(new Event('change'))
    }
  }, [
    REACT_APP_DEVKIT_VENDOR,
    REACT_APP_DEVKIT_API_BASE_URL,
    REACT_APP_DEVKIT_API_PUBLIC_KEY,
    REACT_APP_DEVKIT_GOOGLE_MAPS_API_KEY,
  ])

  return (
    <div className={classes.devkitTheme}>
      <div id="qdk-dealer-locator"></div>
    </div>
  )
}
const useDevkitStyles = createUseStyles((theme: Theme) => ({
  devkitTheme: {
    '& .contrasting-block': {
      display: 'none',
    },
    '& > div:first-child': {
      padding: '25px 100px 0px',
      [theme.breakpoints.down('md')]: {
        padding: '25px 50px 0px',
      },
    },
    '& .map': {
      gridColumn: '1 / -1',
      marginTop: ' -4px !important',
    },
    '& .page-error, & h3': {
      textAlign: 'center',
      gridColumn: '1 / -1',
      display: 'flex',
      justifyContent: 'center',
      border: 'none',
      marginBottom: 20,
    },
    '& .location-search': {
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    '& .location-container': {
      marginBottom: 2,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 300,
      marginRight: 'auto',
      paddingRight: 10,
      '& > div:first-child': {
        paddingTop: 7,
      },
    },
    '& .address-form-container': {
      paddingLeft: 0,
    },
    '& .address-form-container input': {
      ...theme.typography.urwInput(16),
      padding: '7px 8px 3px 8px',
      color: theme.colors.secondaryGrey900,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.colors.secondaryGrey300,
      borderRadius: 2,
      minWidth: 255,
      marginRight: 10,
      [theme.breakpoints.down('sm')]: {
        minWidth: 50,
      },
    },
    '& .location-search button': {
      backgroundColor: theme.colors.primaryBlack,
      marginLeft: 0,
      '&:after': {
        content: '"Search"',
      },
    },
    '& .dealers': {
      ...theme.traits.gridColumn({
        lgCols: 6,
        lgStart: 4,
      }),
      gridColumn: '1 / -1',
      margin: 0,
      marginTop: theme.spacing.XXL,
      paddingTop: 0,
    },
    '& .qdk-dealer': {
      paddingTop: theme.spacing.L,
      paddingBottom: theme.spacing.L,
    },
    '& button': {
      ...theme.typography.bebas(16),
      ...theme.traits.clickable,
      padding: '4px 14px',
      color: theme.colors.primaryRed,
      borderColor: theme.colors.primaryRed,
      borderStyle: 'solid',
      borderWidth: 2,
      borderRadius: 2,
      background: 'none',
    },
    '& .load-more': {
      gridColumn: '1 / -1',
      '& > button': {
        ...theme.traits.clickable,
        backgroundColor: theme.colors.primaryRed,
      },
      marginBottom: 20,
    },
    '& .dealer-name': {
      ...theme.typography.bebas(24),
      color: theme.colors.secondaryGrey900,
      mmarginBottom: 2,
    },
    '& .dealer-meta': {
      ...theme.typography.urw(18),
      color: theme.colors.secondaryGrey900,
      marginBottom: 2,
      textTransform: 'uppercase',
    },
    '& .dealer-address': {
      textTransform: 'uppercase',
    },
    '& .dealer-details a': {
      color: theme.colors.primaryRed,
    },
    '& .hide-show-details > a': {
      ...theme.traits.clickable,
      ...theme.typography.urwInput(18),
      color: theme.colors.primaryRed,
      textDecoration: 'none',
    },
    '& .dealer-actions': {
      marginTop: 8,
    },
    '& .dealer-stock-status': {
      marginBottom: '1rem',
      fontWeight: 100,
    },
    '& .dealer-availability-time': {
      display: 'none',
    },
    '& .qdk-loader-wrapper': {
      display: 'none',
    },
  },
}))
