import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { CallToAction } from '../../components/CallToAction'
import { HeroImage } from '../../components/HeroImage'
import { ProductTitle } from '../../components/typography/ProductTitle'
import { Theme } from '../../theme'
import * as routes from '../../routes'
import { useQuery } from 'react-query'
import { fetchApiData, getHeaderFlexAlignment } from '../../utils'
import { Entries, Entry } from '../../types'
import { SalsaSponsoredTeamMemberQuery_entries_sponsoredTeamMember_sponsoredTeamMember_Entry as SalsaSponsoredTeamMemberQuery } from '../../api-types/SalsaSponsoredTeamMemberQuery'
import { SalsaSponsoredTeamPageQuery_entry_salsaSponsoredTeamPage_salsaSponsoredTeamPage_Entry as SalsaSponsoredTeamPageQuery } from '../../api-types/SalsaSponsoredTeamPageQuery'
import { LayoutContainer } from '../../components/LayoutContainer'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  contentContainer: {
    ...theme.traits.gridLayout,
    rowGap: 40,
    marginTop: theme.spacing.XXL,
    paddingLeft: theme.spacing.L,
    paddingRight: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  teamText: {
    ...theme.typography.urw(21),
    ...theme.traits.gridColumn({
      lgCols: 6,
      lgStart: 4,
      mdCols: 6,
      mdStart: 2,
    }),
    textAlign: 'center',
    color: theme.colors.secondaryGrey700,
    marginBottom: 56,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.urw(16),
    },
  },
  memberList: {
    display: 'grid',
    gridColumnGap: theme.spacing.S,
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginRight: theme.spacing.S,
    marginLeft: theme.spacing.S,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  memberContainer: {
    gridColumn: 'span 4',
    textDecoration: 'none',
  },
  memberImage: { width: '100%' },
  memberName: {
    ...theme.typography.bebas(31),
    letterSpacing: '1px',
    marginBottom: 2,
    color: theme.colors.secondaryGrey700,
  },
  memberTitle: {
    ...theme.typography.urw(21),
    color: theme.colors.secondaryGrey700,
  },
  memberLabel: {
    padding: theme.spacing.M,
  },
}))

export const SalsaSponsoredTeam: React.FC = () => {
  const classes = useStyles()
  const {
    data: pageData,
    isLoading: isPageLoading,
  } = useQuery('salsa/teampage', () =>
    fetchApiData<Entry<SalsaSponsoredTeamPageQuery>>('salsateampage'),
  )
  const { data: teamData, isLoading: isTeamLoading } = useQuery(
    'salsa/team',
    () => fetchApiData<Entries<SalsaSponsoredTeamMemberQuery>>('salsateam'),
  )

  if (isPageLoading || isTeamLoading) {
    return <LoadingIndicator />
  }

  const hero = pageData?.entry?.hero && pageData?.entry?.hero[0]
  const heroImage = hero?.image && hero?.image[0]
  const missionStatement =
    pageData?.entry?.missionStatement && pageData?.entry?.missionStatement[0]
  const callToAction =
    pageData?.entry?.callToActionInternalLink &&
    pageData?.entry?.callToActionInternalLink[0]
  return (
    <>
      <HeroImage
        src={heroImage?.url || ''}
        justifyContent={getHeaderFlexAlignment(hero?.headingAlignment)}
        alt={heroImage?.alttext || ''}
      >
        <ProductTitle>{hero?.heading}</ProductTitle>
      </HeroImage>
      <LayoutContainer>
        <Breadcrumbs />
        <div className={classes.contentContainer}>
          <h1 className={classes.srOnly}>Sponsored Riders</h1>
          <div
            className={classes.teamText}
            dangerouslySetInnerHTML={{
              __html: missionStatement?.body || '',
            }}
          ></div>
        </div>
        <ul className={classes.memberList}>
          {teamData?.entries?.map((member) => (
            <li
              role="group"
              aria-label="item"
              key={member.sponsoredTeamMemberLocation}
            >
              <Link
                to={`${routes.team}/${member.slug}`}
                className={classes.memberContainer}
              >
                <img
                  src={
                    (member.imageThumbnail && member.imageThumbnail[0]?.url) ||
                    ''
                  }
                  alt=""
                  className={classes.memberImage}
                />
                <div className={classes.memberLabel}>
                  <div className={classes.memberName}>
                    {(member.hero && member.hero[0]?.heading) || ''}
                  </div>
                  <div className={classes.memberTitle}>
                    {member.sponsoredTeamMemberLocation}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <CallToAction
          title={callToAction?.label || ''}
          route={callToAction?.linkTo || '#'}
          text={callToAction?.linkLabel || ''}
        />
      </LayoutContainer>
    </>
  )
}
