import React, { createContext } from 'react'
import { useQuery } from 'react-query'
import { HomePageLandingQuery_entry_homePageLanding_homePageLanding_Entry as HomePageLandingQuery } from '../../api-types/HomePageLandingQuery'

import { Entry } from '../../types'
import { fetchApiData } from '../../utils'
import { HomeBottomSection } from './HomeBottomSection'
import { HomeContentSection } from './HomeContentSection'
import { HomeHeroSection } from './HomeHeroSection'
import { LoadingIndicator } from '../../components/LoadingIndicator'

export const HomePageContext = createContext<HomePageLandingQuery | undefined>(
  undefined,
)

export const Homepage: React.FC = () => {
  const { data, isLoading } = useQuery('/homepage', () =>
    fetchApiData<Entry<HomePageLandingQuery>>('homepage'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <HomePageContext.Provider value={data?.entry}>
      <div>
        <HomeHeroSection />
        <HomeContentSection />
        <HomeBottomSection />
      </div>
    </HomePageContext.Provider>
  )
}
