import * as React from 'react'

export const FatTireIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={31}
      height={30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.25 13.97c0-.842-.683-1.525-1.525-1.525h-1.108a11.234 11.234 0 00-.18-.687l.97-.548a1.525 1.525 0 00.577-2.079l-1.008-1.783a1.525 1.525 0 00-2.078-.577l-.973.55c-.17-.186-.345-.365-.526-.539l.577-.963a1.525 1.525 0 00-.525-2.092l-1.758-1.052a1.525 1.525 0 00-2.092.525l-.576.962a11.31 11.31 0 00-.966-.266v-1.12c0-.843-.683-1.526-1.525-1.526h-2.049c-.842 0-1.525.683-1.525 1.525v1.121a11.32 11.32 0 00-.817.22l-.562-.969a1.525 1.525 0 00-2.085-.553L7.724 3.622a1.525 1.525 0 00-.553 2.085l.562.969c-.198.184-.389.376-.573.574l-.983-.566a1.525 1.525 0 00-2.083.561L3.072 9.021c-.42.73-.168 1.662.562 2.082l.977.562a11.32 11.32 0 00-.209.78H3.275c-.842 0-1.525.683-1.525 1.526v2.048c0 .843.683 1.526 1.525 1.526h1.122c.054.24.117.476.186.71l-.969.548a1.525 1.525 0 00-.577 2.078l1.008 1.783a1.525 1.525 0 002.079.577l.971-.549c.167.183.339.36.517.53l-.571.954a1.525 1.525 0 00.525 2.093l1.758 1.052c.723.433 1.66.197 2.092-.525l.568-.949c.32.104.646.194.977.27v1.108c0 .842.683 1.525 1.525 1.525h2.049c.842 0 1.525-.683 1.525-1.525v-1.108c.28-.064.556-.139.828-.223l.556.958a1.525 1.525 0 002.085.553l1.771-1.028a1.525 1.525 0 00.554-2.085l-.559-.963c.2-.187.393-.38.58-.582l.952.548c.73.42 1.662.169 2.083-.561l1.021-1.776c.42-.73.169-1.663-.562-2.083l-.954-.549c.079-.256.148-.517.208-.782h1.102c.842 0 1.525-.683 1.525-1.525v-2.048zM15.51 6.66a8.346 8.346 0 100 16.692 8.346 8.346 0 000-16.691z"
        fill={props.color || '#989898'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 5.625a9.375 9.375 0 100 18.75 9.375 9.375 0 000-18.75zM3 15C3 8.096 8.596 2.5 15.5 2.5S28 8.096 28 15s-5.596 12.5-12.5 12.5S3 21.904 3 15z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
