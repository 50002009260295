import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  platformDescription: {
    width: theme.measurements.contentNarrowWidth,
    margin: '0 auto',
    marginBottom: theme.spacing.L,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingRight: theme.spacing.L,
      paddingLeft: theme.spacing.L,
    },
  },
  descriptionText: {
    ...theme.typography.urw(21),
    color: theme.colors.secondaryGrey700,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.urw(16),
    },
  },
  headingText: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey500,
    marginBottom: theme.spacing.M,
  },
}))

export const PlatformDescription: React.FC<{
  description: string
  heading?: string
  className?: string
}> = ({ description, heading, className }) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.platformDescription, className)}>
      {heading && <h2 className={classes.headingText}>{heading}</h2>}
      <div
        className={classes.descriptionText}
        dangerouslySetInnerHTML={{
          __html: description || '',
        }}
      ></div>
    </div>
  )
}
