import React from 'react'
import { GDPRPageQuery_entry_gdprPage_gdprPage_Entry as GDPRPageQuery } from '../api-types/GDPRPageQuery'
import { useQuery } from 'react-query'
import { fetchApiData } from '../utils'
import { Entry } from '../types'
import { LayoutContainer } from '../components/LayoutContainer'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { LoadingIndicator } from '../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  gdprPage: {
    paddingTop: theme.spacing.XXL,
    paddingBottom: theme.spacing.XXL,
    ...theme.typography.urw(21),
    '& *': {
      fontFamily: theme.typography.urw(16).fontFamily,
    },
    '& h1, & h2, & h3, & h4, & h5, & p, & li': {
      margin: 'revert',
    },
    '& ol, & ul': {
      listStyle: 'revert',
      padding: 'revert',
      margin: 'revert',
    },
    '& h1': {
      ...theme.typography.bebas(48),
      marginBottom: theme.spacing.L,
      marginTop: theme.spacing.XL,
      paddingBottom: theme.spacing.XXL,
      borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
    },
  },
}))

export const GDPRPage: React.FC = () => {
  const { data, isLoading } = useQuery('gdpr', () =>
    fetchApiData<Entry<GDPRPageQuery>>('gdpr'),
  )
  const styles = useStyles()

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <LayoutContainer contentClass={styles.gdprPage}>
      <div
        dangerouslySetInnerHTML={{ __html: data?.entry?.articleRawBody || '' }}
      />
    </LayoutContainer>
  )
}
