import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CategoryNav } from '../../components/CategoryNav'
import * as Routes from '../../routes'
import { Articles } from './icons/Articles'
import { Stories } from './icons/Stories'
import { Videos } from './icons/Videos'
import { JournalArticles } from './JournalArticles'
import { JournalLanding } from './JournalLanding'
import { JournalStories } from './JournalStories'
import { JournalVideos } from './JournalVideos'
import { ArticlePage } from './ArticlePage'
import { StoryPage } from './StoryPage'
import { VideoPage } from './VideoPage'

export const JournalPageRouter: React.FC = () => {
  return (
    <>
      <CategoryNav routes={journalNavRoutes} />
      <Switch>
        <Route path={Routes.storyPart} component={StoryPage} />
        <Route path={[Routes.story, Routes.storyPart]} component={StoryPage} />
        <Route path={Routes.video} component={VideoPage} />
        <Route path={Routes.article} component={ArticlePage} />
        <Route path={Routes.stories} component={JournalStories} />
        <Route path={Routes.videos} component={JournalVideos} />
        <Route path={Routes.articles} component={JournalArticles} />
        <Route path={Routes.journal} component={JournalLanding} />
      </Switch>
    </>
  )
}

const journalNavRoutes = [
  {
    route: Routes.articles,
    label: 'Articles',
    icon: <Articles />,
  },
  {
    route: Routes.videos,
    label: 'Videos',
    icon: <Videos />,
  },
  {
    route: Routes.stories,
    label: 'Stories',
    icon: <Stories />,
  },
]
