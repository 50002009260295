import React from 'react'
import { Hierarchy } from '../../../product-types'
import { PlatformSearch } from '../../../components/search/PlatformSearch'
import { LayoutContainer } from '../../../components/LayoutContainer'
import { Breadcrumbs } from '../../../components/Breadcrumbs'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../../theme'

const useStyles = createUseStyles((theme: Theme) => ({
  paddedBreadcrumbs: { marginBottom: theme.spacing.XL },
}))

export const AllGear: React.FC = () => {
  const classes = useStyles()
  return (
    <LayoutContainer>
      <div className={classes.paddedBreadcrumbs}>
        <Breadcrumbs />
      </div>
      <PlatformSearch hierarchy={Hierarchy.GEAR} />
    </LayoutContainer>
  )
}
