import * as React from 'react'
import { theme } from '../../theme'

export const MapPin: React.FC = () => {
  return (
    <svg
      aria-hidden="true"
      width="18"
      height="26"
      viewBox="0 0 18 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.14062C0 4.10048 4.0374 0 9 0C13.9626 0 18 4.10048 18 9.14062C18 10.793 17.561 12.4124 16.7305 13.8237L9.7684 25.6297C9.63405 25.8576 9.39245 25.9979 9.1309 26H9.12495C8.86565 26 8.62455 25.8639 8.4877 25.6399L1.34345 13.9472C0.46445 12.5054 0 10.8435 0 9.14062ZM9.1136 23.7751L15.4433 13.0415C16.1344 11.867 16.505 10.5179 16.505 9.14062C16.505 4.9405 13.1355 1.51327 9 1.51327C4.8645 1.51327 1.49 4.9405 1.49 9.14062C1.49 10.56 1.8867 11.9445 2.61835 13.1445L9.1136 23.7751Z"
        fill={theme.colors.secondaryGrey700}
      />
      <path
        clipRule="evenodd"
        d="M4.28564 10.0997C4.28564 7.47133 6.40047 5.33301 8.99993 5.33301C11.5994 5.33301 13.7142 7.47133 13.7142 10.0997C13.7142 12.6825 11.6663 14.8663 8.99993 14.8663C6.36606 14.8663 4.28564 12.7112 4.28564 10.0997ZM5.8466 10.0997C5.8466 11.8563 7.2579 13.2881 8.99993 13.2881C10.7696 13.2881 12.148 11.8307 12.148 10.0997C12.148 8.34746 10.7329 6.91131 8.99993 6.91131C7.26696 6.91131 5.8466 8.34746 5.8466 10.0997Z"
        fill={theme.colors.secondaryGrey700}
      />
    </svg>
  )
}
