import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link, useLocation } from 'react-router-dom'
import { Theme } from '../theme'
import { MapPin } from './icons/MapPin'
import * as Routes from '../routes'
import { LayoutContainer } from './LayoutContainer'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  topBarContainer: {
    height: 52,
    backgroundColor: theme.colors.secondaryGrey300,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  topBarContent: {
    display: 'flex',
    width: '100%',
  },
  contactLinkContainer: {
    ...theme.traits.flexCentering,
    ...theme.typography.urw(16),
    color: '#404146',
  },
  topBarContactText: {
    marginLeft: theme.spacing.M,
  },
  topBarRightNav: {
    ...theme.traits.flexCentering,
  },
  rightLinkList: {
    ...theme.traits.flexCentering,
    flexDirection: 'row',
    '& > li:not(:last-child)': {
      marginRight: theme.spacing.XXL,
    },
    '& > li:first-child': {
      marginRight: 'auto',
    },
    width: '100%',
    justifyContent: 'flex-end',
  },
  link: {
    ...theme.typography.urw(16),
    color: '#404146',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '&:focus': {
      color: theme.colors.primaryBlack,
    },
  },
}))

export const HeaderTopBar: React.FC = () => {
  const classes = useStyles()
  const { pathname } = useLocation()

  return (
    <>
      <a
        href={`${pathname}#main`}
        className={[classes.srOnly, classes.srOnlyFocusable].join(' ')}
      >
        Skip to main content.
      </a>
      <LayoutContainer
        contentClass={classes.topBarContent}
        containerClass={classes.topBarContainer}
      >
        <nav className={classes.topBarContent} aria-label="top">
          <ul className={classes.rightLinkList}>
            <li>
              <Link
                to={Routes.contact}
                className={classes.contactLinkContainer}
              >
                <div>
                  <MapPin />
                </div>
                <div className={classes.topBarContactText}>Contact</div>
              </Link>
            </li>
            <li>
              <Link className={classes.link} to={Routes.dealers}>
                Dealers
              </Link>
            </li>
            <li>
              <Link className={classes.link} to={Routes.supportAndSafety}>
                Support & Safety
              </Link>
            </li>
          </ul>
        </nav>
      </LayoutContainer>
    </>
  )
}
