import React from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import * as Routes from '../routes'

const useStyles = createUseStyles((theme: Theme) => ({
  copyrightFooter: {
    ...theme.typography.urw(16),
    textAlign: 'center',
    color: theme.colors.secondaryGrey100,
    marginBottom: theme.spacing.L,
    padding: `0 ${theme.spacing.XL}px`,
  },
  linkBreak: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  link: {
    fontWeight: 500,
    color: theme.colors.secondaryGrey100,
    paddingBottom: 6,
  },
}))

export const CopyrightFooter: React.FC = () => {
  const classes = useStyles()
  return (
    <p className={classes.copyrightFooter}>
      <span>
        &copy; {new Date().getFullYear()} Salsa Cycles All Rights Reserved
      </span>
      <span> / </span>
      <br className={classes.linkBreak} />
      <span>
        <Link to={Routes.privacy} className={classes.link}>
          Privacy Policy
        </Link>
      </span>
      <span> / </span>
      <span>
        <Link to={Routes.accessibility} className={classes.link}>
          Accessibility
        </Link>
      </span>
    </p>
  )
}
