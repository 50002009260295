import React from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import * as Routes from '../routes'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: '100%',
    paddingBottom: theme.spacing.XXXL,
    display: 'grid',
    padding: `104px 0`,
    paddingRight: theme.spacing.M,
    gridTemplateColumns: 'repeat(5, 1fr) auto',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr) auto',
      gridTemplateRows: 'repeat(2, auto)',
      padding: `${theme.spacing.XXL}px ${theme.spacing.XXXL}px`,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, auto)',
      gridTemplateRows: 'repeat(6, auto)',
      padding: `${theme.spacing.XXL}px ${theme.spacing.L}px`,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    paddingBottom: theme.spacing.XXL,
    paddingRight: theme.spacing.M,
    whiteSpace: 'nowrap',
  },
  navHeading: {
    ...theme.typography.bebas(26),
    marginBottom: theme.spacing.L,
    color: theme.colors.primaryWhite,
  },
  navItem: {
    ...theme.typography.urwInput(18),
    marginBottom: theme.spacing.L,
    color: theme.colors.secondaryGrey100,
  },
}))

export const FooterNav: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {navSections.map((section) => (
        <nav
          key={section.url}
          className={classes.section}
          aria-label={`${section.label.toLowerCase()} footer`}
        >
          <h2 className={classes.navHeading} key={section.url}>
            <Link to={section.url} className={classes.navHeading}>
              {section.label}
            </Link>
          </h2>
          <ul>
            {section.items.map((item) => (
              <li className={classes.navItem} key={item.url}>
                <Link
                  key={item.url}
                  to={{ pathname: item.url }}
                  target={item.url.includes('http') ? '_blank' : '_self'}
                  className={classes.navItem}
                  dangerouslySetInnerHTML={{
                    __html: item.label.replace('& ', '&<br />'),
                  }}
                />
              </li>
            ))}
          </ul>
        </nav>
      ))}
    </div>
  )
}

const navSections = [
  {
    label: 'Bikes',
    url: Routes.bikes,
    items: [
      { label: 'Mountain', url: Routes.mountainBikes },
      { label: 'Gravel & Road', url: Routes.roadBikes },
      { label: 'Fat Tire', url: Routes.fatTireBikes },
      { label: 'Ebike', url: Routes.eBikes },
      { label: 'Archived', url: Routes.archivedBikes },
    ],
  },
  {
    label: 'Gear',
    url: Routes.gear,
    items: [
      { label: 'Clothing', url: Routes.clothing },
      { label: 'Bike Accessories', url: Routes.accessories },
      { label: 'Bike Parts', url: Routes.parts },
    ],
  },
  {
    label: 'Salsa',
    url: Routes.salsa,
    items: [
      { label: 'About Salsa', url: Routes.about },
      { label: 'Adventure by Bike', url: Routes.campaign },
      { label: 'Features & Technology', url: Routes.features },
      { label: 'Sponsored Riders', url: Routes.team },
      { label: 'Events', url: Routes.events },
      { label: 'Press', url: Routes.press },
      { label: 'Careers', url: 'https://www.qbp.com/careers' },
      { label: 'Contact', url: Routes.contact },
    ],
  },
  {
    label: 'Journal',
    url: Routes.journal,
    items: [
      { label: 'Articles', url: Routes.articles },
      { label: 'Video', url: Routes.videos },
      { label: 'Stories', url: Routes.stories },
    ],
  },
  {
    label: 'Dealers',
    url: Routes.dealers,
    items: [
      { label: 'Distributors', url: Routes.distributors },
      { label: 'Dealers Sign Up', url: Routes.dealerSignup },
    ],
  },
  {
    label: 'Safety & Support',
    url: Routes.supportAndSafety,
    items: [
      { label: 'Bike & Frame Registration', url: Routes.bikeRegistration },
      { label: 'Warranty', url: Routes.warranty },
      { label: 'Recalls', url: Routes.recalls },
      { label: 'Instructions & Downloads', url: Routes.downloads },
    ],
  },
]
