import React, { useEffect, useMemo, useState } from 'react'
import {
  QueryClientProvider as ReactQueryClientProvider,
  QueryClient,
} from 'react-query'
import { useLocation } from 'react-router-dom'

const client = new QueryClient()

export const QueryClientProvider: React.FC<{
  children: (error: unknown) => React.ReactElement
}> = ({ children }) => {
  const [error, setError] = useState<unknown>()
  const location = useLocation()

  useEffect(() => {
    setError(null)
  }, [location.pathname])

  const defaultOptions = useMemo(
    () => ({
      queries: {
        onError: (error: unknown) => setError(error),
      },
    }),
    [setError],
  )
  client.setDefaultOptions(defaultOptions)
  return (
    <ReactQueryClientProvider client={client}>
      {children(error)}
    </ReactQueryClientProvider>
  )
}
