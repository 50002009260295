import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'

const useStyles = createUseStyles((theme: Theme) => ({
  platformJournal: {
    marginBottom: theme.spacing.XXL + theme.spacing.M,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  title: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.L,
  },
  entries: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
}))

export const PlatformJournalSection: React.FC<{ title: string }> = ({
  title,
  children,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.platformJournal}>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.entries}>{children}</div>
    </div>
  )
}
