import React, { useContext, useState } from 'react'
import { FeatherIcon } from '../icons/FeatherIcon'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../../theme'
import { Checkbox } from '../Checkbox'
import { SearchContext } from './PlatformSearch'
import { Filter } from '../../product-types'

interface PlatformFilterProps {
  filter: Filter
  filterIndex: number
  showCount: boolean
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  label: {
    ...theme.typography.urwBold(16),
    color: theme.colors.secondaryGrey700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 700,
    height: 32,
    marginBottom: theme.spacing.S,
    marginTop: theme.spacing.S,
    '&:hover': { cursor: 'pointer' },
    width: '100%',
    border: 'none',
    background: 'none',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 2,
    marginBottom: theme.spacing.S,
  },
  showMoreLabel: {
    ...theme.typography.urwBold(14),
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    background: 'none',
    color: theme.colors.secondaryGrey500,
    marginBottom: theme.spacing.M,
    '&:hover': { cursor: 'pointer' },
    '& span': {
      marginRight: theme.spacing.XS,
    },
  },
}))

export const PlatformFilter: React.FC<PlatformFilterProps> = (props) => {
  const { filter, filterIndex } = props
  const [getValue, setValue] = useContext(SearchContext)
  const isOpenDefault =
    filterIndex < 3 ||
    filter.options.some((option) =>
      Boolean(getValue(filter.name, option.id ?? option.name)),
    )
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault)
  const [showAll, setShowAll] = useState<boolean>(false)
  const toggleIsOpen = () => setIsOpen((prev) => !prev)
  const toggleShowAll = () => setShowAll((prev) => !prev)
  if (!filter?.options || !filter?.options.length) {
    return null
  }
  return (
    <div>
      <div className={classes.srOnly}>
        The following check boxes filter bike results by feature. Use Tab to
        access the results.
      </div>
      <button
        onKeyPress={toggleIsOpen}
        onClick={toggleIsOpen}
        className={classes.label}
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-controls={filter.displayName.replace(/ /g, '_').toLowerCase()}
      >
        <h3 className={classes.label}>{filter.displayName}</h3>
        <FeatherIcon
          name={isOpen ? 'chevron-up' : 'chevron-down'}
          size={24}
          color={theme.colors.secondaryGrey700}
        />
      </button>
      {isOpen && (
        <div
          id={filter.displayName.replace(/ /g, '_').toLowerCase()}
          role="region"
          aria-label="Filter checkbox list"
        >
          {filter.options
            .sort((a, b) => {
              const textA = a.name.toUpperCase()
              const textB = b.name.toUpperCase()
              return textA < textB ? -1 : textA > textB ? 1 : 0
            })
            .map((option, i) => {
              if ((i < 5 || showAll) && option.name) {
                return (
                  <Checkbox
                    key={i}
                    autoFocus={showAll}
                    checked={Boolean(
                      getValue(filter.name, option.id ?? option.name),
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setValue(
                        filter.name,
                        option.id ?? option.name,
                        event.target.checked,
                      )
                    }
                    id={option.name}
                    className={classes.option}
                    rightLabel={option.name?.concat(
                      props.showCount ? ` (${option.numEntries})` : '',
                    )}
                  />
                )
              }
              return null
            })}
          {filter.options.length > 5 && (
            <button
              className={classes.showMoreLabel}
              onClick={toggleShowAll}
              onKeyPress={toggleShowAll}
              aria-expanded={showAll ? 'true' : 'false'}
              aria-controls={filter.displayName
                .replace(/ /g, '_')
                .toLowerCase()}
            >
              <span>{showAll ? 'Show Less' : 'Show More'}</span>
              <FeatherIcon
                name={showAll ? 'chevron-up' : 'chevron-down'}
                size={16}
                color={theme.colors.secondaryGrey500}
              />
            </button>
          )}
        </div>
      )}
    </div>
  )
}
