import React, { useCallback, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { TextField } from './TextField'
import { MarketoForm, MktoForm } from '../MarketoForm'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing.XL}px ${theme.spacing.L}px`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 30,
    },
  },
  form: {
    position: 'relative',
    '&:focus-within > label': {
      fontSize: '12px',
      top: '11px',
      left: '12px',
    },
  },
  labelContainer: {
    marginRight: theme.spacing.XXL,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing.M,
      marginRight: 0,
    },
  },
  label: {
    ...theme.typography.urwBold(26),
    color: theme.colors.primaryWhite,
    marginTop: 5,
  },
  floatingLabel: {
    position: 'absolute',
    top: '23px',
    left: '27px',
    zIndex: 9,
    fontSize: 21,
    color: theme.colors.grey600,
    fontWeight: 100,
    [theme.breakpoints.down('sm')]: {
      top: '25px',
      left: '20px',
      fontSize: 16,
    },
  },
  floating: {
    fontSize: '12px',
    top: '11px',
    left: '12px',
  },
  textFieldContainer: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'stretch',
    },
  },
  textField: {
    width: 610,
    lineHeight: '47px',
    paddingBottom: 1,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignSelf: 'stretch',
      minWidth: 300,
      fontSize: 16,
      paddingLeft: 12,
    },
  },
  error: {
    position: 'absolute',
    ...theme.typography.urw(21),
    color: theme.colors.primaryRed,
    fontSize: '1em',
    lineHeight: 'normal',
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: '1em',
      lineHeight: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },
  },
  disclaimer: {
    color: theme.colors.primaryWhite,
    fontSize: 12,
    position: 'absolute',
    bottom: '-30px',
    fontWeight: 100,
    '& > a': {
      fontWeight: 'normal',
      marginLeft: 3,
      color: theme.colors.primaryWhite,
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '-44px',
    },
  },
}))

export const NewsLetterSignup: React.FC = () => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [successMsg, setSuccessMsg] = useState<string>()
  const [labelFloat, setLabelFloat] = useState<boolean>(false)
  const formRef = useRef<MktoForm | null>(null)

  const handleFormReady = useCallback((form: MktoForm) => {
    formRef.current = form
    form.onSuccess(() => {
      // set success message here
      setSuccessMsg('Thank you! You are subscribed to emails.')
      setErrorMessage('')
      // prevent redirect
      return false
    })
  }, [])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const values = formRef.current?.getValues()
    formRef.current?.setValues({
      ...values,
      Email: e.target.value,
    })
    if (e.target.value) {
      setLabelFloat(true)
    } else {
      setLabelFloat(false)
    }
  }, [])

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (formRef.current?.validate()) {
      formRef.current?.submit()
    } else {
      setErrorMessage('Must be valid email! example@yourdomain.com')
    }
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.labelContainer}>
        <h2 className={classes.label}>Get the latest Salsa News!</h2>
      </div>
      <form className={classes.form} onSubmit={handleSubmit}>
        <label
          className={`${classes.floatingLabel} 
          ${labelFloat ? classes.floating : ''}`}
          htmlFor={classes.textField}
        >
          Enter your email address
        </label>
        <TextField
          containerClassName={classes.textFieldContainer}
          className={classes.textField}
          id={classes.textField}
          endIcon="mail"
          ariaLabel="Your email address will be added to the Salsa Cycles Newsletter list"
          autocomplete="email"
          aria-autocomplete="inline"
          onChange={handleChange}
          success={successMsg}
        />
        {errorMessage && <p className={classes.error}> {errorMessage} </p>}
        <div className={classes.disclaimer}>
          Submitting this form subscribes you to emails from Salsa Cycles.
          <a
            aria-label="Launches privacy policy page for Salsa Cycles"
            href="https://salsacycles.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            View our privacy policy.
          </a>
        </div>
      </form>
      <div style={{ display: 'none' }}>
        <MarketoForm formId="1181" onReady={handleFormReady} />
      </div>
    </div>
  )
}
