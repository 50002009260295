import React from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { Instagram } from './icons/Instagram'
import { Facebook } from './icons/Facebook'
import { Vimeo } from './icons/Vimeo'
import { YouTube } from './icons/YouTube'
import { Twitter } from './icons/Twitter'

const useStyles = createUseStyles((theme: Theme) => ({
  socialFooter: {
    marginBottom: theme.spacing.XXL,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  iconLink: {
    '&:not(:last-child)': {
      marginRight: theme.spacing.XXL,
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing.L,
      },
    },
  },
}))

export const SocialFooter: React.FC = () => {
  const classes = useStyles()
  return (
    <nav aria-label="social">
      <ul className={classes.socialFooter}>
        <li className={classes.iconLink} key="instagram">
          <Link
            to={{ pathname: 'https://www.instagram.com/salsacycles/' }}
            target={'_blank'}
            aria-label="Salsa Cycles on Instagram"
          >
            <Instagram />
          </Link>
        </li>
        <li className={classes.iconLink} key="facebook">
          <Link
            to={{ pathname: 'https://www.facebook.com/salsacycles/' }}
            target={'_blank'}
            aria-label="Salsa Cycles on Facebook"
          >
            <Facebook />
          </Link>
        </li>
        <li className={classes.iconLink} key="vimeo">
          <Link
            to={{ pathname: 'https://vimeo.com/salsacycles' }}
            target={'_blank'}
            aria-label="Salsa Cycles on Vimeo"
          >
            <Vimeo />
          </Link>
        </li>
        <li className={classes.iconLink} key="youtube">
          <Link
            to={{ pathname: 'https://www.youtube.com/c/SalsaCyclesOfficial' }}
            target={'_blank'}
            aria-label="Salsa Cycles on Youtube"
          >
            <YouTube />
          </Link>
        </li>
        <li className={classes.iconLink} key="twitter">
          <Link
            to={{ pathname: 'https://twitter.com/salsacycles' }}
            target={'_blank'}
            aria-label="Salsa Cycles on Twitter"
          >
            <Twitter />
          </Link>
        </li>
      </ul>
    </nav>
  )
}
