import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarketoForm } from '../../MarketoForm'
import { Link } from 'react-router-dom'
import * as Routes from '../../routes'
import { classNames } from '../../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  formTraits: {
    marginBottom: 136,
    width: '90% !important',
    '& input': {
      width: '260px !important',
    },
  },
  gridContainer: {
    ...theme.traits.gridLayout,
  },
  topSectionTitleFont: {
    marginTop: 24,
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey900,
  },
  leftSection: {
    ...theme.traits.gridColumn({
      lgCols: 5,
      lgStart: 2,
      mdCols: 5,
      mdStart: 1,
      smCols: 3,
      smStart: 1,
    }),
  },
  leftSectionTitleFont: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey700,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  rightSection: {
    ...theme.traits.gridColumn({
      lgCols: 4,
      lgStart: 8,
      mdCols: 4,
      mdStart: 8,
      smCols: 3,
      smStart: 1,
    }),
  },
  rightSectionTitleFont: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey700,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  rightContent: {
    marginTop: 25,
    marginBottom: 96,
    '& > p': {
      marginBottom: theme.spacing.S,
      ...theme.typography.urw(18),
      color: theme.colors.secondaryGrey700,
      '& > strong': {
        color: theme.colors.secondaryGrey900,
      },
      '& > a': {
        ...theme.traits.clickable,
        color: theme.colors.primaryRed,
        textDecoration: 'none',
      },
    },
  },
  blankLine: {
    marginTop: 24,
  },
  requiredKey: {
    margin: '10px 0px',
    textAlign: 'right',
    fontSize: 12,
    '& > span': {
      color: theme.colors.primaryRed,
      display: 'inline-block',
      marginRight: 3,
      fontSize: 14,
    },
  },
}))

export const SalsaContact: React.FC = () => {
  const classes = useStyles()
  const handleFormReady = useCallback(() => {
    document
      .getElementById('FirstName')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('LastName')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('Email')
      ?.setAttribute('aria-autocomplete', 'inline')
  }, [])
  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.gridContainer}>
        <h1
          className={classNames(
            classes.leftSection,
            classes.topSectionTitleFont,
          )}
        >
          Get in touch
        </h1>
        <div className={classes.leftSection}>
          <div className={classes.requiredKey}>
            <span>*</span>
            indicates a required field
          </div>
          <h2 className={classes.leftSectionTitleFont}>Contact Form</h2>
          <div className={classes.formTraits}>
            <MarketoForm formId="1189" onReady={handleFormReady} />
          </div>
        </div>
        <div className={classes.rightSection}>
          <h2 className={classes.rightSectionTitleFont}>Contact info</h2>
          <div className={classes.rightContent}>
            <p>
              For <strong>Questions & Technical Support:</strong>
            </p>
            <p>
              Please email us at{' '}
              <a href={'mailto:support@salsacycles.com'}>
                support@salsacycles.com
              </a>
            </p>
            <p>
              Or call
              <a href={'tel:+8776686223'}> (877) 668-6223</a>
            </p>
            <p className={classes.blankLine}>
              Salsa Cycles <br />
              6400 West 105th Street <br />
              Bloomington, MN 55438
            </p>
          </div>
          <h2 className={classes.rightSectionTitleFont}>
            Salsa sponsorship Opportunities
          </h2>
          <div className={classes.rightContent}>
            <p>
              For <strong>Event Sponsorship or Partnership Requests:</strong>
            </p>
            <p>
              Please complete our{' '}
              <Link to={Routes.eventRegistration}>
                Event Sponsorship Application
              </Link>
            </p>
            <p className={classes.blankLine}>
              To apply to our <strong> Sponsored Rider Program:</strong>
            </p>
            <p>
              Please complete our{' '}
              <Link to={Routes.riderRegistration}>
                Sponsored Rider Application
              </Link>
            </p>
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}
