import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Link } from 'react-router-dom'
import { FilterState } from '../../hooks'

const useStyles = createUseStyles((theme: Theme) => ({
  searchResultCard: {
    textAlign: 'center',
    color: theme.colors.secondaryGrey700,
    textDecoration: 'none',
  },
  imageContainer: {
    position: 'relative',
  },
  secondImageContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  image: {
    width: '100%',
  },
  title: {
    ...theme.typography.bebas(24),
    marginBottom: theme.spacing.XS,
  },
  subtitle: {
    ...theme.typography.urw(16),
    marginBottom: theme.spacing.XS,
    color: '#404146',
  },
  subtitle2: {
    ...theme.typography.urwBold(14),
    color: theme.colors.secondaryGrey900,
    marginBottom: theme.spacing.XS,
  },
  subtitle3: {
    ...theme.typography.urw(14),
    color: '#404146',
  },
}))

interface SearchResultCardProps {
  title: string
  subtitle?: string
  subtitle2?: string
  subtitle3?: string
  image1: string
  image2?: string
  href: string
  filterState?: FilterState
}

export const SearchResultCard: React.FC<SearchResultCardProps> = (props) => {
  const {
    title,
    subtitle,
    subtitle2,
    subtitle3,
    image1,
    image2,
    href,
    filterState,
  } = props
  const [isHovering, setIsHovering] = useState(false)
  const classes = useStyles()

  return (
    <li role="group" aria-label="item">
      <Link
        to={{
          pathname: href,
          state: filterState,
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={classes.searchResultCard}
      >
        <div className={classes.imageContainer}>
          <img src={image1} alt="" className={classes.image} />
          {isHovering && image2 && (
            <div className={classes.secondImageContainer}>
              <img src={image2} alt="" className={classes.image} />
            </div>
          )}
        </div>
        <p className={classes.title}>{title.replace(/[/]/g, ' / ')}</p>
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
        {subtitle2 && <p className={classes.subtitle2}>{subtitle2}</p>}
        {subtitle3 && <p className={classes.subtitle3}>{subtitle3}</p>}
      </Link>
    </li>
  )
}
