import React from 'react'

export const Settings: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7H4.18C4.74528 8.55744 6.46608 9.36175 8.02352 8.79648C8.85887 8.49329 9.51681 7.83535 9.82 7H21C21.5523 7 22 6.55228 22 6C22 5.44771 21.5523 5 21 5H9.82C9.25472 3.44256 7.53392 2.63825 5.97648 3.20352C5.14113 3.50671 4.48319 4.16465 4.18 5H3C2.44772 5 2 5.44771 2 6C2 6.55228 2.44772 7 3 7ZM19 9C17.7318 9.00356 16.6028 9.80427 16.18 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H16.18C16.7323 14.5621 18.4463 15.3807 20.0084 14.8284C21.5705 14.2761 22.3891 12.5621 21.8368 11C21.412 9.79843 20.2744 8.99643 19 9ZM13.82 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H13.82C13.5168 19.8353 12.8589 20.4933 12.0235 20.7965C10.4661 21.3617 8.74528 20.5574 8.18 19H3C2.44771 19 2 18.5523 2 18C2 17.4477 2.44771 17 3 17H8.18C8.48319 16.1646 9.14113 15.5067 9.97648 15.2035C11.5339 14.6382 13.2547 15.4426 13.82 17Z"
        fill="#636569"
      />
    </svg>
  )
}
