import React from 'react'
import { createUseStyles } from 'react-jss'
import { FilledMapPin } from '../../components/icons/FilledMapPin'
import { Theme } from '../../theme'
import { LayoutContainer } from '../../components/LayoutContainer'
import { Breadcrumbs } from '../../components/Breadcrumbs'

const useStyles = createUseStyles((theme: Theme) => ({
  topSection: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    marginTop: 24,
  },
  topSectionTitle: {
    ...theme.typography.bebas(48),
    lineHeight: '60px',
    fontWeight: 400,
    color: theme.colors.secondaryGrey900,
    textTransform: 'uppercase',
  },
  topSectionSubTitle: {
    ...theme.typography.bebas(26),
    color: theme.colors.secondaryGrey700,
    fontWeight: 200,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  distributorList: {
    marginTop: theme.spacing.XL,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridColumnGap: 120,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridColumnGap: 60,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  distributorContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 44,
  },
  distributor: {
    marginBottom: 24,
    width: 240,
    height: 233,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginBottom: 50,
    },
  },
  location: {
    ...theme.typography.bebas(21),
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.secondaryGrey500,
    marginBottom: theme.spacing.L,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.XS,
    },
  },
  mapPin: {
    marginRight: 12,
  },
  company: {
    ...theme.typography.bebas(24),
    color: theme.colors.secondaryGrey700,
    fontWeight: 400,
    letterSpacing: '1.29px',
  },
  addressBlock: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
    fontWeight: 200,
  },
  genericLink: {
    ...theme.typography.urw(18),
    color: theme.colors.primaryRed,
    fontWeight: 200,
    borderBottom: `1px solid ${theme.colors.primaryRed}`,
  },
  mapPinIconContainer: {
    marginLeft: 12,
  },
}))

export const Distributors: React.FC = () => {
  const classes = useStyles()
  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.topSection}>
        <h1 className={classes.topSectionTitle}>Distributors</h1>
        <p className={classes.topSectionSubTitle}>
          Check out our distributor locations
        </p>
      </div>

      <div className={classes.distributorContainer}>
        <div className={classes.distributorList}>
          {salsaDistributors.map((distributor) => (
            <div className={classes.distributor}>
              <div className={classes.location}>
                <FilledMapPin />
                <p className={classes.mapPinIconContainer}>
                  {distributor.location}
                </p>
              </div>
              <p className={classes.company}>{distributor.company}</p>
              {/* <p className={classes.addressBlock}>{distributor.address1}</p>
              <p className={classes.addressBlock}>{distributor.address2}</p> */}
              <p className={classes.addressBlock}>{distributor.phoneNumber}</p>
              <p>
                <a
                  className={classes.genericLink}
                  href={distributor.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </p>
            </div>
          ))}
        </div>
      </div>
    </LayoutContainer>
  )
}

const salsaDistributors = [
  {
    location: 'Australia',
    company: 'SCV Imports',
    // address1: 'PO Box 3213',
    // address2: 'Tuggerah NSW 2259',
    phoneNumber: '61 2 4353 2633',
    link: 'https://www.scvimports.com.au/',
  },
  {
    location: 'Austria',
    company: 'COSMIC SPORTS GMBH',
    // address1: 'Leyher Strasse 47',
    // address2: '90763 Fürth',
    phoneNumber: '49 911 310755 51',
    link: 'https://cosmicsports.de/',
  },
  {
    location: 'BENELUX',
    company: 'COSMIC SPORTS BV',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '31 23 573 5518',
    link: 'https://cosmicsports.de/',
  },
  {
    location: 'CHILE',
    company: 'MKR IMPORTS',
    // address1: 'San Diego #1184',
    // address2: 'Santiago',
    phoneNumber: '56 22 2555 5743',
    link: 'https://www.mkr.cl/',
  },
  {
    location: 'ECUADOR',
    company: 'ENCIKLA CÍA. LTDA.',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '02-5144500',
    link: 'http://cikla.ec/',
  },
  {
    location: 'Finland',
    company: 'FOXCOMP FINLAND OY',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '358 40 5208788',
    link: 'https://foxcomp.fi/',
  },
  {
    location: 'France',
    company: 'TRAFFIC DISTRIBUTION',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '49 0221 500057 20',
    link: 'https://www.traffic-distribution.com/',
  },
  {
    location: 'Germany',
    company: 'Cosmic Sports',
    // address1: 'Leyher Str. 47',
    // address2: '90763 Fürth, Germany',
    phoneNumber: '49 911 3107550',
    link: 'https://cosmicsports.de/',
  },
  {
    location: 'Italy',
    company: 'RACEWARE SRL',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '39 0544 219097',
    link: 'https://pedaldomain.com/',
  },
  {
    location: 'Japan',
    company: 'MOTOCROSS INTERNATIONAL',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '81 52 773 0256',
    link: 'http://ride2rock.jp/',
  },
  {
    location: 'PHILIPPINES',
    company: 'NANOTECH TRADING',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '63 32 254 4912',
    link: 'http://www.nanotechtradinginc.com/',
  },
  {
    location: 'SWITZERLAND',
    company: 'FRAME OF MIND',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '41 41 630 4920',
    link: 'https://www.frameofmind.ch/',
  },
  {
    location: 'United Kingdom',
    company: 'LYON EQUIPMENT',
    // address1: '123 France Ave',
    // address2: 'Richfield, MN 55423',
    phoneNumber: '44 15396 24040',
    link: 'https://www.lyon.co.uk/',
  },
]
