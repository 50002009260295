import React from 'react'
import { LayoutContainer } from '../components/LayoutContainer'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'

const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL
const supportPhoneNumber = process.env.REACT_APP_SUPPORT_PHONE

const errorMessages = new Map()
  .set(500, 'Oops, Something went wrong')
  .set(404, 'Page not found')

const useStyles = createUseStyles((theme: Theme) => ({
  errorPage: {
    paddingTop: theme.spacing.XXXL,
    paddingBottom: theme.spacing.XXXL,
    ...theme.typography.urw(21),
    '& a': {
      color: theme.colors.error,
    },
  },
  errorMessage: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey900,
    marginBottom: theme.spacing.L,
  },
}))

export const ErrorPage: React.FC<{ errorCode: number }> = ({ errorCode }) => {
  const classes = useStyles()
  const message = errorMessages.get(errorCode) || 'Oops, Something went wrong'
  return (
    <LayoutContainer contentClass={classes.errorPage}>
      <h1 className={classes.errorMessage}>{message}</h1>
      <div>
        <span>For questions & technical support, please email us at: </span>
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        <span> or call </span>
        <a href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</a>
      </div>
    </LayoutContainer>
  )
}
