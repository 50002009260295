import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing.L,
    },
  },
  heading: {
    ...theme.typography.bebas(24),
    color: theme.colors.secondaryGrey900,
  },
  dataContainer: {
    ...theme.traits.gridLayout,
    columnGap: 0,
  },
  dataCell: {
    gridColumn: 'span 6',
    color: theme.colors.secondaryGrey700,
    borderBottom: `2px solid ${theme.colors.secondaryGrey100}`,
    paddingTop: theme.spacing.S,
    paddingBottom: theme.spacing.S,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      borderBottom: 'none',
    },
    '& > span': {
      ...theme.typography.urw(18),
    },
  },
  dataKey: {
    fontWeight: 500,
  },
  dataValue: {
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      borderBottom: `2px solid ${theme.colors.secondaryGrey100}`,
    },
  },
}))

type Props = {
  heading?: string
  data: Record<string, string>
}

export const KeyValueDataView: React.FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {props.heading && <h3 className={classes.heading}>{props.heading}</h3>}
      <dl className={classes.dataContainer}>
        {Object.entries(props.data).map(([key, value], i) => (
          <React.Fragment key={i}>
            <dt className={classNames(classes.dataCell, classes.dataKey)}>
              <span>{key}</span>
            </dt>
            <dd className={classNames(classes.dataCell, classes.dataValue)}>
              <span>{value}</span>
            </dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  )
}
