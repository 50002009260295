import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'
import { HeroImage } from './HeroImage'

interface ProductPageMediaProps extends React.HTMLAttributes<HTMLDivElement> {
  source: string
  title?: string
  type?: string
}

const useStyles = createUseStyles((theme: Theme) => ({
  productPageMediaProps: {
    width: '100%',
    marginBottom: theme.spacing.XXXL + theme.spacing.M,
  },
  title: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey700,
    width: '100%',
    padding: theme.spacing.L,
    paddingTop: 0,
  },
  image: {
    width: '100%',
  },
}))

export const ProductPageMedia: React.FC<ProductPageMediaProps> = (props) => {
  const { source, title, className, type, ...restProps } = props
  const classes = useStyles()
  return (
    <section
      className={classNames(classes.productPageMediaProps, className)}
      {...restProps}
    >
      {title && <p className={classes.title}>{title}</p>}
      <HeroImage src={source} alt={title || ''} type={type} />
    </section>
  )
}
