import React, { useState, useEffect, useCallback, useRef } from 'react'
import FocusTrap from 'focus-trap-react'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../theme'
import { FeatherIcon } from './icons/FeatherIcon'
import { RISEmbed } from './RISEmbed'

interface Props {
  show: boolean
  active: boolean
  onExit: () => void
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  modalContainerOuter: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(33, 34, 34, 0.5)',
    zIndex: 999,
  },
  modalContainerInner: {
    ...theme.traits.flexCentering,
    height: '100vh',
  },
  modal: {
    width: '100%',
    maxWidth: 800,
    maxHeight: 'calc(100vh - 100px)',
    margin: theme.spacing.S,
    backgroundColor: theme.colors.secondaryGrey100,
    borderRadius: 10,
    overflowY: 'auto',
    boxShadow:
      '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing.XL,
    paddingRight: theme.spacing.XL,
    paddingLeft: theme.spacing.XL,
  },
  closeIcon: {
    '&:hover': { cursor: 'pointer' },
    padding: theme.spacing.XS,
    border: 'none',
    background: 'none',
    '&:focus': { border: '2px solid #4c8bf5', borderRadius: '3px' },
  },
  heading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryBlack,
    marginLeft: 50,
    marginBottom: theme.spacing.S,
    marginTop: '-35px',
  },
}))

export const RISModal: React.FC<Props> = ({ active, show, onExit }) => {
  const classes = useStyles()
  const [focusTrapped, setFocusTrapped] = useState(false)
  const buttonCloseRef = useRef<HTMLButtonElement>(null)
  const escapePressed = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onExit()
        setFocusTrapped(false)
      }
    },
    [onExit],
  )

  useEffect(() => {
    document.addEventListener('keydown', escapePressed)
    if (show) {
      buttonCloseRef.current?.focus()
      setFocusTrapped(active)
    }
    return () => {
      document.removeEventListener('keydown', escapePressed)
    }
  }, [show, active, escapePressed])

  if (!show) {
    return null
  }

  const handleClose = () => {
    onExit()
    setFocusTrapped(false)
  }

  return (
    <FocusTrap active={focusTrapped}>
      <div
        className={classes.modalContainerOuter}
        aria-modal="true"
        role="dialog"
        aria-label="Shop Local"
        onClick={handleClose}
      >
        <div className={classes.modalContainerInner}>
          <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
            <div className={classes.toolbar}>
              <button
                ref={buttonCloseRef}
                className={classes.closeIcon}
                onClick={handleClose}
                onKeyPress={handleClose}
                aria-label="close shop local modal"
              >
                <FeatherIcon
                  name="x"
                  size={24}
                  color={theme.colors.primaryBlack}
                />
              </button>
            </div>
            <h1 className={classes.heading}>SHOP LOCAL</h1>
            <RISEmbed />
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}
