import * as React from 'react'

export const MountainBikesIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      aria-hidden="true"
      width={31}
      height={22}
      viewBox="0 0 31 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.25.75a1.253 1.253 0 011.103.662l.002.003 9.998 18.747A1.25 1.25 0 0129.25 22H1.75a1.25 1.25 0 01-1.093-1.857l6.25-11.25a1.25 1.25 0 012.165-.036l2.702 4.504 6.371-11.946.002-.002A1.252 1.252 0 0119.25.75zm-3.75 15c-.656 0-1.33-.132-1.895-.51l-2.272 4.26h15.834l-2.288-4.29c-.564.387-1.23.54-1.879.54-.608 0-1.135-.248-1.502-.46-.35-.2-.7-.457-.99-.668l-.068-.05a7.93 7.93 0 00-.83-.556c-.244-.133-.349-.141-.36-.141-.005 0-.097.005-.325.132-.227.129-.472.31-.8.556l-.028.02c-.293.22-.662.497-1.047.713-.397.224-.93.454-1.55.454zm-.606-2.85c.01.064.022.113.034.15.024.072.046.093.05.097.003.004.025.026.097.05.078.026.213.053.425.053.005 0 .097-.005.325-.132.227-.129.472-.31.8-.556l.028-.02c.293-.22.662-.497 1.047-.713.397-.224.93-.454 1.55-.454.614 0 1.152.226 1.554.445.408.221.798.505 1.11.733l.029.02c.327.24.574.42.8.55.167.096.25.12.27.126h.003c.317-.002.441-.09.479-.125.018-.018.074-.076.106-.233L19.25 4.67l-4.356 8.23zm-11.02 6.6l4.168-7.501 2.283 3.806L8.477 19.5H3.874z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
