import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'

interface NameDescriptionProps {
  title: string
  body: string
  image: string
  imageAlt: string
}

const useStyles = createUseStyles((theme: Theme) => ({
  nameDescription: {
    backgroundColor: theme.colors.secondaryGrey100,
    display: 'flex',
    padding: `${theme.spacing.XXXL}px ${theme.spacing.XXL}px`,
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      paddingRight: 0,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: theme.spacing.XXXL,
  },
  title: {
    ...theme.typography.bebas(31),
    marginBottom: theme.spacing.L,
  },
  body: {
    ...theme.typography.urw(21),
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.urw(16),
    },
  },
  image: {
    alignSelf: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: theme.spacing.XL,
    },
  },
}))

export const NameDescription: React.FC<NameDescriptionProps> = (props) => {
  const { title, body, image, imageAlt } = props
  const classes = useStyles()
  return (
    <div className={classes.nameDescription}>
      <div className={classes.textContainer}>
        <h2 className={classes.title}>{title}</h2>
        <p className={classes.body}>{body}</p>
      </div>
      <img src={image} alt={imageAlt} className={classes.image} />
    </div>
  )
}
