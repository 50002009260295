import React, { createContext, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Theme, theme } from '../../theme'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import * as Routes from '../../routes'
import { useSliderRef } from '../../components/carousel/carousel.hooks'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import { Button } from '../../components/Button'
import { useQuery } from 'react-query'
import { fetchApiData, getHeaderFlexAlignment } from '../../utils'
import { AboutSalsaPageQuery_entry_aboutSalsa_aboutSalsa_Entry as AboutSalsaPAgeEntry } from '../../api-types/AboutSalsaPageQuery'
import { Entry } from '../../types'
import { HeroImage } from '../../components/HeroImage'
import { LayoutContainer } from '../../components/LayoutContainer'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  heroImage: {
    ...theme.traits.flexCentering,
    height: 640,
    backgroundSize: 'cover',
  },
  heroText: {
    ...theme.typography.bebas(120),
    letterSpacing: '2px',
    color: theme.colors.primaryWhite,
    textShadow: `0px 10px 30px ${theme.colors.primaryShadow}`,
    paddingRight: `${theme.spacing.XL}px`,
    paddingLeft: `${theme.spacing.XL}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  missionStatementContainer: {
    ...theme.traits.gridLayout,
    marginTop: 60,
    marginBottom: 96,
    '& > *': {
      ...theme.traits.gridColumn({
        lgStart: 4,
        lgCols: 6,
        mdStart: 2,
        mdCols: 6,
      }),
      [theme.breakpoints.down('sm')]: {
        gridColumn: '1 / -1',
      },
    },
  },
  missionStatementTitle: {
    ...theme.typography.bebas(48),
    textAlign: 'center',
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.L,
  },
  missionStatementText: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
  },
  imagesContainer: {
    display: 'grid',
    rowGap: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      rowGap: theme.spacing.S,
    },
  },
  images2PerRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      gridColumnGap: theme.spacing.S,
    },
  },
  images3PerRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: theme.spacing.L,
    [theme.breakpoints.down('sm')]: {
      gridColumnGap: theme.spacing.S,
    },
  },
  images: { width: '100%' },
  bottomImageLabelAndNav: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    color: theme.colors.primaryWhite,
    marginRight: theme.spacing.XXXL,
  },
  bottomImageLabel: {
    ...theme.typography.bebas(120),
    letterSpacing: '2px',
    textShadow: `0px 10px 30px ${theme.colors.primaryShadow}`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bottomImageLink: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  darkButton: {
    backgroundColor: theme.colors.secondaryGrey900,
  },
  sponsorContainer: {},
  sponsorText: {
    ...theme.typography.bebas(31),
    marginTop: 128,
    marginBottom: 48,
    maringRight: 24,
    color: theme.colors.secondaryGrey700,
    [theme.breakpoints.down('sm')]: {
      marginTop: 64,
    },
  },
  inclusionContainer: {
    backgroundColor: theme.colors.secondaryGrey100,
    paddingTop: 96,
    paddingBottom: 96,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 48,
      paddingBottom: 46,
    },
  },
  inclustionTextContainer: {
    display: 'grid',
    paddingTop: theme.spacing.L,
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: theme.spacing.XXL + theme.spacing.M,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '& ul': {
      marginLeft: theme.spacing.M,
    },
  },
  inclusionHeader: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey900,
  },
  inclusionText: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey900,
  },
  listItem: {
    listStyle: 'disc',
    ...theme.typography.urw(18),
    marginBottom: theme.spacing.XL,
  },
  sliderContainer: (arrayLength: number) => ({
    marginBottom: 128,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.XXL,
    },
    '& > button': {
      display: arrayLength > 3 ? 'block' : 'none',
      [`@media screen and (max-width: 376px)`]: {
        display: arrayLength > 1 ? 'block' : 'none',
      },
    },
  }),
  sliderButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: 'calc(50% - 20px)',
    padding: 0,
    borderStyle: 'none',
    background: 'none',
  },
  sliderLogo: {
    ...theme.traits.flexCentering,
  },
  logos: {
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 75,
    },
  },
  inclusionLink: {
    textDecoration: 'none',
    color: theme.colors.primaryRed,
    marginLeft: theme.spacing.M,
  },
  inclusionLinkContainer: {
    marginTop: theme.spacing.L,
  },
}))

const AboutSalsaQueryContext = createContext<AboutSalsaPAgeEntry | null>(null)
export const useSalsaQueryData = (): AboutSalsaPAgeEntry | null =>
  useContext(AboutSalsaQueryContext)

export const AboutSalsa: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('salsa/aboutsalsa', () =>
    fetchApiData<Entry<AboutSalsaPAgeEntry>>('aboutsalsapage'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const hero1 = data?.entry?.hero && data?.entry?.hero[0]
  const hero1Image = hero1?.image && hero1?.image[0]
  const hero2 = data?.entry?.hero && data?.entry?.hero[1]
  const hero2Image = hero2?.image && hero2?.image[0]
  const missionStatement =
    data?.entry?.missionStatement && data?.entry?.missionStatement[0]
  const sectionHeading1 =
    data?.entry?.sectionHeadings && data?.entry?.sectionHeadings[0]
  const sectionHeading2 =
    data?.entry?.sectionHeadings && data?.entry?.sectionHeadings[1]
  const collageImages =
    data?.entry?.aboutSalsaPhotoCollage?.map(
      (block) => block?.imageAsset && block?.imageAsset[0],
    ) || []

  return (
    <AboutSalsaQueryContext.Provider value={data?.entry || null}>
      <HeroImage
        src={hero1Image?.url || ''}
        alt={hero1Image?.alttext || ''}
        type={hero1Image?.mimeType || ''}
        justifyContent={getHeaderFlexAlignment(hero1?.headingAlignment)}
      >
        <h1 className={classes.heroText}>{hero1?.heading}</h1>
      </HeroImage>
      <LayoutContainer>
        <Breadcrumbs />
        <div className={classes.missionStatementContainer}>
          <h2 className={classes.missionStatementTitle}>
            {missionStatement?.heading}
          </h2>
          <div
            className={classes.missionStatementText}
            dangerouslySetInnerHTML={{
              __html: missionStatement?.body || '',
            }}
          ></div>
        </div>
        <div className={classes.imagesContainer}>
          <div className={classes.images2PerRow}>
            <img
              className={classes.images}
              src={collageImages[0]?.url || ''}
              alt={collageImages[0]?.alttext || ''}
            />
            <img
              className={classes.images}
              src={collageImages[1]?.url || ''}
              alt={collageImages[1]?.alttext || ''}
            />
          </div>
          <div className={classes.images3PerRow}>
            <img
              className={classes.images}
              src={collageImages[2]?.url || ''}
              alt={collageImages[2]?.alttext || ''}
            />
            <img
              className={classes.images}
              src={collageImages[3]?.url || ''}
              alt={collageImages[3]?.alttext || ''}
            />
            <img
              className={classes.images}
              src={collageImages[4]?.url || ''}
              alt={collageImages[4]?.alttext || ''}
            />
          </div>
        </div>
        <div>
          <h3 className={classes.sponsorText}>
            {sectionHeading1?.headingText}
          </h3>
          <SalsaSlider />
        </div>
      </LayoutContainer>
      <LayoutContainer containerClass={classes.inclusionContainer}>
        <div className={classes.inclusionHeader}>
          {sectionHeading2?.headingText}
        </div>
        <div className={classes.inclustionTextContainer}>
          <div className={classes.inclusionText}>
            <div
              className={classes.inclusionText}
              dangerouslySetInnerHTML={{
                __html: data?.entry?.aboutSalsaCommitmentBody || '',
              }}
            ></div>
            <div className={classes.inclusionLinkContainer}>
              To learn more, check out:
              <span>
                <Link
                  to={{
                    pathname:
                      data?.entry?.aboutSalsaLinkToCommitmentToInclusion || '#',
                  }}
                  target={'_blank'}
                  className={classes.inclusionLink}
                >
                  QBP's Commitment to Inclusion
                </Link>
              </span>
            </div>
          </div>
          <ul>
            {data?.entry?.aboutSalsaCommitmentListItem?.map((item) => (
              <li key={item?.uid} className={classes.listItem}>
                <div
                  className={classes.inclusionText}
                  dangerouslySetInnerHTML={{
                    __html: item?.bulletPoint || '',
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </LayoutContainer>
      <HeroImage
        src={hero2Image?.url || ''}
        alt={hero2Image?.alttext || ''}
        type={hero2Image?.mimeType || ''}
        justifyContent={getHeaderFlexAlignment(hero2?.headingAlignment)}
      >
        <div className={classes.bottomImageLabelAndNav}>
          <div className={classes.bottomImageLabel}>{hero2?.heading}</div>
          <Link
            to={hero2?.linkUrl || Routes.staff}
            className={classes.bottomImageLink}
          >
            <Button className={classes.darkButton}>{hero2?.linkLabel}</Button>
          </Link>
        </div>
      </HeroImage>
    </AboutSalsaQueryContext.Provider>
  )
}

const SalsaSlider: React.FC = () => {
  const [sliderRef, handleForward, handleBackward] = useSliderRef()

  const entry = useSalsaQueryData()
  const classes = useStyles(entry?.aboutSalsaBrands?.length ?? 0)

  const settings = {
    infinite: (entry?.aboutSalsaBrands?.length ?? 0) > 3,
    centerMode: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: (entry?.aboutSalsaBrands?.length ?? 0) > 1,
        },
      },
    ],
  }

  return (
    <div className={classes.sliderContainer}>
      <Slider ref={sliderRef} className={'center'} {...settings}>
        {entry?.aboutSalsaBrands?.map((sponsor) => (
          <div className={classes.sliderLogo} key={sponsor?.uid}>
            <Link
              to={{ pathname: sponsor?.linkTo || '#' }}
              target={sponsor?.linkTo?.includes('http') ? '_blank' : '_self'}
            >
              <img
                className={classes.logos}
                src={(sponsor?.logoAsset && sponsor?.logoAsset[0]?.url) || ''}
                alt={
                  (sponsor?.logoAsset && sponsor?.logoAsset[0]?.alttext) || ''
                }
              />
            </Link>
          </div>
        ))}
      </Slider>
      <button
        className={classes.sliderButton}
        style={{ left: 0 }}
        onClick={handleBackward}
      >
        <FeatherIcon
          name="chevron-left"
          color={theme.colors.secondaryGrey500}
          size={40}
        />
      </button>
      <button
        className={classes.sliderButton}
        style={{ right: 0 }}
        onClick={handleForward}
      >
        <FeatherIcon
          name="chevron-right"
          color={theme.colors.secondaryGrey500}
          size={40}
        />
      </button>
    </div>
  )
}
