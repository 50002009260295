import React from 'react'

export const Articles: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={31}
      height={30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.175 10.413l-6.8-7.5a1.25 1.25 0 00-.925-.413H8.7a3.162 3.162 0 00-3.2 3.125v18.75A3.162 3.162 0 008.7 27.5h13.6a3.162 3.162 0 003.2-3.124V11.25c0-.31-.117-.608-.325-.837zm-2.614.837h-4.925a.988.988 0 01-.888-1.062V5h.138l5.675 6.25zM22.3 25H8.7a.663.663 0 01-.7-.623V5.625A.663.663 0 018.698 5h5.552v5.188a3.488 3.488 0 003.387 3.562H23v10.625a.662.662 0 01-.698.625H22.3z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
