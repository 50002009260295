import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarketoForm } from '../../MarketoForm'

const useStyles = createUseStyles((theme: Theme) => ({
  gridContainer: {
    ...theme.traits.gridLayout,
    marginTop: 24,
    marginBottom: 132,
  },
  topSection: {
    ...theme.traits.gridColumn({
      lgCols: 6,
      lgStart: 4,
      mdCols: 6,
      mdStart: 2,
      smCols: 4,
      smStart: 1,
    }),
  },
  topSectionTitleFont: {
    ...theme.typography.bebas(48),
    color: theme.colors.secondaryGrey900,
  },
  topSectionSubTitleFont: {
    ...theme.typography.urw(26),
    color: theme.colors.secondaryGrey700,
  },
  topSectionParagraphFont: {
    ...theme.typography.urw(18),
    color: theme.colors.secondaryGrey700,
    marginBottom: 24,
  },
  topSectionFormFont: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey900,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  requiredKey: {
    margin: '10px 0px',
    textAlign: 'right',
    fontSize: 12,
    '& > span': {
      color: theme.colors.primaryRed,
      display: 'inline-block',
      marginRight: 3,
      fontSize: 14,
    },
  },
}))

export const DealerSignup: React.FC = () => {
  const classes = useStyles()
  const handleFormReady = useCallback(() => {
    document
      .getElementById('FirstName')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('LastName')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('Email')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('Company')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('accountCountry')
      ?.setAttribute('aria-autocomplete', 'list')
    document
      .getElementById('accountAddress')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('accountCity')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('accountState')
      ?.setAttribute('aria-autocomplete', 'list')
    document
      .getElementById('accountPostalCode')
      ?.setAttribute('aria-autocomplete', 'inline')
    document
      .getElementById('MainPhone')
      ?.setAttribute('aria-autocomplete', 'inline')
  }, [])
  return (
    <LayoutContainer>
      <Breadcrumbs />
      <div className={classes.gridContainer}>
        <div className={classes.topSection}>
          <h1 className={classes.topSectionTitleFont}>BECOME A SALSA DEALER</h1>
          <p className={classes.topSectionSubTitleFont}>
            Add a little adventure to your sales floor
          </p>
          <p className={classes.topSectionParagraphFont}>
            Salsa Cycles products deliver adventure to new and seasoned riders
            alike. Want to grow your business by helping riders find their
            Adventure by Bike? Apply today to be an authorized Salsa retailer.
          </p>
          <div className={classes.requiredKey}>
            <span>*</span>
            indicates a required field
          </div>
          <div>
            <MarketoForm formId="1190" onReady={handleFormReady} />
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}
