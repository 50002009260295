import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../../theme'
import { HomePageContext } from './Homepage'
import { LayoutContainer } from '../../components/LayoutContainer'

const useStyles = createUseStyles((theme: Theme) => ({
  mainContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 540,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      height: 309,
    },
  },
  mainContainerContent: {
    ...theme.traits.flexCentering,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  sectionTitle: {
    ...theme.typography.bebas(120),
    letterSpacing: '2px',
    color: theme.colors.primaryWhite,
    textShadow: `0px 10px 30px ${theme.colors.secondaryGrey900}`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    ...theme.typography.bebas(26),
    ...theme.traits.flexCentering,
    background: theme.colors.primaryRed,
    color: theme.colors.primaryWhite,
    borderRadius: 2,
    letterSpacing: '0.8px',
    padding: '12px 24px',
  },
  linkContainer: {
    ...theme.traits.flexCentering,
    paddingTop: 12,
  },
  textContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileBottom: {
    ...theme.traits.flexCentering,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileLink: {
    ...theme.typography.bebasButton(26),
    ...theme.traits.flexCentering,
    background: theme.colors.primaryRed,
    color: theme.colors.primaryWhite,
    borderRadius: 2,
    letterSpacing: '0.8px',
    padding: `12px 24px`,
    marginTop: theme.spacing.L,
    marginBottom: theme.spacing.L,
  },
  mobileSectionTitleContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  sectionTitleMobile: {
    ...theme.typography.bebas(37),
    background: theme.colors.primaryWhite,
    marginLeft: theme.spacing.M,
    color: theme.colors.secondaryGrey700,
    marginBottom: theme.spacing.S,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export const HomeBottomSection: React.FC = () => {
  const classes = useStyles()
  const data = useContext(HomePageContext)

  const cta =
    data?.callToActionInternalLink && data?.callToActionInternalLink[0]

  const bottomImage = data?.homePageBottomImage && data?.homePageBottomImage[0]

  return (
    <>
      <div className={classes.mobileSectionTitleContainer}>
        <h2 className={classes.sectionTitleMobile}>{cta?.label || ''}</h2>
      </div>
      <LayoutContainer
        containerClass={classes.mainContainer}
        contentClass={classes.mainContainerContent}
        containerStyle={{
          backgroundImage: `url(${bottomImage && bottomImage.url}`,
        }}
      >
        <div className={classes.textContainer}>
          <h2 className={classes.sectionTitle}>{cta?.label || ''}</h2>
          <div className={classes.linkContainer}>
            <Link to={cta?.linkTo || '#'} className={classes.link}>
              {cta?.linkLabel || ''}
            </Link>
          </div>
        </div>
      </LayoutContainer>
      <div className={classes.mobileBottom}>
        <Link to={cta?.linkTo || '#'} className={classes.mobileLink}>
          View All of Salsa's Gear
        </Link>
      </div>
    </>
  )
}
