import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react'
import { createUseStyles } from 'react-jss'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Theme } from '../../theme'
import { SalsaContentSection } from './SalsaContentSection'
import * as Routes from '../../routes'
import { Button } from '../../components/Button'
import { fetchApiData, getHeaderFlexAlignment } from '../../utils'
import { SalsaLandingPageQuery_entry_salsaLanding_salsaLanding_Entry as SalsaLandingPageEntry } from '../../api-types/SalsaLandingPageQuery'
import { Entry } from '../../types'
import { HeroImage } from '../../components/HeroImage'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  pageCtaContainer: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    background: theme.colors.primaryWhite,
  },
  pageCtaTitle: {
    ...theme.typography.bebas(48),
    marginTop: 120,
    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
      textAlign: 'center',
    },
  },
  pageCtaText: {
    ...theme.typography.urw(21),
    color: theme.colors.secondaryGrey700,
    marginTop: theme.spacing.M,
    textAlign: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      fontSize: 14,
      lineHeight: '2',
    },
  },
  pageContainer: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
  },
  ctaLink: {
    marginTop: 40,
    marginBottom: 120,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 60,
    },
  },
  darkButton: {
    backgroundColor: theme.colors.secondaryGrey900,
    ...theme.typography.bebasButton(26),
    [theme.breakpoints.down('sm')]: {
      padding: '12px 24px',
    },
  },
  sponsoredTeam: {
    marginBottom: 56,
  },
  sponsoredTeamTitle: {
    ...theme.typography.bebas(120),
    textAlign: 'center',
    color: theme.colors.primaryWhite,
    textShadow: `0px 10px 30px ${theme.colors.primaryShadow}`,
    paddingRight: theme.spacing.L,
    paddingLeft: theme.spacing.L,
  },
  sponsoredTeamLink: {
    marginTop: theme.spacing.M,
    paddingRight: theme.spacing.L,
    paddingLeft: theme.spacing.L,
    '& button': {
      ...theme.typography.bebasButton(26),
      [theme.breakpoints.down('sm')]: {
        padding: '12px 24px',
      },
    },
  },
  sponsoredTeamLinkContainer: {
    ...theme.traits.flexCentering,
    flexDirection: 'column',
    width: 440,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bottomSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundSize: 'cover',
    height: 640,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  technologyLink: {
    marginTop: theme.spacing.M,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.XXL,
    },
  },
  bottomTitle: {
    ...theme.typography.bebas(120),
    color: theme.colors.primaryWhite,
    textShadow: `0px 10px 30px ${theme.colors.primaryShadow}`,
    letterSpacing: '2px',
  },
  mobileHeadingContainer: {
    backgroundColor: theme.colors.secondaryGrey900,
    padding: theme.spacing.M,
  },
  mobileHeading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryWhite,
    textAlign: 'center',
  },
  mobileLinkContainer: {
    padding: theme.spacing.L,
    display: 'flex',
    justifyContent: 'center',
  },
  mobileTextContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

const LandingPageContext = createContext<SalsaLandingPageEntry | undefined>(
  undefined,
)
export const useSalsaLandingPageEntry = (): SalsaLandingPageEntry | undefined =>
  useContext(LandingPageContext)

export const SalsaLandingPage: React.FC = () => {
  const classes = useStyles()
  const videoRef = useRef<HTMLVideoElement>(null)

  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (isPlaying) {
      videoRef.current?.play()
    }
  }, [isPlaying, setIsPlaying])

  const { data, isLoading } = useQuery('salsa/landing', () =>
    fetchApiData<Entry<SalsaLandingPageEntry>>('salsalandingpage'),
  )

  if (isLoading) {
    return <LoadingIndicator />
  }

  const media = data?.entry?.media && data?.entry?.media[0]
  const missionStatement =
    data?.entry?.missionStatement && data?.entry?.missionStatement[0]
  const hero1 = data?.entry?.hero && data?.entry?.hero[0]
  const hero1Image = hero1?.image && hero1?.image[0]
  const hero2 = data?.entry?.hero && data?.entry?.hero[1]
  const hero2Image = hero2?.image && hero2?.image[0]

  return (
    <LandingPageContext.Provider value={data?.entry}>
      <div className={classes.pageContainer}>
        <HeroImage
          src={media?.url || ''}
          alt={media?.alttext || ''}
          type={media?.mimeType || 'video'}
        />
        <h1 className={classes.srOnly}>Who is Salsa?</h1>
        <div className={classes.pageCtaContainer}>
          <h2 className={classes.pageCtaTitle}>{missionStatement?.heading}</h2>
          <div
            className={classes.pageCtaText}
            dangerouslySetInnerHTML={{
              __html: missionStatement?.body || '',
            }}
          ></div>
          <Link
            className={classes.ctaLink}
            to={missionStatement?.linkUrl ?? Routes.about}
          >
            <Button className={classes.darkButton}>
              {missionStatement?.linkLabel}
            </Button>
          </Link>
        </div>
        <HeroImage
          src={hero1Image?.url || ''}
          alt={hero1Image?.alttext || ''}
          justifyContent={getHeaderFlexAlignment(hero1?.headingAlignment)}
          type={hero1Image?.mimeType || ''}
          className={classes.sponsoredTeam}
        >
          <div className={classes.sponsoredTeamLinkContainer}>
            <h2 className={classes.sponsoredTeamTitle}>{hero1?.heading}</h2>
            <Link
              className={classes.sponsoredTeamLink}
              to={hero1?.linkUrl ?? Routes.team}
            >
              <Button>{hero1?.linkLabel}</Button>
            </Link>
          </div>
        </HeroImage>
        <div className={classes.mobileTextContainer}>
          <div className={classes.mobileHeadingContainer}>
            <h2 className={classes.mobileHeading}>{hero1?.heading}</h2>
          </div>
          <div className={classes.mobileLinkContainer}>
            <Link
              className={classes.sponsoredTeamLink}
              to={hero1?.linkUrl ?? Routes.team}
            >
              <Button>{hero1?.linkLabel}</Button>
            </Link>
          </div>
        </div>
        <SalsaContentSection />
        <div className={classes.bottomSection}>
          <HeroImage
            src={hero2Image?.url || ''}
            alt={hero2Image?.alttext || ''}
            type={hero2Image?.mimeType || ''}
            justifyContent={getHeaderFlexAlignment(hero2?.headingAlignment)}
          >
            <div className={classes.sponsoredTeamLinkContainer}>
              <h2 className={classes.bottomTitle}>{hero2?.heading}</h2>
              <Link
                className={classes.technologyLink}
                to={hero2?.linkUrl ?? Routes.features}
              >
                <Button className={classes.darkButton}>
                  {hero2?.linkLabel}
                </Button>
              </Link>
            </div>
          </HeroImage>
        </div>
        <div className={classes.mobileTextContainer}>
          <div className={classes.mobileHeadingContainer}>
            <h2 className={classes.mobileHeading}>{hero2?.heading}</h2>
          </div>
          <div className={classes.mobileLinkContainer}>
            <Link
              className={classes.technologyLink}
              to={hero2?.linkUrl ?? Routes.features}
            >
              <Button className={classes.darkButton}>{hero2?.linkLabel}</Button>
            </Link>
          </div>
        </div>
      </div>
    </LandingPageContext.Provider>
  )
}
