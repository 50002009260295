import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ContactIcon } from '../../categoryIcons/ContactIcon'
import { EventsIcon } from '../../categoryIcons/EventsIcon'
import { FeaturesAndTechIcon } from '../../categoryIcons/FeaturesAndTechIcon'
import { GlobeIcon } from '../../categoryIcons/GlobeIcon'
import { SponsoredTeamIcon } from '../../categoryIcons/SponsoredTeamIcon'
import { CampaignIcon } from '../../categoryIcons/CampaignIcon'
import { CategoryNav } from '../../components/CategoryNav'
import * as Routes from '../../routes'
import { FeaturesPage } from '../Features/FeaturesPage'
import { AboutSalsa } from './AboutSalsa'
import { SalsaContact } from './SalsaContact'
import { SalsaEvents } from './SalsaEvents'
import { SalsaLandingPage } from './SalsaLandingPage'
import { SalsaSponsoredTeam } from './SalsaSponsoredTeam'
import { SalsaPressPage } from './SalsaPressPage'
import { CampaignLanding } from './CampaignLanding'
import { SponsoredRider } from './SponsoredRider'

export const SalsaPageRouter: React.FC = () => {
  return (
    <>
      <CategoryNav routes={salsaNavRoutes} />
      <Switch>
        <Route path={Routes.campaign} component={CampaignLanding} />
        <Route path={Routes.about} component={AboutSalsa} />
        <Route path={Routes.rider} component={SponsoredRider} />
        <Route path={Routes.team} component={SalsaSponsoredTeam} />
        <Route path={Routes.features} component={FeaturesPage} />
        <Route path={Routes.events} component={SalsaEvents} />
        <Route path={Routes.contact} component={SalsaContact} />
        <Route path={Routes.media}>media</Route>
        <Route path={Routes.press} component={SalsaPressPage} />
        <Route path={Routes.salsa} component={SalsaLandingPage} />
      </Switch>
    </>
  )
}

const salsaNavRoutes = [
  {
    route: Routes.about,
    label: 'About Salsa',
    icon: <GlobeIcon />,
  },
  {
    route: Routes.campaign,
    label: 'Adventure by Bike',
    icon: <CampaignIcon />,
  },
  {
    route: Routes.features,
    label: 'Features & Technology',
    icon: <FeaturesAndTechIcon />,
  },
  {
    route: Routes.events,
    label: 'Events',
    icon: <EventsIcon />,
  },
  {
    route: Routes.team,
    label: 'Sponsored Riders',
    icon: <SponsoredTeamIcon />,
  },
  {
    route: Routes.contact,
    label: 'Contact',
    icon: <ContactIcon />,
  },
]
