import { ReactNode } from 'react'
import * as routes from './routes'
import { OptionValue } from './hooks'
import { JournalLandingPageQuery_entry_journalLandingPage_journalLandingPage_Entry_journalCategoryField as JournalCategoryField } from './api-types/JournalLandingPageQuery'
import {
  Filter,
  HierarchyKey,
  routeFilters,
  HierarchyMap,
} from './product-types'
import { theme } from './theme'

export function classNames(
  ...args: Array<string | boolean | undefined>
): string {
  return args.filter((i) => typeof i === 'string').join(' ')
}

export function jsxJoin(
  string: string,
  splitterString: string,
  component: ReactNode,
): JSX.Element[] {
  const content: Array<string> = string.split(splitterString)
  return content.map((item: string, index: number) => (
    <>
      {item}
      {index + 1 !== content.length ? component : ''}
    </>
  ))
}

export function fetchApiData<
  Res,
  Params extends Record<string, OptionValue> = Record<string, never>
>(
  path: string,
  params?: Partial<{
    query: Params
    rawData: boolean
  }>,
): Promise<Res> {
  const query = new URLSearchParams()
  if (params?.query !== undefined) {
    Object.entries(params.query).forEach(([key, value]) => {
      if (value != null) {
        if (Array.isArray(value)) {
          value.forEach((val) => query.append(key, encodeURIComponent(val)))
        } else {
          query.set(key, encodeURIComponent(value))
        }
      }
    })
  }

  const baseUrl = process.env.REACT_APP_API_URL
  const previewToken = new URLSearchParams(window.location.search).get('token')
  return fetch(`${baseUrl}/${path}?${query.toString()}`, {
    headers: previewToken
      ? {
          'X-Craft-Token': previewToken,
        }
      : undefined,
  })
    .then((res) => {
      if (!res.ok) {
        return Promise.reject(res.status)
      }
      return res.json()
    })
    .then((res) => {
      if (res.data !== undefined && !params?.rawData) {
        return res.data
      }

      return res
    })
}

export const getHeaderFlexAlignment = (
  alignment: string | null | undefined,
): 'flex-start' | 'flex-end' | 'center' => {
  switch (alignment) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    default:
      return 'center'
  }
}

export const getRouteForProduct = (category: string, slug: string): string => {
  return category.startsWith('salsa.bikes')
    ? `${routes.bikes}/${slug}`
    : `${routes.gear}/${slug}`
}

export const getRouteForPlatform = (
  hierarchyString: string,
  name: string | undefined,
): string => {
  const hierarchy = HierarchyMap[hierarchyString as HierarchyKey]
  const route = routeFilters[hierarchy]?.route
  if (!route || !name) return ''
  return `${route}/${name}`
}

export const getAssetUrl = (filename: string): string => {
  if (!filename) {
    return ''
  }

  if (!process.env.REACT_APP_ASSETS_URL) {
    console.error('Must set env variable REACT_APP_ASSETS_URL')
  }
  if (filename.startsWith('http://') || filename.startsWith('https://')) {
    return filename
  }
  return `${process.env.REACT_APP_ASSETS_URL}/${filename}`
}

// convert journalCategoryFields (aka CMS journal filters) to common Filter
export const journalCategoriesToFilters = (
  categories: (JournalCategoryField | null)[] | null | undefined,
): Filter[] => {
  return (
    categories?.map((category) => ({
      name: category?.title ?? '',
      displayName: category?.title ?? '',
      id: category?.id ?? '',
      options:
        category?.children?.map((child) => ({
          name: child?.title ?? '',
          id: child?.id ?? '',
          numEntries: 0,
        })) || [],
    })) || []
  )
}

export const getMaxWidth = (contentWidth?: string | null): string | number => {
  switch (contentWidth) {
    case 'fullWidth':
      return 'unset'
    case 'narrow':
      return theme.measurements.contentNarrowWidth
    case 'contained':
    default:
      return theme.measurements.contentMaxWidth
  }
}

export const resizeVimeoVideos = (rootElement: Element): void => {
  const iframes = rootElement.querySelectorAll<'iframe'>('iframe')
  iframes.forEach((iframe) => {
    if (!iframe.parentElement?.style) {
      return
    }

    iframe.style.position = 'absolute'
    iframe.parentElement.style.position = 'relative'
    iframe.parentElement.style.height = '0'
    iframe.parentElement.style.paddingBottom = '56%'
  })
}

export const formatDateString = (dateStr: string): string => {
  if (!dateStr) return ''
  const date = new Date(dateStr)
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
  return `${month} ${day}, ${year}`
}
