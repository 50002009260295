import React from 'react'
import { ProductCategorySearchAndFilterPage } from '../../components/ProductCategorySearchAndFilter'
import { Hierarchy } from '../../product-types'

export const RoadBikes: React.FC = () => {
  return (
    <ProductCategorySearchAndFilterPage
      hierarchy={Hierarchy.GRAVEL_ROAD_BIKES}
    />
  )
}
