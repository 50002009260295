import React, { useState, useEffect, useCallback, useRef } from 'react'
import FocusTrap from 'focus-trap-react'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../theme'
import { Link } from 'react-router-dom'
import { FeatherIcon } from './icons/FeatherIcon'
import { useQuery } from 'react-query'
import { fetchApiData } from '../utils'
import { ApiFilterParams, ApiResponse } from '../hooks'
import { Hierarchy, ProductResponse } from '../product-types'
import * as routes from '../routes'
import sanitizeValue from '../sanitizeValue'

interface Props {
  show: boolean
  active: boolean
  onExit: () => void
}

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  searchInstructions: {
    display: 'none',
  },
  resultsCount: {
    display: 'none',
  },
  searchContainerOuter: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(33, 34, 34, 0.5)',
    zIndex: 999,
  },
  searchContainerInner: {
    ...theme.traits.flexCentering,
    height: '100vh',
  },
  search: {
    width: '100%',
    maxWidth: 600,
    maxHeight: 600,
    margin: theme.spacing.S,
    backgroundColor: theme.colors.secondaryGrey100,
    borderRadius: 10,
    overflowY: 'auto',
    boxShadow:
      '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing.XL,
    paddingRight: theme.spacing.XL,
    paddingLeft: theme.spacing.XL,
  },
  closeIcon: {
    '&:hover': { cursor: 'pointer' },
    padding: theme.spacing.XS,
    border: 'none',
    background: 'none',
    '&:focus': { border: '2px solid #4c8bf5', borderRadius: '3px' },
  },
  heading: {
    ...theme.typography.bebas(31),
    color: theme.colors.primaryBlack,
    textAlign: 'center',
    marginBottom: theme.spacing.S,
  },
  subheading: {
    ...theme.typography.urw(16),
    color: '#404146',
    textAlign: 'center',
    marginBottom: theme.spacing.L,
  },
  searchInput: {
    ...theme.typography.urwInput(16),
    marginLeft: theme.spacing.XL,
    marginRight: theme.spacing.XL,
    marginBottom: theme.spacing.XXL,
    padding: `${theme.spacing.S}px ${theme.spacing.M}px`,
    width: `calc(100% - ${theme.spacing.XL * 2}px)`,
  },
  searchResults: {
    backgroundColor: theme.colors.primaryWhite,
    padding: `0 ${theme.spacing.L}px`,
  },
  searchResult: {
    padding: `${theme.spacing.L}px 0`,
    borderBottom: `1px solid ${theme.colors.secondaryGrey300}`,
  },
  searchResultHeading: {
    ...theme.typography.urw(18),
    color: theme.colors.primaryRed,
    '& em': {
      ...theme.typography.urwBold(18),
      fontStyle: 'normal',
      textDecoration: 'underline',
      color: theme.colors.primaryRed,
    },
  },
  searchResultName: {
    ...theme.typography.bebas(16),
    color: theme.colors.secondaryGrey500,
    marginTop: theme.spacing.S,
    marginBottom: theme.spacing.S,
  },
  searchResultDetails: {
    ...theme.typography.urw(16),
    color: theme.colors.primaryBlack,
  },
}))

export const GlobalSearch: React.FC<Props> = ({ active, show, onExit }) => {
  const classes = useStyles()
  const [focusTrapped, setFocusTrapped] = useState(false)
  const [liSelected, setLISelected] = useState(false)
  const buttonCloseRef = useRef<HTMLButtonElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const liRef = useRef<HTMLLIElement>(null)
  const [searchInput, setSearchInput] = useState('')
  const escapePressed = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setSearchInput('')
        onExit()
        setFocusTrapped(false)
      }
    },
    [onExit],
  )
  const sanitizedSearchInput = sanitizeValue(searchInput)

  useEffect(() => {
    document.addEventListener('keydown', escapePressed)
    if (show) {
      buttonCloseRef.current?.focus()
      setFocusTrapped(active)
    }
    return () => {
      document.removeEventListener('keydown', escapePressed)
    }
  }, [show, active, escapePressed])

  const query: ApiFilterParams = {
    hierarchy: [
      Hierarchy.BIKE_PARTS,
      Hierarchy.BIKE_ACCESSORIES,
      Hierarchy.CLOTHING,
      Hierarchy.MOUNTAIN_BIKES,
      Hierarchy.GRAVEL_ROAD_BIKES,
      Hierarchy.FAT_TIRE_BIKES,
      Hierarchy.EBIKES,
    ],
    q: sanitizedSearchInput,
    sortBy: '_score',
    limit: 10,
  }

  const { data } = useQuery(
    ['global-search', sanitizedSearchInput],
    () =>
      fetchApiData<ApiResponse<ProductResponse>, ApiFilterParams>('products', {
        query,
      }),
    { enabled: !!sanitizedSearchInput, keepPreviousData: true },
  )

  if (!show) {
    return null
  }

  const handleClose = () => {
    setSearchInput('')
    onExit()
    setFocusTrapped(false)
  }

  const handleSelected = (e: {
    currentTarget: { getAttribute: (arg0: string) => any }
  }) => {
    const myID = e.currentTarget.getAttribute('id')
    inputRef.current?.setAttribute('aria-activedescendant', myID)
    setLISelected(true)
  }

  const searchHits = data as ProductResponse | null

  return (
    <FocusTrap active={focusTrapped}>
      <div
        className={classes.searchContainerOuter}
        aria-modal="true"
        role="dialog"
        aria-label="Search"
        onClick={handleClose}
      >
        <div className={classes.searchContainerInner}>
          <div
            className={[classes.srOnly, classes.searchInstructions].join(' ')}
            id="searchInstructions"
          >
            Begin typing to search, use Tab key to navigate, click or press
            Enter to select an item.
          </div>
          <div className={classes.search} onClick={(e) => e.stopPropagation()}>
            <div className={classes.toolbar}>
              <button
                ref={buttonCloseRef}
                className={classes.closeIcon}
                onClick={handleClose}
                onKeyPress={handleClose}
                aria-label="close global search modal"
              >
                <FeatherIcon
                  name="x"
                  size={24}
                  color={theme.colors.primaryBlack}
                />
              </button>
            </div>
            <h1 className={classes.heading}>Search</h1>
            <p className={classes.subheading}>
              Please type in a word to search this site.
            </p>
            <label
              htmlFor="searchInput"
              className={classes.srOnly}
              id="searchLabel"
            >
              Search
            </label>
            <input
              id="searchInput"
              ref={inputRef}
              className={classes.searchInput}
              placeholder="Search"
              value={searchInput}
              aria-autocomplete="list"
              aria-haspopup="listbox"
              aria-controls="searchResults"
              aria-describedby="searchInstructions"
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && searchHits?.searchHits?.length && (
              <div
                className={[classes.srOnly, classes.resultsCount].join(' ')}
                aria-live="assertive"
              >
                {searchHits.searchHits.length} results found
              </div>
            )}

            <ul
              className={classes.searchResults}
              role="listbox"
              id="searchResults"
              aria-labelledby="searchLabel"
            >
              {searchInput &&
                searchHits?.searchHits?.map((searchHit) => {
                  const titleHighlight =
                    searchHit?.highlightFields?.name &&
                    searchHit.highlightFields.name[0]
                  const descriptionHighlight =
                    searchHit?.highlightFields?.description &&
                    searchHit.highlightFields.description[0]
                  const titleHTML =
                    titleHighlight ||
                    descriptionHighlight ||
                    searchHit?.content?.name
                  const isBike = searchHit?.content?.hierarchy?.some((h) =>
                    h.code.startsWith('salsa.bikes'),
                  )
                  const url = isBike
                    ? `${routes.bikes}/${searchHit?.content?.fullRecord['URL Title']}`
                    : `${routes.gear}/${searchHit?.content?.fullRecord['URL Title']}`
                  return (
                    <li
                      key={searchHit.content.id}
                      role="option"
                      ref={liRef}
                      id={`result_${searchHit.content.id}`}
                      className={classes.searchResult}
                      onFocus={handleSelected}
                      aria-selected={liSelected}
                    >
                      <Link
                        to={url}
                        className={classes.searchResultHeading}
                        dangerouslySetInnerHTML={{ __html: titleHTML }}
                        onClick={handleClose}
                      />
                      <p className={classes.searchResultName}>
                        {searchHit.content.platform &&
                        searchHit.content.platform !== ' '
                          ? `${searchHit.content.platform}  /  ${searchHit.content.name}`
                          : searchHit.content.name}
                      </p>
                      <p className={classes.searchResultDetails}>
                        {searchHit?.content?.hierarchy
                          ?.map((h) => h.description)
                          .join(' / ')}
                      </p>
                    </li>
                  )
                })}
              {searchInput && searchHits?.searchHits?.length === 0 && (
                <li className={classes.searchResult}>
                  <p className={classes.searchResultName}>No results found</p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}
