import * as React from 'react'

export const GlobeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={25}
      height={26}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 13C25 6.096 19.404.5 12.5.5S0 6.096 0 13s5.596 12.5 12.5 12.5S25 19.904 25 13zm-2.585-1.25h-3.663a16.137 16.137 0 00-2.912-8.175 10 10 0 016.575 8.175zm-13.567 2.5h7.4a14.3 14.3 0 01-3.75 8.262 13.75 13.75 0 01-3.65-8.262zm0-2.5a14.25 14.25 0 013.65-8.25 13.988 13.988 0 013.75 8.25h-7.4zM9.3 3.537a16.475 16.475 0 00-2.962 8.213h-3.75A10 10 0 019.3 3.537zM2.588 14.25h3.75a16.075 16.075 0 002.937 8.2 10 10 0 01-6.687-8.2zm13.188 8.188a16.425 16.425 0 002.975-8.188h3.688a10 10 0 01-6.663 8.188z"
        fill={props.color || '#989898'}
      />
    </svg>
  )
}
