import * as routes from './routes'

// These hierarchy codes are exported from Enterworks and feed through the CPS
export enum Hierarchy {
  BIKES = 'salsa.bikes',
  GRAVEL_ROAD_BIKES = 'salsa.bikes.gravelandroadbikes',
  MOUNTAIN_BIKES = 'salsa.bikes.mountainbikes',
  FAT_TIRE_BIKES = 'salsa.bikes.fattirebikes',
  EBIKES = 'salsa.bikes.ebikes',
  BIKE_ACCESSORIES = 'salsa.gear.bikeaccessories',
  GEAR = 'salsa.gear',
  ARCHIVED_GEAR = 'archived.gear',
  BIKE_PARTS = 'salsa.gear.bikeparts',
  CLOTHING = 'salsa.gear.clothing',

  // These aren't real hierarchies, they're just used to determine
  // default filters for a given page
  ARCHIVED = 'archived',
  ARTICLE = 'articles',
  JOURNAL = 'journal',
  STORY = 'story',
  VIDEO = 'video',
}

export type HierarchyKey =
  | 'salsa.bikes'
  | 'salsa.bikes.gravelandroadbikes'
  | 'salsa.bikes.mountainbikes'
  | 'salsa.bikes.fattirebikes'
  | 'salsa.bikes.ebikes'
  | 'salsa.gear.bikeaccessories'
  | 'salsa.gear'
  | 'archived.gear'
  | 'salsa.gear.bikeparts'
  | 'salsa.gear.clothing'

export const HierarchyMap: Record<HierarchyKey, Hierarchy> = {
  'salsa.bikes': Hierarchy.BIKES,
  'salsa.bikes.gravelandroadbikes': Hierarchy.GRAVEL_ROAD_BIKES,
  'salsa.bikes.mountainbikes': Hierarchy.MOUNTAIN_BIKES,
  'salsa.bikes.fattirebikes': Hierarchy.FAT_TIRE_BIKES,
  'salsa.bikes.ebikes': Hierarchy.EBIKES,
  'salsa.gear.bikeaccessories': Hierarchy.BIKE_ACCESSORIES,
  'salsa.gear': Hierarchy.GEAR,
  'archived.gear': Hierarchy.ARCHIVED_GEAR,
  'salsa.gear.bikeparts': Hierarchy.BIKE_PARTS,
  'salsa.gear.clothing': Hierarchy.CLOTHING,
}

export interface FilterOption {
  name: string
  numEntries: number
  id?: string
}

export interface Filter {
  name: string
  displayName: string
  options: FilterOption[]
}

export const routeFilters = {
  [Hierarchy.BIKES]: {
    // The actual URL thi hierachy is associated with
    route: routes.allBikes,
    // The filters for fetching the available filter fields for a list of product
    defaultFilterFilters: {
      hierarchy: [
        Hierarchy.MOUNTAIN_BIKES,
        Hierarchy.FAT_TIRE_BIKES,
        Hierarchy.GRAVEL_ROAD_BIKES,
        Hierarchy.EBIKES,
      ],
    },
    // default filter fields for fetching data
    defaultSearchFilters: {
      hierarchy: [
        Hierarchy.MOUNTAIN_BIKES,
        Hierarchy.FAT_TIRE_BIKES,
        Hierarchy.GRAVEL_ROAD_BIKES,
        Hierarchy.EBIKES,
      ],
    },
  },
  [Hierarchy.ARCHIVED]: {
    route: routes.archivedBikes,
    defaultFilterFilters: {
      hierarchy: [
        Hierarchy.MOUNTAIN_BIKES,
        Hierarchy.FAT_TIRE_BIKES,
        Hierarchy.GRAVEL_ROAD_BIKES,
        Hierarchy.EBIKES,
      ],
      status: 'Archived',
    },
    defaultSearchFilters: {
      hierarchy: [
        Hierarchy.MOUNTAIN_BIKES,
        Hierarchy.FAT_TIRE_BIKES,
        Hierarchy.GRAVEL_ROAD_BIKES,
        Hierarchy.EBIKES,
      ],
      status: 'Archived',
    },
  },
  [Hierarchy.MOUNTAIN_BIKES]: {
    route: routes.mountainBikes,
    defaultFilterFilters: {
      hierarchy: Hierarchy.MOUNTAIN_BIKES,
    },
    defaultSearchFilters: {
      hierarchy: Hierarchy.MOUNTAIN_BIKES,
    },
  },
  [Hierarchy.GRAVEL_ROAD_BIKES]: {
    route: routes.roadBikes,
    defaultFilterFilters: {
      hierarchy: Hierarchy.GRAVEL_ROAD_BIKES,
    },
    defaultSearchFilters: {
      hierarchy: Hierarchy.GRAVEL_ROAD_BIKES,
    },
  },
  [Hierarchy.FAT_TIRE_BIKES]: {
    route: routes.fatTireBikes,
    defaultFilterFilters: {
      hierarchy: Hierarchy.FAT_TIRE_BIKES,
    },
    defaultSearchFilters: {
      hierarchy: Hierarchy.FAT_TIRE_BIKES,
    },
  },
  [Hierarchy.EBIKES]: {
    route: routes.eBikes,
    defaultFilterFilters: {
      hierarchy: Hierarchy.EBIKES,
    },
    defaultSearchFilters: {
      hierarchy: Hierarchy.EBIKES,
    },
  },
  [Hierarchy.BIKE_ACCESSORIES]: {
    route: routes.accessories,
    defaultFilterFilters: {
      hierarchy: Hierarchy.BIKE_ACCESSORIES,
    },
    defaultSearchFilters: {
      hierarchy: Hierarchy.BIKE_ACCESSORIES,
    },
  },
  [Hierarchy.GEAR]: {
    route: routes.gear,
    defaultFilterFilters: {
      hierarchy: [
        Hierarchy.BIKE_PARTS,
        Hierarchy.BIKE_ACCESSORIES,
        Hierarchy.CLOTHING,
      ],
    },
    defaultSearchFilters: {
      hierarchy: [
        Hierarchy.BIKE_PARTS,
        Hierarchy.BIKE_ACCESSORIES,
        Hierarchy.CLOTHING,
      ],
    },
  },
  [Hierarchy.ARCHIVED_GEAR]: {
    route: routes.archivedGear,
    defaultFilterFilters: {
      hierarchy: [
        Hierarchy.BIKE_PARTS,
        Hierarchy.BIKE_ACCESSORIES,
        Hierarchy.CLOTHING,
      ],
      status: 'Archived',
    },
    defaultSearchFilters: {
      hierarchy: [
        Hierarchy.BIKE_PARTS,
        Hierarchy.BIKE_ACCESSORIES,
        Hierarchy.CLOTHING,
      ],
      status: 'Archived',
    },
  },
  [Hierarchy.BIKE_PARTS]: {
    route: routes.parts,
    defaultFilterFilters: {
      hierarchy: Hierarchy.BIKE_PARTS,
    },
    defaultSearchFilters: {
      hierarchy: Hierarchy.BIKE_PARTS,
    },
  },
  [Hierarchy.CLOTHING]: {
    route: routes.clothing,
    defaultFilterFilters: {
      hierarchy: Hierarchy.CLOTHING,
    },
    defaultSearchFilters: {
      hierarchy: Hierarchy.CLOTHING,
    },
  },
  [Hierarchy.JOURNAL]: {
    route: routes.journal,
    defaultFilterFilters: {},
    defaultSearchFilters: {},
  },
  [Hierarchy.ARTICLE]: {
    route: routes.articles,
    defaultFilterFilters: {},
    defaultSearchFilters: {},
  },

  [Hierarchy.VIDEO]: {
    route: routes.videos,
    defaultFilterFilters: {},
    defaultSearchFilters: {},
  },
  [Hierarchy.STORY]: {
    route: routes.stories,
    defaultFilterFilters: {},
    defaultSearchFilters: {},
  },
}

export interface ProductResponse {
  totalHits: number
  searchHits: {
    content: Product
    highlightFields: Record<string, string[]>
  }[]
}

interface Sequenced {
  sequencePim: string
}

export interface FrameSpec extends Sequenced {
  'Frame Spec Title': string
  'Frame Spec Content': string
}

interface FrameFeature extends Sequenced {
  'Frame Feature Content': string
  'Frame Feature Title': string
}

export interface BuildKitPiece extends Sequenced {
  'Part of Bike': string
  'Component Description': string
  'Build Kit Component': string
}

interface UPC extends Sequenced {
  'UPC Category': string
  'UPC Main': string
  'UPC Value': string
}

interface SKU {
  fullRecord: {
    'Frame Type': string
    'Wheel Size': string[]
    'Prop 65 Text': string
    'Intended Age Warning': string
    Color: string[]
    'Choking Hazard Warning': string
    UPCs: UPC[]
    Brand: string
    'Consumer Brand SKU Status': string
    'Fork Type': string
    'MSRP Price DC10': string
    'Model Code': string
    'Frame Material': string
    SKU: string
    'Experience QBrand Bikes': string[]
    'Online Date': string
    'RIS Selector Name': string
  }
  fullRecordJson: string | null
  SKU: string
  'Online Date': number | Date
  'Model Code': string
  'MSRP Price DC10': number
}

export interface Geometry extends Sequenced {
  'BB Drop Metric': string
  'Geometry Size': string
  'Head Tube Length Metric': string
  'Seat Tube Length Metric': string
  'Fork Offset Metric': string
  'Wheelbase Metric': string
  'Reach Metric': string
  'Fork Length Metric': string
  'Top Tube Effective Metric': string
  'Standover Metric': string
  'Seatpost Offset Metric': string
  'Chainstay Length Metric': string
  'Rider Height Metric': string
  'Head Tube Angle': string
  'Handlebar Width': string
  'Geometry Group': string
  'Stem Length': string
  'Seat Tube Angle': string
  'Stack Metric': string
  'Crank Length': string
  'BB Center to Center Metric': string
}

export type HierarchyBody = {
  code: string
  description: string
  sequenceNum: number
  parentCode: string
}

export type Product = {
  id: number
  name: string
  description: string
  code: string
  lowestPrice: number
  highestPrice: number
  platform: string | null
  skus: SKU[]
  status: string
  hierarchy: HierarchyBody[]
  fullRecord: {
    Platform: string | null
    'Frame Specs': FrameSpec[] | null
    'Buy Local Now': string | null
    repo: string | null
    'Consumer Model Bullets': string[] | null
    'Consumer Brand Model Status': string | null
    'Geometry Drawing File': string | null
    'Geometry Note': string[] | null
    'SEO Title': string | null
    'Frame Features': FrameFeature[] | null
    'Consumer Model Images': string[] | null
    Geometry: Geometry[] | null
    'Thumbnail Image': string | null
    'Consumer Brand Hierarchy': string[] | null
    'SEO Description': string | null
    Video: string | null
    'Model Code': string | null
    'URL Title': string | null
    QBrand: string | null
    'Consumer Model Description': string | null
    'Consumer Model Name': string | null
    'Build Kit': BuildKitPiece[] | null
    'Tech Documents': string[] | null
  }
}

export type Platform = {
  name: string
  lowestPrice: number
  highestPrice: number
  numModels: number
  images: string[]
  hierarchy: string
}

export type ServerErrorResponse = {
  error: string
  message: string
  path: string
  status: number
  timestamp: string
}

export type SortOption = {
  label: string
  value: string
  ascending: boolean
  useKeywordSearch: boolean
}

export const JOURNAL_ENTRY_TYPES = {
  article_article_Entry: {
    label: 'Article',
    linkTo: routes.articles,
  },
  video_video_Entry: {
    label: 'Video',
    linkTo: routes.videos,
  },
  story_story_Entry: {
    label: 'Story',
    linkTo: routes.stories,
  },
}
