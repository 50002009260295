import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { FixedHeightCarousel } from './carousel/FixedHeightCarousel'

interface ProductCarouselSectionProps {
  className?: string
  title: string
  media: {
    src: string
    alt: string
  }[]
}

const useStyles = createUseStyles((theme: Theme) => ({
  productCarouselSection: {
    ...theme.typography.bebas(31),
    color: theme.colors.secondaryGrey700,
    width: '100%',
    padding: theme.spacing.L,
    paddingTop: 0,
  },
}))

export const ProductCarouselSection: React.FC<ProductCarouselSectionProps> = ({
  title,
  media,
  className,
}) => {
  const classes = useStyles()
  return (
    <div
      className={className}
      role="region"
      aria-label="bikes in action carousel"
    >
      <h2 className={classes.productCarouselSection}>{title}</h2>
      <FixedHeightCarousel slidesToShow={1}>
        {media.map((item, i) => (
          <img
            key={i + item.src}
            src={item.src}
            alt={item.alt}
            role="group"
            aria-label={`slide ${i + 1} of ${media.length}`}
          />
        ))}
      </FixedHeightCarousel>
    </div>
  )
}
