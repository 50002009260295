import React, { useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'

interface DevKitConfig {
  apiBaseUrl: string
  apiPublicKey: string
  googleMapsApiKey: string
  cartReplacesSelector?: string
  addToCartSelector?: string
  availabilitySelector?: string
  cartIconSelector?: string
  checkoutSelector?: string
  drawerSelector?: string
  findRetailersSelector?: string
}

declare global {
  interface Window {
    QDKDealerLocator: (
      vendor: string,
      selector: string,
      config: Pick<
        DevKitConfig,
        'apiBaseUrl' | 'apiPublicKey' | 'googleMapsApiKey'
      >,
    ) => void
  }
}

export const DealersEmbed: React.FC = () => {
  const classes = useDevkitStyles()
  const [rootId, setRootId] = useState<string>()

  const onDealerRootMounted = useCallback(
    (el: HTMLDivElement) => {
      if (el && !rootId) {
        setRootId(el.id)
      }
    },
    [rootId],
  )

  const {
    REACT_APP_DEVKIT_VENDOR,
    REACT_APP_DEVKIT_API_BASE_URL,
    REACT_APP_DEVKIT_API_PUBLIC_KEY,
    REACT_APP_DEVKIT_GOOGLE_MAPS_API_KEY,
  } = process.env

  useEffect(() => {
    if (
      !rootId ||
      !REACT_APP_DEVKIT_VENDOR ||
      !REACT_APP_DEVKIT_API_BASE_URL ||
      !REACT_APP_DEVKIT_API_PUBLIC_KEY ||
      !REACT_APP_DEVKIT_GOOGLE_MAPS_API_KEY
    ) {
      return
    }

    window.QDKDealerLocator(REACT_APP_DEVKIT_VENDOR, `#${rootId}`, {
      apiBaseUrl: REACT_APP_DEVKIT_API_BASE_URL,
      apiPublicKey: REACT_APP_DEVKIT_API_PUBLIC_KEY,
      googleMapsApiKey: REACT_APP_DEVKIT_GOOGLE_MAPS_API_KEY,
    })
  }, [
    rootId,
    REACT_APP_DEVKIT_VENDOR,
    REACT_APP_DEVKIT_API_BASE_URL,
    REACT_APP_DEVKIT_API_PUBLIC_KEY,
    REACT_APP_DEVKIT_GOOGLE_MAPS_API_KEY,
  ])

  return (
    <div className={classes.devkitTheme}>
      <div id="dealers-root" ref={onDealerRootMounted}></div>
    </div>
  )
}
const useDevkitStyles = createUseStyles((theme: Theme) => ({
  devkitTheme: {
    '& > div': {
      ...theme.traits.gridLayout,
    },
    '& > div:first-child > h1': {
      display: 'none',
    },
    '& .map': {
      display: 'none',
      gridColumn: '1 / -1',
    },
    '& .page-error, & h3': {
      textAlign: 'center',
      gridColumn: '1 / -1',
      display: 'flex',
      justifyContent: 'center',
      border: 'none',
    },
    '& .location-search': {
      gridColumn: '1 / -1',
      maxWidth: 350,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .location-container': {
      ...theme.typography.bebas(16),
      color: theme.colors.secondaryGrey900,
      marginBottom: 2,
      letterSpacing: 0.2,
    },
    '& .address-form-container': {
      paddingLeft: 0,
    },
    '& .address-form-container > form': {
      flexDirection: 'column',
    },
    '& .address-form-container input': {
      ...theme.typography.urwInput(16),
      padding: '8px 16px',
      color: theme.colors.secondaryGrey900,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.colors.secondaryGrey300,
      borderRadius: 2,
      marginBottom: theme.spacing.M,
    },
    '& .location-search button': {
      backgroundColor: theme.colors.primaryRed,
      marginLeft: 0,
      '&:after': {
        content: '"Update"',
      },
    },
    '& .dealers': {
      ...theme.traits.gridColumn({
        lgCols: 6,
        lgStart: 4,
      }),
      gridColumn: '1 / -1',
      margin: 0,
      marginTop: theme.spacing.XXL,
      paddingTop: 0,
    },
    '& .qdk-dealer': {
      paddingTop: theme.spacing.L,
      paddingBottom: theme.spacing.L,
    },
    '& button': {
      ...theme.typography.bebas(16),
      ...theme.traits.clickable,
      padding: '4px 14px',
      color: theme.colors.primaryRed,
      borderColor: theme.colors.primaryRed,
      borderStyle: 'solid',
      borderWidth: 2,
      borderRadius: 2,
      background: 'none',
    },
    '& .load-more': {
      gridColumn: '1 / -1',
      '& > button': {
        ...theme.traits.clickable,
        backgroundColor: theme.colors.primaryRed,
      },
    },
    '& .dealer-name': {
      ...theme.typography.bebas(24),
      color: theme.colors.secondaryGrey900,
      mmarginBottom: 2,
    },
    '& .dealer-meta': {
      ...theme.typography.urw(18),
      color: theme.colors.secondaryGrey900,
      marginBottom: 2,
      textTransform: 'uppercase',
    },
    '& .dealer-address': {
      textTransform: 'uppercase',
    },
    '& .dealer-details a': {
      color: theme.colors.primaryRed,
    },
    '& .hide-show-details > a': {
      ...theme.traits.clickable,
      ...theme.typography.urwInput(18),
      color: theme.colors.primaryRed,
      textDecoration: 'none',
    },
    '& .dealer-stock-status': {
      display: 'none',
    },
    '& .qdk-loader-wrapper': {
      display: 'none',
    },
  },
}))
